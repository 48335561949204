import { QueryClient, useQuery } from "@tanstack/react-query";
import React, { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  PageContentStyled,
  PageStyled,
} from "./components/containers/pageContentStyled";
import Footer from "./components/footer";
import { GlobalStyle } from "./components/globalStyle.styled";
import {
  HideOnMobileWrapper,
  ShowOnMobileWrapper,
} from "./components/hideOnMobile";
import ScrollToTop from "./components/misc/scrollToTop";
import Navbar from "./components/navbar/navbar";
import Sidebar from "./components/sidebar/sidebar";
import {
  getAllCoins,
  getMostCompared,
  getTrendingTop10,
} from "./components/stream";
import { Theme } from "./components/theme.styled";
import { AllCoinsContext, PopupVisibleContext, TrendsContext, StocksContext } from "./context";
import { LoadingPageStyled } from "./pages/loadingPage.styled";
import Halving from "./pages/halving";
import ErrorBoundary from "./components/misc/errorBoundary";
import { SpeedInsights } from "@vercel/speed-insights/react";
import { Analytics } from "@vercel/analytics/react";
import { DreamfolioGradient } from "./components/portfolio/dreamfolioGradient.styled";
import Cookies from "js-cookie";
import axios from "axios";

export const queryClient = new QueryClient();

function App() {
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Enter" || event.key === "ArrowDown") {
        // Prevent default navigation behavior
        event.preventDefault();
        event.stopPropagation();
        // Add custom logic here if needed
      }
    };

    // Attach event listener
    document.addEventListener("keydown", handleKeyDown);

    // Clean up the event listener
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const [isPopupVisible, setPopupVisible] = React.useState(false);

  const { pathname } = useLocation();

  const { data, status } = useQuery(["allItems"], () => getAllCoins(), {
    staleTime: Infinity,
    onSuccess: (data) => {},
  });

  const { data: mcData, status: mcStatus } = useQuery(
    ["mostComparedParent"],
    () => getMostCompared(),
    {
      staleTime: Infinity,
    }
  );

  const { data: trendingData, status: trendingStatus } = useQuery(
    ["trendingParent"],
    () => getTrendingTop10(),
    {
      staleTime: Infinity,
    }
  );

  const { data: stocksData, status: stocksStatus } = useQuery(
    ["stocksList"],
    () => axios.get("https://api.comparemcap.com/list-company").then(res => res.data),
    {
      staleTime: Infinity,
    }
  );

  return (
    <Theme>
      <SpeedInsights />
      <Analytics />
      <ErrorBoundary>
        {status === "success" &&
        mcStatus === "success" &&
        trendingStatus === "success" &&
        stocksStatus === "success" ? (
          <AllCoinsContext.Provider value={data}>
            <StocksContext.Provider value={stocksData}>
              <TrendsContext.Provider value={{ mcData, trendingData }}>
                <ToastContainer
                  toastStyle={{ backgroundColor: "#453955" }}
                  theme="dark"
                />
                <GlobalStyle />
                <ScrollToTop />
                {pathname.includes("dreamfolio") && <DreamfolioGradient />}
                {!pathname.includes("/terminal") && (
                  <React.Fragment>
                    {!pathname.includes("halving") && <Navbar />}
                  </React.Fragment>
                )}
                {pathname.includes("/halving") ? (
                  <Halving />
                ) : (
                  <PageStyled
                    style={
                      pathname.includes("/compare/") ? { maxWidth: "85rem" } : {}
                    }
                  >
                    <PageContentStyled
                      noMargin={
                        (pathname.includes("/coin/") &&
                          (pathname.includes("roi") ||
                            pathname.includes("compare"))) ||
                        pathname.includes("terminal") ||
                        pathname.includes("holder-compare")
                      }
                    >
                      <PopupVisibleContext.Provider
                        value={{ isPopupVisible, setPopupVisible }}
                      >
                        {status === "success" && (
                          <AllCoinsContext.Provider value={data}>
                            <Outlet />
                          </AllCoinsContext.Provider>
                        )}
                      </PopupVisibleContext.Provider>
                    </PageContentStyled>
                  </PageStyled>
                )}
                {!pathname.includes("/terminal") ? (
                  pathname.includes("/coin") ||
                  pathname.includes("holder-compare") ? (
                    <HideOnMobileWrapper>
                      <Footer />
                    </HideOnMobileWrapper>
                  ) : (
                    <Footer />
                  )
                ) : null}
              </TrendsContext.Provider>
            </StocksContext.Provider>
          </AllCoinsContext.Provider>
        ) : (
          <LoadingPageStyled>
            <img src={"/logos/comparemarketcap-logo.svg"} alt="logo" />
          </LoadingPageStyled>
        )}
      </ErrorBoundary>
    </Theme>
  );
}

export default App;