import styled from "styled-components";
import {Column, FullRow} from "../containers/flexbox.styled";
import {HorizontalSeperator} from "../containers/boxes.styled";

export const StyledDedicatedStatsWrapper = styled(Column)`
  gap: 1rem;
  align-items: start;
  width: 100%;
`

export const StyledDedicatedStats = styled(Column)`
  gap: 0.8rem;
  align-items: start;
  width: 100%;
  
  ${FullRow}{
    justify-content: space-between;
    & > *:last-child{
      text-align: end;
    }
  }
  
  ${HorizontalSeperator}{
    height: 0.05rem;
  }
`