import styled from "styled-components";
import { FullBox } from "../containers/boxes.styled";
import { paddings } from "../paddings.styled";

export const StyledSmallList = styled(FullBox)`
  height: 8rem;
  padding: ${paddings.MID};
  @media (max-width: 70rem) {
    min-width: 18rem;
  }
  width: 100%;

  img {
    width: 1rem;
  }
`;

export const StyledSmallListLoader = styled(FullBox)`
  height: 8rem;
  padding: ${paddings.MID};
  @media (max-width: 70rem) {
    min-width: 18rem;
  }
  background: ${({ theme }) =>
    `linear-gradient(-90deg, ${theme.colors.LEVEL} 25%, ${theme.colors.SECONDARY} 50%, ${theme.colors.LEVEL} 75%)`};
  background-size: 200% 100%; /* Double the width, same height */
  animation: loading 2s infinite;
  @keyframes loading {
    0% {
      background-position: 200% 0;
    }
    100% {
      background-position: -200% 0;
    }
  }
`;
