export const defaultCoins = {
  ethereum: {
    image:
      "https://assets.coingecko.com/coins/images/279/large/ethereum.png?1595348880",
    coinName: "Ethereum",
    symbol: "ETH",
    id: "ethereum",
  },
  bitcoin: {
    image:
      "https://assets.coingecko.com/coins/images/1/large/bitcoin.png?1547033579",
    coinName: "Bitcoin",
    symbol: "BTC",
    id: "bitcoin",
  },
  apple: {
    image: "https://logo.clearbit.com/www.apple.com",
    companyName: "Apple Inc.",
    symbol: "AAPL",
    id: "AAPL-stock",
  },
  bnb: {
    image:
      "https://assets.coingecko.com/coins/images/825/large/bnb-icon2_2x.png?1696501970",
    coinName: "BNB",
    symbol: "BNB",
    id: "binancecoin",
  },
  gmx: {
    image:
      "https://assets.coingecko.com/coins/images/18323/large/arbit.png?1696517814",
    coinName: "GMX",
    symbol: "gmx",
    id: "arbitrum",
  },

  bobo: {
    id: "bobo-coin-eth",
    marketcap: 57870300,
    coinName: "BOBO Coin",
    symbol: "BOBO",
    price: 8.386999752474367e-7,
    totalSup: 69000000176128,
    maxSup: 69000000176128,
    high24: null,
    low24: null,
    ath: null,
    image: "https://assets.coingecko.com/coins/images/30388/large/bobologo.png",
    contractAddress: "0xb90b2a35c65dbc466b04240097ca756ad2005295",
    blockChain: "eth",
  },
};

export const defaultAthMarketCaps = [
  {
    id: "bone-shibaswap",
    marketcap: 3563813413,
  },
  {
    id: "bobo-coin-eth",
    marketcap: 245000000,
  },
  {
    id: "floki",
    marketcap: 3500000000,
  },
];
