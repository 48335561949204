import { useEffect, useRef, useState } from "react";
import { BiSearch } from "react-icons/bi";
import { Column } from "../containers/flexbox.styled";
import { Icon } from "../icon.styled";
import { paddings } from "../paddings.styled";
import { borderRads, textSizes } from "../sizes";
import DropdownItem, { PromoteButton } from "./dropdownItem";
import {
  DropdownMenuSearchInput,
  StyledDropdownMenu,
} from "./dropdownMenu.styled";
import { Paragraph, SmallText } from "../texts.styled";
import Switch from "../misc/switch";
import { theme } from "../theme.styled";
import { formatNumber, graphFormatter } from "../../util/numberFormatter";
import MultiSwitch from "../misc/multiSwitch";
import { useNavigate } from "react-router-dom";

export default function DropdownMenu({
  status,
  ison,
  dropdownItems,
  setCoin,
  width,
  height,
  marginTop,
  left,
  isBox,
  backgroundColor,
  noPromotion,
  dontAutoFocus,
  hidePromote,
  isDreamfolio,
  isHolderCompare,
  mode,
  setMode,
  showStockSwitch = false,
  customStyle,
}) {
  const [searchQuery, setSearchQuery] = useState("");
  let allItems = dropdownItems;
  const [searchResults, setSearchResults] = useState(allItems);
  const scrollRef = useRef(null);
  let inputRef = useRef(null);

  const [athMode, setAthMode] = useState(0);
  const [customMarketCap, setCustomMarketCap] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    if (ison === true) inputRef && inputRef.current.focus();
  }, [ison]);

  useEffect(() => {
    if (customMarketCap < 0) {
      setCustomMarketCap(customMarketCap * -1);
    }

    //if there are any signs, remove them
    if (customMarketCap.includes("-")) {
      setCustomMarketCap(customMarketCap.replace("-", ""));
    }
    if (customMarketCap.includes("+")) {
      setCustomMarketCap(customMarketCap.replace("+", ""));
    }

    //remove all characyerts except the numbers from 0-9
    setCustomMarketCap(customMarketCap.replace(/[^0-9]/g, ""));
  }, [customMarketCap]);

  const handleSelection = (item) => {
    // add athMode field to item
    if (isDreamfolio) {
      if (athMode === 1) {
        item.athMode = true;
      } else {
        item.athMode = false;
      }
    }

    setCoin(item);
  };

  function updateSearchQuery(event) {
    let val = event.target.value.toLowerCase();
    setSearchQuery(val);

    const filteredAndSortedItems = [];

    for (let i = 0; i < allItems.length; i++) {
      const item = allItems[i];
      const isStock = item.id.endsWith('-stock');

      // Check if symbol or name starts with the search query
      const symbolMatch = item.symbol.toLowerCase().startsWith(val);
      const nameMatch = isStock
        ? item.companyName?.toLowerCase().startsWith(val)
        : item.coinName?.toLowerCase().startsWith(val);
      const contractMatch = item.contractAddress?.toLowerCase().startsWith(val);

      if (symbolMatch || nameMatch || contractMatch) {
        filteredAndSortedItems.push({
          ...item,
          index: symbolMatch ? 0 : nameMatch ? 1 : 2
        });
      }
    }

    // Sort by index (symbol matches first, then name matches, then contract matches)
    filteredAndSortedItems.sort((a, b) => a.index - b.index);
    setSearchResults(filteredAndSortedItems);
  }

  const firstIndex = (item) => {
    const symbolIndex = item.symbol.toLowerCase().indexOf(searchQuery.toLowerCase());
    const nameIndex = item.coinName
      ? item.coinName.toLowerCase().indexOf(searchQuery.toLowerCase())
      : Infinity;
    const contractIndex = item.contractAddress
      ? item.contractAddress.toLowerCase().indexOf(searchQuery.toLowerCase())
      : Infinity;

    if (symbolIndex === 0 || nameIndex === 0 || contractIndex === 0) return 0;
    return Math.min(
      symbolIndex === -1 ? Infinity : symbolIndex,
      nameIndex === -1 ? Infinity : nameIndex,
      contractIndex === -1 ? Infinity : contractIndex
    );
  };

  // const promotedCoin = {
  //   id: "homeless-sol",
  //   coinName: "Live on Street until we hit 50M",
  //   symbol: "HOMELESS",
  //   image:
  //     "https://qwz547u5ood5gucs.public.blob.vercel-storage.com/images/coin-logos/homeless-sol.webp",
  // };
  const promotedCoin = null;


  useEffect(() => {
    scrollRef.current?.scrollTo(0, 0);
  }, [searchQuery]);

  return (
    <StyledDropdownMenu
      backgroundColor={backgroundColor || "black"}
      isBox={isBox}
      left={left}
      height={height}
      width={width}
      ison={ison ? 1 : 0}
      marginTop={marginTop}
      style={customStyle}
    >
      <Column style={{
        position: "sticky",
        top: "0",
        zIndex: 4,
        backgroundColor: backgroundColor || "black" // Match parent background
      }}>
        <DropdownMenuSearchInput>
          <Icon padding={`0 0 0 ${paddings.MID}`} size={textSizes.fontSizes.BIG}>
            <BiSearch />
          </Icon>
          <input
            ref={inputRef}
            autoFocus={dontAutoFocus ? false : true}
            value={searchQuery}
            onChange={(e) => updateSearchQuery(e)}
            placeholder="Search"
          />
        </DropdownMenuSearchInput>

        {showStockSwitch && !isDreamfolio && !isHolderCompare && (
          <div className="switch-container">
            <Switch
              style={{ width: "100%" }}
              // fullWidth={true}
              options={[
                { name: "Crypto", onClick: () => setMode("crypto") },
                { name: "Stocks", onClick: () => setMode("stocks") }
              ]}
              backgroundColor="#1E1825"
              selectedColor="#2D2435"
              selectedIndexs={mode === "stocks" ? 1 : 0}
            />
          </div>
        )}

        {isDreamfolio && (
          <div style={{
            backgroundColor: theme.colors.OLDLEVEL,
            width: `calc(100% - 1rem)`,
            marginRight: "auto",
          }}>
            <Switch
              fullWidth={true}
              options={[
                { name: "Current", onClick: () => setAthMode(0) },
                { name: "ATH", onClick: () => setAthMode(1) },
                { name: "Custom", onClick: () => setAthMode(2) },
              ]}
              backgroundColor={theme.colors.OLDLEVEL}
              selectedColor={theme.colors.OLDLEVEL2}
              selectedIndexs={athMode}
            />
          </div>
        )}
      </Column>

      <div ref={scrollRef} style={{
        // overflowY: "scroll",
        paddingTop: "1.4rem",
        height: `calc(100% - 3rem)`,
      }}>
        <Column style={isDreamfolio ? { marginTop: "1rem" } : {}}>
          {!noPromotion &&
            (promotedCoin ? (
              <DropdownItem
                isPromoted={true}
                key={-1}
                coin={promotedCoin}
                onClick={() => {
                  navigate("/coin/homeless-sol");
                }}
              />
            ) : (
              !hidePromote && (
                <PromoteButton>Promote your project here</PromoteButton>
              )
            ))}

          {status === "success" && (isDreamfolio ? athMode !== 2 : true) &&
            (searchQuery === "" ?
              allItems?.slice(0, 25).map((item, index) => {
                let isDisabled = isDreamfolio && athMode === 1 && !item.athDate;
                return (
                  <div style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    position: "relative",
                    paddingLeft: "1rem",
                  }} key={index}>
                    <DropdownItem
                      isDreamfolio={isDreamfolio}
                      coin={item}
                      disabled={isDisabled}
                      onClick={isDisabled ? () => { } : () => handleSelection(item)}
                    />
                    {isDisabled && (
                      <SmallText
                        weight={textSizes.fontWeights.BOLD}
                        size={textSizes.fontSizes.SMALLER}
                        style={{
                          position: "absolute",
                          width: "100%",
                          textAlign: "center",
                        }}
                      >
                        ATH not available
                      </SmallText>
                    )}
                  </div>
                );
              }) :
              searchResults?.slice(0, 100).map((item, index) => {
                let isDisabled = isDreamfolio && athMode === 1 && !item.athDate;
                return (
                  <div style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    position: "relative",
                    paddingLeft: "1rem",
                  }} key={index}>
                    <DropdownItem
                      isDreamfolio={isDreamfolio}
                      coin={item}
                      disabled={isDisabled}
                      onClick={isDisabled ? () => { } : () => handleSelection(item)}
                    />
                    {isDisabled && (
                      <SmallText
                        weight={textSizes.fontWeights.BOLD}
                        size={textSizes.fontSizes.SMALLER}
                        style={{
                          position: "absolute",
                          width: "100%",
                          textAlign: "center",
                        }}
                      >
                        ATH not available
                      </SmallText>
                    )}
                  </div>
                );
              })
            )
          }

          {isDreamfolio && athMode === 2 && (
            <Column gap="0.5rem" width={"100%"}>
              <MultiSwitch
                options={[
                  { name: "50M", onClick: () => setCustomMarketCap("50000000") },
                  { name: "100M", onClick: () => setCustomMarketCap("100000000") },
                  { name: "250M", onClick: () => setCustomMarketCap("250000000") },
                  { name: "500M", onClick: () => setCustomMarketCap("500000000") },
                  { name: "1B", onClick: () => setCustomMarketCap("1000000000") },
                  { name: "2B", onClick: () => setCustomMarketCap("2000000000") },
                ]}
                selectedColor={theme.colors.LEVEL2}
                unSelectedColor={theme.colors.LEVEL}
                adaptiveWidth={true}
                isDreamfolio={true}
              />

              <input
                placeholder="Custom Market Cap"
                value={Number(customMarketCap).toLocaleString()}
                onChange={(e) => setCustomMarketCap(e.target.value)}
                style={{
                  width: "100%",
                  height: "3rem",
                  backgroundColor: theme.colors.SECONDARY,
                  border: "none",
                  borderRadius: "0.5rem",
                  marginTop: "0.5rem",
                  color: "white",
                  paddingLeft: "0.5rem",
                }}
              />
              <button
                style={{
                  height: "3rem",
                  backgroundColor: theme.colors.LEVEL2,
                  width: "100%",
                  border: "none",
                  borderRadius: borderRads.SMALL,
                }}
                onClick={() => {
                  setCoin({
                    id: "custom" + new Date().getTime(),
                    coinName: graphFormatter(customMarketCap),
                    symbol: graphFormatter(customMarketCap),
                    marketCap: customMarketCap,
                    image: "logos/empty-coin.svg",
                  });
                }}
              >
                Compare with Market Cap
              </button>
            </Column>
          )}
        </Column>
      </div>
    </StyledDropdownMenu>
  );
}