import styled from "styled-components";
import { SwitchOption } from "./switchOption.styled";
import { borderRads, borderWidths, textSizes } from "../sizes";

export const StyledSwitch = styled.div`
  width: ${({ fullWidth }) => (fullWidth ? "100%" : "fit-content")};
  display: flex;
  align-items: center;
  background-color: ${({ theme, backgroundColor }) =>
    backgroundColor || theme.colors.LEVEL};
  border-radius: ${borderRads.SMALL};
  overflow: hidden;
  align-self: ${({ center }) => (center ? "center" : "start")};
  height: ${({ isCompare }) => (isCompare ? "2.3rem" : "2rem")};
  border: ${({ isCompare }) => (isCompare ? "0.13rem solid #711a75" : "none")};

  ${SwitchOption} {
    width: ${({ fullWidth, width }) => (fullWidth ? "100%" : width || "7rem")};
    position: relative;
  }

  @media (max-width: 40rem) {
    height: ${({ isCompare }) => (isCompare ? "2.1rem" : "2.5rem")};

    ${SwitchOption} {
      padding-right: 0.2rem;
      padding-left: 0.2rem;
    }
  }
`;
