import styled from "styled-components";
import {Column} from "../containers/flexbox.styled";
import {paddings} from "../paddings.styled";
import {borderRads} from "../sizes";

export const StyledShortBoxContentWrapper = styled(Column)`
  gap: 1rem;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: ${({withDesktopBackground, theme}) => withDesktopBackground && theme.colors.LEVEL};
  padding: ${({withDesktopBackground}) => withDesktopBackground && paddings.MID};
  border-radius: ${({withoutBorderRadius}) => withoutBorderRadius ? "none" : borderRads.SMALL};
  
  @media(max-width: 70rem){
    width: 100%;
  }
  
  @media(max-width: 40rem){
    border-radius: ${borderRads.SMALL};
    width: 100%;
    padding: ${paddings.MID};
    background-color: ${({withMobileBackground, theme}) => withMobileBackground && theme.colors.LEVEL};
  }
`