import { Title } from "chart.js";
import { Column, Row } from "../containers/flexbox.styled";
import { Icon } from "../icon.styled";
import { textSizes } from "../sizes";
import { BigText, Header, PageTitle, SmallText } from "../texts.styled";
import {
  StyledHalvingResultColumn,
  StyledHalvingResultThree,
} from "./halvingBox.styled";
import { IoMdTime } from "react-icons/io";
import {
  HorizontalSeperator,
  VerticalSeperator,
} from "../containers/boxes.styled";
import { HideOnMobileWrapper } from "../hideOnMobile";
import React, { useContext, useEffect, useState } from "react";
import { HalvingContext, HalvingDateContext } from "../../context";
import { epoch, formatNumber } from "../../util/numberFormatter";
import { HalvingGraphNumber } from "./halving.styled";

export default function HalvingResultThree({ setTotalX }) {
  const { halvingCoins, coin2 } = useContext(HalvingContext);
  let targetCoin = halvingCoins.find((coin) => coin.id === coin2.id);

  return (
    <StyledHalvingResultThree>
      <HalvingResultColumn afterXMonths={6} />

      <VerticalSeperator height={"8rem"} />

      <HalvingResultColumn afterXMonths={11} />

      <VerticalSeperator height={"8rem"} />

      <HalvingResultColumn
        setTotalX={setTotalX}
        afterXMonths={18}
        hideSeperator={true}
      />
    </StyledHalvingResultThree>
  );
}

function HalvingResultColumn({ afterXMonths, hideSeperator, setTotalX }) {
  const [date, setDate] = useState(null);
  const [multiplier, setMultiplier] = useState(null);

  const [loading, setLoading] = useState(true);
  const [monthsBetween, setMonthsBetween] = useState(null);

  const { bitcoinData, coinData, halvingCoins, coin2 } =
    useContext(HalvingContext);

  const oHalvingDate = useContext(HalvingDateContext);

  useEffect(() => {
    let myDate = new Date();
    let halvingDate = new Date(oHalvingDate);

    let oDate;
    let targetCoin = halvingCoins.find((coin) => coin.id === coin2.id);

    switch (afterXMonths) {
      case 6:
        oDate = new Date(targetCoin.date1);
        break;
      case 11:
        oDate = new Date(targetCoin.date2);
        break;
      case 18:
        oDate = new Date(targetCoin.date3);
        break;
      default:
        break;
    }

    let diff = getMonthsBetween(new Date("2020-05-11"), new Date(oDate));
    halvingDate.setMonth(halvingDate.getMonth() + diff);
    setDate(halvingDate);
  }, []);

  useEffect(() => {
    if (!date) return;
    setLoading(true);
    calculatePrice();
  }, [date]);

  function calculatePrice() {
    // let oDate = new Date(date);
    // oDate.setFullYear(oDate.getFullYear() - 4);

    let oDate;

    let targetCoin = halvingCoins.find((coin) => coin.id === coin2.id);

    switch (afterXMonths) {
      case 6:
        oDate = new Date(targetCoin.date1);
        break;
      case 11:
        oDate = new Date(targetCoin.date2);
        break;
      case 18:
        oDate = new Date(targetCoin.date3);
        break;
      default:
        break;
    }

    oDate = epoch(oDate);

    let myDate = findClosestTime(
      oDate,
      bitcoinData.price.map((item) => item.time)
    );

    let myBtcPrice = bitcoinData.price?.find(
      (item) => item.time === myDate
    ).value;

    let halvingDate = new Date("2020-05-01");
    let halvingDifference = dateDifferenceInDays(
      new Date(oHalvingDate),
      new Date()
    );
    halvingDate.setDate(halvingDate.getDate() - halvingDifference);
    halvingDate = epoch(halvingDate);

    halvingDate = findClosestTime(
      halvingDate,
      bitcoinData.price.map((item) => item.time)
    );

    let oBtcDate = bitcoinData?.price.find((item) => item.time === halvingDate);
    let oBtcPrice = oBtcDate.value;

    let times = myBtcPrice / oBtcPrice;
    setMultiplier(times);
    if (afterXMonths === 18) {
      setTotalX(formatNumber(times));
    }
    setMonthsBetween(getMonthsBetween(new Date("2020-05-01"), new Date(oDate)));

    setLoading(false);
  }

  return (
    multiplier &&
    !loading && (
      <StyledHalvingResultColumn>
        <Column>
          {afterXMonths === 18 && (
            <SmallText
              className="endOfCycle"
              size={textSizes.fontSizes.SMALLER}
              color={({ theme }) => theme.colors.LIGHT_TEXT}
            >
              Possible ATH of this cycle
            </SmallText>
          )}

          <Row className="date" justify="center" gap={"0.5rem"}>
            <Icon size={"2rem"}>
              <IoMdTime />
            </Icon>
            <Header>
              {date.getFullYear()} {monthNames[date.getMonth()]}
            </Header>
            <HalvingGraphNumber>
              {afterXMonths === 6 ? "1" : afterXMonths === 11 ? "2" : "3"}
            </HalvingGraphNumber>
          </Row>

          <SmallText
            size={textSizes.fontSizes.SMALLER}
            color={({ theme }) => theme.colors.LIGHT_TEXT}
          >
            {monthsBetween} months after halving
          </SmallText>
        </Column>
        <Column>
          <BigText>${formatNumber(coinData.price * multiplier)}</BigText>
          <BigText>({formatNumber(multiplier)}x)</BigText>
        </Column>
        {!hideSeperator && (
          <HorizontalSeperator style={{ marginTop: "0.5rem" }} width={"100%"} />
        )}
      </StyledHalvingResultColumn>
    )
  );
}

const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

function findClosestTime(epochTime, timeArray) {
  // Calculate the differences between the epoch time and each time in the array
  let differences = timeArray.map((time) => Math.abs(epochTime - time));

  // Find the index of the minimum difference
  let minDifferenceIndex = differences.indexOf(Math.min(...differences));

  // Return the closest time from the array
  return timeArray[minDifferenceIndex];
}

export function dateDifferenceInDays(date1, date2) {
  // Convert both dates to milliseconds
  let date1_ms = date1.getTime();
  let date2_ms = date2.getTime();

  // Calculate the difference in milliseconds
  let difference_ms = Math.abs(date2_ms - date1_ms);

  // Convert the difference to days
  let difference_days = Math.ceil(difference_ms / (1000 * 60 * 60 * 24));

  return difference_days;
}

function getMonthsBetween(startDate, endDate) {
  let startYear = startDate.getFullYear();
  let startMonth = startDate.getMonth();
  let endYear = endDate.getFullYear();
  let endMonth = endDate.getMonth();

  // Calculate the difference in months
  let diffMonths = (endYear - startYear) * 12 + (endMonth - startMonth);

  // Adjust if the end date is in the same month as or before the start date
  if (endDate.getDate() < startDate.getDate()) {
    diffMonths--;
  }

  return diffMonths;
}
