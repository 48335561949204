import React, { useContext } from "react";
import { Arc } from "@visx/shape";
import { Group } from "@visx/group";
import { Text } from "@visx/text";
import { scaleOrdinal } from "@visx/scale";
import { useSprings, animated } from "@react-spring/web";
import ReactECharts from "echarts-for-react";
import { SmallText } from "../texts.styled";
import { textSizes } from "../sizes";
import { HolderCompareCoinsContext } from "../../context";
import { formatNumber, graphFormatter } from "../../util/numberFormatter";

const innerData = [30, 70]; // Inner donut data
const outerData = [60, 40]; // Outer donut data

const colors = scaleOrdinal({
  domain: [0, 1],
  range: [
    "#0097D1",
    "rgba(255,255,255,0.1)",
    "#AAE600",
    "rgba(255,255,255,0.1)",
  ], // Color scheme
});

const AnimatedArc = animated(Arc);

const DonutChart = ({ width, height, isDesktop }) => {
  const innerTotal = outerData.reduce((a, b) => a + b, 0);
  const outerTotal = outerData.reduce((a, b) => a + b, 0);

  // Compute angles and percentages for outer arcs
  const outerArcs = outerData.map((d, i) => {
    const startAngle =
      i === 0
        ? 0
        : outerData
            .slice(0, i)
            .reduce((a, b) => a + (b / outerTotal) * 2 * Math.PI, 0);
    const endAngle = startAngle + (d / outerTotal) * 2 * Math.PI;
    return { startAngle, endAngle };
  });

  // Compute angles and percentages for inner arcs
  const innerArcs = innerData.map((d, i) => {
    const startAngle =
      i === 0
        ? 0
        : innerData
            .slice(0, i)
            .reduce((a, b) => a + (b / innerTotal) * 2 * Math.PI, 0);
    const endAngle = startAngle + (d / innerTotal) * 2 * Math.PI;
    return { startAngle, endAngle };
  });

  // Use useSprings to animate outer arcs
  const outerSprings = useSprings(
    outerArcs.length,
    outerArcs.map((arc) => ({
      from: { startAngle: arc.startAngle, endAngle: arc.startAngle },
      to: { startAngle: arc.startAngle, endAngle: arc.endAngle },
      config: { tension: 170, friction: 26 },
    }))
  );

  // Use useSprings to animate inner arcs
  const innerSprings = useSprings(
    innerArcs.length,
    innerArcs.map((arc) => ({
      from: { startAngle: arc.startAngle, endAngle: arc.startAngle },
      to: { startAngle: arc.startAngle, endAngle: arc.endAngle },
      config: { tension: 170, friction: 26 },
    }))
  );

  return (
    <svg width={width} height={height}>
      <Group top={height / 2} left={width / 2}>
        {/* Outer Donut */}
        {outerSprings.map((springProps, i) => (
          <AnimatedArc
            cornerRadius={3}
            key={`arc-outer-${i}`}
            innerRadius={isDesktop ? 50 : 42}
            outerRadius={isDesktop ? 70 : 57}
            fill={colors(i)}
            padAngle={0.025}
            {...springProps}
          />
        ))}
        {/* Inner Donut */}
        {innerSprings.map((springProps, i) => (
          <AnimatedArc
            cornerRadius={3}
            key={`arc-inner-${i}`}
            innerRadius={isDesktop ? 20 : 17}
            outerRadius={isDesktop ? 40 : 32}
            fill={colors(i + outerData.length)}
            padAngle={0.05}
            {...springProps}
          />
        ))}
        <Text
          x={0}
          y={height * 0.2} // Position slightly below the bottom of the outer donut
          dy=".33em"
          fontSize={12}
          fill="#ffffff"
          textAnchor="middle"
          fontWeight={600}
        >
          {`${innerData[0]}%`}
        </Text>
        <Text
          x={0}
          y={height * 0.42} // Position slightly below the bottom of the outer donut
          dy=".33em"
          fontSize={12}
          fill="#ffffff"
          textAnchor="middle"
          fontWeight={600}
        >
          {`${outerData[0]}%`}
        </Text>
      </Group>
    </svg>
  );
};
/* 
const DoubleDonutChart = ({ isDesktop }) => (
  <div>
    <DonutChart
      width={isDesktop ? 160 : 140}
      height={isDesktop ? 145 : 115}
      isDesktop={isDesktop}
    />
  </div>
); */
const DoubleDonutChart = ({ isDesktop }) => {
  const { coin1Data, coin2Data, compareData, excludeCex } = useContext(
    HolderCompareCoinsContext
  );

  const getOption = () => ({
    tooltip: {
      trigger: "item",
      backgroundColor: "rgba(0, 0, 0, 0.9)", // Tooltip background color
      width: "1000",
      textStyle: {
        color: "#fff", // White text color for contrast
        fontSize: 14, // Font size for better readability
      },
      formatter: (params) => {
        const { name, value, percent } = params.data;
        return `<div
                  style="
                    width: "fit-content";
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                  "
                >
                  <div
                    style="
                      width: 100%;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                    "
                  >
                    <p style="color: rgba(255,255,255,1)">${name}</p>
                  </div>
                  <div
                    style="
                      width: 100%;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                    "
                  >
                    <p style="font-weight: 600; color: rgba(255,255,255,1)">${graphFormatter(
                      value,
                      false,
                      false,
                      true
                    )} (${graphFormatter(percent, false, false, true)}%)</p> 
                  </div>
                </div>

        `;
      }, // Customize the tooltip format as needed
      position: (point, params, dom, rect, size) => {
        // Get the tooltip element's dimensions
        const tooltipWidth = size.contentSize[0];
        const tooltipHeight = size.contentSize[1];

        // Get the viewport dimensions
        const viewportWidth = window.innerWidth;
        const viewportHeight = window.innerHeight;

        // Get the x and y positions where the tooltip would appear
        const x = point[0];
        const y = point[1];

        // Calculate the new position for the tooltip
        const newX = Math.min(x, viewportWidth - tooltipWidth - 10); // 10px padding
        const newY = Math.min(y, viewportHeight - tooltipHeight - 10); // 10px padding

        return [newX, newY];
      },
    },
    legend: null, // Hide the legend
    title: null, // Hide the title
    series: [
      {
        name: "Inner Pie",
        type: "pie",
        radius: ["22%", "52%"], // Outer pie radius
        avoidLabelOverlap: false,
        padAngle: 1,
        itemStyle: {
          borderRadius: 4,
        },
        label: {
          show: false, // Hide labels
        },
        emphasis: {
          label: {
            show: false, // Hide emphasis labels
          },
        },
        labelLine: {
          show: false, // Hide label lines
        },
        color: ["#aae600", "rgba(255,255,255,0.1)"],

        data: [
          {
            value: compareData[`amountTop100Total${excludeCex ? "NoCex" : ""}`],
            percent: graphFormatter(
              compareData[`percentage1Top100Total${excludeCex ? "NoCex" : ""}`],
              false,
              false,
              true
            ),
            name: `Top 100 ${coin2Data.symbol.toUpperCase()} ${
              coin2Data.symbol.toUpperCase() === coin1Data.symbol.toUpperCase()
                ? "(right)"
                : ""
            } Holders`,
          },
          {
            value:
              compareData[`coin1CircSupply${excludeCex ? "NoCex" : ""}`] -
              compareData[`amountTop100Total${excludeCex ? "NoCex" : ""}`],
            percent:
              100 -
              graphFormatter(
                compareData[
                  `percentage1Top100Total${excludeCex ? "NoCex" : ""}`
                ],
                false,
                false,
                true
              ),
            name: `All ${coin1Data.symbol.toUpperCase()} ${
              coin2Data.symbol.toUpperCase() === coin1Data.symbol.toUpperCase()
                ? "(left)"
                : ""
            } Holders`,
          },
        ],
      },
      {
        name: "Outer Pie",
        type: "pie",
        radius: ["63%", "93%"], // Outer pie radius
        avoidLabelOverlap: false,
        padAngle: 1,
        itemStyle: {
          borderRadius: 4,
        },
        label: {
          show: false, // Hide labels
        },
        emphasis: {
          label: {
            show: false, // Hide emphasis labels
          },
        },
        labelLine: {
          show: false, // Hide label lines
        },
        color: ["#0097D1", "rgba(255,255,255,0.1)"], // Colors for inner pie

        data: [
          {
            value:
              (compareData[
                `percentage1AllCommonTotal${excludeCex ? "NoCex" : ""}`
              ] /
                100) *
              compareData[`coin1CircSupply${excludeCex ? "NoCex" : ""}`],
            percent: graphFormatter(
              compareData[
                `percentage1AllCommonTotal${excludeCex ? "NoCex" : ""}`
              ],
              false,
              false,
              true
            ),
            name: `All ${coin2Data.symbol.toUpperCase()} ${
              coin2Data.symbol.toUpperCase() === coin1Data.symbol.toUpperCase()
                ? "(right)"
                : ""
            } Holders`,
          },
          {
            value:
              compareData[`coin1CircSupply${excludeCex ? "NoCex" : ""}`] -
              (compareData[
                `percentage1AllCommonTotal${excludeCex ? "NoCex" : ""}`
              ] /
                100) *
                compareData[`coin1CircSupply${excludeCex ? "NoCex" : ""}`],
            percent:
              100 -
              graphFormatter(
                compareData[
                  `percentage1AllCommonTotal${excludeCex ? "NoCex" : ""}`
                ],
                false,
                false,
                true
              ),
            name: `All ${coin1Data.symbol.toUpperCase()} ${
              coin2Data.symbol.toUpperCase() === coin1Data.symbol.toUpperCase()
                ? "(left)"
                : ""
            } Holders`,
          },
        ],
      },
    ],
  });
  return (
    <div style={{ position: "relative" }}>
      <ReactECharts
        style={{
          width: isDesktop ? "160px" : "135px",
          height: isDesktop ? "155px" : "135px",
        }}
        option={getOption()}
      />
      <SmallText
        style={{
          position: "absolute",
          left: "50%",
          transform: "translateX(-50%)",
          bottom: "0.5rem",
        }}
        weight={textSizes.fontWeights.SEMI_BOLD}
      >
        {compareData[`percentage1AllCommonTotal${excludeCex ? "NoCex" : ""}`] >
        0.0001
          ? graphFormatter(
              compareData[
                `percentage1AllCommonTotal${excludeCex ? "NoCex" : ""}`
              ]
            )
          : 0}
        %
      </SmallText>
      <SmallText
        style={{
          position: "absolute",
          left: "50%",
          transform: "translateX(-50%)",
          bottom: isDesktop ? "2.5rem" : "2.3rem",
        }}
        weight={textSizes.fontWeights.SEMI_BOLD}
      >
        {compareData[`percentage1Top100Total${excludeCex ? "NoCex" : ""}`] >
        0.0001
          ? graphFormatter(
              compareData[`percentage1Top100Total${excludeCex ? "NoCex" : ""}`]
            )
          : 0}
        %
      </SmallText>
    </div>
  );
};
export default DoubleDonutChart;
