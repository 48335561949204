import styled from "styled-components";
import { Row } from "../containers/flexbox.styled";
import { paddings } from "../paddings.styled";
import { borderRads, borderWidths, textSizes } from "../sizes";
import { theme } from "../theme.styled";

export const StyledMultiSwitch = styled(Row)`
  width: ${({ width }) => width || "100%"};
  gap: 0.5rem;
  justify-content: ${({ adaptiveWidth }) =>
    adaptiveWidth ? "start" : "space-betwen"};
  overflow: scroll;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
  position: relative;
  @media (max-width: 40rem) {
    gap: 0.3rem;
  }
`;

export const MultiSwitchButton = styled.button`
  width: ${({ adaptiveWidth }) => (adaptiveWidth ? "fit-content" : "100%")};
  padding: ${paddings.LOW}
    ${({ adaptiveWidth, isRoi }) =>
      adaptiveWidth ? paddings.MID : isRoi ? paddings.LOW : "0"};

  font-size: ${({ isDreamfolio }) =>
    isDreamfolio ? textSizes.fontSizes.SMALLER : textSizes.fontSizes.SMALL};
  background-color: ${({ theme, selected, selectedColor, unSelectedColor }) =>
    selected
      ? selectedColor
        ? selectedColor
        : theme.colors.LEVEL
      : unSelectedColor || "transparent"};
  border-radius: ${borderRads.SMALL};
  border: ${borderWidths.SMALL} solid
    ${({ theme, selectedColor }) =>
      selectedColor ? selectedColor : theme.colors.LEVEL};
  white-space: nowrap;

  //if isroi, make max width 4rem
  max-width: ${({ isRoi }) => (isRoi ? "4rem" : "none")};

  @media (max-width: 70rem) {
    padding: ${({ isRoi }) => (isRoi ? "0.3rem" : paddings.LOW)}
      ${({ adaptiveWidth, isRoi }) =>
        adaptiveWidth ? paddings.MID : isRoi ? paddings.LOW : "0"};

    //if isroi and not selected, make color light text
    color: ${({ theme, selected, isRoi }) => isRoi && theme.colors.LIGHT_TEXT};

    //if is roi, make background color transparent
    /* background-color: ${({ isRoi }) => isRoi && theme.colors.SECONDARY}; */

    //if is roi, make border color light text
  }
`;
