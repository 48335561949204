import styled from "styled-components";

export const StyledLoader = styled.span`
  width: ${({ isSmall, isRealSmall }) =>
    isSmall ? "24px" : isRealSmall ? "16px" : "48px"};
  height: ${({ isSmall, isRealSmall }) =>
    isSmall ? "24px" : isRealSmall ? "16px" : "48px"};
  border-radius: 50%;
  display: inline-block;
  position: relative;
  border: ${({ isSmall, isRealSmall }) =>
    isSmall ? "6px solid" : isRealSmall ? "4px solid" : "10px solid"};
  border-color: rgba(255, 255, 255, 0.15) rgba(255, 255, 255, 0.25)
    rgba(255, 255, 255, 0.35) rgba(255, 255, 255, 0.5);
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  margin: auto auto auto auto;

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
