import styled, { keyframes } from "styled-components";
import { BlurBox } from "../containers/boxes.styled";
import { Column, FullRow } from "../containers/flexbox.styled";
import { zIndexes } from "../globalStyle.styled";
import { paddings } from "../paddings.styled";
import { borderRads, borderWidths, textSizes } from "../sizes";

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const DropdownMenuSearchInput = styled(FullRow)`
  gap: ${paddings.MID} !important;
  flex-direction: row !important;
  z-index: ${zIndexes.DROPDOWN_MENU_SEARCH_INPUT};
  padding: 1rem;
  // background-color: black;
  
  input {
    width: 100%;
    background-color: transparent;
    color: ${({ theme }) => theme.colors.LIGHT_TEXT};
    border: none;
    padding: 0.5rem;
    font-size: 1rem;
    height: 2.5rem;
    &::placeholder {
      color: ${({ theme }) => theme.colors.LIGHT_TEXT};
    }
  }
`;

export const StyledDropdownMenu = styled(BlurBox)`
  display: ${({ ison }) => !ison && "none"};
  position: absolute;
  width: 100%;
  height: 400px;
  z-index: ${zIndexes.DROPDOWN_MENU};
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  background-color: ${({ backgroundColor }) => backgroundColor || "black"};
  top: 100%;
  left: ${({ left }) => left && left};
  border: none;
  border-radius: 0;
  margin: 0;
  padding: 0;
  
  /* Fix for iOS keyboard issues */
  @media screen and (max-width: 768px) {
    position: absolute;
    height: auto;
    bottom: auto;
  }

  input:focus {
    outline: none;
  }

  .switch-container {
  position: sticky;
  top: 0;
  background-color: inherit;
  z-index: 3;
}

  .dropdown-items-container {
    padding: 0.5rem 1rem;
    background-color: transparent;
    overflow-y: auto;
    max-height: calc(25rem - 7rem);

    &::-webkit-scrollbar {
      width: 6px;
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: ${({ theme }) => theme.colors.LEVEL2};
      border-radius: ${borderRads.SMALL};
    }

    &::-webkit-scrollbar-track {
      background-color: transparent;
    }
  }

  .promote-button {
    margin: 0.5rem 1rem;
  }

  @media (max-width: 40rem) {
    max-height: 20rem;

    .dropdown-items-container {
      max-height: calc(20rem - 7rem);
    }
  }

  ${Column} {
    gap: 0.25rem;
  }

  ${DropdownMenuSearchInput} {
    margin-bottom: 0.25rem;
  }
`;

export const StyledDropdownSwitch = styled.div`
  width: 100%;
  background-color: #1E1825;
  border-radius: ${borderRads.SMALL};
  overflow: hidden;

  button {
    background-color: transparent;
    color: ${({ theme }) => theme.colors.LIGHT_TEXT};
    border: none;
    padding: 0.75rem;
    width: 50%;
    cursor: pointer;
    transition: background-color 0.2s;

    &.selected {
      background-color: #2D2435;
      color: white;
    }
  }
`;