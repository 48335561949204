import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { useNavigate, useRouteError } from "react-router-dom";
import {
  PrimaryButton,
  TransparentButton,
} from "../components/buttons/buttons.styled";
import { Column, Row } from "../components/containers/flexbox.styled";
import {
  StyledErrorPage,
  StyledErrorPage2,
  StyledErrorPageMessage,
} from "../components/errorPage.styled";
import Footer from "../components/footer";
import { GlobalStyle } from "../components/globalStyle.styled";
import Navbar from "../components/navbar/navbar";
import { BigText, Header, Paragraph, Title } from "../components/texts.styled";
import { Theme } from "../components/theme.styled";
import { Icon } from "../components/icon.styled";
import { paddings } from "../components/paddings.styled";
import { borderRads } from "../components/sizes";

export default function ErrorPage2() {
  return (
    <Theme>
      <GlobalStyle />
      <StyledErrorPage2>
        <StyledErrorPageMessage>
          <Column gap={"6rem"}>
            <img src="/images/error.png" />
            <Column gap="1rem">
              <BigText size="2.4rem">Something went wrong.</BigText>
              <Paragraph>
                The team is working on it. Meanwhile, you can check some past
                comparisons on:
              </Paragraph>

              <a href="https://twitter.com/comparemcap">
                <Row
                  style={{
                    padding: paddings.LOW,
                    backgroundColor: "rgba(255, 255,255, 0.1)",
                    borderRadius: borderRads.MEDIUM,
                    width: "fit-content",
                  }}
                  gap="0.5rem"
                >
                  <svg
                    style={{ width: "2rem", fill: "white" }}
                    version="1.1"
                    id="svg5"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    x="0px"
                    y="0px"
                    viewBox="0 0 1668.56 1221.19"
                    xmlSpace="preserve"
                  >
                    <g id="layer1" transform="translate(52.390088,-25.058597)">
                      <path
                        id="path1009"
                        d="M283.94,167.31l386.39,516.64L281.5,1104h87.51l340.42-367.76L984.48,1104h297.8L874.15,558.3l361.92-390.99
                    h-87.51l-313.51,338.7l-253.31-338.7H283.94z M412.63,231.77h136.81l604.13,807.76h-136.81L412.63,231.77z"
                      />
                    </g>
                  </svg>
                  <Paragraph>@comparemcap</Paragraph>
                </Row>
              </a>
            </Column>
          </Column>
        </StyledErrorPageMessage>
      </StyledErrorPage2>
    </Theme>
  );
}
