import styled from "styled-components";

export const DreamfolioGradient = styled.div`
  background: linear-gradient(
    rgba(113, 26, 117, 0.45) 0%,
    rgba(113, 26, 117, 0) 100%
  );
  position: absolute;
  width: 100%;
  height: 15rem;
  top: 0;
  left: 0;
  z-index: -1;
`;
