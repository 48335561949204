import { useQuery } from "@tanstack/react-query";
import { useContext, useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { AllCoinsContext, DedicatedCoinContext } from "../../context";
import { formatNumber, getSmallNumber } from "../../util/numberFormatter";
import Loader from "../misc/loader";
import MultiSwitch from "../misc/multiSwitch";
import Switch from "../misc/switch";
import { textSizes } from "../sizes";
import { getBullData, getComparer5Coins, getComparer5CoinsBull } from "../stream";
import { BigText, SmallText } from "../texts.styled";
import { StyledComparerFive } from "./comparerFive.styled";
import React from "react";

let isComparePrepared = false;

export default function ComparerFive() {
  const modeOptions = {
    bull: "Bull Season",
    current: "Current",
  };

  const [text, setText] = useState("");

  const compareOptions = {
    ath: {
      label: "ATH",
      currentText: "reaches its ATH market cap",
      bullText: "reaches its ATH market cap",
    },
    ten: {
      label: "#10",
      currentText: "reaches #10 most market cap",
      bullText: "reaches #10 most market cap of",
    },
    fifty: {
      label: "#50",
      currentText: "reaches #50 most market cap",
      bullText: "reaches #50 most market cap of",
    },
    hundred: {
      label: "#100",
      currentText: "reaches #100 most market cap",
      bullText: "reaches #100 most market cap of",
    },
    twoHundred: {
      label: "#200",
      currentText: "reaches #200 most market cap",
      bullText: "reaches #200 most market cap of",
    },
  };

  const [mode, setMode] = useState(modeOptions.current);
  const [selectedSwitchIndex, setSelectedSwitchIndex] = useState(0);
  const [compareMode, setCompareMode] = useState(compareOptions.ath.label);

  useEffect(() => {
    if (compareMode === compareOptions.ath.label) {
      setText(
        mode === modeOptions.current
          ? compareOptions.ath.currentText
          : compareOptions.ath.bullText
      );
    }
    if (compareMode === compareOptions.ten.label) {
      setText(
        mode === modeOptions.current
          ? compareOptions.ten.currentText
          : compareOptions.ten.bullText
      );
    }
    if (compareMode === compareOptions.fifty.label) {
      setText(
        mode === modeOptions.current
          ? compareOptions.fifty.currentText
          : compareOptions.fifty.bullText
      );
    }
    if (compareMode === compareOptions.hundred.label) {
      setText(
        mode === modeOptions.current
          ? compareOptions.hundred.currentText
          : compareOptions.hundred.bullText
      );
    }
    if (compareMode === compareOptions.twoHundred.label) {
      setText(
        mode === modeOptions.current
          ? compareOptions.twoHundred.currentText
          : compareOptions.twoHundred.bullText
      );
    }
  }, [compareMode, mode]);

  const switchElements = [
    {
      name: "Current",
      onClick: () => {
        setMode(modeOptions.current);
        setSelectedSwitchIndex(0);
      },
    },
    {
      name: "Bull Season",
      onClick: () => {
        setMode(modeOptions.bull);
        setSelectedSwitchIndex(1);
      },
    },
  ];

  const [hasClicked, setClicked] = useState(false);

  const compareTo = [
    {
      name: compareOptions.ath.label,
      onClick: () => {
        setClicked(true);
        setCompareMode(compareOptions.ath.label);
      },
    },
    {
      name: compareOptions.ten.label,
      onClick: () => {
        setClicked(true);
        setCompareMode(compareOptions.ten.label);
      },
    },
    {
      name: compareOptions.fifty.label,
      onClick: () => {
        setClicked(true);
        setCompareMode(compareOptions.fifty.label);
      },
    },
    {
      name: compareOptions.hundred.label,
      onClick: () => {
        setClicked(true);
        setCompareMode(compareOptions.hundred.label);
      },
    },
    {
      name: compareOptions.twoHundred.label,
      onClick: () => {
        setClicked(true);
        setCompareMode(compareOptions.twoHundred.label);
      },
    },
  ];

  const coinData = useContext(DedicatedCoinContext);

  const [result, setResult] = useState(0);

  const [currentData, setCurrentData] = useState(null);
  const [bullData, setBullData] = useState(null);
  const [isLoaded, setLoaded] = useState(false);
  const [hideAth, setHideAth] = useState(false);

  const { data: bullCoins, status: bullCoinsStatus } = useQuery(
    ["comparer-five-all-coins-bull"],
    () => getComparer5CoinsBull(),
    {
      onSuccess: (data) => {
        setBullData(data);
      },

      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (bullData !== null && currentData !== null) {
      setCompareResults(mode === modeOptions.current ? currentData : bullData);
    }
  }, [mode]);

  const [compareValues, setCompareValues] = useState({
    currentAth: null,
    currentTen: null,
    currentFifty: null,
    currentHundred: null,
    currentTwoHundred: null,
  });

  function setCompareResults(coins, isFirstTime) {
    setCompareValues({
      currentAth: coinData.ath / coinData.price,
      currentTen: coins[0].marketcap / coinData.marketcap,
      currentFifty: coins[1].marketcap / coinData.marketcap,
      currentHundred: coins[2].marketcap / coinData.marketcap,
      currentTwoHundred: coins[3].marketcap / coinData.marketcap,
    });
    updateResult();
  }

  useEffect(() => {
    if (!hasClicked) {
      if (!compareValues.currentAth) {
        setCompareMode(compareOptions.ten.label);
        setHideAth(true);
      } else {
        setCompareMode(compareOptions.ath.label);
        setHideAth(false);
      }
    }
  }, [compareValues]);

  function updateResult() {
    switch (compareMode) {
      case compareOptions.ath.label:
        setResult(compareValues.currentAth);
        break;
      case compareOptions.ten.label:
        setResult(compareValues.currentTen);
        break;
      case compareOptions.fifty.label:
        setResult(compareValues.currentFifty);
        break;
      case compareOptions.hundred.label:
        setResult(compareValues.currentHundred);
        break;
      case compareOptions.twoHundred.label:
        setResult(compareValues.currentTwoHundred);
        break;
      default:
        break;
    }
  }

  useEffect(() => {
    updateResult();
  }, [mode, compareMode, compareValues]);

  useEffect(() => {
    return () => {
      setCompareValues({
        currentAth: null,
        currentTen: null,
        currentFifty: null,
        currentHundred: null,
        currentTwoHundred: null,
      });
      setBullData(null);
      setCurrentData(null);
    };
  }, []);

  const isMobile = useMediaQuery({
    query: "(max-width: 40rem)",
  });
  const allCoins = useContext(AllCoinsContext);

  const { data, status } = useQuery(
    ["comparer-five"],
    () => getComparer5Coins(),
    {
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        setCurrentData(data);
        setCompareResults(data, true);
      },
    }
  );

  return (
    status === "success" && (
      <StyledComparerFive>
        <Switch
          fullWidth={isMobile}
          center options={switchElements}
          selectedIndexs={selectedSwitchIndex}
        />
        <SmallText textAlign="center">
          If {coinData.coinName} {text}
          <br />
          {mode === modeOptions.bull &&
            compareMode !== compareOptions.ath.label &&
            " last bull season, "}
          its price would be
        </SmallText>
        <MultiSwitch
          startSelected
          extraOption={hideAth ? false : 1}
          options={hideAth ? compareTo.slice(1) : compareTo}
        />
        {result !== 0 ? (
          isNaN(result) ? (
            <SmallText> Data not available for this coin </SmallText>
          ) : (
            <BigText size={textSizes.fontSizes.HEADER}>
              {result * coinData.price > 0.001 ? (
                <span>${formatNumber(result * coinData.price, true)}</span>
              ) : (
                <React.Fragment>
                  <span>0.0</span>
                  <SmallText size={textSizes.fontSizes.MOBILE_LITTLE_NUMBER}>
                    {getSmallNumber(result * coinData.price).zeroCount}
                  </SmallText>
                  <span>
                    {getSmallNumber(result * coinData.price).nonZeroDigits}
                  </span>
                </React.Fragment>
              )}

              <BigText
                color={({ theme }) =>
                  result >= 1 ? theme.colors.GREEN : theme.colors.RED
                }
                weight={textSizes.fontWeights.SEMI_BOLD}
                size={textSizes.fontSizes.HEADER}
              >
                {" "}
                ({formatNumber(result, false, true)}x)
              </BigText>
            </BigText>
          )
        ) : result === 0 ? (
          <SmallText>Data Unavailable</SmallText>
        ) : (
          <BigText>
            <Loader isSmall={true} />
          </BigText>
        )}
      </StyledComparerFive>
    )
  );
}
