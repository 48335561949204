import { useContext, useState, useRef } from "react";
import { DedicatedCoinContext } from "../../context";
import { Column, FullRow, Row } from "../containers/flexbox.styled";
import { BigText, Paragraph, SmallText } from "../texts.styled";
import { VerticalSeperator } from "../containers/boxes.styled";
import {
  CoinInfoMarketAlt,
  CoinInfoSection,
  MostComparedBadge,
  StyledCoinInfo,
} from "./coinInfo.styled";
import DedicatedCoinShareButton from "./dedicatedCoinShareButton";
import { getMostComparedAll } from "../stream";
import { useQuery } from "@tanstack/react-query";
import { Icon } from "../icon.styled";
import { IoIosArrowForward, IoMdFlame } from "react-icons/io";
import SocialMediaSection from "./socialMediaSection";
import { HideOnMobileWrapper, ShowOnMobileWrapper } from "../hideOnMobile";
import CopyButton from "../misc/copyButton";
import { formatNumber, getSmallNumber } from "../../util/numberFormatter";
import { textSizes } from "../sizes";
import { BasicStat, BasicStats } from "./basicStat";
import { PriceChange, PriceChangeMobile } from "./priceChange.styled";
import { BsCaretDownFill, BsCaretUpFill } from "react-icons/bs";
import ComparedMostlyWithMobile from "./comparedMostlyWithMobile";
import { useMediaQuery } from "react-responsive";
import { isSocialLinkValid } from "../../validators";
import { AreaChartWrapper } from "./simpleChart";
import { MdExpandLess, MdExpandMore } from "react-icons/md";
import { ClickAwayListener } from "@mui/base";
import { StyledShareMenu } from "../menus/shareMenu.styled";
import { RouteLink } from "../../routing/routeLink";
import { AiOutlineInfoCircle } from "react-icons/ai";
import styled from "styled-components";
import { BiError } from "react-icons/bi";


const TooltipContainer = styled.div`
  position: relative;
  display: inline-block;
  margin-left: 4px;
  vertical-align: middle; 
`;

const TooltipContent = styled.div`
  position: absolute;
  bottom: 100%;
  padding: 12px 16px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  z-index: 1000;
  margin-bottom: 8px;
  backdrop-filter: blur(9px);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  
  @media (max-width: 768px) {
    right: -16px; // Adjust this value as needed
    transform: translateX(0);
    width: max-content;
    max-width: 200px;
    text-align: center;
  }

  @media (min-width: 769px) {
    white-space: nowrap;
    left: 50%;
    transform: translateX(-50%);
    
    // Prevent right overflow
    ${props => {
      const rect = props.getBoundingClientRect?.();
      if (rect && rect.right > window.innerWidth) {
        return `
          left: auto;
          right: 0;
          transform: translateX(0);
        `;
      }
      return '';
    }}
  }
`;

function MarketCapTooltip() {
  const [isOpen, setIsOpen] = useState(false);
  const tooltipRef = useRef(null);

  return (
    <TooltipContainer>
      <Icon
        size="0.8rem"
        color="#FFB800"  // Yellow color for warning
        style={{ cursor: 'pointer' }}
        onClick={() => setIsOpen(!isOpen)}
      >
        <BiError />
      </Icon>
      {isOpen && (
        <ClickAwayListener onClickAway={() => setIsOpen(false)}>
          <TooltipContent ref={tooltipRef}>
            <SmallText 
              color={({ theme }) => theme.colors.LIGHT_TEXT}
              style={{ fontSize: "0.8rem", opacity: 0.8 }}
            >
              Circulating supply is provided by the project. DYOR.
            </SmallText>
          </TooltipContent>
        </ClickAwayListener>
      )}
    </TooltipContainer>
  );
}

export default function CoinInfo({
  comparedMostlyWithStatus,
  comparedMostlyWithData,
  type,
  holderCount,
  oneDayChange,
}) {
  const coinData = useContext(DedicatedCoinContext);

  const [mcIndex, setMcIndex] = useState(null);
  const [isTagsOn, setTagsOn] = useState(false);

  const { data, status } = useQuery(
    ["mc-rank", coinData.id],
    () => getMostComparedAll(),
    {
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        setMcIndex(data.findIndex((item) => item.id === coinData.id) + 1);
      },
    }
  );

  const isMobile = useMediaQuery({
    query: "(max-width: 70rem)",
  });

  return (
    <StyledCoinInfo type={type}>
      <FullRow>
        <img src={coinData.image} />
        <BigText size={"1.5rem"}>{coinData.symbol.toUpperCase()}</BigText>
        <VerticalSeperator></VerticalSeperator>
        <SmallText color={({ theme }) => theme.colors.LIGHT_TEXT}>
          {coinData.coinName}
        </SmallText>
        <DedicatedCoinShareButton
          coinName={coinData.coinName}
          isMobile={true}
        />
      </FullRow>

      {type === 1 && (
        <Row className="deck">
          {status === "success" &&
            mcIndex !== null &&
            mcIndex < 20 &&
            mcIndex !== 0 && (
              <MostComparedBadge>
                <Icon
                  style={{ overflowX: "visible" }}
                  color="#ffb800"
                  size="1.2rem"
                >
                  <IoMdFlame />
                </Icon>
                <SmallText>{getSuffix(mcIndex)}</SmallText>
              </MostComparedBadge>
            )}
          {(isSocialLinkValid(coinData.twitter) ||
            isSocialLinkValid(coinData.web) ||
            isSocialLinkValid(coinData.reddit) ||
            isSocialLinkValid(coinData.telegram)) && (
              <ShowOnMobileWrapper>
                <CoinInfoSection>
                  <SocialMediaSection />
                </CoinInfoSection>
              </ShowOnMobileWrapper>
            )}

          {coinData.contractAddress &&
            coinData.contractAddress !== "undefined" && (
              <CoinInfoSection>
                <SmallText
                  style={{ overflowX: "auto" }}
                  color={({ theme }) => theme.colors.LIGHT_TEXT}
                >
                  {coinData.contractAddress?.slice(0, 2)}...
                  {coinData.contractAddress?.slice(-2)}
                </SmallText>
                <CopyButton text={coinData.contractAddress} />
              </CoinInfoSection>
            )}

          {coinData.tags.length > 0 && (
            <CoinInfoSection
              style={{ width: "100%", maxWidth: "5rem", cursor: "pointer" }}
            >
              <FullRow
                justify="center"
                gap="0.2rem"
                width="100%"
                onClick={() => setTagsOn(!isTagsOn)}
              >
                <SmallText color={({ theme }) => theme.colors.LIGHT_TEXT}>
                  Tags
                </SmallText>
                {!isTagsOn ? <MdExpandMore /> : <MdExpandLess />}
              </FullRow>
              {isTagsOn && (
                <StyledShareMenu>
                  <Column gap="1rem" align="start">
                    {coinData.tags.map((tag, index) => (
                      <RouteLink
                        to={`/coins/${encodeURIComponent(tag)}`}
                        style={{ cursor: "pointer", width: "100%" }}
                      >
                        <FullRow justify="space-between">
                          <SmallText key={index}>{tag}</SmallText>
                          <Icon size="1rem !important">
                            <IoIosArrowForward />
                          </Icon>
                        </FullRow>
                      </RouteLink>
                    ))}
                  </Column>
                </StyledShareMenu>
              )}
            </CoinInfoSection>
          )}
        </Row>
      )}

      {isMobile &&
        comparedMostlyWithStatus === "success" &&
        comparedMostlyWithData.length >= 3 &&
        (type === 1 || type === 3) && (
          <ShowOnMobileWrapper style={{ width: "100%" }}>
            <ComparedMostlyWithMobile data={comparedMostlyWithData} />
          </ShowOnMobileWrapper>
        )}

      {coinData.coinState !== 0 && type !== 3 && (
        <CoinInfoMarketAlt>
          <FullRow>
            {coinData.price && (
              <Column>
                <SmallText>Price</SmallText>
                <FullRow align="center" justify="center" gap="0.2rem">
                  <span>
                    {coinData.price < 0.001 ? (
                      <td>
                        $0.0
                        <SmallText
                          size={textSizes.fontSizes.MOBILE_LITTLE_NUMBER}
                        >
                          {getSmallNumber(coinData.price).zeroCount}
                        </SmallText>
                        {getSmallNumber(coinData.price).nonZeroDigits}
                      </td>
                    ) : (
                      <td>${formatNumber(coinData.price)}</td>
                    )}
                  </span>
                  {oneDayChange && (
                    <PriceChangeMobile profit={oneDayChange >= 0}>
                      {oneDayChange >= 0 ? (
                        <Icon>
                          <BsCaretUpFill />
                        </Icon>
                      ) : (
                        <Icon>
                          <BsCaretDownFill />
                        </Icon>
                      )}
                      {formatNumber(oneDayChange, false, true)}%
                    </PriceChangeMobile>
                  )}
                </FullRow>
              </Column>
            )}
            {coinData.marketcap && (
              <Column>
                <SmallText>
                  Market Cap
                  {coinData.id === "scoobydoo" && <MarketCapTooltip />}
                </SmallText>
                <Paragraph>${formatNumber(coinData.marketcap, false, true)}</Paragraph>
              </Column>
            )}
          </FullRow>

          {type !== 2 && (
            <FullRow>
              {coinData.volume24H && (
                <Column>
                  <SmallText>Volume</SmallText>
                  <Paragraph>
                    ${formatNumber(coinData.volume24H, false, true)}
                  </Paragraph>
                </Column>
              )}
              {holderCount && (
                <Column>
                  <SmallText>Holders</SmallText>
                  <Paragraph>{formatNumber(holderCount)}</Paragraph>
                </Column>
              )}
            </FullRow>
          )}
        </CoinInfoMarketAlt>
      )}

      <HideOnMobileWrapper>
        {coinData.coinState !== 0 && (
          <Row gap="1rem">
            {coinData.price > 0.001 ? (
              <BigText weight={textSizes.fontWeights.SEMI_BOLD}>
                ${formatNumber(coinData.price, true, false, true, 3)}
              </BigText>
            ) : (
              <BigText weight={textSizes.fontWeights.SEMI_BOLD}>
                $0.0
                <SmallText>
                  {getSmallNumber(coinData.price).zeroCount}
                </SmallText>
                {getSmallNumber(coinData.price).nonZeroDigits}
              </BigText>
            )}
            <PriceChange profit={coinData.change1H >= 0}>
              {coinData.change1H >= 0 ? (
                <Icon>
                  <BsCaretUpFill />
                </Icon>
              ) : (
                <Icon>
                  <BsCaretDownFill />
                </Icon>
              )}
              {formatNumber(coinData.change1H, false, true)}%
            </PriceChange>
          </Row>
        )}
      </HideOnMobileWrapper>

      {coinData.coinState !== 0 && (
        <BasicStats>
          {coinData.marketcap && coinData.marketcap !== 0 && (
            <BasicStat>
              <SmallText style={{ fontWeight: "300" }}>
                Market Cap
                {coinData.id === "brett-eth" && <MarketCapTooltip />}
              </SmallText>
              <Paragraph
                size={textSizes.fontSizes.MEDIUM}
                weight={textSizes.fontWeights.SEMI_BOLD}
              >
                ${formatNumber(coinData.marketcap)}
              </Paragraph>
            </BasicStat>
          )}

          {coinData.volume24H && coinData.volume24H !== 0 && (
            <BasicStat>
              <SmallText style={{ fontWeight: "300" }}>Volume 24h</SmallText>
              <Paragraph
                size={textSizes.fontSizes.MEDIUM}
                weight={textSizes.fontWeights.SEMI_BOLD}
              >
                ${formatNumber(coinData.volume24H)}
              </Paragraph>
            </BasicStat>
          )}
        </BasicStats>
      )}
    </StyledCoinInfo>
  );
}

export function getSuffix(i) {
  let j = i % 10,
    k = i % 100;
  if (j === 1 && k !== 11) {
    return i + "st";
  }
  if (j === 2 && k !== 12) {
    return i + "nd";
  }
  if (j === 3 && k !== 13) {
    return i + "rd";
  }
  return i + "th";
}
