import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import {
  Container,
  LongBox,
  ShortBox,
} from "../components/containers/boxes.styled";
import { DedicatedCoinContext } from "../context";
import { CoinInfo } from "../components/dedicatedCoin/desktopCoinInfo";
import Loader from "../components/misc/loader";
import { formatNumber } from "../util/numberFormatter";
import { useEffect } from "react";
import { Column, FullRow, Row } from "../components/containers/flexbox.styled";
import { AreaChartWrapper } from "../components/dedicatedCoin/simpleChart";
import { RoiCalculatorTable } from "../components/dedicatedCoin/roiCalculator.styled";
import ComparedMostlyWith from "../components/dedicatedCoin/comparedMostlyWith";
import RoiBattle from "../components/dedicatedCoin/roiBattle";
import CommonHolders from "../components/dedicatedCoin/commonHolders";
import CompareWith from "../components/dedicatedCoin/compareWith";
import DedicatedStats from "../components/dedicatedCoin/dedicatedStats";
import { Faq } from "./dedicatedCoinNew";
import BarChartWrapper from "../components/dedicatedCoin/simpleChartBar";
import styled from "styled-components";
import { Paragraph, SmallText } from "../components/texts.styled";
import { borderRads, borderWidths } from "../components/sizes";
import { theme } from "../components/theme.styled";
import { RouteLink } from "../routing/routeLink";
import { TfiFaceSad } from "react-icons/tfi";
import { Icon } from "../components/icon.styled";

export default function DedicatedCoinNewDesktop({
  roi,
  data,
  isError,
  status,
  comparedMostlyWithData,
  comparedMostlyWithStatus,
  holderCount,
  setHolderCount,
  oneDayChange,
  handleOneDayChange,
  coinId,
}) {
  const navigate = useNavigate();

  useEffect(() => {
    const handleNavigation = (e) => {
      navigate(0);
    };

    window.addEventListener("popstate", handleNavigation);

    return () => {
      window.removeEventListener("popstate", handleNavigation);
    };
  }, [navigate]);

  return (
    <Container>
      <Helmet>
        <title>
          {status === "success"
            ? `${
                data.coinName
              }(${data.symbol.toUpperCase()}) price, market cap, volume, circulating supply and more - CompareMarketCap`
            : "CompareMarketCap"}
        </title>

        <meta
          name="description"
          content={
            status === "success"
              ? `${
                  data.coinName
                }(${data.symbol.toUpperCase()})'s current price is $${formatNumber(
                  data.price
                )} with a market cap of $${
                  data.marketcap !== 0 ? formatNumber(data.marketcap) : "-"
                }.`
              : "Find out the value of your cryptocurrency, or crypto coin, compared to the market cap of other cryptocurrencies by comparing them."
          }
        />

        {/* <meta name="coinzilla" content="7e7181bdad599d80e8d5b6d0c4799b4c" />
        <script src="https://coinzillatag.com/lib/sticky.js"></script>
        <script>
          {`
                window.coinzilla_sticky = window.coinzilla_sticky || [];
                    function czilla() {coinzilla_sticky.push(arguments);}
                    czilla('36662f1037eea083937');
                `}
        </script> */}
      </Helmet>

      {status === "success" ? (
        <DedicatedCoinContext.Provider value={data}>
          <LongBox withborder={"true"}>
            <Column gap="1rem" width="100%">
              <CoinInfo
                data={data}
                holderCount={holderCount}
                oneDayChange={oneDayChange}
              />
              <FullRow gap="1rem">
                <div style={{ width: "50%" }}>
                  <AreaChartWrapper
                    key={data.id}
                    coinId={data.id}
                    coin={data}
                    isDesktop={true}
                    handleOneDayChange={handleOneDayChange}
                  />
                </div>
                <div style={{ width: "50%" }}>
                  <BarChartWrapper
                    key={data.id}
                    coinId={data.id}
                    coin={data}
                    isDesktop={true}
                    setHolderCount={setHolderCount}
                  />
                </div>
              </FullRow>
              <RoiCalculatorTable coin={data} />
              <Faq coin={data} />
            </Column>
          </LongBox>
          <ShortBox>
            {comparedMostlyWithStatus && (
              <ComparedMostlyWith data={comparedMostlyWithData} />
            )}
            {data.blockChain !== "tron" && (
              <RoiBattle margin={true} coin={data} />
            )}

            <CommonHolders coin={data} />

            <CompareWith />
            <DedicatedStats />
          </ShortBox>
        </DedicatedCoinContext.Provider>
      ) : isError ? (
        <Column gap="2rem" style={{ margin: "15rem auto" }}>
          <Icon size="8rem" color={({ theme }) => theme.colors.LIGHT_TEXT}>
            <TfiFaceSad />
          </Icon>
          <Paragraph color={({ theme }) => theme.colors.LIGHT_TEXT}>
            No such coin as "{coinId}".
          </Paragraph>
        </Column>
      ) : (
        <Loader height={"40rem"} />
      )}
    </Container>
  );
}

export const Tags = ({ coin }) => {
  return (
    coin.tags.filter((item) => item !== null && item !== "null").length > 0 && (
      <StyledTags>
        <SmallText color={theme.colors.LIGHT_TEXT}>Tags: </SmallText>
        {coin.tags
          .filter((item) => item !== null && item !== "null")
          .map((tag, index) => (
            <RouteLink to={`/coins/${encodeURIComponent(tag)}`}>
              <SmallText key={index}>{tag}</SmallText>
            </RouteLink>
          ))}
      </StyledTags>
    )
  );
};

export const StyledTags = styled(Row)`
  padding: 0.5rem 0.75rem;
  margin-top: auto;
  gap: 0.5rem;
  background-color: ${({ theme }) => theme.colors.LEVEL};
  width: 100%;
  border-radius: ${borderRads.SMALL};

  ${SmallText} {
    font-size: 0.75rem;
  }

  //for each child except the first one
  & > *:not(:first-child) {
    // add 0.5rem padding and background level
    padding: 0.35rem 0.5rem;
    border-radius: ${borderRads.SMALL};
    //make level2 border
    border: ${borderWidths.SMALL} solid ${({ theme }) => theme.colors.LEVEL2};
  }
`;
