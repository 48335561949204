import { MdKeyboardArrowRight } from "react-icons/md";
import { RouteLink } from "../../routing/routeLink";
import { Column, Row } from "../containers/flexbox.styled";
import { Icon } from "../icon.styled";
import { getRecentlyListedPage } from "../stream";
import { Header, Paragraph, SmallText } from "../texts.styled";
import { SwiperSlide } from "swiper/react";
import Swiper from "swiper";
import { Autoplay, Pagination } from "swiper/modules";
import { useQuery } from "@tanstack/react-query";
import styled from "styled-components";
import { borderRads, textSizes } from "../sizes";

export default function RecentlyListedSquareDesktop() {
  const { data, status } = useQuery(
    ["recently-listed-home"],
    () => getRecentlyListedPage(),
    {
      onSuccess: (data) => {},
    }
  );

  return (
    status === "success" && (
      <Column
        style={{
          paddingTop: "0.5rem",
          paddingLeft: "0.5rem",
          maxWidth: "20rem",
        }}
        align="start"
        width="40rem"
        height="100%"
      >
        <Paragraph
          weight={textSizes.fontWeights.MEDIUM}
          style={{ marginBottom: "0.5rem" }}
        >
          Recently Listed
        </Paragraph>
        <RecentlyListedSquareDesktopGrid>
          {data.slice(0, 12).map((item, index) => (
            <RouteLink to={`/coin/${item.id}`} key={index}>
              <Column gap="0.5rem">
                <img src={item.image} />
                <SmallText
                  weight={textSizes.fontWeights.SEMI_BOLD}
                  size={textSizes.fontSizes.SMALLER}
                >
                  {item.symbol}
                </SmallText>
              </Column>
            </RouteLink>
          ))}
        </RecentlyListedSquareDesktopGrid>
      </Column>
    )
  );
}

const RecentlyListedSquareDesktopGrid = styled.div`
  padding: 1rem 0 1rem 0.5rem;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  height: 100%;

  & > * {
    &:hover {
      background-color: ${({ theme }) => theme.colors.LEVEL2};
    }

    width: 100%;
    height: 100%;
    background-color: ${({ theme }) => theme.colors.LEVEL};
    border-radius: ${borderRads.SM};
    align-items: center;
    justify-content: center;

    ${SmallText} {
      width: 4rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      text-align: center;
    }

    img {
      width: 1.5rem;
      height: 1.5rem;
      border-radius: 100%;
    }
  }
`;
