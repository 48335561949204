import React, { useState } from "react";
import { VerticalSeperator } from "../containers/boxes.styled";
import { MultiSwitchButton, StyledMultiSwitch } from "./multiSwitch.styled";
import { theme } from "../theme.styled";

export default function MultiSwitch({
  options,
  extraOption,
  selectedColor,
  unSelectedColor,
  adaptiveWidth,
  startSelected,
  isDreamfolio,
  isRoi,
  isWallets,
}) {
  const [selectedIndex, setSelectedIndex] = useState(startSelected ? 0 : null);

  function handleSelection(item, index) {
    item.onClick();
    setSelectedIndex(index);
  }

  return (
    <StyledMultiSwitch
      adaptiveWidth={adaptiveWidth}
      width={isRoi ? "fit-content" : null}
      style={{ justifyContent: isWallets ? "center" : "start" }}
    >
      {options.map((item, index) => (
        <React.Fragment>
          <MultiSwitchButton
            isRoi={isRoi}
            isDreamfolio={isDreamfolio}
            adaptiveWidth={adaptiveWidth}
            selectedColor={isRoi ? theme.colors.LEVEL2 : selectedColor}
            unSelectedColor={isRoi ? theme.colors.LEVEL : unSelectedColor}
            selected={index === selectedIndex}
            onClick={() => handleSelection(item, index)}
          >
            {item.name}
          </MultiSwitchButton>
          {extraOption && extraOption - 1 === index && <VerticalSeperator />}
        </React.Fragment>
      ))}
    </StyledMultiSwitch>
  );
}
