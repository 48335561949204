import styled, { css } from 'styled-components';

const mobileDesktopOption = css`
  display: ${({desktop})=>(desktop ? "flex" : "none")};

  @media(max-width: 70rem){
    display: ${({mobile})=>(mobile ? "flex" : "none")};
  }
`;

const hideOnMobile = css`
  @media (max-width: 70rem) {
    display: none;
  }
`;

const showOnMobile = css`
  @media (min-width: 70rem) {
    display: none;
  }
`;

const HideOnMobileWrapper = styled.div`
  ${hideOnMobile};
`;

const ShowOnMobileWrapper = styled.div`
  ${showOnMobile};
`;

export {
    HideOnMobileWrapper,
    ShowOnMobileWrapper,
    mobileDesktopOption
}

