import React from "react";
import { add3Dots } from "../../util/numberFormatter";
import { Row, Column } from "../containers/flexbox.styled";
import { SmallBadge } from "../misc/badges.styled";
import { MobileSearchRow } from "../search/searchBar.styled";
import { textSizes } from "../sizes";
import { Header, LightHeader, Paragraph, SmallText } from "../texts.styled";
import { theme } from "../theme.styled";
import {
  StyledDropdownItem,
  StyledDropdownPromoteButton,
  StyledPromotedBadge,
} from "./dropdownItem.styled";
import { FiExternalLink } from "react-icons/fi";
import { Icon } from "../icon.styled";
import { HideOnMobileWrapper } from "../hideOnMobile";

export default function DropdownItem({
  coin,
  mcNumber,
  onClick,
  isPromoted,
  disabled,
  isDreamfolio,
  isFocused,
}) {
  return (
    <StyledDropdownItem
      isFocused={isFocused}
      disabled={disabled}
      className="dropdown-item"
      isPromoted={isPromoted}
      onClick={onClick}
      isDreamfolio={isDreamfolio}
    >
      {isPromoted ? (
        <>
          <SmallText
            style={{
              position: "absolute",
              top: "-0.5rem",
              width: "fit-content",
              right: "0",
              left: "0",
              marginLeft: "auto",
              marginRight: "auto",
              textAlign: "center",
              color: theme.colors.GOLD2,
              fontSize: "0.7rem",
              background: "black",
              padding: "0.3 rem ",
            }}
          >
            Promoted
          </SmallText>
          <div style={{
            border: `1.5px solid ${theme.colors.GOLD}`,
            borderRadius: "0.5rem",
            padding: "0.75rem 1rem",
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}>
            <Row gap="0.75rem">
              <img alt="logo" src={coin.image} />
              {/* <div>
                <Paragraph size={"1rem"} weight={textSizes.fontWeights.BOLD}>
                  {coin.symbol.toUpperCase()}
                </Paragraph>
                <SmallText size={"0.8rem"} color={theme.colors.LIGHT_TEXT}>
                  {coin.companyName || coin.coinName}
                </SmallText>
              </div> */}
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Paragraph size={"1rem"} weight={textSizes.fontWeights.BOLD}>
                  {coin.symbol.toUpperCase()}
                </Paragraph>
                <SmallText size={"0.8rem"} color={theme.colors.LIGHT_TEXT}>
                  {coin.companyName || coin.coinName}
                </SmallText>
              </div>
            </Row>
            <Icon color={theme.colors.GOLD2}>
              <FiExternalLink />
            </Icon>
          </div>
        </>
      ) : (
        <>
          <Row>
            <img alt="logo" src={coin.image} />
            <Paragraph size={"1rem"} weight={textSizes.fontWeights.BOLD}>
              {coin.symbol.toUpperCase()}
            </Paragraph>
            <SmallText size={"0.8rem"} color={theme.colors.LIGHT_TEXT}>
              {coin.companyName || coin.coinName}
            </SmallText>
          </Row>
          {mcNumber !== 0 && <SmallBadge>{mcNumber}</SmallBadge>}
        </>
      )}
    </StyledDropdownItem>
  );
}

export function SearchMenuItem({ img, symbol, name, isFocused }) {
  return (
    <MobileSearchRow isFocused={isFocused}>
      <img alt="logo" src={img} />
      <Header size={"1.2rem"}>{symbol.toUpperCase()}</Header>
      <Paragraph>{add3Dots(name, 10)}</Paragraph>
    </MobileSearchRow>
  );
}

export function PromotedBadge() {
  return <StyledPromotedBadge>PROMOTED</StyledPromotedBadge>;
}

export function PromoteButton() {
  return (
    <StyledDropdownPromoteButton href="mailto:contact@comparemarketcap.com">
      Promote your project here
      <Icon size="1rem">
        <FiExternalLink />
      </Icon>
    </StyledDropdownPromoteButton>
  );
}