import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Column, FullRow, Row } from "../containers/flexbox.styled";
import { theme } from "../theme.styled";
import { borderRads, borderWidths, textSizes } from "../sizes";
import { StyledDropdown } from "../compareSection/dropdown.styled";
import { Header, LightHeader, Paragraph, SmallText } from "../texts.styled";
import { defaultCoins } from "../compareSection/defaultCoins";
import RoiBattleDropdown from "./roiBattleDropdown";
import { PrimaryButton } from "../buttons/buttons.styled";
import {
  getAllCoinsWithAllData,
  getAllCoinsWithPrice,
  getCoinPage,
  getCommonHolders,
  getHolderCompareList,
} from "../stream";
import { formatNumber, getSmallNumber } from "../../util/numberFormatter";
import { RouteLink } from "../../routing/routeLink";
import { MdOutlineCompareArrows } from "react-icons/md";
import { BsPersonFill } from "react-icons/bs";
import Loader from "../misc/loader";
import { useQuery } from "@tanstack/react-query";
import { all } from "axios";
import { Icon } from "../icon.styled";
import { IoMdArrowForward } from "react-icons/io";
import { toast } from "react-toastify";

export default function CommonHolders({ coin, margin }) {
  const [otherCoin, setOtherCoin] = useState(null);
  const notify = () => toast("There has been an error with the pair.");

  const [holderData, setHolderData] = useState(null);

  const [isLoading, setLoading] = useState(false);

  const { data: holderList, status: holderListStatus } = useQuery(
    ["getHolderCoinsList", "coinPage"],
    () => getHolderCompareList(),
    {
      staleTime: Infinity,
    }
  );

  useEffect(() => {
    setHolderData(null);
  }, [otherCoin]);

  useEffect(() => {
    return () => {
      setHolderData(null);
      setOtherCoin(null);
    };
  }, []);

  const { data: dropdownData, status } = useQuery(
    ["common-holder", coin.id],
    () => getAllCoinsWithAllData(),
    {
      refetchOnWindowFocus: false,
      onSuccess: (data) => {},
    }
  );

  useEffect(() => {
    return () => {
      setHolderData(null);
      setOtherCoin(null);
    };
  }, []);

  const handleClick = async () => {
    if (!otherCoin) return;

    setLoading(true);

    try {
      const data = await getCommonHolders(
        coin.id,
        coin.contractAddress,
        otherCoin.id,
        otherCoin.contractAddress,
        coin.blockChain,
        otherCoin.blockChain
      );

      setHolderData({
        100: data.top100CommonCount,
        1000: data.top1000CommonCount,
        all: data.topAllCommonCount,
      });

      setLoading(false);
    } catch (e) {
      notify();
      setLoading(false);
    }
  };

  return (
    holderListStatus === "success" &&
    holderList?.length > 0 &&
    holderList.find((item) => item.id === coin.id) && (
      <StyledMiniCompare
        holderData={holderData}
        style={margin ? { margin: "0 0.5rem" } : {}}
      >
        <FullRow width="fit-content" gap="0.5rem" justify="center">
          <BsPersonFill />
          <LightHeader>COMMON HOLDERS</LightHeader>
        </FullRow>
        <FullRow gap="1rem" justify="center">
          <Row
            style={{
              padding: "0.4rem",
              borderRadius: borderRads.SMALL,
              width: "50%",
              backgroundColor: theme.colors.LEVEL,
            }}
            width="50%"
            gap="0.5rem"
            justify="end"
          >
            <Paragraph weight={textSizes.fontWeights.SEMI_BOLD}>
              {coin.symbol.toUpperCase()}
            </Paragraph>
            <img
              style={{
                width: "1.75rem",
                borderRadius: borderRads.SMALLER,
              }}
              alt="logo"
              src={coin.image}
            />
          </Row>
          <SmallText>vs</SmallText>
          {dropdownData ? (
            <RoiBattleDropdown
              setCoin={setOtherCoin}
              defaultCoin={
                holderList.filter(
                  (item) => item.blockChain === coin.blockChain
                )[0].id === coin.id
                  ? holderList.filter(
                      (item) => item.blockChain === coin.blockChain
                    )[1]
                  : holderList.filter(
                      (item) => item.blockChain === coin.blockChain
                    )[0]
              }
              isCommonHolders={true}
              coin={coin}
              items={holderList.filter(
                (item) => item.group === coin.group && item.id !== coin.id
              )}
              // items={dropdownData}
            />
          ) : (
            <Loader isSmall={true} />
          )}
        </FullRow>
        {holderData ? (
          <StyledCommonHolderResult>
            <Column align="center" width="100%" gap="0.65rem">
              <Column align="center" width="100%" gap="0.25rem">
                <SmallText>Common holder count</SmallText>
                <Header>{formatNumber(holderData.all)}</Header>
              </Column>

              <RouteLink to={`/holder-compare/${coin.id}/${otherCoin.id}`}>
                <Row gap="0.25rem">
                  <SmallText>Detailed holder analysis</SmallText>
                  <Icon>
                    <IoMdArrowForward />
                  </Icon>
                </Row>
              </RouteLink>
            </Column>
          </StyledCommonHolderResult>
        ) : isLoading ? (
          <Loader isSmall={true} />
        ) : (
          <button onClick={() => handleClick()}>
            <SmallText>Go</SmallText>
          </button>
        )}
      </StyledMiniCompare>
    )
  );
}

export const StyledCommonHolderResult = styled(FullRow)`
  & > *:first-child {
    & > *:first-child {
      & > *:first-child {
        color: ${theme.colors.LIGHT_TEXT};
      }
    }
    & > *:last-child {
      padding: 0.25rem 0.5rem;
      background-color: ${theme.colors.LEVEL};
      border-radius: ${borderRads.SMALL};
      color: ${theme.colors.LIGHT_TEXT};
      cursor: pointer;

      &:hover {
        background-color: ${theme.colors.LEVEL2};
      }
    }
  }

  gap: 0.5rem;
`;

export const StyledMiniCompare = styled(Column)`
  /* style={{
          backgroundColor: theme.colors.SECONDARY,
          borderRadius: borderRads.SMALL,
          padding: "0.5rem",
          transition: "0.4s",
          height: !resultData ? "9rem" : "17rem",
        }}
        align="center"
        width="100%"
        gap="1rem" */

  /* max-width: 25rem; */

  background-color: ${theme.colors.SECONDARY};
  border-radius: ${borderRads.SMALL};
  padding: 0.5rem;
  transition: 0.4s;
  height: ${({ holderData }) => (holderData ? "11.6rem" : "8.6rem")};
  align-items: center;
  width: 100%;
  gap: 1rem;

  button {
    background-color: ${theme.colors.LEVEL2};
    padding: 0.5rem 1rem;
    border-radius: ${borderRads.SMALL};
    border: none;
    width: 7rem;
  }

  //for min width 70rem, have 0.5erm padding and background color level2

  @media (min-width: 70rem) {
    padding: 1rem;
    background-color: ${theme.colors.LEVEL};
    height: ${({ holderData }) => (holderData ? "12.7rem" : "9.5rem")};

    ${StyledDropdown} {
      background-color: ${theme.colors.LEVEL2};
    }
  }
`;
