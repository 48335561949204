import styled, { keyframes } from "styled-components";
import { borderRads, borderWidths, textSizes } from "../sizes";
import { Column, FullRow, Row } from "../containers/flexbox.styled";
import { paddings } from "../paddings.styled";
import { FullScreenMenu } from "../containers/fullScreenMenu.styled";
import { Paragraph } from "../texts.styled";
import { zIndexes } from "../globalStyle.styled";
import { Icon } from "../icon.styled";

export const StyledSearchBar = styled.div`
  top: 0;
  bottom: 0;
  position: relative;
  background-color: rgba(255, 255, 255, 0.05);
  width: 100%;
  height: 2.4rem;
  border-radius: ${borderRads.SMALL};

  input {
    height: 100%;
    width: 100%;
    background-color: transparent;
    border: none;
    border-radius: ${borderRads.SMALL};
    color: white;
    padding-left: 1rem;
    font-size: ${textSizes.fontSizes.MEDIUM};
  }
`;

export const StyledMobileSearchMenu = styled(FullScreenMenu)`
  ${Row} {
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
      display: none; /* Safari and Chrome */
    }
  }
  overflow-y: scroll;
  input {
    background-color: rgba(255, 255, 255, 0);
    padding: ${paddings.MID};
    height: 100%;
    width: 100%;
    //remove focus border
    outline: none;
    border: none;
    &::placeholder {
      color: white !important;
    }
    font-size: 1rem;
  }

  .search-bar {
    width: 100%;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: ${borderRads.SMALL};
    height: 3rem;
    border: none;
  }
`;

export const MobileSearchMostCompared = styled(Row)`
  width: 100%;
  overflow-x: scroll;

  ${Column} {
    gap: 0.5rem;
    img {
      height: 3rem;
      cursor: pointer;
    }
  }

  ${Paragraph} {
    font-weight: ${textSizes.fontWeights.SEMI_BOLD};
    cursor: pointer;
  }

  gap: 3rem;

  padding-left: ${paddings.HIGH};
`;

export const MobileSearchRow = styled(FullRow)`
  gap: 0.75rem;
  img {
    width: 1.5rem !important;
  }

  background-color: ${({ isFocused }) => isFocused && "rgba(255,255,255,0.1)"};
`;

const fadeIn = keyframes`
  from {
    height: 0rem;
  }
  to {
    height: 30rem;
  }
`;

export const StyledDesktopSearchMenu = styled(Column)`
  animation: ${fadeIn} 0.3s forwards;
  background-color: rgba(0, 0, 0, 0.9);
  position: absolute;
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
  min-height: 10rem;
  height: 30rem;
  max-height: 30rem;
  width: 100%;
  border-radius: ${borderRads.SMALL};
  z-index: ${zIndexes.DROPDOWN_MENU_SEARCH_INPUT};
  overflow-y: scroll;

  ${MobileSearchRow} {
    border-radius: ${borderRads.SMALL};
    &:hover {
      background-color: rgba(255, 255, 255, 0.1);
      ${Icon} {
        opacity: 1;
      }
    }
    padding: ${paddings.LOW};
    img {
      width: 2rem;
      height: auto;
    }

    ${Icon} {
      margin-left: auto;
      height: 0;
      opacity: 0;
    }
  }

  ${Row} {
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
      display: none; /* Safari and Chrome */
    }
  }

  border: ${borderWidths.MEDIUM} solid ${({ theme }) => theme.colors.LEVEL};
`;
