import { isNumber } from "chart.js/helpers";
import { useContext, useEffect, useRef, useState } from "react";
import { FiTrendingUp } from "react-icons/fi";
import { MdKeyboardArrowRight } from "react-icons/md";
import { RxPinTop } from "react-icons/rx";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AllCoinsContext } from "../../context";
import { RouteLink } from "../../routing/routeLink";
import {
  add3Dots,
  formatNumber,
  getSmallNumber,
  graphFormatter,
  maxLittleNumber,
} from "../../util/numberFormatter";
import { OutlineButton, TransparentButton } from "../buttons/buttons.styled";
import { FullRow, Row } from "../containers/flexbox.styled";
import { HideOnMobileWrapper } from "../hideOnMobile";
import { Icon } from "../icon.styled";
import { borderWidths, textSizes } from "../sizes";
import { Header, SmallText } from "../texts.styled";
import { StyledTable, StyledTableWrapper } from "./table.styled";
import TableSwitch from "./tableSwitch";
import Loader from "../misc/loader";
import { useQuery } from "@tanstack/react-query";
import { getAllCoinsWithAllData } from "../stream";
import React from "react";

export default function TopMarketCapTable({ isWithSwitch, isExternal }) {
  const navigate = useNavigate();

  // const allCoins = useContext(AllCoinsContext);

  let [searchParams, setSearchParams] = useSearchParams();

  let [page, setPage] = useState(1);
  let [pageCount, setPageCount] = useState(1);

  const { data: allCoins, status: allCoinsStatus } = useQuery(
    ["allcoinsmarketcap"],
    () => getAllCoinsWithAllData(),
    {
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        let length = data.length;
        setPageCount(length / 100 + 1);

        if (
          searchParams.get("page") !== null &&
          isNumber(searchParams.get("page"))
        ) {
          if (Number(searchParams.get("page")) > pageCount)
            setPage(Math.floor(pageCount));
          else if (Number(searchParams.get("page")) < 0) setPage(1);
          else setPage(searchParams.get("page"));
        }

        if (searchParams === null) setPage(1);
      },
    }
  );

  useEffect(() => {
    if (allCoinsStatus !== "success") return;
    if (page > Math.floor(pageCount)) setPage(Math.floor(pageCount));

    setSearchParams({ page: page });
  }, [page]);

  const scrollRef = useRef(null);

  function moveToTop() {
    scrollRef.current?.scrollIntoView({ behavior: "smooth" });
  }

  return (
    <StyledTableWrapper ref={scrollRef} isExternal={isExternal}>
      <FullRow verticalOnMobile={true} justify={"space-between"}>
        <Row notFullOnMobile={true} gap={"1rem"}>
          <Icon size={"2rem"} color={({ theme }) => theme.colors.GREEN}>
            <FiTrendingUp />
          </Icon>
          <Header>Cryptocurrency Prices by Market Cap</Header>
        </Row>
        {isWithSwitch && <TableSwitch index={2} />}
      </FullRow>
      {allCoinsStatus === "success" ? (
        <React.Fragment>
          <div style={{ overflowX: "auto", width: "100%" }}>
            <StyledTable>
              <tr>
                <th>#</th>
                <th>Coin</th>
                <th>Price</th>
                <th>1h</th>
                <th>1d</th>
                <th>7d</th>
                <th>Volume(24h)</th>
                <th>Market Cap</th>
              </tr>
              {allCoins
                ?.slice((page - 1) * 100, page * 100)
                .map((item, index) => (
                  <tr onClick={() => navigate(`/coin/${item.id}`)} key={index}>
                    <td>
                      <SmallText>{(page - 1) * 100 + index + 1} </SmallText>
                    </td>
                    <td>
                      <Row gap={"0.7rem"}>
                        <img alt="logo" src={item.image} />
                        <HideOnMobileWrapper>
                          {add3Dots(item.coinName, 20)}
                        </HideOnMobileWrapper>
                        <SmallText
                          weight={textSizes.fontWeights.SEMI_BOLD}
                          color={({ theme }) => theme.colors.LIGHT_TEXT}
                        >
                          {item.symbol.toUpperCase()}
                        </SmallText>
                      </Row>
                    </td>
                    {item.price < 0.001 ? (
                      <td>
                        <SmallText>
                          $0.0
                          <SmallText
                            size={textSizes.fontSizes.MOBILE_LITTLE_NUMBER}
                          >
                            {getSmallNumber(item.price).zeroCount}
                          </SmallText>
                          {getSmallNumber(item.price).nonZeroDigits}
                        </SmallText>
                      </td>
                    ) : (
                      <td>
                        <SmallText>${formatNumber(item.price)}</SmallText>
                      </td>
                    )}
                    <td>
                      <SmallText
                        style={
                          item.change1H
                            ? item.change1H > 1
                              ? { color: "#82dd55" }
                              : { color: "#ff3f3f" }
                            : { color: "#ffffff" }
                        }
                      >
                        {item.change1H
                          ? `${graphFormatter(item.change1H, false, true)}%`
                          : "-"}
                      </SmallText>
                    </td>
                    <td>
                      <SmallText
                        style={
                          item.change24H
                            ? item.change24H > 1
                              ? { color: "#82dd55" }
                              : { color: "#ff3f3f" }
                            : { color: "#ffffff" }
                        }
                      >
                        {item.change24H
                          ? `${graphFormatter(item.change24H, false, true)}%`
                          : "-"}
                      </SmallText>
                    </td>
                    <td>
                      <SmallText
                        style={
                          item.change7D
                            ? item.change7D > 1
                              ? { color: "#82dd55" }
                              : { color: "#ff3f3f" }
                            : { color: "#ffffff" }
                        }
                      >
                        {item.change7D
                          ? `${graphFormatter(item.change7D, false, true)}%`
                          : "-"}
                      </SmallText>
                    </td>
                    <td>
                      <SmallText>${graphFormatter(item.volume24H)}</SmallText>
                    </td>
                    <td>
                      <SmallText>${graphFormatter(item.marketcap)}</SmallText>
                    </td>
                  </tr>
                ))}
            </StyledTable>
          </div>
          <Row notFullOnMobile={true} gap={"1rem"} align={"center"}>
            {page > 1 && (
              <OutlineButton
                borderWidth={borderWidths.SMALL}
                hoverColor={({ theme }) => theme.colors.BRAND}
                borderColor={({ theme }) => theme.colors.BRAND}
                onClick={() => setPage((prevState) => Number(prevState) - 1)}
              >
                Previous
              </OutlineButton>
            )}

            {page < Math.floor(pageCount) && (
              <OutlineButton
                borderWidth={borderWidths.SMALL}
                hoverColor={({ theme }) => theme.colors.BRAND}
                borderColor={({ theme }) => theme.colors.BRAND}
                onClick={() => setPage((prevState) => Number(prevState) + 1)}
              >
                Next
              </OutlineButton>
            )}
          </Row>

          <TransparentButton
            style={{ marginTop: "1rem" }}
            color={"white"}
            onClick={() => moveToTop()}
          >
            <Icon color={"white"} size={"2rem"}>
              <RxPinTop />
            </Icon>
          </TransparentButton>
          {!isWithSwitch && (
            <RouteLink aria-label="Go to top gainers." to={"/top-gainers"}>
              <Row gap={"0.3rem"}>
                <SmallText>See more</SmallText>
                <Icon size={"1.5rem"}>
                  <MdKeyboardArrowRight />
                </Icon>
              </Row>
            </RouteLink>
          )}
        </React.Fragment>
      ) : (
        <Loader />
      )}
    </StyledTableWrapper>
  );
}
