import { ThemeProvider } from "styled-components";

export const theme = {
  colors: {
    PRIMARY: "#17072B",
    SECONDARY: "rgba(255, 255, 255, 0.05)",
    LEVEL: "rgba(255, 255, 255, 0.03)",
    LEVEL2: "rgba(255, 255, 255, 0.07)",
    OLDLEVEL: "#31263F",
    OLDLEVEL2: "#453955",
    BRAND: "#711a75",
    BRAND_HOVER: "#7B2D7F",
    TEXT: "#FFFFFF",
    LIGHT_TEXT: "#B4B4B4",
    GREEN: "#A6FF55",
    RED: "#FF3F3F",
    BLUE: "#4369f2",
    BLUE2: "#2b56f0",
    BADGE_MAIN_BORDER: "#711a75",
    BADGE_ALTERNATE_BORDER: "#4368F2",
    AQUARIUM: "#00B0FF",
    HALVING: "#0085FF",
    GOLD: "#8A631D",
    GOLD2: "#FFD58A",
  },
  fontFamily: {
    DEFAULT: "Montserrat, sans-serif",
  },
};

export const Theme = ({ children }) => (
  <ThemeProvider theme={theme}>{children}</ThemeProvider>
);
