import { useMediaQuery } from "react-responsive";

const textSizes = {
  fontWeights: {
    THIN: 100,
    EXTRA_LIGHT: 200,
    LIGHT: 300,
    REGULAR: 400,
    MEDIUM: 500,
    SEMI_BOLD: 600,
    BOLD: 700,
    EXTRA_BOLD: 800,
    BLACK: 900,
  },
  fontSizes: {
    XLARGE: "3rem",
    LARGE: "2rem",
    BIG: "1.8rem",
    HEADER: "1.3rem",
    MEDIUM: "1rem",
    SMALL: "0.9rem",
    SMALLER: "0.8rem",
    MOBILE_LITTLE_NUMBER: "0.6rem",
  },
};

const iconSizes = {
  LARGE: 2,
  MEDIUM: 1,
  SMALL: 0.75,
};

const borderRads = {
  LARGE: "2rem",
  MEDIUM: "1rem",
  SM: "0.7rem",
  SMALL: "0.5rem",
  SMALLER: "0.3rem",
};

const borderWidths = {
  LARGE: "0.3rem",
  MEDIUM: "0.2rem",
  SMALL: "0.1rem",
};

const boxSizes = {
  SHORT: "25rem",
};

const LEFT_MARGIN = "2rem";
const RIGHT_MARGIN = "2rem";

const GRID_GAP = "1rem";

export {
  textSizes,
  iconSizes,
  borderRads,
  borderWidths,
  LEFT_MARGIN,
  RIGHT_MARGIN,
  boxSizes,
  GRID_GAP,
};
