import styled from "styled-components";
import { StyledDropdown } from "../compareSection/dropdown.styled";
import { Column, FullRow, Row } from "../containers/flexbox.styled";
import { paddings } from "../paddings.styled";
import { borderRads, borderWidths } from "../sizes";
import { BigText, Paragraph, Title } from "../texts.styled";

export const StyledTerminal = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  /* background-color: black; */
  background-image: ${({ image }) =>
    `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), ${image}` || ""};
  background-size: cover;
  background-position: center;
  /* backdrop-filter: brightness(10%); */
  z-index: 100;
`;

export const TerminalDropdownWrapper = styled.div`
  width: 20rem;
  max-width: 25rem;
  @media (max-width: 70rem) {
    width: 100%;
  }

  ${StyledDropdown} {
    background-color: rgba(255, 255, 255, 0.1);
  }
`;

export const StyledTerminalMainColumn = styled(Column)`
  margin: 9rem auto;
  max-width: 80rem;
  gap: 8rem;
  padding: 0 1rem;

  @media (max-width: 90rem) {
    width: 90%;
    margin: 3rem auto;
  }

  @media (max-width: 70rem) {
    margin: 1rem auto;
    gap: 2rem;
    width: 100%;
    & > *:first-child {
      gap: 3rem;
      align-items: center;
      flex-direction: column-reverse;
    }
  }
`;

export const StyledTerminalCoinInfo = styled(Row)`
  gap: 1rem;

  width: fit-content;

  img {
    width: 6rem;
    padding: ${paddings.LOW};
    border-radius: 24421rem;
    background-color: rgba(255, 255, 255, 0.2);
  }

  ${Paragraph} {
    color: ${({ theme }) => theme.colors.LIGHT_TEXT};
  }

  @media (max-width: 70rem) {
    img {
      width: 4rem;
    }
  }
`;

export const StyledTerminalMarketData = styled(Row)`
  width: 100%;
  justify-content: space-between;
  @media (max-width: 70rem) {
    flex-direction: column;
  }

  gap: 2rem;

  ${Paragraph} {
    font-size: 2rem;
  }

  ${Title} {
    font-size: 5rem;
  }

  /* height: 17rem; */

  & > *:first-child,
  & > *:last-child {
    width: 33%;
  }

  @media (max-width: 70rem) {
    ${Title} {
      font-size: 2.5rem;
    }
    ${BigText} {
      font-size: 1.5rem;
    }
    ${Paragraph} {
      font-size: 1.5rem;
    }
    ${Column} {
      align-items: center;
    }
  }
`;

export const TerminalColumn = styled(Column)`
  height: 100%;
  width: 33%;
  align-items: ${({ align }) => align || "center"};
  justify-content: space-between;

  @media (max-width: 70rem) {
    gap: 2rem;
  }
`;

export const StyledTerminalCategory = styled(Paragraph)`
  padding: ${paddings.LOWER} ${paddings.LOW};
  background-color: rgba(255, 255, 255, 0.2);
  font-size: 1.2rem !important;

  @media (max-width: 70rem) {
    font-size: 1rem !important;
  }
`;

export const TerminalControlButtons = styled(FullRow)`
  justify-content: end;
  gap: 2rem;
  position: absolute;
  bottom: 0;
  padding: 2rem;
  @media (max-width: 70rem) {
    gap: 1rem;
    padding: 1rem;
  }
`;

export const TerminalCustomizeMenuWrapper = styled.div`
  @keyframes fadeIn {
    from {
      opacity: 0;
      -webkit-backdrop-filter: blur(0px);
      backdrop-filter: blur(0px);
    }
    to {
      opacity: 1;
      -webkit-backdrop-filter: blur(20px);
      backdrop-filter: blur(20px);
    }
  }

  animation: fadeIn 0.3s forwards;
  background-color: rgba(0, 0, 0, 0.9);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 2rem;
`;

export const TerminalCustomizeMenu = styled(FullRow)`
  background-color: ${({ theme }) => theme.colors.SECONDARY};
  width: fit-content;
  height: fit-content;
  flex-wrap: wrap;
  gap: 1rem;
  border-radius: ${borderRads.MEDIUM};
  border: ${borderWidths.MEDIUM} solid ${({ theme }) => theme.colors.LEVEL};
  padding: ${paddings.MID};

  button,
  label {
    border: none;
    width: 12rem;
    height: 4rem;
    border-radius: ${borderRads.SMALL};
    background-position: center;
    background-size: cover;
    background-color: ${({ theme }) => theme.colors.LEVEL2};
    cursor: pointer;
  }

  button:hover,
  label:hover {
    filter: brightness(0.9);
  }

  align-items: center;
  justify-content: center;

  @media (max-width: 70rem) {
    width: 90%;
  }

  label {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  @media (max-width: 50rem) {
    width: 90%;
    button,
    label {
      width: 100%;
    }
  }
`;
