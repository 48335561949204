import styled from "styled-components";
import {borderRads, borderWidths, textSizes} from "../sizes";
import {Column, FullRow} from "../containers/flexbox.styled";
import {paddings} from "../paddings.styled";
import {Paragraph, SmallText} from "../texts.styled";

export const BlogTagsWrapper = styled.div`
  margin-right: auto;
  width: 100%;
  @media(min-width: 40rem){
    width: 22rem;
  }
`

export const BlogBoxWrapper = styled.div`
  margin-top: 1rem;
  width: 100%;
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
  @media(min-width: 80rem){
    grid-template-columns: repeat(auto-fit, minmax(22rem, 1fr));
  }
`

export const BlogBox = styled.div`
  overflow: hidden;
  img{
    object-position: center;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  border-radius: ${borderRads.SMALL};
  border: ${borderWidths.SMALL} solid ${({theme}) => theme.colors.LEVEL};
  height: 20rem;
  position: relative;
  @media(max-width: 40rem){
    height: 20rem;
  }
`

export const BlogBoxInfo = styled(Column)`
  align-items: start;
  gap: 0.2rem;
  position: absolute;
  width: 100%;

  bottom: 0;
  background-color: rgba(0,0,0,0.6);
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
  padding: ${paddings.MID};
  
  button{
    margin-top: 1rem;
    @media(max-width: 40rem){
      margin-top: 0.2rem;
    }
    padding: ${paddings.LOW} ${paddings.MID};
    background-color: transparent;
    border: ${borderWidths.SMALL} solid white;
    border-radius: ${borderRads.SMALL};
    transition: 0.4s;
    &:hover{
      background-color: white;
      color: black;
      transition: 0.4s;
    }
  }
  
  ${Paragraph}{
    font-weight: ${textSizes.fontWeights.SEMI_BOLD};
    font-size: 1.2rem;
  }

  ${SmallText}{
    color: ${({theme}) => theme.colors.LIGHT_TEXT};
    font-size: ${textSizes.fontSizes.SMALLER};
    overflow: hidden;
  }
`

export const BlogBoxTagsWrapper = styled(FullRow)`
  position: absolute;
  top: 0.2rem;
  right: 0.2rem;
  padding: ${paddings.LOW};
  justify-content: end;
  flex-wrap: wrap;
  gap: 0.5rem;
`

export const BlogBoxTag = styled.div`
  padding: ${paddings.LOWER} ${paddings.LOW};
  background-color: rgba(0,0,0,0.3);
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
  border-radius: ${borderRads.SMALL};
  font-size: ${textSizes.fontSizes.SMALLER};
`