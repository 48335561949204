import { StyledLoader } from "./loader.styled";

export default function Loader({
  height,
  isSmall,
  isRealSmall,
  width,
  position,
  right,
}) {
  return (
    <div
      style={{
        width: width || "100%",
        height: height,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: position,
        right: right,
      }}
    >
      <StyledLoader isSmall={isSmall} isRealSmall={isRealSmall} />
    </div>
  );
}
