import { useQuery } from "@tanstack/react-query";
import React, { Suspense, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { useMediaQuery } from "react-responsive";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Container } from "../components/containers/boxes.styled";
import { Column, FullRow, Row } from "../components/containers/flexbox.styled";
import { RoiCalculatorTable } from "../components/dedicatedCoin/roiCalculator.styled";
import Loader from "../components/misc/loader";

import {
  getAquariumCheck,
  getCoinPage,
  getComparedMostlyWith,
} from "../components/stream";
import { Paragraph, SmallText } from "../components/texts.styled";
import { DedicatedCoinContext } from "../context";
import {
  add3Dots,
  formatNumber,
  graphFormatter,
} from "../util/numberFormatter";
import { borderRads, borderWidths, textSizes } from "../components/sizes";
import CoinInfo from "../components/dedicatedCoin/coinInfo";
import { queryClient } from "../App";
import { AreaChartWrapper } from "../components/dedicatedCoin/simpleChart";
import Example from "../components/dedicatedCoin/simpleChartBar";
import BarChartWrapper from "../components/dedicatedCoin/simpleChartBar";
import { paddings } from "../components/paddings.styled";
import { theme } from "../components/theme.styled";
import parse from "html-react-parser";
import styled from "styled-components";
import { zIndexes } from "../components/globalStyle.styled";
import { TfiFaceSad } from "react-icons/tfi";
import RoiBattle from "../components/dedicatedCoin/roiBattle";
import MiniCompare from "../components/dedicatedCoin/miniCompare";
import ComparedMostlyWithMobile from "../components/dedicatedCoin/comparedMostlyWithMobile";
import ComparerFive from "../components/dedicatedCoin/comparerFive";
import CommonHolders from "../components/dedicatedCoin/commonHolders";
import DedicatedCoinNewDesktop from "./dedicatedCoinNewDesktop";
import ScrollInfo from "../components/dedicatedCoin/scrollInfo";
import { ShowOnMobileWrapper } from "../components/hideOnMobile";
import { set } from "date-fns";
import { Icon } from "../components/icon.styled";

const GraphLazy = React.lazy(() =>
  import("../components/dedicatedCoin/dedicatedCoinGraph")
);

export default function DedicatedCoinNew({ roi, pageMode }) {
  let { coinId } = useParams();

  const [isError, setError] = useState(false);

  const scrollRef = useRef(null);

  const { data, status } = useQuery(
    ["dedicated", coinId],
    () => getCoinPage(coinId),
    {
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        //if path includes roi or compare, return

        if (
          window.location.pathname.includes("roi") ||
          window.location.pathname.includes("compare")
        ) {
          return;
        }

        setActivePage(0);
      },
      onError: (error) => {
        setError(true);
      },
    }
  );

  const [roiOn, setRoiOn] = useState(roi);
  const [holderCount, setHolderCount] = useState(null);
  const [oneDayChange, setOneDayChange] = useState(null);

  const handleOneDayChange = (value) => {
    if (value) setOneDayChange(value);
    else setOneDayChange(data.change24H);
  };

  const [activePage, setActivePage] = useState(0);

  const isMobile = useMediaQuery({
    query: "(max-width: 70rem)",
  });

  useEffect(() => {
    if (roiOn === true) {
      setActivePage(1);
    }

    //if the url is /compare, setActivePate 2
    if (window.location.pathname === "/coin/" + coinId + "/compare") {
      setActivePage(2);
    }
  }, [roiOn]);

  const navigate = useNavigate();

  useEffect(() => {
    const handleNavigation = (e) => {
      navigate(0);
    };

    window.addEventListener("popstate", handleNavigation);

    return () => {
      window.removeEventListener("popstate", handleNavigation);
    };
  }, [navigate]);

  const { data: comparedMostlyWithData, status: comparedMostlyWithStatus } =
    useQuery(
      ["compared-mostly-with", coinId],
      () => getComparedMostlyWith(coinId),
      {
        staleTime: Infinity,
      }
    );

  const location = useLocation();

  useEffect(() => {
    setHolderCount(null);
  }, [location]);

  useEffect(() => {
    return () => {
      setHolderCount(null);
      queryClient.invalidateQueries(["dedicated", coinId]);
    };
  }, []);

  return (
    <>
      <DedicatedCoinMobileContainer key={coinId + "1"}>
        <Helmet>
          <title>
            {status === "success"
              ? `${
                  data.coinName
                }(${data.symbol.toUpperCase()}) Price, Market Cap, Volume Data`
              : "CompareMarketCap"}
          </title>

          <meta
            name="description"
            content={
              status === "success"
                ? `${
                    data.coinName
                  }(${data.symbol.toUpperCase()})'s current price is $${formatNumber(
                    data.price
                  )} with a market cap of $${
                    data.marketcap !== 0 ? formatNumber(data.marketcap) : "-"
                  }.`
                : "Find out the value of your cryptocurrency, or crypto coin, compared to the market cap of other cryptocurrencies by comparing them."
            }
          />

          {/* <meta name="coinzilla" content="7e7181bdad599d80e8d5b6d0c4799b4c" />
      <script src="https://coinzillatag.com/lib/sticky.js"></script>
      <script>
        {`
              window.coinzilla_sticky = window.coinzilla_sticky || [];
                  function czilla() {coinzilla_sticky.push(arguments);}
                  czilla('36662f1037eea083937');
              `}
      </script> */}
        </Helmet>

        {status === "success" ? (
          <DedicatedCoinContext.Provider value={data}>
            {activePage === 0 && (
              <Container gap={"0.5rem"}>
                <ScrollInfo />
                <CoinInfo
                  holderCount={holderCount}
                  comparedMostlyWithData={comparedMostlyWithData}
                  comparedMostlyWithStatus={comparedMostlyWithStatus}
                  type={1}
                  oneDayChange={oneDayChange}
                />{" "}
                <AreaChartWrapper
                  coinId={coinId}
                  coin={data}
                  key={coinId}
                  handleOneDayChange={handleOneDayChange}
                />
                <BarChartWrapper
                  key={coinId}
                  coinId={coinId}
                  coin={data}
                  setHolderCount={setHolderCount}
                />
                <ComparerFive coin={data} />
                <Faq coin={data} />
              </Container>
            )}

            {activePage === 1 && (
              <Container>
                <CoinInfo
                  holderCount={holderCount}
                  comparedMostlyWithData={comparedMostlyWithData}
                  comparedMostlyWithStatus={comparedMostlyWithStatus}
                  type={2}
                  oneDayChange={oneDayChange}
                />
                <RoiCalculatorTable coin={data} />
              </Container>
            )}

            {activePage === 2 && (
              <Container>
                <CoinInfo
                  holderCount={holderCount}
                  comparedMostlyWithData={comparedMostlyWithData}
                  comparedMostlyWithStatus={comparedMostlyWithStatus}
                  type={3}
                  oneDayChange={oneDayChange}
                />
                <MiniCompare margin={true} coin={data} />
                {data.blockChain !== "tron" && (
                  <RoiBattle margin={true} coin={data} />
                )}
                {data.blockChain && data.contractAddress && (
                  <CommonHolders margin={true} coin={data} />
                )}
                <ShowOnMobileWrapper>
                  <p style={{ height: "0.5rem" }}></p>
                </ShowOnMobileWrapper>
              </Container>
            )}
          </DedicatedCoinContext.Provider>
        ) : isError ? (
          <Column gap="2rem" style={{ margin: "15rem auto" }}>
            <Icon size="8rem" color={({ theme }) => theme.colors.LIGHT_TEXT}>
              <TfiFaceSad />
            </Icon>
            <Paragraph color={({ theme }) => theme.colors.LIGHT_TEXT}>
              No such coin as "{coinId}".
            </Paragraph>
          </Column>
        ) : (
          <Loader height={"40rem"} />
        )}
      </DedicatedCoinMobileContainer>

      <DedicatedCoinDesktopContainer key={coinId + "2"}>
        <DedicatedCoinNewDesktop
          isError={isError}
          coinId={coinId}
          setHolderCount={setHolderCount}
          oneDayChange={oneDayChange}
          handleOneDayChange={handleOneDayChange}
          holderCount={holderCount}
          status={status}
          data={data}
          comparedMostlyWithData={comparedMostlyWithData}
          comparedMostlyWithStatus={comparedMostlyWithStatus}
        />
      </DedicatedCoinDesktopContainer>
      {status === "success" && (
        <DedicatedCoinBottomBar
          activePage={activePage}
          setActivePage={setActivePage}
          coin={data}
        />
      )}
    </>
  );
}

export const DedicatedCoinDesktopContainer = styled(Container)`
  @media (max-width: 50rem) {
    display: none;
  }
`;

export const DedicatedCoinMobileContainer = styled(Container)`
  @media (min-width: 50rem) {
    display: none;
  }
`;

export const Faq = ({ coin }) => {
  const [isTextExpanded, setTextExpanded] = useState(false);

  return (
    <>
      {coin.description && (
        <div
          style={{
            width: "100%",
            padding: paddings.MID,
            backgroundColor: theme.colors.SECONDARY,
            borderRadius: borderRads.SMALL,
          }}
        >
          <Column align="center" width={"100%"} gap={"1rem"}>
            <Paragraph weight={textSizes.fontWeights.SEMI_BOLD}>
              Description
            </Paragraph>
            <Column align="start" width={"100%"} gap={"0.5rem"}>
              <Paragraph
                color={theme.colors.LIGHT_TEXT}
                size={textSizes.fontSizes.SMALL}
              >
                {isTextExpanded ? (
                  <React.Fragment>
                    <span
                      style={{
                        color: theme.colors.LIGHT_TEXT,
                      }}
                    >
                      {parse(coin.description)}
                    </span>
                    <span
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={() => setTextExpanded(false)}
                    >
                      {" "}
                      See less
                    </span>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <span
                      style={{
                        color: theme.colors.LIGHT_TEXT,
                      }}
                    >
                      {add3Dots(parse(coin.description), 250)}
                    </span>
                    {parse(coin.description).length > 250 && (
                      <span
                        style={{
                          cursor: "pointer",
                        }}
                        onClick={() => setTextExpanded(true)}
                      >
                        {" "}
                        See more
                      </span>
                    )}
                  </React.Fragment>
                )}{" "}
              </Paragraph>
            </Column>
          </Column>
        </div>
      )}

      <div
        style={{
          width: "100%",
          padding: paddings.MID,
          backgroundColor: theme.colors.SECONDARY,
          borderRadius: borderRads.SMALL,
        }}
      >
        <Column align="center" width={"100%"} gap={"1.5rem"}>
          <Paragraph weight={textSizes.fontWeights.SEMI_BOLD}>
            {coin.symbol.toUpperCase()} FAQ
          </Paragraph>
          {coin.price && (
            <Column align="start" width={"100%"} gap={"0.5rem"}>
              <Paragraph weight={textSizes.fontWeights.SEMI_BOLD}>
                What is the current price of {coin.symbol.toUpperCase()}?
              </Paragraph>
              <Paragraph
                color={theme.colors.LIGHT_TEXT}
                size={textSizes.fontSizes.SMALL}
              >
                {coin.coinName}'s current price is {formatNumber(coin.price)}
              </Paragraph>
            </Column>
          )}
          {coin.marketcap && (
            <Column align="start" width={"100%"} gap={"0.5rem"}>
              <Paragraph weight={textSizes.fontWeights.SEMI_BOLD}>
                What is the current market cap of {coin.symbol.toUpperCase()}?
              </Paragraph>
              <Paragraph
                color={theme.colors.LIGHT_TEXT}
                size={textSizes.fontSizes.SMALL}
              >
                {coin.coinName}'s current market cap is{" "}
                {graphFormatter(coin.marketcap)}
              </Paragraph>
            </Column>
          )}
          {coin.ath && (
            <Column align="start" width={"100%"} gap={"0.5rem"}>
              <Paragraph weight={textSizes.fontWeights.SEMI_BOLD}>
                What is the ATH price of {coin.symbol.toUpperCase()}?
              </Paragraph>
              <Paragraph
                color={theme.colors.LIGHT_TEXT}
                size={textSizes.fontSizes.SMALL}
              >
                {coin.coinName}'s ATH is {formatNumber(coin.ath)}
              </Paragraph>
            </Column>
          )}
        </Column>
      </div>
    </>
  );
};

export const DedicatedCoinBottomBar = ({ coin, activePage, setActivePage }) => {
  const navigate = useNavigate();

  return (
    <StyledDedicatedCoinBottomBar>
      <FullRow justify="space-between">
        <StyledDedicatedCoinBottomBarPage
          isActive={activePage === 0}
          onClick={() => {
            setActivePage(0);
            navigate("/coin/" + coin.id);
          }}
        >
          INFO
        </StyledDedicatedCoinBottomBarPage>
        <StyledDedicatedCoinBottomBarPage
          isActive={activePage === 1}
          onClick={() => {
            setActivePage(1);
            navigate("/coin/" + coin.id + "/roi");
          }}
        >
          ROI
        </StyledDedicatedCoinBottomBarPage>
        <StyledDedicatedCoinBottomBarPage
          isActive={activePage === 2}
          onClick={() => {
            setActivePage(2);
            navigate("/coin/" + coin.id + "/compare");
          }}
        >
          COMPARE
        </StyledDedicatedCoinBottomBarPage>
      </FullRow>
    </StyledDedicatedCoinBottomBar>
  );
};

export const StyledDedicatedCoinBottomBar = styled.div`
  //hide if bigger than 50rem
  @media (min-width: 50rem) {
    display: none;
  }

  width: 100%;
  height: 2.8rem;
  background-color: #4a064c;
  //ADD A TOP BORDER
  display: flex;
  align-items: center;
  justify-content: center;
  outline: ${borderWidths.SMALL} solid rgba(255, 255, 255, 0.4);
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: ${zIndexes.SIDEBAR};

  & > *:first-child {
    height: 100%;
  }
`;

export const StyledDedicatedCoinBottomBarPage = styled(Paragraph)`
  font-size: ${textSizes.fontSizes.MEDIUM};
  text-align: center;
  width: 100%;

  //if is active, make it bold
  font-weight: ${({ isActive }) =>
    isActive ? textSizes.fontWeights.SEMI_BOLD : textSizes.fontWeights.NORMAL};

  line-height: 3rem;
  //add bottom border
  height: 100%;
  border-bottom: ${({ isActive }) =>
    isActive ? `${borderWidths.MEDIUM} solid white` : "none"};
`;
