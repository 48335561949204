import React, { useContext, useEffect, useRef, useState } from "react";
import { BsArrowRightShort } from "react-icons/bs";
import { AllCoinsContext, TrendsContext } from "../../context";
import { add3Dots } from "../../util/numberFormatter";
import { SearchMenuItem } from "../compareSection/dropdownItem";
import { Column } from "../containers/flexbox.styled";
import { Icon } from "../icon.styled";
import { paddings } from "../paddings.styled";
import {
  MobileSearchMostCompared,
  MobileSearchRow,
  StyledDesktopSearchMenu,
} from "../search/searchBar.styled";
import { textSizes } from "../sizes";
import { Header, Paragraph } from "../texts.styled";
import { RouteLink } from "../../routing/routeLink";
import { useNavigate } from "react-router-dom";

export default function DesktopSearchMenu({
  onClose,
  searchQuery,
  setSearchQuery,
  setLoading,
}) {
  const { mcData, trendingData } = useContext(TrendsContext);

  const allItems = useContext(AllCoinsContext);

  const [searchResults, setSearchResults] = useState(allItems);
  const [searchTimeout, setSearchTimeout] = useState(null);

  const [focusedIndex, setFocusedIndex] = useState(-1); // State for keyboard navigation
  const itemsRef = useRef([]); // Ref for storing item elements

  let firstQueryDone = false;

  const navigate = useNavigate();

  useEffect(() => {
    if (searchTimeout) clearTimeout(searchTimeout);
    setLoading(true);
    setSearchTimeout(
      setTimeout(() => {
        handleSearch();
      }, 700)
    );
  }, [searchQuery]);

  const handleSearch = () => {
    setFocusedIndex(-1); // Reset focused index on search

    const regex = new RegExp(searchQuery.toLowerCase(), "i");

    const filteredAndSortedItems = [];

    for (let i = 0; i < allItems.length; i++) {
      const item = allItems[i];

      if (
        regex.test(item.symbol) ||
        regex.test(item.coinName) ||
        (item.contractAddress && regex.test(item.contractAddress))
      ) {
        filteredAndSortedItems.push({ ...item, index: firstIndex(item) });
      }
    }
    filteredAndSortedItems.sort((a, b) => a.index - b.index);

    setSearchResults(filteredAndSortedItems);

    setLoading(false);
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      switch (event.key) {
        case "ArrowDown":
          event.preventDefault();
          setFocusedIndex((prevIndex) =>
            prevIndex <
            (searchQuery
              ? Math.min(searchResults.length, 100)
              : Math.min(allItems.length, 25)) -
              1
              ? prevIndex + 1
              : 0
          );
          break;
        case "ArrowUp":
          event.preventDefault();
          setFocusedIndex((prevIndex) =>
            prevIndex > 0
              ? prevIndex - 1
              : (searchQuery
                  ? Math.min(searchResults.length, 100)
                  : Math.min(allItems.length, 25)) - 1
          );
          break;
        case "Enter":
          if (focusedIndex >= 0) {
            onClose();
            navigate(`/coin/${searchResults[focusedIndex].id}`);
          }
          break;
        default:
          break;
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [focusedIndex]);

  const firstIndex = (item) => {
    const [name, symbol, ca] = [
      item.coinName.toLowerCase().indexOf(searchQuery.toLowerCase()),
      item.symbol.toLowerCase().indexOf(searchQuery.toLowerCase()),
      item.contractAddress
        ? item.contractAddress.indexOf(searchQuery.toLowerCase())
        : Infinity,
    ];

    let index = Infinity;
    for (let curr of [name, symbol, ca]) {
      if (curr === 0) {
        // Exit early if the first occurrence is found
        return 0;
      }

      if (curr < index && curr !== -1) {
        index = curr;
      }
    }

    return index;
  };

  return (
    <StyledDesktopSearchMenu>
      <Column width="100%" gap="1rem" align="start">
        {searchQuery !== "" && searchResults.length === 0 && (
          <Paragraph style={{ paddingLeft: "1rem", paddingTop: "1rem" }}>
            No coins found.
          </Paragraph>
        )}

        {searchQuery === "" && (
          <React.Fragment>
            <Column
              padding={`${paddings.MID} 0 ${paddings.MID} ${paddings.MID}`}
              width="100%"
              gap="1rem"
            >
              <Header width="100%">Most Compared 🔥</Header>
              <MobileSearchMostCompared>
                {mcData.slice(0, 4).map((item, index) => (
                  <RouteLink
                    onClick={() => onClose()}
                    key={index}
                    to={`/coin/${item.id}`}
                  >
                    <Column>
                      <img alt="logo" src={item.image} />
                      <Paragraph>{item.symbol.toUpperCase()}</Paragraph>
                    </Column>
                  </RouteLink>
                ))}
              </MobileSearchMostCompared>
            </Column>
            <Column padding={paddings.MID} width="100%" gap="1rem">
              <Header width="100%">Top Gainers 💸</Header>
              {trendingData.slice(0, 5).map((item, index) => (
                <RouteLink
                  style={{ width: "100%" }}
                  onClick={() => onClose()}
                  key={index}
                  to={`/coin/${item.id}`}
                >
                  <MobileSearchRow>
                    <img alt="logo" src={item.image} />
                    <Header size={"1.2rem"}>{item.symbol.toUpperCase()}</Header>
                    <Paragraph>{add3Dots(item.coinName, 10)}</Paragraph>
                    <Icon size={textSizes.fontSizes.LARGE}>
                      <BsArrowRightShort />
                    </Icon>
                  </MobileSearchRow>
                </RouteLink>
              ))}
            </Column>
          </React.Fragment>
        )}

        {
          <Column gap="1rem" padding={paddings.MID} width="100%">
            <Header width="100%">Search Results</Header>
            {searchResults.slice(0, 100).map((item, index) => {
              const isFocused = focusedIndex === index;

              return (
                <RouteLink
                  style={{ width: "100%" }}
                  key={index}
                  to={`/coin/${item.id}`}
                  onClick={() => onClose()}
                >
                  <SearchMenuItem
                    isFocused={isFocused}
                    symbol={item.symbol}
                    name={item.coinName}
                    img={item.image}
                  />
                </RouteLink>
              );
            })}
          </Column>
        }
      </Column>
    </StyledDesktopSearchMenu>
  );
}
