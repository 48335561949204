import React, { useContext, useState } from "react";
import { Arc } from "@visx/shape";
import { Group } from "@visx/group";
import { scaleOrdinal } from "@visx/scale";
import { useSprings, animated } from "@react-spring/web";
import { Tooltip, useTooltip } from "@visx/tooltip";
import localPoint from "@visx/event/lib/localPointGeneric";
import ReactECharts from "echarts-for-react";
import {
  add3Dots,
  formatNumber,
  graphFormatter,
} from "../../util/numberFormatter";
import { HolderCompareCoinsContext } from "../../context";
// 100 data points
const innerData = //array of 100 numbers
  Array.from({ length: 100 }, () => 1);

// Generate 100 unique colors
const colors = scaleOrdinal({
  domain: Array.from({ length: 100 }, (_, i) => i),
  range: [
    "#e60049",
    "#0bb4ff",
    "#50e991",
    "#e6d800",
    "#9b19f5",
    "#ffa300",
    "#dc0ab4",
    "#b3d4ff",
    "#00bfa0",
    "#ea5545",
    "#f46a9b",
    "#ef9b20",
    "#edbf33",
    "#ede15b",
    "#bdcf32",
    "#87bc45",
    "#27aeef",
    "#b33dc6",
    "#b30000",
    "#7c1158",
    "#4421af",
    "#1a53ff",
    "#0d88e6",
    "#00b7c7",
    "#5ad45a",
    "#8be04e",
    "#ebdc78",
    "#fd7f6f",
    "#7eb0d5",
    "#b2e061",
    "#bd7ebe",
    "#ffb55a",
    "#ffee65",
    "#beb9db",
    "#fdcce5",
    "#8bd3c7",
    "#e60049",
    "#0bb4ff",
    "#50e991",
    "#e6d800",
    "#9b19f5",
    "#ffa300",
    "#dc0ab4",
    "#b3d4ff",
    "#00bfa0",
    "#ea5545",
    "#f46a9b",
    "#ef9b20",
    "#edbf33",
    "#ede15b",
    "#bdcf32",
    "#87bc45",
    "#27aeef",
    "#b33dc6",
    "#b30000",
    "#7c1158",
    "#4421af",
    "#1a53ff",
    "#0d88e6",
    "#00b7c7",
    "#5ad45a",
    "#8be04e",
    "#ebdc78",
    "#fd7f6f",
    "#7eb0d5",
    "#b2e061",
    "#bd7ebe",
    "#ffb55a",
    "#ffee65",
    "#beb9db",
    "#fdcce5",
    "#8bd3c7",
    "#e60049",
    "#0bb4ff",
    "#50e991",
    "#e6d800",
    "#9b19f5",
    "#ffa300",
    "#dc0ab4",
    "#b3d4ff",
    "#00bfa0",
    "#ea5545",
    "#f46a9b",
    "#ef9b20",
    "#edbf33",
    "#ede15b",
    "#bdcf32",
    "#87bc45",
    "#27aeef",
    "#b33dc6",
    "#b30000",
    "#7c1158",
    "#4421af",
    "#1a53ff",
    "#0d88e6",
    "#00b7c7",
    "#5ad45a",
    "#8be04e",
    "#ebdc78",
    "#fd7f6f",
    "#7eb0d5",
    "#b2e061",
    "#bd7ebe",
  ],
});

const AnimatedArc = animated(Arc);

const HolderDistChart = ({ width, height, coin, coinNo, top }) => {
  const { compareData } = useContext(HolderCompareCoinsContext);

  const topCount = parseInt(top.replace("Top ", ""));
  const topData = compareData["top100Coin" + coinNo.toString()].slice(
    0,
    topCount
  );

  const othersSum =
    compareData[`coin${coinNo}CircSupply`] -
    topData.reduce((acc, item) => acc + item.balance, 0);

  const data = [
    ...topData.map((item) => ({
      value: item.balance,
      name: add3Dots(item.owner || item.walletaddress, 12).toString(),
      percent: item.percentage,
    })),
    {
      value: othersSum,
      name: "Others",
      percent:
        100 -
        (topData.reduce((acc, item) => acc + item.balance, 0) /
          compareData[`coin${coinNo}CircSupply`]) *
          100,
      itemStyle: {
        color: "rgba(255, 255, 255, 0.1)", // Set color to gray
      },
    },
  ];

  const option = {
    tooltip: {
      trigger: "item",
      backgroundColor: "rgba(0, 0, 0, 0.9)", // Tooltip background color
      width: "1000",
      textStyle: {
        color: "#fff", // White text color for contrast
        fontSize: 14, // Font size for better readability
      },
      formatter: (params) => {
        const { name, value, percent } = params.data;
        return `<div
                  style="
                    width: 11.3rem;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                  "
                >
                  <div
                    style="
                      width: 100%;
                      display: flex;
                      align-items: center;
                      justify-content: space-between;
                    "
                  >
                    <p style="color: rgba(255,255,255,0.5)
                      ; overflow: hidden;
                      text-overflow: ellipsis;
                      white-space: nowrap;
                    ">Holder:</p> ${name}<br />
                  </div>
                  <div
                    style="
                      width: 100%;
                      display: flex;
                      align-items: center;
                      justify-content: space-between;
                    "
                  >
                    <p style="font-weight: 600; color: rgba(255,255,255,0.5)">Balance:</p> ${graphFormatter(
                      value,
                      false,
                      false,
                      true
                    )} (${formatNumber(percent)}%)<br />
                  </div>
                </div>

        `;
      },
      position: (point, params, dom, rect, size) => {
        const [x, y] = point; // x and y coordinates of the mouse
        const tooltipWidth = size.contentSize[0]; // Width of the tooltip
        const chartWidth = rect.width; // Width of the chart
        const chartX = rect.x; // X position of the chart
        const offset = 10; // Offset from the pie chart

        // Determine the position based on the x coordinate
        // If the hover point is in the right half of the chart, position tooltip on the left
        /* const isRightSideOfChart = x > chartWidth; */

        const isRightSideOfChart = false;

        return isRightSideOfChart
          ? [x - tooltipWidth - offset, y] // Position on the left of the chart
          : [x + offset, y]; // Position on the right of the chart
      },
    },
    series: [
      {
        name: "Example Data",
        type: "pie",
        radius: ["50%", "90%"],
        padAngle: 0.4,
        data: data,

        label: {
          show: false,
          position: "center",
        },
        itemStyle: {
          borderRadius: 4, // Set the border radius
        },
      },
    ],
    graphic: [
      {
        type: "image",
        id: "centerImage",
        right: "center",
        bottom: "center",
        style: {
          image: coin.image,
          width: 33, // Adjust the width as needed
          height: 33, // Adjust the height as needed
        },
      },
    ],
  };
  return (
    <ReactECharts option={option} style={{ height: height, width: width }} />
  );
};

const HolderDistChartWrapper = ({ coin, coinNo, top }) => (
  <div>
    <HolderDistChart
      width={135}
      height={135}
      coin={coin}
      coinNo={coinNo}
      top={top}
    />
  </div>
);

export default HolderDistChartWrapper;
