import { Column, Row } from "../containers/flexbox.styled";
import { BigText, Header, SmallText } from "../texts.styled";
import { StyledHalvingInfo } from "./halvingInfo.styled";

export default function HalvingInfo() {
  return (
    <StyledHalvingInfo>
      <BigText>How does halving work?</BigText>
      <Row>
        <img src="/images/btc-three.png" />
        <Column>
          <Header>Digital Scarcity</Header>
          <SmallText>
            Bitcoin halving is a significant event in the crypto world that
            occurs approximately every four years, and the next one is expected
            in April 2024. Essentially, this event cuts the reward for mining
            Bitcoin transactions in half, decreasing the rate at which new
            bitcoins are generated and released into circulation. This halving
            process is built into the Bitcoin protocol by its creator, Satoshi
            Nakamoto, as a way to enforce digital scarcity, mimicking the effect
            of halving the inflow of a precious metal like gold.
          </SmallText>
        </Column>
      </Row>
      <Row>
        <Column>
          <Header>Reduced Rewards</Header>
          <SmallText>
            The 2024 halving will reduce the reward for mining a block from the
            current 6.25 bitcoins to 3.125 bitcoins. This event is closely
            watched by investors and enthusiasts alike, as it has historically
            led to increased volatility and significant price movements in the
            Bitcoin market, reflecting speculations on the reduced supply’s
            impact on Bitcoin’s value.
          </SmallText>
        </Column>
        <img src="/images/chart.png" />
      </Row>
    </StyledHalvingInfo>
  );
}
