import { ClickAwayListener } from "@mui/base";
import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { FullRow } from "../containers/flexbox.styled";
import DesktopSearchMenu from "../menus/DesktopSearchMenu";
import Loader from "../misc/loader";
import { StyledSearchBar } from "./searchBar.styled";

export default function SearchBar() {
  const [isDesktopSearchMenuOn, setDesktopSearchMenuOn] = useState(false);

  const isMobile = useMediaQuery({
    query: "(max-width: 70rem)",
  });

  const [searchQuery, setSearchQuery] = useState("");

  function updateSearchQuery(event) {
    setSearchQuery(event.target.value);
  }

  useEffect(() => {
    if (searchQuery && searchQuery.length > 0 && !isDesktopSearchMenuOn) {
      setDesktopSearchMenuOn(true);
    }
  }, [searchQuery]);

  function handleClose() {
    setSearchQuery("");
    setDesktopSearchMenuOn(false);
  }

  const [isLoading, setLoading] = useState(false);

  return (
    <StyledSearchBar>
      <FullRow style={{ height: "100%" }}>
        <input
          value={searchQuery}
          onChange={(e) => updateSearchQuery(e)}
          onClick={() => {
            setTimeout(() => setDesktopSearchMenuOn(true), 50);
          }}
          placeholder="Search by address or name"
        />
        {isLoading && <Loader width={"3rem"} isSmall={true} />}
      </FullRow>

      <ClickAwayListener
        onClickAway={() => {
          isDesktopSearchMenuOn === true && setDesktopSearchMenuOn(false);
        }}
      >
        <div
          style={
            !isMobile && isDesktopSearchMenuOn
              ? { display: "block" }
              : { display: "none" }
          }
        >
          <DesktopSearchMenu
            setSearchQuery={setSearchQuery}
            searchQuery={searchQuery}
            onClose={() => handleClose()}
            setLoading={setLoading}
          />
        </div>
      </ClickAwayListener>
    </StyledSearchBar>
  );
}
