import axios from "axios";
import { epoch } from "../util/numberFormatter";
import { isBlockChainValid, isContractAddressValid } from "../validators";

// const db = "r21r28j6cg.execute-api.eu-west-2.amazonaws.com/prod"; //cagri-dev
// const db = "989hals1s8.execute-api.eu-west-3.amazonaws.com/prod"; //beta api
// const db = "h74bf85gq5.execute-api.ap-southeast-1.amazonaws.com/prod"; //singapore api
// const db = "dybqtf9pd8.execute-api.eu-west-1.amazonaws.com/prod"; //euro api
const db = "api.comparemcap.com"; //prod
const s = "comparerarity.com";

export const getRecentlyListedHome = async () => {
  //
  const response = await axios.get(`https://${db}/recently-added-home`);

  let data = response.data;

  return data;
};

export const getRecentlyListedPage = async () => {
  //

  const response = await axios.get(`https://${db}/recently-added-page`);

  let data = response.data;

  return data;
};

export const getHolderCompareList = async () => {
  const response = await axios.get(`https://${db}/list-holder-compare`);
  let data = response.data;

  //remove shib from data
  data = data.filter((item) => item.id !== "shiba-inu");

  data = data.map((item) => {
    let group;
    if (
      item.blockChain === "eth" ||
      item.blockChain === "bsc" ||
      item.blockChain === "base"
    ) {
      group = "ebb";
    } else if (item.blockChain === "solana") {
      group = "sol";
    }
    return {
      ...item,
      group: group,
    };
  });
  return data;
};

export const sendCompareButtonClick = async (coin1, coin2) => {
  axios.post(`https://${db}/compare-click`, {
    coin1: coin1,
    coin2: coin2,
    time: new Date(),
  });
};

export const getMostCompared = async (limit) => {
  //

  const response = await axios.get(`https://${db}/most-compared`);

  let data = response.data;

  if (limit) data = data?.slice(0, limit);

  return data;
};

export const getMostComparedAll = async (limit) => {
  //

  const response = await axios.get(`https://${db}/most-compared-all`);

  let data = response.data;

  if (limit) data = data?.slice(0, limit);

  return data;
};

export const getMostComparedPairs = async () => {
  //

  const response = await axios.get(`https://${db}/get-most-compared-pairs`);

  let data = response.data;

  return data;
};

export const getComparedMostlyWith = async (id) => {
  //

  const response = await axios.get(
    `https://${db}/get-most-compared-pair/?coin=${id}`
  );

  let data = response.data;

  return data;
};

export const getTrending = async (limit) => {
  //

  const response = await axios.get(`https://${db}/trending`);

  let data = response.data;

  if (limit) data = response.data.slice(0, limit);

  return data;
};

let excludeFromAquarium = [
  "binancecoin",
  "usd-coin",
  "tether",
  "chainlink",
  "matic-network",
  "binance-usd",
];

export const getAllCoins = async (forAquarium = false, isWithSmallImage) => {
  //

  const response = await axios.get(`https://${db}/list`);

  let data = response.data;

  if (isWithSmallImage) {
    let newData = data.map((item) => {
      return { ...item, image: item.image.replace("large", "small") };
    });
    data = newData;
  }

  if (forAquarium) {
    data = data.filter(
      (item) =>
        isContractAddressValid(item.contractAddress) &&
        isBlockChainValid(item.blockChain) &&
        !excludeFromAquarium.includes(item.id)
    );
  }

  return data;
};

export const getAllCoinsWithAllData = async () => {
  const response = await axios.get(`https://${db}/all-marketcap`);

  let data = response.data;

  return data;
};

export const getPortfolioList = async () => {
  const response = await axios.get(`https://${db}/list-portfolio`);

  let data = response.data;

  return data;
};

export const getAllCoinsWithPrice = async () => {
  const response = await axios.get(`https://${db}/list-ext`);

  let data = response.data;

  return data;
};

export const getAquariumList = async () => {
  const response = await axios.get(`https://${db}/get-aquarium-list/`);

  let data = response.data;

  return data;
};

export const getAquariumCheck = async (id) => {
  const response = await axios.get(`https://${db}/aquarium-check`);

  let data = response.data;

  const isAquarium = data.some((item) => item.id === id);

  return isAquarium;
};

export const getCoin = async (id) => {
  //

  if (!id) return;

  const response = await axios.get(`https://${db}/get-coin/?id=${id}`);

  let data = response.data;

  return data;
};

export const getCoinPage = async (id) => {
  //

  // make it with try catch

  try {
    const response = await axios.get(`https://${db}/get-coin-page/?id=${id}`);

    let data = response.data;

    //if data.web null set it to empty string
    if (!data.web) {
      data.web = "";
    }

    if (
      data.web.slice(0, 5) === "http:") {
      data.web = data.web.replace("http:", "https:");
    }

    let group;

    if (
      data.blockChain === "eth" ||
      data.blockChain === "bsc" ||
      data.blockChain === "base"
    ) {
      group = "ebb";
    } else if (data.blockChain === "solana") {
      group = "sol";
    }

    data.group = group;

    return data;
  } catch (error) {
    throw error;
  }
};

export const getCoinHolders = async (bc, ca) => {
  //

  let data = 0;

  if (isBlockChainValid(bc) && isContractAddressValid(ca)) {
    let response = await axios.get(
      `https://${db}/get-holdercount/?blockChain=${bc}&contractAddress=${ca}`
    );

    response = JSON.parse(response.data.Payload).body;

    data = response.holderCounts[0].holderCount;
  }

  return data;
};

export const getAthMarketCap = async (id, athDate, cData) => {
  if (id === "bobo-coin-eth") {
    return 245000000;
  }

  if (id === "michicoin" || id === "shark-cat") return "coin-ds";

  if (cData.source === "ds") return "coin-ds";

  const response = await axios.get(`https://${db}/marketcap-history?id=${id}`);

  const data = response.data.market_caps;
  const mCapAth = data.find((item) => item[0] === epoch(athDate.slice(0, 10)));

  let result = mCapAth ? mCapAth[1] : null;

  if (result && result < cData.marketcap) {
    result = null;
  }

  return result;
};

export const getAthMarketCapDreamfolio = async (id, athDate, cData) => {
  /* {
    id: "bone-shibaswap",
    marketcap: 3563813413,
  },
  {
    id: "bobo-coin-eth",
    marketcap: 245000000,
  },
  {
    id: "floki",
    marketcap: 3500000000,
  }, */

  const response = await axios.get(`https://${db}/marketcap-history?id=${id}`);

  const data = response.data.market_caps;
  const mCapAth = data.find((item) => item[0] === epoch(athDate.slice(0, 10)));

  let result = mCapAth ? mCapAth[1] : null;

  if (result === 0) {
    let customData = await getCoinPage(id);
    let customAth = customData.ath;
    let customCircSupply = customData.circSupply;
    result = customAth * customCircSupply;
  }

  return result;
};

export const getDsHistoricalPriceData = async (
  pairAddress,
  chain,
  timeframe = "hour",
  limit = 1000,
  aggregate = 1
) => {
  const response = await axios.get(
    `https://${db}/marketcap-history-ds?pairAddress=${pairAddress}&network=${chain}&timeframe=${timeframe}&limit=${limit}&aggregate=${aggregate}`
  );

  const data = response.data.data.attributes.ohlcv_list.map((item) => {
    return {
      x: item[0],
      y: item[4],
    };
  });

  return data;

  const priceData = data.prices.map((item) => {
    return {
      x: item[0],
      y: item[1],
    };
  });

  const marketCapData = data.market_caps.map((item) => {
    return {
      x: item[0],
      y: item[1],
    };
  });

  return {
    marketcap: marketCapData,
    price: priceData,
  };
};

export const getNonDsHistoricalPriceData = async (id) => {
  const response = await axios.get(`https://${db}/marketcap-history?id=${id}`);

  const data = response.data;

  const priceData = data.prices.map((item) => {
    return {
      x: item[0],
      y: item[1],
    };
  });

  return priceData;
};

export const getHistoricalDataOfCoin = async (id) => {
  const response = await axios.get(`https://${db}/marketcap-history?id=${id}`);

  const data = response.data;

  const priceData = data.prices.map((item) => {
    return {
      x: item[0],
      y: item[1],
    };
  });

  const marketCapData = data.market_caps.map((item) => {
    return {
      x: item[0],
      y: item[1],
    };
  });

  return {
    marketcap: marketCapData,
    price: priceData,
  };
};

export const getHistoricalDataOfMultipleCoins = async (coins) => {
  //

  let historicalData = [];

  let isError = false;
  for (let i = 0; i < coins.length; i++) {
    const response = await axios
      .get(
        `https://api.coingecko.com/api/v3/coins/${coins[i].id}/market_chart?vs_currency=usd&days=365`
      )
      .catch((e) => {
        //
        isError = true;
      });

    if (isError) return null;

    const data = response.data;
    /* const responseHolders = await axios.get(
      `https://${db}/get-holdercount/?blockChain=${coins[i].blockChain}&contractAddress=${coins[i].contractAddress}`
    );
    const dataHolders = JSON.parse(responseHolders.data.Payload).body; */

    const dataHolders = [];

    const priceData =
      data.prices === undefined
        ? null
        : data.prices.map((item) => {
            return {
              x: item[0],
              y: item[1],
            };
          });

    const marketCapData =
      data.market_caps === undefined
        ? null
        : data.market_caps.map((item) => {
            return {
              x: item[0],
              y: item[1],
            };
          });

    const holdersData =
      dataHolders === undefined
        ? null
        : dataHolders.reverse().map((item) => {
            return {
              x: item.lastUpdated,
              y: item.holderCount,
            };
          });

    historicalData.push({
      priceData: priceData,
      marketCapData: marketCapData,
      holdersData,
    });
  }

  return historicalData;
};

export const getCompareCoins = async (coin1, coin2, isComparePage) => {
  const isCoin1Stock = coin1.endsWith('-stock');
  const isCoin2Stock = coin2.endsWith('-stock');
  
  let endpoint;
  if (isCoin1Stock && isCoin2Stock) {
    endpoint = `compare/?company1=${coin1}&company2=${coin2}`;
  } else if (isCoin1Stock) {
    endpoint = `compare/?company1=${coin1}&coin2=${coin2}`;
  } else if (isCoin2Stock) {
    endpoint = `compare/?coin1=${coin1}&company2=${coin2}`;
  } else {
    endpoint = `compare/?coin1=${coin1}&coin2=${coin2}`;
  }

  if (isComparePage) {
    endpoint += '&isComparePage=1';
  }

  const response = await axios.get(`https://${db}/${endpoint}`);

  return {
    coin1data: response.data[0],
    coin2data: response.data[1],
  };
};

export const getBullData = async () => {
  //

  const response = await axios.get(`https://${db}/get-bull-ranking`);

  let data = response.data;

  return data;
};

export const getAquarium = async (coin) => {
  //

  const response = await axios.get(
    `https://${db}/get-aquarium/?blockChain=${coin.blockChain}&contractAddress=${coin.contractAddress}&coin=${coin.id}`
  );

  const responseJSON = response.data;

  let data = responseJSON;

  return data;
};

export const getUserCreatureWithContractAddress = async (wa, id, bc) => {
  //

  const response = await axios.get(
    `https://${db}/get-user-creature/?walletAddress=${wa}&id=${id}&blockChain=${bc}`
  );

  let data = response.data;

  return data;
};

export const getUserCreatureWithAmount = async (amount, id) => {
  //

  const response = await axios.get(
    `https://${db}/get-user-creature/?amount=${amount}&id=${id}`
  );

  let data = response.data;

  return data;
};

export const getArticles = async (amount, id) => {
  //

  const response = await axios.get(`https://${s}/articles`);

  let data = response.data;

  return data;
};

export const getComparer5Coins = async () => {
  //

  const response = await axios.get(`https://${db}/5-compare`);

  let data = response.data;

  return data;
};

export const getComparer5CoinsBull = async () => {
  //

  const response = await axios.get(`https://${db}/5-compare-bull`);

  let data = response.data;

  return data;
};

export const getTop3MarketCap = async () => {
  //

  const response = await axios.get(`https://${db}/top3-marketcap`);

  let data = response.data;

  return data;
};

export const getTrendingTop10 = async () => {
  //

  const response = await axios.get(`https://${db}/trending-top10`);

  let data = response.data;

  return data;
};

export const getExternalCoinGraphData = async (ca, bc, isDaily) => {
  const response = await axios.get(
    `https://api.geckoterminal.com/api/v2/networks/${bc}/pools/${ca}/ohlcv/${
      isDaily ? "day" : "minute"
    }?aggregate=${isDaily ? "1" : "15"}&limit=1000&currency=usd`
  );

  let data = response.data.data.attributes.ohlcv_list;
  let finalData = data.map((item) => ({
    time: item[0],
    open: item[1],
    high: item[2],
    low: item[3],
    close: item[4],
  }));

  finalData.sort((a, b) => a.time - b.time);

  // Step 2: Remove objects with the same time
  let uniqueFinalData = finalData.filter((item, index, array) => {
    // Return true only if it's the first occurrence of the time
    return index === 0 || item.time !== array[index - 1].time;
  });

  return uniqueFinalData;
};
export const getHalvingHistoricData = async (id) => {
  const response = await axios.get(`https://${db}/marketcap-history?id=${id}`);

  const data = response.data;

  const priceData = data.prices.map((item) => {
    return {
      time: item[0],
      value: item[1],
    };
  });

  const marketCapData = data.market_caps.map((item) => {
    return {
      x: item[0],
      y: item[1],
    };
  });

  //
  //   marketcap: marketCapData,
  //   price: priceData,
  // });

  return {
    marketcap: marketCapData,
    price: priceData,
  };
};

export const getHalvingDate = async () => {
  const response = await axios.get(`https://${db}/halving-date`);

  let data = response.data;
  let date = data[0].halvingDate;
  return date;
};

export const getHalvingCoins = async () => {
  const response = await axios.get(`https://${db}/halving`);

  let data = response.data;

  data = data.map((item) => ({
    id: item.coinId,
    coinName: item.coinName,
    image: item.coinLogo,
    symbol: item.coinSymbol,
    date1: item.date1,
    date2: item.date2,
    date3: item.date3,
  }));

  return data;
};

export const sendErrorLog = async (error, errorInfo) => {
  axios.post(`https://${db}/client-error`, {
    error: error,
    errorInfo: errorInfo,
    time: new Date(),
  });
};

export const getPortfolioCoins = async (walletAddress, blockChain) => {
  try {
    const response = await axios.get(
      `https://${db}/get-portfolio?walletAddress=${walletAddress}&blockChain=${blockChain}`
    );

    let data = response.data;

    return data;
  } catch (error) {
    try {
      const response = await axios.get(
        `https://${db}/get-portfolio?walletAddress=${walletAddress}&blockChain=${blockChain}`
      );

      let data = response.data;

      return data;
    } catch (error) {
      try {
        const response = await axios.get(
          `https://${db}/get-portfolio?walletAddress=${walletAddress}&blockChain=${blockChain}`
        );

        let data = response.data;

        return data;
      } catch (error) {
        throw error;
      }
    }
  }
};

export const getCommonHolders = async (id1, ca1, id2, ca2, bc1, bc2) => {
  try {
    const response = await axios.get(
      `https://${db}/holder-compare?id1=${id1}&ca1=${ca1}&id2=${id2}&ca2=${ca2}&bc1=${bc1}&bc2=${bc2}`
    );

    let data = response.data;

    return data;
  } catch (error) {
    throw error; // Re-throw the error if you need to handle it further up the call stack
  }
};

export const getHistoricalHolderData = async (id, ca, bc) => {
  try {
    const response = await axios.get(
      `https://${db}/get-holdercount/?blockChain=${bc}&contractAddress=${ca}&coinId=${id}`
    );

    let data = response.data;

    if (data.length === 0) {
      throw new Error("No data found");
      return;
    }
    return data;
  } catch (error) {
    throw error; // Re-throw the error if you need to handle it further up the call stack
  }
};

export const getCoinRoi = async (id) => {
  try {
    const response = await axios.get(
      `https://${db}/get-roi-percents?coin=${id}`
    );

    let data = response.data;

    return data;
  } catch (error) {
    throw error;
  }
};

export const getTags = async () => {
  try {
    const response = await axios.get(`https://${db}/tags`);

    let data = response.data;

    //add "all" tag to the start
    /*     data.unshift("Meme");
     */

    return {
      "Parody Meme": [],
      "Pepe-Like": [],
      "Elon Musk-Inspired": [],
      "Wall Street Bets Themed": [],
      "Celebrity-Themed": [],
      "Chinese-Themed": [],
      "Animal-Themed": ["Cat-Themed", "Dog-Themed", "Frog-Themed"],
      "The Boy’s Club": [],
    };
  } catch (error) {
    throw error;
  }
};

export const getTagCoins = async (tag) => {
  try {
    // if tag is All Meme Coins, set it to meme
    if (tag === "All Meme Coins") {
      tag = "Meme";
    }    

    const response = await axios.get(`https://${db}/tag-marketcaps?tag=${tag}`);

    let data = response.data;

    return data;
  } catch (error) {
    throw error;
  }
};
