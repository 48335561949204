import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { BiGitCompare } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import {
  add3Dots,
  formatNumber,
  getSmallNumber,
  graphFormatter,
  maxLittleNumber,
} from "../../util/numberFormatter";
import { FullRow, Row } from "../containers/flexbox.styled";
import { HideOnMobileWrapper } from "../hideOnMobile";
import { Icon } from "../icon.styled";
import Loader from "../misc/loader";
import { textSizes } from "../sizes";
import { getMostComparedAll } from "../stream";
import { Header, SmallText } from "../texts.styled";
import { StyledTable, StyledTableWrapper } from "./table.styled";
import TableSwitch from "./tableSwitch";

export default function MostComparedTable({ isWithSwitch, isExternal }) {
  const navigate = useNavigate();

  const [coins, setCoins] = useState(null);

  const { data, status } = useQuery(
    ["most-compared-table"],
    () => getMostComparedAll(20),
    {
      onSuccess: (data) => {
        setCoins(data);
      },
    }
  );

  return status === "success" && coins !== null ? (
    <StyledTableWrapper isExternal={isExternal}>
      <FullRow verticalOnMobile={true} justify={"space-between"}>
        <Row notFullOnMobile={true} gap={"1rem"}>
          <Icon size={"2rem"} color={({ theme }) => theme.colors.BRAND}>
            <BiGitCompare />
          </Icon>
          <Header>Most Compared Coins</Header>
        </Row>
        {isWithSwitch && <TableSwitch />}
      </FullRow>
      <StyledTable>
        <tr>
          <th>#</th>
          <th>Coin</th>
          <th>Price</th>
          <th>1h</th>
          <th>1d</th>
          <th>7d</th>
          <th>Volume(24h)</th>
          <th>Market Cap</th>
        </tr>
        {coins.map((item, index) => (
          <tr onClick={() => navigate(`/coin/${item.id}`)} key={index}>
            <td>
              <SmallText>{index + 1} </SmallText>
            </td>
            <td>
              <Row gap={"0.7rem"}>
                <img alt="logo" src={item.image} />
                <HideOnMobileWrapper>
                  {add3Dots(item.coinName, 20)}
                </HideOnMobileWrapper>
                <SmallText
                  weight={textSizes.fontWeights.SEMI_BOLD}
                  color={({ theme }) => theme.colors.LIGHT_TEXT}
                >
                  {item.symbol.toUpperCase()}
                </SmallText>
              </Row>
            </td>
            {item.price < 0.001 ? (
              <td>
                <SmallText>
                  $0.0
                  <SmallText size={textSizes.fontSizes.MOBILE_LITTLE_NUMBER}>
                    {getSmallNumber(item.price).zeroCount}
                  </SmallText>
                  {getSmallNumber(item.price).nonZeroDigits}
                </SmallText>
              </td>
            ) : (
              <td>
                <SmallText>${formatNumber(item.price)}</SmallText>
              </td>
            )}
            <td>
              <SmallText
                style={
                  item.change1H
                    ? item.change1H > 1
                      ? { color: "#82dd55" }
                      : { color: "#ff3f3f" }
                    : { color: "#ffffff" }
                }
              >
                {item.change1H
                  ? `${graphFormatter(item.change1H, false, true)}%`
                  : "-"}
              </SmallText>
            </td>
            <td>
              <SmallText
                style={
                  item.change24H
                    ? item.change24H > 1
                      ? { color: "#82dd55" }
                      : { color: "#ff3f3f" }
                    : { color: "#ffffff" }
                }
              >
                {item.change24H
                  ? `${graphFormatter(item.change24H, false, true)}%`
                  : "-"}
              </SmallText>
            </td>
            <td>
              <SmallText
                style={
                  item.change7D
                    ? item.change7D > 1
                      ? { color: "#82dd55" }
                      : { color: "#ff3f3f" }
                    : { color: "#ffffff" }
                }
              >
                {item.change7D
                  ? `${graphFormatter(item.change7D, false, true)}%`
                  : "-"}
              </SmallText>
            </td>
            <td>
              <SmallText>${graphFormatter(item.volume24H)}</SmallText>
            </td>
            <td>
              <SmallText>${graphFormatter(item.marketcap)}</SmallText>
            </td>
          </tr>
        ))}
      </StyledTable>
    </StyledTableWrapper>
  ) : (
    <Loader height={"30rem"} />
  );
}
