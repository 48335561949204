import { useEffect, useState } from "react";
import styled from "styled-components";

export default function SimpleToggle({ onTrue, onFalse, excludeCex }) {
  const [isOn, setOn] = useState(false);

  useEffect(() => {
    if (isOn) {
      console.log("on");
      onTrue();
    } else {
      console.log("off");
      onFalse();
    }
  }, [isOn]);

  return (
    <StyledToggleContainer onClick={() => setOn(!isOn)}>
      <StyledToggle className={excludeCex ? "on" : ""} />
    </StyledToggleContainer>
  );
}

export const StyledToggleContainer = styled.div`
  width: 40px;
  height: 20px;
  background-color: ${({ theme }) => theme.colors.LEVEL2};
  border-radius: 20px;
  position: relative;
  cursor: pointer;
`;

export const StyledToggle = styled.div`
  width: 20px;
  height: 20px;
  background-color: ${({ theme }) => theme.colors.LEVEL2};
  border: 1px solid ${({ theme }) => theme.colors.LEVEL2};
  border-radius: 50%;
  position: absolute;
  left: 0;
  transition: all 0.3s ease;

  &.on {
    left: 20px;
    background-color: white;
  }
`;
