import { useQuery } from "@tanstack/react-query";
import React from "react";
import { MdOutlineCompareArrows } from "react-icons/md";
import { RouteLink } from "../../routing/routeLink";
import { add3Dots } from "../../util/numberFormatter";
import { Icon } from "../icon.styled";
import Loader from "../misc/loader";
import { borderRads, borderWidths, textSizes } from "../sizes";
import { getMostComparedAll, getMostComparedPairs } from "../stream";
import { Header, LightHeader, Paragraph, SmallText } from "../texts.styled";
import {
  StyledMostComparedPairRow,
  StyledMostComparedRow,
  StyledMostComparedTable,
  StyledMostComparedTableColumn,
} from "./mostComparedTable.styled";
import RecentlyListedSquare from "../recentlyListedSquare";
import RecentlyListedSquareDesktop from "./recentlyListedSquareDesktop";
import { Column } from "../containers/flexbox.styled";
import { VerticalSeperator } from "../containers/boxes.styled";
import styled from "styled-components";
import { mostWantedData } from "../mostWantedMobile";

export default function MostComparedTable() {
  const { data: pairData, status: pairStatus } = useQuery(
    ["mcCoinsTablePair"],
    () => getMostComparedPairs(),
    {
      staleTime: Infinity,
    }
  );

  return (
    <StyledMostComparedTable>
      {pairStatus === "success" ? (
        <React.Fragment>
          <RecentlyListedSquareDesktop />
          <VerticalSeperator
            height="70%"
            style={{ margin: "auto 0", opacity: "0.2" }}
          />
          <StyledMostComparedTableColumn>
            <Paragraph
              weight={textSizes.fontWeights.MEDIUM}
              style={{ marginBottom: "1rem" }}
            >
              Most Compared Pairs
            </Paragraph>
            {pairData.map((item, index) => (
              <RouteLink
                key={index}
                to={`compare/${item.coin1Id}/${item.coin2Id}`}
                style={{ width: "100%" }}
              >
                <StyledMostComparedPairRow>
                  <StyledMostComparedRow>
                    <LightHeader
                      width="0.5rem"
                      $color={({ theme }) => theme.colors.LIGHT_TEXT}
                    >
                      {index + 1}
                    </LightHeader>
                    <img alt="logo" src={item.coinImage1} />
                    <SmallText
                      size={textSizes.fontSizes.SMALLER}
                      weight={textSizes.fontWeights.SEMI_BOLD}
                    >
                      {item.coin1Symbol.toUpperCase()}
                    </SmallText>
                  </StyledMostComparedRow>

                  <Icon size={textSizes.fontSizes.HEADER}>
                    <MdOutlineCompareArrows />
                  </Icon>

                  <Paragraph>GO TO COMPARISON</Paragraph>

                  <StyledMostComparedRow>
                    <SmallText
                      size={textSizes.fontSizes.SMALLER}
                      weight={textSizes.fontWeights.SEMI_BOLD}
                    >
                      {item.coin2Symbol.toUpperCase()}
                    </SmallText>
                    <img alt="logo" src={item.coinImage2} />
                  </StyledMostComparedRow>
                </StyledMostComparedPairRow>
              </RouteLink>
            ))}
          </StyledMostComparedTableColumn>
          <VerticalSeperator
            height="70%"
            style={{ margin: "auto 0", opacity: "0.2" }}
          />
          <StyledMostWantedDesktop>
            <Column>
              <Paragraph
                weight={textSizes.fontWeights.MEDIUM}
                style={{
                  marginBottom: "1rem",
                  whiteSpace: "nowrap",
                  textAlign: "end",
                }}
              >
                Most Wanted
              </Paragraph>
              <SmallText
                size={textSizes.fontSizes.SMALLER}
                style={{
                  whiteSpace: "nowrap",
                  position: "absolute",
                  right: "0",
                  top: "1.3rem",
                }}
                color={({ theme }) => theme.colors.LIGHT_TEXT}
              >
                Everybody wants to be them
              </SmallText>
            </Column>

            <Column
              gap="1rem"
              justify="space-between"
              style={{ width: "100%", height: "19rem", paddingTop: "1rem" }}
            >
              {mostWantedData.map((item, index) => (
                <RouteLink
                  style={{ width: "100%", height: "100%" }}
                  to={`/coin/${item.id}`}
                  key={index}
                >
                  <StyledMostWantedCoin>
                    <img src={item.image} />
                    <SmallText weight={textSizes.fontWeights.SEMI_BOLD}>
                      {item.symbol.toUpperCase()}
                    </SmallText>
                  </StyledMostWantedCoin>
                </RouteLink>
              ))}
            </Column>
          </StyledMostWantedDesktop>
        </React.Fragment>
      ) : (
        <Loader height={"20rem"} />
      )}
    </StyledMostComparedTable>
  );
}

const StyledMostWantedDesktop = styled(Column)`
  padding-top: 0.5rem;
  padding-right: 0.5rem;
  min-width: 6rem;
  justify-content: start;

  //hide it under 70rem
  @media (max-width: 75rem) {
    display: none;
  }
`;

const StyledMostWantedCoin = styled(Column)`
  border-radius: ${borderRads.SM};
  gap: 0.5rem;
  img {
    width: 2.6rem;
    border-radius: 100%;
  }

  align-items: center;
  justify-content: center;
  border: ${borderWidths.SMALL} solid ${({ theme }) => theme.colors.LEVEL2};
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.LEVEL};
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => theme.colors.LEVEL2};
  }
`;
