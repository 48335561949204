import styled from "styled-components";
import { PrimaryButton } from "./buttons/buttons.styled";
import { Column } from "./containers/flexbox.styled";
import { StyledFooter } from "./footer.styled";
import { StyledNavbar } from "./navbar/navbar.styled";
import { StyledSearchBar } from "./search/searchBar.styled";
import { BigText, Title } from "./texts.styled";

export const StyledErrorPage = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-image: url("/images/bg.webp");
  background-position: center;
  background-size: cover;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${StyledNavbar} {
    background-color: rgba(255, 255, 255, 0.1);
    ${StyledSearchBar} {
      background-color: rgba(255, 255, 255, 0.1) !important;
      input {
        background-color: rgba(255, 255, 255, 0.1) !important;
        color: white;
        &::placeholder {
          color: white !important;
        }
      }
    }
  }

  ${StyledFooter} {
    background-color: rgba(255, 255, 255, 0.1);
  }
`;

export const StyledErrorPageMessage = styled.div`
  display: flex;
  align-items: center;
  gap: 8rem;
  margin: 5rem auto;

  img {
    width: 20rem;
  }

  ${Column} {
    align-items: start;
  }

  ${Title} {
    font-size: 8rem;
  }

  ${PrimaryButton} {
    background-color: rgba(255, 255, 255, 0.1);
    &:hover {
      background-color: rgba(255, 255, 255, 0.2);
    }
  }

  @media (max-width: 50rem) {
    gap: 2rem;
    ${Column} {
      align-items: center;
    }
    flex-direction: column;
    img {
      width: 12rem;
    }
  }
`;

export const StyledErrorPage2 = styled(StyledErrorPage)`
  background-image: none;
  ${Column} {
    align-items: center;
  }

  ${StyledErrorPageMessage} {
    text-align: center;
    margin: 7rem auto;
    max-width: 80%;
    gap: 2rem;
    ${Column} {
      align-items: center;
    }
    flex-direction: column;
    img {
      height: auto;
      width: 30rem;
      max-width: 90%;
    }
  }

  @media (max-width: 70rem) {
    ${BigText} {
      font-size: 1.5rem;
    }
  }
`;
