import {Icon} from "../icon.styled";
import {BiSearchAlt} from "react-icons/bi";
import {TransparentButton} from "./buttons.styled";
import {textSizes} from "../sizes";

export default function MobileSearchButton({onClick}){
    return(
        <TransparentButton aria-label="search-button" onClick={()=>onClick()}>
            <Icon size={textSizes.fontSizes.LARGE}>
                <BiSearchAlt/>
            </Icon>
        </TransparentButton>
    )
}