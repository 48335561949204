import styled from "styled-components";
import { Column } from "../containers/flexbox.styled";
import { paddings } from "../paddings.styled";
import { borderRads, textSizes } from "../sizes";

export const StyledTopTwoBanners = styled.div`
  width: 100%;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  gap: 1rem;
  @media (max-width: 50rem) {
    flex-direction: column;
    gap: 0.2rem;
  }
  height: auto;
`;

export const BannerButtonWrapper = styled(Column)`
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
  gap: 0.8rem;
  @media (max-width: 40rem) {
    right: 0.7rem;
  }
`;

export const BannerButton = styled.button`
  padding: ${paddings.LOW} ${paddings.HIGH};
  background-color: white;
  border: none;
  border-radius: ${borderRads.SMALL};
  color: black;
  font-weight: ${textSizes.fontWeights.BOLD};
  font-size: ${textSizes.fontSizes.MEDIUM};
  &:hover {
    background-color: #dddddd;
  }
  @media (max-width: 40rem) {
    font-size: ${textSizes.fontSizes.SMALLER};
    padding: ${paddings.LOW} ${paddings.MID};
  }
`;

export const BannerButtonLight = styled.button`
  padding: ${paddings.LOW} ${paddings.HIGH};
  background-color: transparent;
  border: none;
  border-radius: ${borderRads.SMALL};
  font-weight: ${textSizes.fontWeights.LIGHT};
  font-size: ${textSizes.fontSizes.MEDIUM};
  &:hover {
    color: #dddddd;
  }
`;

export const StyledBigBannerWrapper = styled.div`
  height: fit-content;
  @media (max-width: 50rem) {
    display: none;
  }
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  /* min-height: 8.5rem; */
`;

export const StyledSmallBannerWrapper = styled.div`
  height: fit-content;
  @media (max-width: 50rem) {
    display: none;
  }
  position: relative;
`;

export const StyledBigBannerWrapperMobile = styled.div`
  display: none;
  @media (max-width: 50rem) {
    display: flex;
    width: 100%;
  }
  min-height: 3.3rem;

  position: relative;

  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
`;

export const StyledSmallBannerWrapperMobile = styled.div`
  display: none;
  @media (max-width: 50rem) {
    display: block;
    width: 100%;
  }
  position: relative;
  min-height: 2;
`;

export const StyledBigBanner = styled.img`
  border-radius: ${borderRads.SMALL};
  width: 100%;
  background-position: center;
  background-size: cover;
  max-height: 8rem;
  max-width: 55rem;
`;

export const StyledSmallBanner = styled.img`
  border-radius: ${borderRads.SMALL};
  width: 100%;
  height: 100%;
  background-color: white;
  background-position: center;
  background-size: cover;
`;
