import styled from "styled-components";
import {textSizes} from "./sizes";
import {LightHeader, Paragraph} from "./texts.styled";
import {Icon} from "./icon.styled";
import {paddings} from "./paddings.styled";
import {zIndexes} from "./globalStyle.styled";

export const StyledFooter = styled.div`
  left: 0;
  bottom: 0;
  width: 100%;
  min-width: 100%;
  height: fit-content;
  background-color: ${({theme}) => theme.colors.SECONDARY};
  color: white;
  text-align: center;
  margin-top: 10rem;

  display: flex;
  align-items: center;
  flex-direction: column;
  z-index: 0;
  padding: 2rem;
  
  gap: 2rem;
`

export const StyledFooterSections = styled.div`
  
  width: 100%;
  
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 1rem 8%;
  align-items: start;
  
  row-gap: 5rem;
  column-gap: 2rem;

  @media(max-width: 70rem){
    padding: 2rem 6%;
  }
  
  @media(max-width: 40rem){
    padding: 0;
    row-gap: 3rem;
    flex-direction: column-reverse;
  }
`

export const FooterSection = styled.div`
  width: fit-content;
  ${LightHeader} {
    font-weight: ${textSizes.fontWeights.SEMI_BOLD};
  }
  
  display: flex;
  align-items: start;
  gap: 1rem;
  flex-direction: column;

  @media(max-width: 70rem){
    min-width: 12rem;
  }

  @media(max-width: 40rem){
    min-width: 10rem;
    align-items: start;
    ${Paragraph}{
      font-size: ${textSizes.fontSizes.SMALLER};
    }
  }
  
  a{
    font-weight: ${textSizes.fontWeights.LIGHT};
    &:hover{
      text-decoration: underline;
      cursor: pointer;
    }
  }

  ${Icon}{
    font-size: 2rem;
  }

  svg{
    height: 1.5rem;
    fill: ${({theme}) => theme.colors.TEXT};
  }
`