import { StyledHomePageMainTable } from "./homePageMainTable.styled";
import { LightHeader } from "../texts.styled";
import { LongBox } from "../containers/boxes.styled";
import MostComparedTable from "./mostComparedTable";

export default function HomePageMainTable() {
  return (
    <StyledHomePageMainTable>
      <MostComparedTable />
    </StyledHomePageMainTable>
  );
}
