import styled from "styled-components";

export const LoadingPageStyled = styled.div`
  background-color: ${({theme}) => theme.colors.PRIMARY};
  
  position: absolute;
  
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  
  display: flex;
  
  align-items: center;
  justify-content: center;
  
  img{
    width: 6rem;
    animation: imageFade 4s infinite;
  }

  @keyframes imageFade {
    0% { opacity: 0; }
    50% { opacity: 1; }
    100% { opacity: 1; }
  }
  
`