import styled, { keyframes } from "styled-components";
import { zIndexes } from "../globalStyle.styled";
import { paddings } from "../paddings.styled";
import { borderRads } from "../sizes";
import { Icon } from "../icon.styled";
import { Row } from "../containers/flexbox.styled";
import { SmallText } from "../texts.styled";

export const StyledShareMenu = styled.div`
  position: absolute;
  width: fit-content;
  height: fit-content;
  flex-direction: column;
  display: flex;
  align-items: start;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  z-index: ${zIndexes.DROPDOWN_MENU};
  background-color: rgba(0, 0, 0, 0.9);
  right: 0;
  padding: ${paddings.MID};
  border-radius: ${borderRads.SMALL};
  gap: 0.6rem;
  top: 2rem;
  animation: fadeIn 0.3s forwards;

  ${SmallText} {
    white-space: nowrap;
  }

  ${Icon} {
    font-size: 2rem;
  }
  ${Row} {
    gap: 1.5rem;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @media (max-width: 40rem) {
    ${Icon} {
      font-size: 2rem;
    }
    ${Row} {
      gap: 2rem;
    }
  }
`;
