import styled from "styled-components";
import { Column } from "../containers/flexbox.styled";
import { SmallBadge } from "../misc/badges.styled";
import { paddings } from "../paddings.styled";
import { borderRads, borderWidths } from "../sizes";

export const StyledCompareAgainMobile = styled(Column)`
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;

  display: none;

  @media (max-width: 62rem) {
    display: flex;
  }

  img {
    height: auto;
    width: 2rem;
  }

  & > *:first-child {
    padding: ${paddings.LOW};
    background-color: ${({ theme }) => theme.colors.LEVEL2};
    border: none;
    border-radius: ${({ isMenuOn }) =>
      isMenuOn ? `1.6rem 1.6rem 0 0` : "10rem"};
  }

  & > *:nth-child(2) {
    align-items: center;
    display: flex;
    justify-content: center;

    //set background image to public/expand-button.svg
    background-image: url("/expand-button.svg");

    width: 3rem;
    border: none;
    position: absolute;
    background-color: rgba(255, 255, 255, 0);
    background-position: center;
    background-size: cover;
    top: 100%;
    border-radius: 0 0 ${borderRads.SMALL} ${borderRads.SMALL};
    border-top: none;
    padding: 0;
    height: 1.07rem;
    padding-bottom: 0.5rem;
    svg {
      padding: 0;
    }
    z-index: 2;
  }
  margin-bottom: 0.65rem;

  ${SmallBadge} {
    color: ${({ theme }) => theme.colors.LIGHT_TEXT};
  }
`;
