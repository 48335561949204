import styled from "styled-components";
import {Column} from "../containers/flexbox.styled";
import {paddings} from "../paddings.styled";
import {borderRads, borderWidths} from "../sizes";

export const StyledComparerFive = styled(Column)`
  border: ${borderWidths.MEDIUM} solid ${({theme}) => theme.colors.LEVEL};
  background-color: rgba(0, 0, 0, 0.2);
  padding: ${paddings.HIGH};
  gap: 1rem;
  align-items: center;
  border-radius: ${borderRads.SMALL};
  width: 100%;
  
  @media(max-width: 70rem){
    width: 100%;
    padding: ${paddings.LOW};
    background-color: ${({theme}) => theme.colors.SECONDARY};
    border: none;
  }
`