import styled from "styled-components";
import { FullRow } from "../containers/flexbox.styled";
import { mobileDesktopOption } from "../hideOnMobile";

export const StyledThreeListSection = styled(FullRow)`
  position: relative;
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
  &::-webkit-scrollbar {
    display: none;  /* Safari and Chrome */
  }
`

export const StyledThreeListWrapper = styled.div`
  ${mobileDesktopOption};
  overflow: scroll;
  position: relative;
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
  &::-webkit-scrollbar {
    display: none;  /* Safari and Chrome */
  }
  width: 100%;
`