import styled from "styled-components";
import { borderRads, borderWidths, iconSizes, textSizes } from "../../sizes";
import { FullRow, Row } from "../../containers/flexbox.styled";
import { paddings } from "../../paddings.styled";
import { Paragraph, SmallText } from "../../texts.styled";
import { zIndexes } from "../../globalStyle.styled";

export const StyledMostCompared = styled(FullRow)`
  border: ${borderWidths.SMALL} solid ${({ theme }) => theme.colors.LEVEL};
  border-radius: ${borderRads.SMALL};
  align-items: center;
  overflow: hidden;
  will-change: transform;
`;

export const MostComparedHeader = styled(Row)`
  height: 100%;
  width: fit-content;
  background-color: ${({ theme }) => theme.colors.OLDLEVEL};
  padding: ${paddings.LOW} ${paddings.MID};

  z-index: ${zIndexes.MOST_COMPARED_HEADER};

  white-space: nowrap;

  @media (max-width: 70rem) {
    ${Paragraph} {
      font-size: ${textSizes.fontSizes.SMALL};
    }
  }
`;

export const MostComparedAd = styled(Row)`
  height: 100%;
  width: fit-content;
  background-color: ${({ theme }) => theme.colors.OLDLEVEL};
  padding: ${paddings.LOW};

  border: ${borderWidths.SMALL} solid ${({ theme }) => theme.colors.GOLD};
  border-radius: 0 ${borderRads.SMALL} ${borderRads.SMALL} 0;

  z-index: ${zIndexes.MOST_COMPARED_HEADER};
  position: absolute;
  right: 0;

  img {
    border-radius: 0.2rem;
    height: 1.5rem;
  }

  transition: 0.5s;

  white-space: nowrap;

  ${Row} {
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
  }

  @media (max-width: 70rem) {
    ${Paragraph} {
      font-size: ${textSizes.fontSizes.SMALL};
    }
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.OLDLEVEL2};
  }
`;

export const MostComparedContent = styled.div`
  width: 100%;
  background-color: transparent;
  display: flex;
  align-items: center;
  gap: 2rem;
`;

export const MostComparedCoins = styled(Row)`
  height: 100%;
  gap: 1.5rem;
  width: 100%;

  @keyframes horizontalScrollDesktop {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-500%);
    }
  }

  @keyframes horizontalScrollMobile {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-800%);
    }
  }

  display: inline-flex;
  white-space: nowrap;
  animation: horizontalScrollDesktop 70s linear infinite;
  padding-left: 100%;

  @media (max-width: 70rem) {
    animation: horizontalScrollMobile 40s linear infinite;
    gap: 1rem;
    ${SmallText} {
      display: none;
    }
  }

  &:hover {
    animation-play-state: paused;
    cursor: pointer;
  }
`;

export const MostComparedCoin = styled(Row)`
  height: 100%;
  gap: 0.4rem;
  align-items: center;
  img {
    width: 1.2rem;
  }

  ${Paragraph} {
    font-size: ${textSizes.fontSizes.SMALL} !important;
    font-weight: 600 !important;
    color: rgba(255, 255, 255, 0.8);
  }
`;
