import React from "react";
import { TfiClose } from "react-icons/tfi";
import { RouteLink } from "../../routing/routeLink";
import AquariumHeader from "../aquarium/aquariumHeader";
import {
  NavbarPrimaryButton,
  TransparentButton,
} from "../buttons/buttons.styled";
import { Column } from "../containers/flexbox.styled";
import { FullScreenMenu } from "../containers/fullScreenMenu.styled";
import { zIndexes } from "../globalStyle.styled";
import { Icon } from "../icon.styled";
import { LightHeader, SmallText } from "../texts.styled";
import { StyledMobileNavMenu } from "./mobileNavMenu.styled";
import styled from "styled-components";
import { textSizes } from "../sizes";

export default function MobileNavMenu({ onClose, isOn }) {
  if (!isOn) return null;

  return (
    <FullScreenMenu zindex={zIndexes.MOBILE_NAV_MENU}>
      <StyledMobileNavMenu>
        <Column>
          <img
            style={{ width: "2rem" }}
            src="/logos/comparemarketcap-logo.svg"
            alt="cmc"
          />
          <RouteLink onClick={onClose} to={"/"}>
            <LightHeader>HOME</LightHeader>
          </RouteLink>
          <RouteLink onClick={onClose} to={"/compare"}>
            <LightHeader>COMPARE</LightHeader>
          </RouteLink>
          {/* <RouteLink onClick={onClose} to={"/aquarium"}>
            <AquariumHeader width={"9rem"} />
          </RouteLink> */}
          <RouteLink onClick={onClose} to={"/halving"}>
            <HalvingLogo src="/logos/halving-logo.png" />
          </RouteLink>
          <RouteLink onClick={onClose} to={"/terminal/bitcoin"}>
            <LightHeader>TERMINAL</LightHeader>
          </RouteLink>
          <RouteLink
            onClick={onClose}
            to={"/dreamfolio"}
            style={{ position: "relative" }}
          >
            <SmallText
              color={({ theme }) => theme.colors.GREEN}
              weight={textSizes.fontWeights.BOLD}
              style={{
                marginRight: "0.5rem",
                position: "absolute",
                right: "100%",
                top: "0.25rem",
              }}
            >
              NEW
            </SmallText>

            <img
              src="/logos/dreamfolio-logo.png"
              style={{ width: "8.5rem" }}
              alt="dreamfolio"
            />
          </RouteLink>
        </Column>
        <Column>
          <RouteLink onClick={onClose} to={"/promote"}>
            <NavbarPrimaryButton>
              <LightHeader>GET LISTED</LightHeader>
            </NavbarPrimaryButton>
          </RouteLink>

          <TransparentButton onClick={() => onClose()}>
            <Icon color={"white"} size={"3rem"}>
              <TfiClose />
            </Icon>
          </TransparentButton>
        </Column>
      </StyledMobileNavMenu>
    </FullScreenMenu>
  );
}
const HalvingLogo = styled.img`
  width: 14rem;
`;
