import { Column, FullRow, Row } from "../containers/flexbox.styled";
import React, { useContext, useEffect, useState } from "react";
import moment from "moment";
import { useQuery } from "@tanstack/react-query";
import {
  getAllCoins,
  getCoin,
  getHalvingHistoricData,
  getHistoricalDataOfMultipleCoins,
} from "../stream";
import { AllCoinsContext, DedicatedCoinContext } from "../../context";
import Loader from "../misc/loader";
import {
  GraphCoinButton,
  GraphCoinButtonColor,
  GraphCoinButtons,
  GraphModeButton,
  TransparentButton,
} from "../buttons/buttons.styled";
import { Paragraph, SmallText } from "../texts.styled";
import { textSizes } from "../sizes";
import { Icon } from "../icon.styled";
import DropdownMenu from "../compareSection/dropdownMenu";
import { IoMdClose } from "react-icons/io";
import { isBlockChainValid, isContractAddressValid } from "../../validators";
import { toast } from "react-toastify";
import {
  GraphMessage,
  HalvingGraphWrapper,
  StyledDedicatedCoinGraph,
} from "../dedicatedCoin/dedicatedCoinGraph.styled";
import { useMediaQuery } from "react-responsive";
import LineChartComponent from "./halvingGraphChart";

export const graphCoinColors = ["#61d406", "#0085FF", "#FF3F3F"];

export const modes = {
  price: "price",
  marketcap: "marketcap",
  holder: "holder",
};

export default function HalvingGraph({ coin }) {
  const { data: coinData, status } = useQuery(
    ["halving", coin],
    () => getCoin(coin.id),
    {
      refetchOnWindowFocus: false,
      onSuccess: (data) => {},
    }
  );

  const {
    data: bitcoinData,
    status: bitcoinStatus,
    refetch: fetchCoin2,
  } = useQuery(["halving-coin2"], () => getHalvingHistoricData(coin.id), {
    refetchOnWindowFocus: false,
    onSuccess: (data) => {},
  });
  return (
    status === "success" && (
      <Column style={{ maxWidth: "70rem" }} width="100%" gap="0.5rem">
        <HalvingGraphWrapper>
          <Row>
            <img src={coinData.image} />
            <SmallText>{coinData.coinName} price</SmallText>
          </Row>
          {bitcoinStatus === "success" ? (
            <StyledDedicatedCoinGraph isHalving={true}>
              <LineChartComponent
                isHalving={true}
                coinData={coinData}
                data={bitcoinData.price}
                mode={"price"}
              />
            </StyledDedicatedCoinGraph>
          ) : (
            <Loader />
          )}
        </HalvingGraphWrapper>
      </Column>
    )
  );
}
