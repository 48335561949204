import styled from "styled-components";
import { Column, FullRow, Row } from "./containers/flexbox.styled";
import { paddings } from "./paddings.styled";
import { borderRads, textSizes } from "./sizes";
import { Paragraph, SmallText } from "./texts.styled";
import { MdExpandLess, MdExpandMore } from "react-icons/md";
import { Icon } from "./icon.styled";
import { useEffect, useState } from "react";
import { defaultCoins } from "./compareSection/defaultCoins";
import DreamfolioDropdown from "./portfolio/dreamfoiloDropdown";
import { PrimaryButton, TransparentButton } from "./buttons/buttons.styled";
import { FaCheck } from "react-icons/fa6";
import {
  add3Dots,
  formatNumber,
  graphFormatter,
} from "../util/numberFormatter";
import { getAthMarketCap, getCoin } from "./stream";
import { useQuery } from "@tanstack/react-query";
import Loader from "./misc/loader";
import { theme } from "./theme.styled";
import { RouteLink } from "../routing/routeLink";
import { CiCircleInfo } from "react-icons/ci";
import { ClickAwayListener, Modal } from "@mui/base";
import { MdOutlineVisibility, MdOutlineVisibilityOff } from "react-icons/md";
import { LuMinus, LuPlus } from "react-icons/lu";
import { HiOutlineExclamation } from "react-icons/hi";
import { Box } from "@mui/material";
import { FaSort } from "react-icons/fa";

export default function DreamfolioList({
  data,
  isCompare,
  setCoinAmountsInUSD,
  dreamfolioData,
  setDreamfolioData,
  dropdownData,
  filter,
  setFilter,
}) {
  return (
    <Column
      style={{ width: "100%", alignItems: "start", position: "relative" }}
      gap="1rem"
    >
      <Row
        style={{
          position: "absolute",
          right: "0",
          top: "-1.5rem",
          width: "fit-content",
          cursor: "pointer",
        }}
        onClick={() => {
          if (filter.includes("Dream")) setFilter("Amount");
          else setFilter("Dream amount");
        }}
        gap="0.5rem"
      >
        <SmallText
          size={textSizes.fontSizes.SMALLER}
          color={({ theme }) => theme.colors.LIGHT_TEXT}
        >
          {filter.includes("Dream") ? "Dream amount" : "Amount"}
        </SmallText>
        <Icon color={theme.colors.LIGHT_TEXT}>
          <FaSort />
        </Icon>
      </Row>
      {data.map((coin, index) => {
        if (
          dreamfolioData.find((item) => item.customId === coin.customId) &&
          dreamfolioData.find((item) => item.customId === coin.customId)
            .isVisible === true
        ) {
          return (
            <DreamfolioCoin
              setCoinAmountsInUSD={setCoinAmountsInUSD}
              key={coin.customId}
              coin={coin}
              isCompare={isCompare}
              dreamfolioData={dreamfolioData}
              setDreamfolioData={setDreamfolioData}
              dropdownData={dropdownData}
            />
          );
        }
      })}

      {data.find(
        (coin) =>
          dreamfolioData.find((item) => item.customId === coin.customId) &&
          dreamfolioData.find((item) => item.customId === coin.customId)
            .isVisible === false
      ) && (
        <SmallText style={{ marginTop: "1rem", opacity: 0.4 }}>
          Hidden Coins
        </SmallText>
      )}

      {data.map(
        (coin, index) =>
          dreamfolioData.find((item) => item.customId === coin.customId) &&
          dreamfolioData.find((item) => item.customId === coin.customId)
            .isVisible === false && (
            <DreamfolioCoin
              isHidden={true}
              setCoinAmountsInUSD={setCoinAmountsInUSD}
              key={coin.customId}
              coin={coin}
              isCompare={isCompare}
              dreamfolioData={dreamfolioData}
              setDreamfolioData={setDreamfolioData}
              dropdownData={dropdownData}
            />
          )
      )}
    </Column>
  );
}

const StyledDreamfolioCoin = styled(Column)`
  opacity: ${({ isFetching, isHidden }) => (isFetching || isHidden ? 0.5 : 1)};
  background-color: rgba(255, 255, 255, 0.02);
  padding: ${paddings.HIGH};
  border-radius: ${borderRads.SMALL};
  transition: 0.1s;
  width: ${({ resultMode, isCompare, compareMode }) =>
    resultMode && isCompare && !compareMode ? "95%" : "100%"};
  height: ${({ compareMode }) => (compareMode ? "10rem" : "4.5rem")};
  justify-content: ${({ compareMode }) => (compareMode ? "start" : "center")};
  align-items: start;
  gap: 0.8rem;
  transition: 0.1s;

  margin-right: 1.5rem;

  ${Paragraph} {
    white-space: nowrap;
  }
  ${SmallText} {
    white-space: nowrap;
  }
`;

export function DreamfolioCoin({
  isCompare,
  coin,
  setCoinAmountsInUSD,
  dreamfolioData,
  setDreamfolioData,
  isFake,
  isFullFake,
  dropdownData,
  isHidden,
}) {
  const [compareMode, setCompareMode] = useState(false);
  const [compareCoin, setCompareCoin] = useState(defaultCoins.bitcoin);
  const [resultMode, setResultMode] = useState(false);

  const [isFetching, setFetching] = useState(false);

  const [infoOn, setInfoOn] = useState(false);

  const [isVisible, setVisible] = useState(true);

  const myCoin = coin;

  function handleCompare(comparingCoin) {
    setCompareCoin(comparingCoin);

    setDreamfolioData((prev) => {
      return prev.map((coin) => {
        if (coin.customId === myCoin.customId) {
          return { ...coin, compareCoin: comparingCoin };
        }
        return coin;
      });
    });
    return;
  }

  useEffect(() => {
    if (dreamfolioData) {
      const coin = dreamfolioData.find(
        (coin) => coin.customId === myCoin.customId
      );

      if (coin.compareCoin) {
        setCompareCoin(coin.compareCoin);
        setResultMode(true);
        setCompareMode(false);
        fetchCompareCoin(coin.compareCoin);
      } else {
        setResultMode(false);
        setCompareCoin(defaultCoins.bitcoin);
      }
    }
  }, [dreamfolioData]);

  const [futureData, setFutureData] = useState(null);

  useEffect(() => {
    let timeout = setTimeout(() => {
      if (infoOn) {
        setInfoOn(false);
      }
    }, 2000);

    return () => {
      clearTimeout(timeout);
    };
  }, [infoOn]);

  //make a async function as an alternative to useQuery, and make the same operations after

  async function fetchCompareCoin(fetchCoin) {
    try {
      if (fetchCoin.id.includes("custom")) {
        setFetching(true);
        let marketCap = parseFloat(fetchCoin.marketCap);

        setFutureData({
          price: (marketCap / myCoin.marketCap) * myCoin.amountInUSD,
          times: marketCap / myCoin.marketCap,
        });

        setCoinAmountsInUSD((prev) => {
          return prev.map((coin) => {
            if (coin.customId === myCoin.customId) {
              return {
                ...coin,
                amount: (marketCap / myCoin.marketCap) * myCoin.amountInUSD,
                edited: true,
              };
            }
            return coin;
          });
        });
        setFetching(false);
      } else if (fetchCoin.athMode) {
        setFetching(true);
        const data = await getAthMarketCap(
          fetchCoin.id,
          fetchCoin.athDate,
          fetchCoin
        );

        setFutureData({
          price: (data / myCoin.marketCap) * myCoin.amountInUSD,
          times: data / myCoin.marketCap,
        });
        setCoinAmountsInUSD((prev) => {
          return prev.map((coin) => {
            if (coin.customId === myCoin.customId) {
              return {
                ...coin,
                amount: (data / myCoin.marketCap) * myCoin.amountInUSD,
                edited: true,
              };
            }
            return coin;
          });
        });
        setFetching(false);
      } else {
        setFetching(true);
        const data = await getCoin(fetchCoin.id);
        if (myCoin.customId === data.customId) {
          setFutureData({
            price: myCoin.amountInUSD,
            times: 1,
          });
          setFetching(false);
          return;
        }

        setFutureData({
          price: (data.marketcap / myCoin.marketCap) * myCoin.amountInUSD,
          times: data.marketcap / myCoin.marketCap,
        });
        setCoinAmountsInUSD((prev) => {
          return prev.map((coin) => {
            if (coin.customId === myCoin.customId) {
              return {
                ...coin,
                amount:
                  (data.marketcap / myCoin.marketCap) * myCoin.amountInUSD,
                edited: true,
              };
            }
            return coin;
          });
        });
        setFetching(false);
      }
    } catch (e) {
      setFetching(false);
    }
  }

  const { data, status, refetch } = useQuery(
    ["portfolio-compare", myCoin.id, compareCoin.id],
    () => getCoin(compareCoin.id),
    {
      enabled: false,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        if (myCoin.id === data.id) {
          setFutureData({
            price: myCoin.amountInUSD,
            times: 1,
          });
          return;
        }
        setFutureData({
          price: (data.marketcap / myCoin.marketCap) * myCoin.amountInUSD,
          times: data.marketcap / myCoin.marketCap,
        });
        setCoinAmountsInUSD((prev) => {
          return prev.map((coin) => {
            if (coin.customId === myCoin.customId) {
              return {
                ...coin,
                amount:
                  (data.marketcap / myCoin.marketCap) * myCoin.amountInUSD,
                edited: true,
              };
            }
            return coin;
          });
        });
      },
    }
  );

  const [visibleForOnce, setVisibleForOnce] = useState(false);

  return (
    <StyledDreamfolioCoin
      style={isFake ? { marginRight: "0" } : {}}
      isFetching={isFetching}
      isCompare={isCompare}
      compareMode={compareMode}
      resultMode={resultMode}
      isHidden={isHidden}
    >
      {coin.id === "null" && <UnlistedMessage />}
      {!isFake && !isFullFake && (
        <TransparentButton
          className="coin-visibility-button"
          onClick={() => {
            //find the coin in the dreamfolioData and change its visibility

            //if the id is "null" and not visible currently, return

            let amIVisible = dreamfolioData.find(
              (item) => item.customId === myCoin.customId
            ).isVisible;

            if (coin.id === "null" && !amIVisible && !visibleForOnce) {
              return;
            }

            setDreamfolioData((prev) => {
              return prev.map((coin) => {
                if (coin.customId === myCoin.customId) {
                  const visibility = coin.isVisible;

                  if (coin.id === "null") {
                    setVisibleForOnce(true);
                  }

                  setVisible(!visibility);
                  return { ...coin, isVisible: !visibility };
                }
                return coin;
              });
            });
          }}
          style={{
            left: "0rem",
            top: "0.17rem",
            position: "absolute",
            opacity: 0.4,
          }}
        >
          <Icon
            size={textSizes.fontSizes.MEDIUM}
            color={theme.colors.LIGHT_TEXT}
          >
            {dreamfolioData &&
            dreamfolioData.find((item) => item.customId === coin.customId) &&
            dreamfolioData.find((item) => item.customId === coin.customId)
              .isVisible ? (
              <MdOutlineVisibilityOff />
            ) : coin.id === "null" ? (
              <UnlistedModalOpener
                visibleForOnce={visibleForOnce}
                coin={coin}
                confirmCallback={() => {
                  setDreamfolioData((prev) => {
                    return prev.map((coin) => {
                      if (coin.customId === myCoin.customId) {
                        const visibility = coin.isVisible;

                        if (coin.id === "null") {
                          setVisibleForOnce(true);
                        }

                        setVisible(!visibility);
                        return { ...coin, isVisible: !visibility };
                      }
                      return coin;
                    });
                  });
                }}
              />
            ) : (
              <MdOutlineVisibility />
            )}
          </Icon>
        </TransparentButton>
      )}

      {resultMode && isCompare && !compareMode && (
        <div
          style={{
            borderRadius: borderRads.SMALL,
            position: "absolute",
            top: 0,
            height: "100%",
            width: "80%",
            right: 0,
            bottom: 0,
            background:
              futureData !== null
                ? futureData.times >= 1
                  ? `linear-gradient(90deg, rgba(130, 221, 85, 0)
           0%, rgba(130, 221, 85, 0.2) 100%)`
                  : `linear-gradient(90deg, rgba(255, 63, 63, 0)
           0%, rgba(255, 63, 63, 0.2) 100%)`
                : "transparent",
          }}
        ></div>
      )}

      <FullRow
        justify="space-between"
        style={{
          position: "relative",
          opacity: isFake ? 0.3 : isFullFake ? 0 : 1,
        }}
      >
        <Row>
          <a
            target="_blank"
            style={{
              pointerEvents: coin.marketCap === -1 ? "none" : "auto",
            }}
            href={`/coin/${coin.id}`}
          >
            <img
              src={coin.image}
              alt="logo"
              style={{ width: "2.2rem", height: "2.2rem" }}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = "logos/empty-coin.svg";
              }}
            />
          </a>

          <Column align="start">
            <Paragraph weight={textSizes.fontWeights.BOLD}>
              {add3Dots(coin.name, 10)}{" "}
              {coin.chain ? (
                <span
                  style={{
                    fontSize: textSizes.fontSizes.SMALL,
                    color: theme.colors.LIGHT_TEXT,
                    fontWeight: textSizes.fontWeights.REGULAR,
                  }}
                >
                  ({coin.chain.toUpperCase()})
                </span>
              ) : (
                ""
              )}
            </Paragraph>
            {resultMode && isCompare && !compareMode ? (
              <>
                <SmallText color={({ theme }) => theme.colors.LIGHT_TEXT}>
                  {coin.amount > 100000
                    ? graphFormatter(coin.amount)
                    : formatNumber(coin.amount)}{" "}
                  {add3Dots(coin.symbol, 7).toUpperCase()}
                </SmallText>
                <SmallText>${formatNumber(coin.amountInUSD)}</SmallText>
              </>
            ) : (
              <SmallText color={({ theme }) => theme.colors.LIGHT_TEXT}>
                {coin.symbol.toUpperCase()}
              </SmallText>
            )}
          </Column>
        </Row>
        {!(resultMode && isCompare && !compareMode) ? (
          <>
            <Column
              align="end"
              style={
                isCompare && coin.change24H !== -1
                  ? { marginRight: "2rem", transition: "0.4s" }
                  : { marginRight: 0, transition: "0.4s" }
              }
            >
              <Paragraph weight={textSizes.fontWeights.MEDIUM}>
                ${formatNumber(coin.amountInUSD)}
              </Paragraph>
              {!isFake && coin.change24H !== -1 && (
                <Paragraph
                  style={{ whiteSpace: "nowrap" }}
                  color={({ theme }) =>
                    coin.change24H > 0 ? theme.colors.GREEN : theme.colors.RED
                  }
                >
                  %{formatNumber(coin.change24H)}
                </Paragraph>
              )}
            </Column>
            {!(coin.id === "usd-coin" || coin.id === "tether") ? (
              <DreamfolioCompareButton
                onClick={() => {
                  setCompareMode((prev) => !prev);
                }}
                isOn={isCompare && coin.change24H !== -1}
              >
                <Icon
                  size="2rem"
                  color={({ theme }) =>
                    isCompare ? theme.colors.LIGHT_TEXT : "transparent"
                  }
                >
                  {compareMode ? <MdExpandLess /> : <MdExpandMore />}
                </Icon>
              </DreamfolioCompareButton>
            ) : (
              isCompare && (
                <Column
                  style={{
                    position: "absolute",
                    right: "0",
                  }}
                >
                  <TransparentButton
                    onClick={() => {
                      setInfoOn((prev) => !prev);
                    }}
                  >
                    <Icon size={"1.2rem"}>
                      <CiCircleInfo style={{ fill: theme.colors.LIGHT_TEXT }} />
                    </Icon>
                  </TransparentButton>
                  {infoOn && (
                    <ClickAwayListener onClickAway={() => setInfoOn(false)}>
                      <div
                        style={{
                          position: "absolute",
                          right: "0",
                          textAlign: "end",
                          backgroundColor: theme.colors.LEVEL,
                          width: "fit-content",
                          zIndex: 2,
                          top: "1.5rem",
                          padding: paddings.LOW,
                          borderRadius: borderRads.SMALL,
                        }}
                      >
                        <SmallText size={textSizes.fontSizes.SMALLER}>
                          {coin.symbol.toUpperCase()} is not comparable
                          <br />
                          as it is a stable coin
                        </SmallText>
                      </div>
                    </ClickAwayListener>
                  )}
                </Column>
              )
            )}
          </>
        ) : (
          <>
            {futureData === null ? (
              <Loader isSmall={true} />
            ) : (
              <Column style={{ right: "1rem" }} align="end">
                <Paragraph
                  size={textSizes.fontSizes.MEDIUM}
                  weight={textSizes.fontWeights.SEMI_BOLD}
                >
                  ${formatNumber(futureData.price)}
                </Paragraph>
                <SmallText
                  color={({ theme }) =>
                    futureData.times >= 1
                      ? theme.colors.GREEN
                      : theme.colors.RED
                  }
                >
                  {formatNumber(futureData.times)}x
                </SmallText>
              </Column>
            )}
            <DreamfolioCompareImage
              isCustom={compareCoin.id.includes("custom")}
              onClick={() => {
                if (isFetching) return;
                setCompareMode(true);
              }}
              src={compareCoin.image}
            />
            {compareCoin.id.includes("custom") && (
              <SmallText
                style={{
                  pointerEvents: "none",
                  position: "absolute",
                  width: "2rem",
                  left: "100%",
                  fontWeight: textSizes.fontWeights.BOLD,
                  fontSize: textSizes.fontSizes.MOBILE_LITTLE_NUMBER,
                }}
              >
                {graphFormatter(compareCoin.marketCap)}
              </SmallText>
            )}
            {compareCoin.athMode === true && (
              <SmallText
                style={{
                  pointerEvents: "none",
                  position: "absolute",
                  width: "2rem",
                  left: "102%",
                  bottom: "0",
                  fontWeight: textSizes.fontWeights.BOLD,
                  fontSize: textSizes.fontSizes.MOBILE_LITTLE_NUMBER,
                  backgroundColor: theme.colors.PRIMARY,
                  padding: "0.1rem",
                  borderRadius: "0.5rem",
                }}
              >
                ATH
              </SmallText>
            )}
          </>
        )}
      </FullRow>
      {compareMode && (
        <Column style={{ marginTop: "0.5rem" }} width="100%" gap="0.5rem">
          <FullRow justify={"space-between"}>
            <SmallText>Compare to</SmallText>
            <SmallText
              onClick={() => {
                setDreamfolioData((prev) => {
                  return prev.map((coin) => {
                    if (coin.id === myCoin.id) {
                      return {
                        id: coin.id,
                        compareCoin: null,
                        customId: coin.customId,
                        isVisible: coin.isVisible,
                      };
                    }
                    return coin;
                  });
                });

                setCoinAmountsInUSD((prev) => {
                  return prev.map((loopCoin) => {
                    if (loopCoin.customId === myCoin.customId) {
                      return {
                        id: loopCoin.id,
                        customId: loopCoin.customId,
                        amount: parseFloat(myCoin.amountInUSD),
                        edited: false,
                      };
                    }
                    return loopCoin;
                  });
                });
                setResultMode(false);
                setCompareMode(false);
              }}
              color={theme.colors.LIGHT_TEXT}
              style={{ opacity: "0.7" }}
            >
              Reset Coin
            </SmallText>
          </FullRow>
          <FullRow>
            <DreamfolioDropdown
              isFull={true}
              defaultCoin={defaultCoins.bitcoin}
              setCoin={handleCompare}
              coinList={dropdownData}
            />
            {/* <TransparentButton
              onClick={() => handleCompare()}
              style={{ width: "2rem" }}
            >
              <Icon size="1.5rem">
                <FaCheck />
              </Icon>
            </TransparentButton> */}
          </FullRow>
        </Column>
      )}
    </StyledDreamfolioCoin>
  );
}

export function UnlistedMessage({ isTable }) {
  const [infoOn, setInfoOn] = useState(false);

  return (
    <div
      style={{
        position: isTable ? "relative" : "absolute",
        //center align
        textAlign: "center !important",
        top: isTable ? "auto" : "0.8rem",
        right: isTable ? "auto" : "0rem",
        transform: isTable ? "translate(0, 0)" : "translate(-50%, -50%)",
        zIndex: 3,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Icon
        color={theme.colors.RED}
        size={isTable ? "1.3rem" : "1.3rem"}
        style={{
          cursor: "pointer",
        }}
        onClick={() => {
          setInfoOn(true);
        }}
      >
        <HiOutlineExclamation
          style={{
            opacity: 0.8,
          }}
        />

        {infoOn && (
          <ClickAwayListener onClickAway={() => setInfoOn(false)}>
            <div
              style={{
                position: "absolute",
                backgroundColor: theme.colors.OLDLEVEL,
                width: "fit-content",
                left: isTable ? "50%" : "auto",
                right: isTable ? "auto" : 0,
                transform: isTable ? "translate(-50%, 0)" : "translate(0, 0)",
                height: "fit-content",
                top: "1.5rem",
                padding: paddings.LOW,
                borderRadius: borderRads.SMALL,
                maxWidth: "15rem",
                width: "15rem",
                lineHeight: "1.3rem",
              }}
            >
              <span
                style={{
                  fontSize: textSizes.fontSizes.SMALLER,
                  color: "white",
                  whiteSpace: "wrap",
                  textAlign: "center",
                  lineHeight: "0.5rem",
                }}
                size={textSizes.fontSizes.SMALLER}
              >
                This token is not listed on CompareMarketCap. We advise you to
                be extra cautious and DYOR.
              </span>
            </div>
          </ClickAwayListener>
        )}
      </Icon>
    </div>
  );
}

const style = {
  position: "fixed",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "20rem",
  bgcolor: theme.colors.OLDLEVEL2,
  border: theme.colors.OLDLEVEL,
  borderRadius: borderRads.SMALL,
  boxShadow: 24,
  p: 3,
  zIndex: 1,
};

export function UnlistedModalOpener({ confirmCallback, coin, visibleForOnce }) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <TransparentButton
        style={{
          cursor: "pointer",
        }}
        onClick={() => {
          if (visibleForOnce) return;
          handleOpen();
        }}
      >
        <Icon size={textSizes.fontSizes.MEDIUM}>
          <MdOutlineVisibility />
        </Icon>
      </TransparentButton>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ClickAwayListener onClickAway={handleClose}>
          <Box sx={style}>
            <Column align="center" gap="1rem">
              <SmallText style={{ margin: "0 auto" }}>
                This token is not listed on CompareMarketCap. We advise you to
                be extra cautious and DYOR.
              </SmallText>
              <PrimaryButton
                style={{
                  padding: "0.5rem 1rem",
                }}
                onClick={() => {
                  confirmCallback();
                  handleClose();
                }}
                backgroundColor={theme.colors.SECONDARY}
                outlineColor={theme.colors.SECONDARY}
              >
                Okay
              </PrimaryButton>
            </Column>
          </Box>
        </ClickAwayListener>
      </Modal>
    </div>
  );
}
const DreamfolioCompareImage = styled.img`
  position: absolute;
  right: -2.2rem;
  outline: 0.38rem solid ${({ theme }) => theme.colors.PRIMARY};
  top: 0.5rem;
  border-radius: 12000rem;
  width: 2.4rem;
  height: 2.4rem;
  background-color: ${({ theme }) => theme.colors.PRIMARY};

  //make it black if it is custom
  filter: ${({ isCustom }) => (isCustom ? "brightness(0.6)" : "none")};
`;

const DreamfolioCompareButton = styled(Row)`
  right: 0rem;
  position: absolute;
  height: 100%;
  justify-content: center;

  width: ${({ isOn }) => (isOn ? "1rem" : "0")};
  visibility: ${({ isOn }) => (isOn ? "visible" : "hidden")};
  transition: 0.4s;
`;
