import CookieConsent, { Cookies } from "react-cookie-consent";

export default function CookieWarning(){
    return(
        <CookieConsent
            location="bottom"
            buttonText="I understand."
            cookieName="myAwesomeCookieName2"
            buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
            expires={150}
        >
            This website uses cookies to enhance the user experience.{" "}
        </CookieConsent>
    )
}