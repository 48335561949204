import styled from "styled-components";
import { Column } from "../containers/flexbox.styled";
import { borderWidths, textSizes } from "../sizes";
import { RouteLink } from "../../routing/routeLink";
import { SmallText } from "../texts.styled";
import { StyledSwitch } from "../misc/switch.styled";
import { SwitchOption } from "../misc/switchOption.styled";

export const StyledTableWrapper = styled(Column)`
  width: 100%;
  gap: 1rem;
  margin: ${({ isExternal }) => (isExternal ? "1rem" : "1rem")} auto;

  & > *:first-child {
    align-items: start;
  }

  @media (max-width: 70rem) {
    & > *:first-child {
      align-items: start;
    }
    ${StyledSwitch} {
      width: 100%;
    }
    ${SwitchOption} {
      width: 50%;
    }
  }
`;

export const StyledTable = styled.table`
  width: 100%;
  height: 2rem;
  border-collapse: collapse;

  & > * > * {
    text-align: end;
  }

  & > * > *:first-child {
    @media (max-width: 40rem) {
      position: sticky;
      left: 0;
      z-index: 1;

      background-color: ${({ theme, isHome }) =>
        !isHome && theme.colors.PRIMARY};
    }

    text-align: start;
  }
  & > * > *:nth-child(2) {
    @media (max-width: 40rem) {
      position: sticky;
      left: 1.3rem;
      z-index: 1;

      background-color: ${({ theme, isHome }) =>
        !isHome && theme.colors.PRIMARY};
    }

    text-align: start;
  }

  tr > * {
    padding: 1rem 1.5rem;
  }

  tr:not(:last-child) {
    border-bottom: ${borderWidths.SMALL} solid rgba(255, 255, 255, 0.2);
  }

  tr > *:first-child {
    width: 0;
    padding-right: 0;
  }

  th {
    font-weight: ${textSizes.fontWeights.MEDIUM};
  }

  td {
    font-weight: ${textSizes.fontWeights.LIGHT};
  }

  tr {
    &:not(:first-child):hover {
      & > * {
        background-color: ${({ theme }) => theme.colors.SECONDARY};
      }

      cursor: pointer;
    }
  }

  img {
    width: 1.5rem;
  }

  @media (max-width: 70rem) {
    tr > *:nth-child(4) {
      display: ${({ isHome }) => (isHome ? "auto" : "none")};
    }
  }

  @media (max-width: 50rem) {
    tr > *:nth-child(7) {
      display: none;
    }
  }

  @media (max-width: 40rem) {
    tr > *:nth-child(6) {
      display: none;
    }
    tr > * {
      padding: 0.8rem 0.5rem;
    }
    ${SmallText} {
      color: white;
    }
    td {
      font-size: ${textSizes.fontSizes.SMALLER};
    }
  }
`;
