import styled from "styled-components";
import { Column } from "../containers/flexbox.styled";
import { paddings } from "../paddings.styled";
import { borderRads, borderWidths, textSizes } from "../sizes";
import { Paragraph } from "../texts.styled";
import { zIndexes } from "../globalStyle.styled";

export const StyledSidebar = styled(Column)`
  align-items: start;
  background-color: ${({ theme }) => theme.colors.SECONDARY};
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  left: ${paddings.HIGH};
  padding: ${paddings.LOW};
  border: ${({ theme }) => `${borderWidths.SMALL} solid ${theme.colors.LEVEL}`};
  border-radius: ${borderRads.SMALL};
  overflow-x: hidden;
  gap: 0.8rem;
  z-index: ${zIndexes.SIDEBAR};

  width: 4.5rem;

  transition: 0.4s;

  ${Paragraph} {
    display: none;
  }

  &:hover {
    width: 14rem;
    transition: 0.4s;
    ${Paragraph} {
      display: block;
    }
  }
`;
