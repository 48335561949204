import { getCoin } from "../components/stream";

export const getFakePortfolioCoins = async () => {
  let fakeCoins = [];

  for (let i = 0; i < fakePortfolioCoins.length; i++) {
    const coin = fakePortfolioCoins[i];
    const data = await getCoin(coin.id);

    fakeCoins.push({
      id: data.id,
      amountInUSD: coin.amount,
      name: data.coinName,
      symbol: data.symbol,
      image: data.image,
      marketCap: data.marketcap,
      price: data.price,
      change24H: data.change24H,
      amount: coin.amount / data.price,
      contractAddress: data.contractAddress,
    });
  }

  return fakeCoins;
};

const fakePortfolioCoins = [
  {
    id: "harrypotterobamasonic10in",
    amount: 53.2,
  },
];
