import { useContext } from "react";
import { DedicatedCoinContext } from "../../context";
import { formatNumber } from "../../util/numberFormatter";
import { HorizontalSeperator } from "../containers/boxes.styled";
import { FullRow } from "../containers/flexbox.styled";
import { StyledShortBoxContentWrapper } from "../misc/shortBoxContentWrapper.styled";
import { LightHeader, SmallText } from "../texts.styled";
import { StyledDedicatedStats } from "./dedicatedStats.styled";
import { theme } from "../theme.styled";

export default function DedicatedStats() {
  const coinData = useContext(DedicatedCoinContext);

  return (
    <StyledShortBoxContentWrapper
      withoutBorderRadius={"true"}
      style={{ marginTop: "1rem" }}
    >
      <LightHeader>Stats</LightHeader>
      <StyledDedicatedStats>
        <FullRow>
          <SmallText color={theme.colors.LIGHT_TEXT}>Price</SmallText>
          <SmallText>${formatNumber(coinData.price, false, true)}</SmallText>
        </FullRow>
        <HorizontalSeperator width={"100%"} />
        <FullRow>
          <SmallText color={theme.colors.LIGHT_TEXT}>Market Cap</SmallText>
          <SmallText>${formatNumber(coinData.marketcap)}</SmallText>
        </FullRow>
        <HorizontalSeperator width={"100%"} />
        <FullRow>
          <SmallText color={theme.colors.LIGHT_TEXT}>Volume</SmallText>
          <SmallText>${formatNumber(coinData.volume24H)}</SmallText>
        </FullRow>
        <HorizontalSeperator width={"100%"} />
        <FullRow>
          <SmallText color={theme.colors.LIGHT_TEXT}>
            Circulating Supply
          </SmallText>
          <SmallText>{formatNumber(coinData.circSupply)}</SmallText>
        </FullRow>
        <HorizontalSeperator width={"100%"} />
        <FullRow>
          <SmallText color={theme.colors.LIGHT_TEXT}>
            24h Low / 24h High
          </SmallText>
          <SmallText>
            {coinData.low24 ? (
              <span>
                ${formatNumber(coinData.low24, false, true)} / $
                {formatNumber(coinData.high24, false, true)}
              </span>
            ) : (
              "-"
            )}
          </SmallText>
        </FullRow>
        <HorizontalSeperator width={"100%"} />
        <FullRow>
          <SmallText color={theme.colors.LIGHT_TEXT}>All Time High</SmallText>
          <SmallText>
            {coinData.ath ? (
              <span>${formatNumber(coinData.ath, false, true)}</span>
            ) : (
              "-"
            )}
          </SmallText>
        </FullRow>
      </StyledDedicatedStats>
    </StyledShortBoxContentWrapper>
  );
}
