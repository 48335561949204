import styled from "styled-components";
import { FullRow } from "../containers/flexbox.styled";
import { StyledSwitch } from "../misc/switch.styled";
import { SwitchOption } from "../misc/switchOption.styled";
import { paddings } from "../paddings.styled";
import { borderRads, borderWidths, textSizes } from "../sizes";

export const AmountInputStyled = styled(FullRow)`
  background-color: transparent;
  border-radius: ${borderRads.SMALL};
  border: ${borderWidths.SMALL} solid ${({theme}) => theme.colors.LEVEL2};
  padding: 0;
  font-size: ${textSizes.fontSizes.MEDIUM};
  font-family: ${({theme})=>theme.fontFamily.DEFAULT};
  overflow: hidden;

  input::placeholder {
    color: ${({theme})=>theme.colors.LIGHT_TEXT};
    font-style: italic;
  }
  
  height: fit-content;
  input{
    text-align: center;
    background-color: transparent;
    outline: none;
    border: none;
    width: 100%;
    border-radius: 0;
  }
  
  ${StyledSwitch}{
  border-radius: 0;
  }
  
  ${SwitchOption}{
    width: 5rem;
    padding: ${paddings.LOW};
  } 
`
