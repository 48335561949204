import Switch from "../misc/switch";
import {useNavigate} from "react-router-dom";

export default function TableSwitch({index}) {

    const navigate = useNavigate()

    const switchOptions = [
        {
            name: "Most Compared",
            onClick: () => navigate("/most-compared")
        },
        {
            name: "Top Gainers",
            onClick: () => navigate("/top-gainers")
        },
        {
            name: "Market Cap",
            onClick: () => navigate("/top-market-cap")
        }
    ]

    return (
        <Switch width={"10rem"} options={switchOptions} selectedIndexs={index}/>
    )
}