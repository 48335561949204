import { Icon } from "../icon.styled";
import { BiSolidShareAlt } from "react-icons/bi";
import { TransparentButton } from "../buttons/buttons.styled";
import React, { useContext, useState, useMemo } from "react";
import { StyledShareMenu } from "../menus/shareMenu.styled";
import { ClickAwayListener } from "@mui/base";
import {
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import { FaTwitter, FaTelegramPlane, FaWhatsapp } from "react-icons/fa";
import { SmallText } from "../texts.styled";
import { Row } from "../containers/flexbox.styled";
import { CoinsContext, DedicatedCoinContext } from "../../context";
import CopyButton from "../misc/copyButton";
import { textSizes } from "../sizes";
import styled from "styled-components";
import { RiTwitterXLine } from "react-icons/ri";
import { formatNumber } from "../../util/numberFormatter";

export default function CompareShareButton({ isMobile, coinName }) {
  const [isOn, setOn] = useState(false);

  const { coin1data, coin2data } = useContext(CoinsContext);

  const isStock = useMemo(() => 
    coin2data?.id?.endsWith('-stock'), 
    [coin2data]
  );

  // Memoize the share title to ensure consistent message
  const shareTitle = useMemo(() => {
    const coin2MarketCap = coin2data.market_cap || coin2data.marketcap;
    const futurePrice = (coin2MarketCap / coin1data.marketcap) * coin1data.price;
    const multiplier = coin2MarketCap / coin1data.marketcap;

    return `If ${coin1data.symbol.toUpperCase()} reaches ${
      isStock 
        ? `${coin2data.companyName} (${coin2data.symbol})`
        : coin2data.symbol.toUpperCase()
    }'s current market cap, its price would be $${formatNumber(
      futurePrice
    )}. That makes ${formatNumber(multiplier)}x!`;
  }, [coin1data, coin2data, isStock]);


  return (
    <CompareShareButtonWrapper>
      <TransparentButton onClick={() => setOn(true)}>
        <Icon size="1.5rem" color={({ theme }) => theme.colors.LIGHT_TEXT}>
          <BiSolidShareAlt />
        </Icon>
      </TransparentButton>
      
      {isOn && (
        <ClickAwayListener onClickAway={() => setOn(false)}>
          <StyledShareMenu>
            <SmallText>
              {`Share ${coin1data.symbol.toUpperCase()} vs ${
                isStock ? `${coin2data.companyName}` : coin2data.symbol.toUpperCase()
              }`}
            </SmallText>
            <Row gap="1rem">
              <TwitterShareButton
                style={{ display: "flex", alignItems: "center" }}
                title={shareTitle}
                url={`comparemarketcap.com/compare/${coin1data.id}/${coin2data.id}`}
                hashtags={[coin1data.coinName, coin2data.symbol]}
              >
                <Icon>
                  <RiTwitterXLine />
                </Icon>
              </TwitterShareButton>
              
              <WhatsappShareButton
                style={{ display: "flex", alignItems: "center" }}
                title={shareTitle}
                url={`comparemarketcap.com/compare/${coin1data.id}/${coin2data.id}`}
              >
                <Icon>
                  <FaWhatsapp />
                </Icon>
              </WhatsappShareButton>
              
              <CopyButton
                text={`comparemarketcap.com/compare/${coin1data.id}/${coin2data.id}`}
              />
            </Row>
          </StyledShareMenu>
        </ClickAwayListener>
      )}
    </CompareShareButtonWrapper>
  );
}

export const CompareShareButtonWrapper = styled(Row)`
  position: absolute;
  right: 0;
  width: fit-content;
  @media (max-width: 70rem) {
    position: relative !important;
  }
`;
