import styled from "styled-components";
import { borderRads, borderWidths, textSizes } from "../sizes";
import { Column, Row } from "../containers/flexbox.styled";
import { paddings } from "../paddings.styled";
import Dropdown from "../compareSection/dropdown";
import { StyledDropdown } from "../compareSection/dropdown.styled";
import {
  OutlineButton,
  PrimaryButton,
  TransparentButton,
} from "../buttons/buttons.styled";
import { BigText, Header, Paragraph, SmallText } from "../texts.styled";
import { Icon } from "../icon.styled";
import {
  HorizontalSeperator,
  VerticalSeperator,
} from "../containers/boxes.styled";

export const StyledHalvingBoxWrapper = styled(Column)`
  background-color: ${({ theme }) => theme.colors.OLDLEVEL};
  max-width: 55rem;
  transition: 0.5s;
  width: 80%;
  margin: 0 auto;
  height: fit-content;
  @media (max-width: 63rem) {
    /* height: ${({ pressed }) => (pressed ? "68rem" : "24rem")}; */
  }
  @media (max-width: 40rem) {
    margin-top: -2rem;

    width: 90%;
    gap: 0.5rem;
    /* height: ${({ pressed }) => (pressed ? "68rem" : "22rem")}; */
    padding: ${({ pressed }) =>
      pressed ? paddings.LOW : `${paddings.MID} ${paddings.HIGH}`};
  }

  ${Paragraph} {
    font-size: 1.2rem !important;
    @media (max-width: 50rem) {
      font-size: ${textSizes.fontSizes.SMALL} !important;
    }
  }

  ${SmallText} {
    font-size: ${textSizes.fontSizes.SMALL} !important;
    font-weight: ${textSizes.fontWeights.LIGHT} !important;
    @media (max-width: 50rem) {
      font-size: ${textSizes.fontSizes.SMALL} !important;
    }
  }
  margin-top: -3rem;
  z-index: 1;
  /* height: ${({ pressed }) => (pressed ? "56rem" : "14rem")}; */
  border: ${borderWidths.SMALL} solid ${({ theme }) => theme.colors.LEVEL2};
  border-radius: ${borderRads.MEDIUM};
  padding: 1rem 2rem;
  gap: 1.5rem;
  filter: drop-shadow(0px 4px 15px rgba(0, 0, 0, 0.3));
`;

export const StyledHalvingBox = styled(Row)`
  width: 100%;
  justify-content: space-between;
  gap: 2rem;
  align-items: start;

  @media (max-width: 70rem) {
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }
`;

export const HalvingBoxDropdownWrapper = styled(Column)`
  align-items: start;
  gap: 1rem;
  width: ${({ isFake }) => (isFake ? "fit-content" : "100%")};
  &:first-child {
    ${StyledDropdown} {
      border: ${borderWidths.SMALL} solid ${({ theme }) => theme.colors.HALVING};
    }
  }
  &:nth-child(3) {
    ${StyledDropdown} {
      border: ${borderWidths.SMALL} solid ${({ theme }) => theme.colors.LEVEL2};
    }
  }

  ${OutlineButton} {
    height: 4.2rem !important;
    width: 6rem;
    @media (max-width: 70rem) {
      margin-top: 0.5rem;
      width: 100% !important;
      height: auto !important;
    }
  }

  @media (max-width: 70rem) {
    width: 100% !important;
    gap: 0.3rem;
  }
`;

export const HalvingResultImageRow = styled(Row)`
  gap: 1rem;
  justify-content: center;

  img {
    width: 3rem;
    @media (max-width: 50rem) {
      width: 2rem;
    }
  }
`;

export const StyledHalvingResultThree = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  margin-top: 0.5rem;

  ${VerticalSeperator} {
    display: block !important;
  }
  ${HorizontalSeperator} {
    display: none !important;
  }

  @media (max-width: 70rem) {
    ${HorizontalSeperator} {
      display: none !important;
    }
    ${VerticalSeperator} {
      display: none !important;
    }
  }
  @media (max-width: 50rem) {
    gap: 1rem;
  }
  @media (max-width: 45rem) {
    ${HorizontalSeperator} {
      display: block !important;
    }
    ${VerticalSeperator} {
      display: none !important;
    }
  }

  .endOfCycle {
    @media (min-width: 70rem) {
      position: absolute;
      top: -1rem;
    }
    white-space: nowrap;
  }
`;

export const StyledHalvingResultColumn = styled(Column)`
  gap: 1rem;
  width: 33%;
  min-width: 15rem;
  @media (max-width: 45rem) {
    gap: 0.5rem;
  }
  ${BigText} {
    font-weight: ${textSizes.fontWeights.LIGHT};
    &:first-child {
    }
    &:nth-child(2) {
      color: ${({ theme }) => theme.colors.GREEN};
      font-size: ${textSizes.fontSizes.HEADER};
    }
    font-size: ${textSizes.fontSizes.BIG};
    @media (max-width: 50rem) {
      font-size: ${textSizes.fontSizes.HEADER};
    }
  }

  & > *:first-child {
    @media (max-width: 45rem) {
      .date {
        @media (max-width: 45rem) {
          flex-direction: row;
          gap: 0.5rem;
          ${Header} {
            font-size: ${textSizes.fontSizes.MEDIUM};
          }
          ${Icon} {
            font-size: ${textSizes.fontSizes.HEADER};
          }
        }
      }
    }
  }

  & > *:nth-child(2) {
    @media (max-width: 45rem) {
      flex-direction: row;
      gap: 0.5rem;
    }
  }
`;

export const StyledHalvingBoxBackButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: none;
  position: absolute;
  top: 0.75rem;
  left: 0.75rem;
  gap: 0;
  @media (max-width: 45rem) {
    top: 0.3rem;
    left: 0.3rem;
    & > *:nth-child(2) {
      display: none;
    }
  }
`;
