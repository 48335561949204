import styled from "styled-components";
import { Column } from "../containers/flexbox.styled";
import { LongBox } from "../containers/boxes.styled";
import { Header } from "../texts.styled";

export const StyledHomePageMainTable = styled(LongBox)`
  height: 100%;
  ${Header} {
    margin-top: 0.5rem;
  }
`;
