import styled from "styled-components";

const Flexbox = styled.div`
  display: flex;
  align-items: ${({align})=>(align || "center")};
  align-self: ${({alignSelf})=>(alignSelf && alignSelf)};
  justify-content: ${({justify})=>(justify)};
  gap: ${({gap})=>(gap)};
  width: ${({width})=>(width)};
  height: ${({height})=>(height)};
  padding: ${({padding})=>(padding)};
  position: ${({position})=>(position || "relative")};
  background-color: ${({backgroundColor})=>(backgroundColor && backgroundColor)};
`

const Row = styled(Flexbox)`
  flex-direction: ${({reverse})=>(reverse ? "row-reverse" : "row")};
  @media(max-width: 70rem){
    flex-direction: ${({verticalOnMobile}) => verticalOnMobile && "column"};
    align-items: ${({verticalOnMobile}) => verticalOnMobile && "center"};
    gap: ${({gap})=> gap  ? gap : "1rem"};
    width: ${({notFullOnMobile}) => !notFullOnMobile && "100%"};
  }
`

const FullRow = styled(Row)`
  overflow: ${({overflow})=>(overflow)};
  width: 100%;
  flex-wrap: ${({wrap}) => wrap && "wrap"};
`

const Column = styled(Flexbox)`
  flex-direction: ${({reverse})=>(reverse ? "column-reverse" : "column")};
`

export {
  Column,
  FullRow, Row
};
