//empty react component

import { IoIosArrowForward } from "react-icons/io";
import { RouteLink } from "../../routing/routeLink";
import { NavbarPrimaryButton } from "../buttons/buttons.styled";
import { Row } from "../containers/flexbox.styled";
import { HalvingNavbarWrapper } from "../halving/halving.styled";
import { LightHeader, SmallText } from "../texts.styled";
import { Icon } from "../icon.styled";
import { textSizes } from "../sizes";

export default function NavbarDreamfolio() {
  return (
    <RouteLink style={{ alignItems: "center" }} to={"/dreamfolio"}>
      <NavbarPrimaryButton
        backgroundColor={"transparent"}
        hoverColor={"rgba(255,255,255,0.1)"}
      >
        <Row>
          <SmallText
            color={({ theme }) => theme.colors.GREEN}
            weight={textSizes.fontWeights.BOLD}
            style={{ marginRight: "0.5rem" }}
          >
            NEW
          </SmallText>
          <LightHeader>DREAMFOLIO</LightHeader>
        </Row>
      </NavbarPrimaryButton>
    </RouteLink>
  );
}
