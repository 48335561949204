import styled from "styled-components";
import { Column, Row } from "../containers/flexbox.styled";
import { Header, LightHeader, Paragraph, SmallText } from "../texts.styled";
import { borderRads, borderWidths, textSizes } from "../sizes";
import { paddings } from "../paddings.styled";
import { Icon } from "../icon.styled";

export const StyledMostComparedTable = styled(Row)`
  height: 100%;
  align-items: start;
  justify-content: space-between;
  gap: 2.5rem;
`;

export const StyledMostComparedTableColumn = styled(Column)`
  justify-content: space-between;
  align-items: start;
  width: 100%;
  max-width: 20rem;
  padding-top: 0.5rem;

  height: 100%;

  &:first-child {
    min-width: 20rem;
  }

  &:last-child {
    width: 100%;
    padding-right: ${paddings.HIGH};
  }

  img {
    width: 1.7rem;
  }

  ${Header} {
    font-size: ${textSizes.fontSizes.MEDIUM};
    font-weight: ${textSizes.fontWeights.BOLD};
    margin-bottom: 0.5rem;
  }

  ${Row} {
    gap: 0.8rem;
  }
`;

export const StyledMostComparedRow = styled(Row)`
  border-radius: ${borderRads.SMALL};
  padding: ${paddings.LOW};
  &:hover {
    background-color: ${({ theme }) => theme.colors.LEVEL2};
  }
`;

export const StyledMostComparedPairRow = styled(Row)`
  justify-content: space-between;
  width: 100%;
  border-radius: ${borderRads.SMALL};

  & > *:first-child {
    justify-content: start;
    width: 15%;
    max-width: 15%;
  }

  & > *:last-child {
    justify-content: end;
    width: 15%;
    max-width: 15%;
  }

  ${Paragraph} {
    display: none;
    font-size: ${textSizes.fontSizes.SMALLER};
    background-color: ${({ theme }) => theme.colors.SECONDARY};
    padding: 0.5rem;
    border-radius: ${borderRads.SMALL};
  }

  ${StyledMostComparedRow} {
    background-color: transparent;
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.LEVEL};
    cursor: pointer;
  }
`;
