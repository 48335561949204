import { Row } from "../containers/flexbox.styled";
import { borderRads, textSizes } from "../sizes";
import { DedicatedCoinContext } from "../../context";
import styled from "styled-components";
import { paddings } from "../paddings.styled";
import { zIndexes } from "../globalStyle.styled";
import { Paragraph, SmallText } from "../texts.styled";
import {
  add3Dots,
  formatNumber,
  getSmallNumber,
} from "../../util/numberFormatter";
import { useState, useContext, useEffect } from "react";

export default function ScrollInfo() {
  const coinData = useContext(DedicatedCoinContext);

  const [show, setShow] = useState(false);
  const [lastScrollY, setLastScrollY] = useState(0);

  const controlNavbar = () => {
    if (window.scrollY > 100) {
      // if scroll down hide the navbar
      if (!show) setShow(true);
    } else {
      // if scroll up show the navbar
      if (show) setShow(false);
    }

    // remember current page location to use in the next move
    setLastScrollY(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener("scroll", controlNavbar);

    // cleanup function
    return () => {
      window.removeEventListener("scroll", controlNavbar);
    };
  }, [lastScrollY]);

  return (
    <StyledScrollInfo show={show}>
      <img src={coinData.image} alt={coinData.coinName} />
      <Paragraph weight={textSizes.fontWeights.BOLD}>
        {add3Dots(coinData.symbol.toUpperCase(), 8)}
      </Paragraph>
      <SmallText>{add3Dots(coinData.coinName, 15)}</SmallText>
      <Paragraph
        style={{ marginLeft: "auto" }}
        weight={textSizes.fontWeights.SEMI_BOLD}
      >
        {coinData.price < 0.001 ? (
          <span>
            $0.0
            <SmallText
              size={textSizes.fontSizes.MOBILE_LITTLE_NUMBER}
              weight={textSizes.fontWeights.MEDIUM}
            >
              {getSmallNumber(coinData.price).zeroCount}
            </SmallText>
            {getSmallNumber(coinData.price).nonZeroDigits}
          </span>
        ) : (
          <span>${formatNumber(coinData.price)}</span>
        )}
      </Paragraph>
    </StyledScrollInfo>
  );
}

const StyledScrollInfo = styled(Row)`
  position: fixed;
  background-color: #554d62;
  padding: ${paddings.MID} 1.2rem;
  /* backdrop-filter: blur(20px); */
  left: 50%;
  transform: translate(-50%, 0);
  z-index: ${zIndexes.SIDEBAR};
  gap: 0.5rem;
  img {
    height: 1.5rem;
  }

  transition: 0.4s;

  @media (min-width: 70rem) {
    display: none;
  }

  //SMALL
  top: ${({ show }) => (show ? "1rem" : "-3rem")};
  border-radius: ${borderRads.SMALL};
  width: 90%;

  //FULL WIDTH
  /* top: ${({ show }) => (show ? "0" : "-3rem")};
  width: 100%; */
`;
