import styled from "styled-components";
import { Column, Row } from "../containers/flexbox.styled";
import { SmallText } from "../texts.styled";

export const StyledHalvingInfo = styled(Column)`
  margin-top: 3rem !important;
  width: 90%;
  margin: 0 auto;
  gap: 5rem;

  ${Row} {
    max-width: 55rem;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    gap: 5rem;

    @media (max-width: 50rem) {
      flex-direction: column;
      gap: 2rem;
    }

    &:nth-child(3) {
      ${Column} {
        align-items: end;
        @media (max-width: 50rem) {
          align-items: start;
        }
      }
      @media (max-width: 50rem) {
        flex-direction: column-reverse;
      }
    }

    img {
      max-width: 45%;
      @media (max-width: 50rem) {
        max-width: 50% !important;
        max-height: 10rem;
        width: auto;
        height: auto;
        max-width: none;
      }
    }

    ${Column} {
      align-items: start;
      gap: 1.5rem;
      max-width: 45%;
      @media (max-width: 50rem) {
        width: 100%;
        max-width: none;
        gap: 1.5rem;
      }
    }
  }
`;
