import styled from "styled-components";
import { Row } from "../containers/flexbox.styled";
import { textSizes } from "../sizes";
import { paddings } from "../paddings.styled";

export const PriceChange = styled(Row)`
  font-size: ${textSizes.fontSizes.SMALL};
  background-color: ${({ theme, profit }) =>
    profit ? theme.colors.GREEN : theme.colors.RED};
  font-weight: ${textSizes.fontWeights.REGULAR};
  border-radius: 10000rem;
  padding: ${paddings.LOWER} ${paddings.LOW};
  gap: 0.2rem;
  width: fit-content;
`;

export const PriceChangeMobile = styled(Row)`
  width: fit-content;
  font-size: ${textSizes.fontSizes.SMALL};
  svg {
    fill: ${({ theme, profit }) =>
      profit ? theme.colors.GREEN : theme.colors.RED};
  }
  color: ${({ theme, profit }) =>
    profit ? theme.colors.GREEN : theme.colors.RED};
  font-weight: ${textSizes.fontWeights.REGULAR};
  padding: 0;
  gap: 0.1rem;
`;
