import styled from "styled-components";
import { Column } from "../containers/flexbox.styled";
import { paddings } from "../paddings.styled";
import { zIndexes } from "../globalStyle.styled";
import { borderRads, borderWidths } from "../sizes";
import { StyledDropdown } from "../compareSection/dropdown.styled";

export const StyledCompareAgainMenuMobile = styled(Column)`
  filter: drop-shadow(0px 10px 5px rgba(0, 0, 0, 0.7));
  width: 100%;
  padding: ${paddings.MID};
  padding-bottom: 0;
  background-color: ${({ theme }) => theme.colors.OLDLEVEL};
  gap: 1rem;
  position: absolute;
  top: 3rem;
  z-index: ${zIndexes.MOST_COMPARED_HEADER};
  border-radius: 0 0 ${borderRads.SMALL} ${borderRads.SMALL};
  border-left: ${borderWidths.SMALL} solid ${({ theme }) => theme.colors.LEVEL2};
  border-right: ${borderWidths.SMALL} solid
    ${({ theme }) => theme.colors.LEVEL2};
  border-bottom: ${borderWidths.SMALL} solid
    ${({ theme }) => theme.colors.LEVEL2};

  ${StyledDropdown} {
    background-color: ${({ theme }) => theme.colors.SECONDARY};
  }
`;
