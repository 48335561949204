//empty react component

import { useState } from "react";
import { Column, FullRow } from "./containers/flexbox.styled";
import MultiSwitch from "./misc/multiSwitch";
import RecentlyListedSquare from "./recentlyListedSquare";
import { useQuery } from "@tanstack/react-query";
import { getMostComparedPairs } from "./stream";
import { SmallText } from "./texts.styled";
import { borderRads, textSizes } from "./sizes";
import { theme } from "./theme.styled";
import { paddings } from "./paddings.styled";
import { IoIosArrowForward } from "react-icons/io";
import { Icon } from "./icon.styled";
import { HorizontalSeperator } from "./containers/boxes.styled";
import { RouteLink } from "../routing/routeLink";
import styled from "styled-components";
import MostWantedMobile from "./mostWantedMobile";

export default function TrendingSwitchMobile() {
  const [mode, setMode] = useState(0);

  return (
    <StyledTrendingSwitchMobile>
      <MultiSwitch
        options={[
          {
            name: "Recently Listed",
            onClick: () => {
              setMode(0);
            },
          },
          {
            name: "Most Wanted",
            onClick: () => {
              setMode(2);
            },
          },
          {
            name: "Most Compared Pairs",
            onClick: () => {
              setMode(1);
            },
          },
        ]}
        adaptiveWidth={true}
        startSelected={true}
        gap={"0.4rem !important"}
      />
      {mode === 0 ? (
        <RecentlyListedSquare />
      ) : mode === 1 ? (
        <MostComparedPairs />
      ) : (
        <MostWantedMobile />
      )}
    </StyledTrendingSwitchMobile>
  );
}

const StyledTrendingSwitchMobile = styled(Column)`
  gap: 0.75rem;
  width: 100%;

  @media (min-width: 70rem) {
    display: none;
  }
`;

function MostComparedPairs() {
  const { data, status } = useQuery(
    ["mcCoinsPairMobile"],
    () => getMostComparedPairs(),
    {
      onSuccess: (data) => {},
      staleTime: Infinity,
    }
  );

  return (
    status === "success" && (
      <Column
        gap="0.7rem"
        style={{
          padding: paddings.HIGH,
          borderRadius: borderRads.SMALL,
          backgroundColor: theme.colors.SECONDARY,
        }}
        width="100%"
      >
        {data.map((item, index) => (
          <>
            <FullRow
              style={{ position: "relative" }}
              justify="center"
              key={index}
            >
              <SmallText
                weight={textSizes.fontWeights.SEMI_BOLD}
                style={{ position: "absolute", left: "0.1rem" }}
              >
                {index + 1}
              </SmallText>
              <RouteLink
                to={`compare/${item.coin1Id}/${item.coin2Id}`}
                weight={textSizes.fontWeights.SEMI_BOLD}
                style={{ position: "absolute", right: "0rem" }}
              >
                <Icon color={({ theme }) => theme.colors.LIGHT_TEXT}>
                  <IoIosArrowForward />
                </Icon>
              </RouteLink>
              <SmallText
                style={{
                  width: "4rem",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  textAlign: "end",
                }}
                weight={textSizes.fontWeights.SEMI_BOLD}
              >
                {item.coin1Symbol.toUpperCase()}
              </SmallText>
              <img style={{ width: "1.5rem" }} src={item.coinImage1} />
              <SmallText>vs</SmallText>
              <img style={{ width: "1.5rem" }} src={item.coinImage2} />
              <SmallText
                style={{
                  width: "4rem",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  textAlign: "start",
                }}
                weight={textSizes.fontWeights.SEMI_BOLD}
              >
                {item.coin2Symbol.toUpperCase()}
              </SmallText>
            </FullRow>
            {index !== data.length - 1 && (
              <HorizontalSeperator width="100%" style={{ opacity: "0.2" }} />
            )}
          </>
        ))}
      </Column>
    )
  );
}
