import styled from "styled-components";
import { textSizes } from "./sizes";

const PlainText = styled.span`
  color: ${({ theme, color }) => color || theme.colors.TEXT};
  margin: ${({ margin }) => margin && margin};
  font-weight: ${({ weight }) => weight && weight};
  font-size: ${({ size }) => size && size};
  width: ${({ width, center }) => (width ? width : center && "100%")};
  min-width: ${({ minWidth }) => minWidth && minWidth};
  text-align: ${({ textAlign, center }) =>
    textAlign ? textAlign : center && "center"};
  font-weight: ${({ weight }) => weight || textSizes.fontWeights.REGULAR};
`;

const Title = styled(PlainText)`
  font-size: ${({ size }) => size || textSizes.fontSizes.LARGE};
  font-weight: ${({ weight }) => weight || textSizes.fontWeights.SEMI_BOLD};
`;

const BigText = styled(PlainText)`
  font-size: ${({ size }) => size || textSizes.fontSizes.BIG};
  font-weight: ${({ weight }) => weight || textSizes.fontWeights.SEMI_BOLD};
`;

const Header = styled(PlainText)`
  font-size: ${({ size }) => size || textSizes.fontSizes.HEADER};
  font-weight: ${({ weight }) => weight || textSizes.fontWeights.SEMI_BOLD};
  text-align: ${({ textAlign, center }) =>
    textAlign ? textAlign : center && "center"};
`;

const PageTitle = styled(PlainText)`
  font-size: ${({ size }) => size || textSizes.fontSizes.HEADER};
  font-weight: ${({ weight }) => weight || textSizes.fontWeights.MEDIUM};
  text-align: start;
  margin-top: 1rem;

  @media (max-width: 40rem) {
    font-size: ${textSizes.fontSizes.MEDIUM};
  }
`;

const Paragraph = styled(PlainText)`
  font-size: ${({ size }) => size || textSizes.fontSizes.MEDIUM};

  @media (max-width: 40rem) {
    font-size: ${({ size }) => size || textSizes.fontSizes.SMALL};
  }
`;

const SmallText = styled(PlainText)`
  font-size: ${({ size }) => size || textSizes.fontSizes.SMALL};
  max-width: ${({ maxWidth }) => maxWidth && maxWidth};

  @media (max-width: 40rem) {
    font-size: ${({ size }) => size || textSizes.fontSizes.SMALLER};
  }
`;

const LightHeader = styled(PlainText)`
  font-size: ${({ size }) => size || textSizes.fontSizes.MEDIUM};
  font-weight: ${({ weight }) => weight || textSizes.fontWeights.REGULAR};
  text-transform: uppercase;
  margin: ${({ center }) => center && "0 auto"};
  font-size: ${({ size }) => size || textSizes.fontSizes.SMALL};

  @media (max-width: 40rem) {
    font-size: ${({ size }) => size || textSizes.fontSizes.SMALL};
  }
`;

const PageDescription = styled(PlainText)`
  font-size: ${({ size }) => size || textSizes.fontSizes.SMALL};
  text-align: start;

  color: ${({ theme }) => theme.colors.LIGHT_TEXT};
`;

export {
  Title,
  BigText,
  Header,
  PageTitle,
  PageDescription,
  Paragraph,
  SmallText,
  LightHeader,
};
