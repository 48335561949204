import { Column, FullRow, Row } from "../containers/flexbox.styled";
import { Icon } from "../icon.styled";
import { BigText, Header, Paragraph, SmallText } from "../texts.styled";
import {
  PromoteCard,
  RecommendedBadge,
  StyledListingCard,
  StyledPromoteCard,
} from "./cards.styled";
import { PiTargetLight } from "react-icons/pi";
import { MdOutlineMail, MdOutlinePlaylistAdd } from "react-icons/md";
import { PrimaryButton, TransparentButton } from "../buttons/buttons.styled";
import { useEffect, useState } from "react";
import { borderRads, textSizes } from "../sizes";
import { HideOnMobileWrapper, ShowOnMobileWrapper } from "../hideOnMobile";
import { IoIosTimer, IoMdClose } from "react-icons/io";
import { ClickAwayListener, Modal } from "@mui/base";
import { Backdrop, Box } from "@mui/material";
import { theme } from "../theme.styled";
import { FiExternalLink } from "react-icons/fi";
import { FaFileAlt, FaTelegramPlane } from "react-icons/fa";
import { GrMail } from "react-icons/gr";
import CopyButton from "../misc/copyButton";
import { BiCopy } from "react-icons/bi";


export default function ListingCard({ data }) {
  return (
    <StyledListingCard isRecommended={data.isRecommended} isPremium={data.isPremium}>
      <Row justify="space-between">
        <Row justify="start" gap="0.5rem" align="center">
          <ShowOnMobileWrapper>
            <Icon className="headerIcon" size="5rem">
              {data.icon}
            </Icon>
          </ShowOnMobileWrapper>
          <Header>{data.name}</Header>
        </Row>
        <ShowOnMobileWrapper>
          <Header>${data.price}</Header>
        </ShowOnMobileWrapper>
      </Row>

      <HideOnMobileWrapper>
        <Icon size="5rem">{data.icon}</Icon>
      </HideOnMobileWrapper>
      <Column gap="0.75rem" width="100%">
        <FullRow>
          <SmallText>{data.service1}</SmallText>
          <Icon className="serviceIcon" size="1.2rem">
            {data.icon1}
          </Icon>
        </FullRow>
        <FullRow>
          <SmallText>{data.service2}</SmallText>
          <Icon className="serviceIcon" size="1.2rem">
            {data.icon2}
          </Icon>
        </FullRow>
        <FullRow>
          <SmallText>{data.service3}</SmallText>
          <Icon className="serviceIcon" size="1.2rem">
            {data.icon3}
          </Icon>
        </FullRow>
        <FullRow>
          <SmallText>{data.service4}</SmallText>
          <Icon className="serviceIcon" size="1.2rem">
            {data.icon4}
          </Icon>
        </FullRow>
      </Column>
      <HideOnMobileWrapper>
        <BigText>${data.price}</BigText>
      </HideOnMobileWrapper>

      <ListingMenuModal />

      {data.isRecommended && (
        <RecommendedBadge>
          <SmallText weight={textSizes.fontWeights.LIGHT}>
            Recommended
          </SmallText>
        </RecommendedBadge>
      )}
    </StyledListingCard>
  );
}

export function PromotionCard({ data, ref }) {
  return (
    <StyledPromoteCard isRecommended={data.isRecommended}>
      <Row>
        <Header>{data.name}</Header>
      </Row>
      <img ref={ref} src={data.image} />
      <Column gap="0.5rem" width="100%">
        <FullRow>
          <SmallText
            style={{ display: "flex", alignItems: "center", gap: "0.4rem" }}
          >
            <Icon>
              <IoIosTimer />
            </Icon>
            {data.service1}
          </SmallText>
          <SmallText weight={textSizes.fontWeights.SEMI_BOLD}>
            ${data.price1}
          </SmallText>
        </FullRow>
        <FullRow>
          <SmallText
            style={{ display: "flex", alignItems: "center", gap: "0.4rem" }}
          >
            <Icon>
              <IoIosTimer />
            </Icon>
            {data.service2}
          </SmallText>
          <SmallText weight={textSizes.fontWeights.SEMI_BOLD}>
            ${data.price2}
          </SmallText>
        </FullRow>
        <FullRow>
          <SmallText
            style={{ display: "flex", alignItems: "center", gap: "0.4rem" }}
          >
            <Icon>
              <IoIosTimer />
            </Icon>
            {data.service3}
          </SmallText>
          <SmallText weight={textSizes.fontWeights.SEMI_BOLD}>
            ${data.price3}
          </SmallText>
        </FullRow>
      </Column>
      <ListingMenuModal isPromote={true} />

      {data.isRecommended && (
        <RecommendedBadge>
          <SmallText weight={textSizes.fontWeights.LIGHT}>
            Recommended
          </SmallText>
        </RecommendedBadge>
      )}
    </StyledPromoteCard>
  );
}

export function ListingMenuModal({ isPromote }) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div style={{ width: "100%" }}>
      <PrimaryButton
        style={{
          padding: "0.5rem 1rem",
          width: "6rem",
          margin: "0 auto",
          display: "block",
        }}
        onClick={() => handleOpen()}
        borderRadius="2rem"
      >
        Contact
      </PrimaryButton>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            sx: {
              //Your style here....
              backgroundColor: "rgba(0, 0, 0, 0.5)",
            },
          },
        }}
      >
        <ClickAwayListener onClickAway={handleClose}>
          <Box sx={style} style={{ padding: "2rem 2rem", maxWidth: "90%" }}>
            <div style={{ position: "absolute", right: "1rem", top: "1rem" }}>
              <TransparentButton onClick={handleClose}>
                <Icon size="1.2rem">
                  <IoMdClose />
                </Icon>
              </TransparentButton>
            </div>
            <Column align="center" gap="3rem">
              {!isPromote && (
                <Column gap="0.5rem">
                  <Row gap="0.5rem" align="center" justify="center">
                    <Icon>
                      <FaFileAlt />
                    </Icon>
                    <SmallText>Listing Form</SmallText>
                  </Row>
                  <a
                    target="_blank"
                    href="https://docs.google.com/forms/d/e/1FAIpQLSfzBr3vudp7WAIhIR_Nyn4igldSN5LT3zwyptzcmulaTHNSeg/viewform?usp=sf_link"
                  >
                    <Row
                      gap="0.5rem"
                      className="form-link"
                      style={{
                        padding: "0.5rem 1rem",
                        borderRadius: borderRads.SMALL,
                      }}
                    >
                      <SmallText color={theme.colors.LIGHT_TEXT}>
                        Google Forms
                      </SmallText>
                      <Icon color={theme.colors.LIGHT_TEXT}>
                        <FiExternalLink />
                      </Icon>
                    </Row>
                  </a>
                </Column>
              )}

              <Column gap="0.5rem">
                <Row gap="0.5rem" align="center" justify="center">
                  <Icon>
                    <FaTelegramPlane />
                  </Icon>
                  <SmallText>Telegram</SmallText>
                </Row>
                <a target="_blank" href="https://t.me/CompareMarketCapListing">
                  <Row
                    className="form-link"
                    gap="0.5rem"
                    style={{
                      padding: "0.5rem 1rem",
                      borderRadius: borderRads.SMALL,
                    }}
                  >
                    <SmallText color={theme.colors.LIGHT_TEXT}>
                      t.me/CompareMarketCapListing
                    </SmallText>
                    <Icon color={theme.colors.LIGHT_TEXT}>
                      <FiExternalLink />
                    </Icon>
                  </Row>
                </a>
                <CopyButton text="@CompareMarketCapListing" isRight={true}>
                  <Row gap="0.5rem" align="center" justify="center">
                    <SmallText
                      color={theme.colors.LIGHT_TEXT}
                      size={textSizes.fontSizes.SMALLER}
                    >
                      Copy handle
                    </SmallText>
                    <Icon color={theme.colors.LIGHT_TEXT}>
                      <BiCopy />
                    </Icon>
                  </Row>
                </CopyButton>
              </Column>
              <Column gap="0.5rem">
                <Row gap="0.5rem" align="center" justify="center">
                  <Icon>
                    <GrMail />
                  </Icon>
                  <SmallText>Mail</SmallText>
                </Row>
                <a href="mailto:contact@comparemarketcap.com">
                  <Row
                    className="form-link"
                    gap="0.5rem"
                    style={{
                      padding: "0.5rem 1rem",
                      borderRadius: borderRads.SMALL,
                    }}
                  >
                    <SmallText color={theme.colors.LIGHT_TEXT}>
                      contact@comparemarketcap.com
                    </SmallText>
                    <Icon color={theme.colors.LIGHT_TEXT}>
                      <FiExternalLink />
                    </Icon>
                  </Row>
                </a>
                <CopyButton text="contact@comparemarketcap.com" isRight={true}>
                  <Row gap="0.5rem" align="center" justify="center">
                    <SmallText
                      color={theme.colors.LIGHT_TEXT}
                      size={textSizes.fontSizes.SMALLER}
                    >
                      Copy address
                    </SmallText>
                    <Icon color={theme.colors.LIGHT_TEXT}>
                      <BiCopy />
                    </Icon>
                  </Row>
                </CopyButton>
              </Column>
            </Column>
          </Box>
        </ClickAwayListener>
      </Modal>
    </div>
  );
}

const style = {
  position: "fixed",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: theme.colors.OLDLEVEL,
  borderRadius: borderRads.SMALL,
  boxShadow: 24,
  zIndex: 1,
};
