import styled from "styled-components";
import {paddings} from "../paddings.styled";
import {borderRads, borderWidths, textSizes} from "../sizes";

export const CoinBadge = styled.div`
  border: ${borderWidths.SMALL} solid ${({alternate, theme}) => alternate ? theme.colors.BADGE_ALTERNATE_BORDER : theme.colors.BADGE_MAIN_BORDER};
  border-radius: ${borderRads.SMALL};
  background-color: black;
  padding: ${paddings.LOW} ${paddings.MID};
  font-size: ${textSizes.fontSizes.SMALLER};
`