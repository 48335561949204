import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import { Column, Row } from "../containers/flexbox.styled";
import { SmallText } from "../texts.styled";
import { Icon } from "../icon.styled";
import { borderRads } from "../sizes";
import { useState } from "react";
import { theme } from "../theme.styled";
import styled from "styled-components";
import { zIndexes } from "../globalStyle.styled";
import { ClickAwayListener } from "@mui/material";
import { RouteLink } from "../../routing/routeLink";
import { BsArrow90DegDown } from "react-icons/bs";
import { useNavigate } from "react-router-dom";

export default function SelectMenu({ options, selected, setSelected, name }) {
  const [isOn, setIsOn] = useState(false);

  const navigate = useNavigate();

  return (
    <StyledSelectMenuWrapper align="start" gap="0.5rem">
      <SmallText
        color={({ theme }) => theme.colors.LIGHT_TEXT}
        style={{
          marginLeft: "0.5rem",
        }}
      >
        {name}
      </SmallText>
      <StyledSelectMenuButton onClick={() => setIsOn((prev) => !prev)}>
        <Row justify="space-between">
          <SmallText style={{ whiteSpace: "nowrap" }}>{selected}</SmallText>
          <Icon color={({ theme }) => theme.colors.LIGHT_TEXT}>
            {!isOn ? <FiChevronDown /> : <FiChevronUp />}
          </Icon>
        </Row>
      </StyledSelectMenuButton>
      {isOn && (
        <ClickAwayListener
          onClickAway={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setIsOn(false);
          }}
        >
          <StyledSelectMenu>
            {options.map((option) => (
              <Row
                onClick={() => {
                  setSelected(option.item);
                  setIsOn(false);
                  if (option.onClick) {
                    option.onClick();
                  }
                }}
                gap="0rem"
                align="center"
                style={
                  option.degree === 1
                    ? {
                        paddingLeft: "0.5rem",
                        fontSize: "0.8rem",
                      }
                    : {}
                }
              >
                {option.degree > 0 && (
                  <Icon
                    size="0.7rem"
                    color={theme.colors.LIGHT_TEXT} //rotate it 90
                    style={{ transform: "rotate(-90deg)" }}
                  >
                    <BsArrow90DegDown />
                  </Icon>
                )}

                <SmallText
                  key={option}
                  color={
                    selected === option.item ? "white" : theme.colors.LIGHT_TEXT
                  }
                  style={
                    option.degree === 1
                      ? {
                          whiteSpace: "nowrap",
                          paddingLeft: "0.5rem",
                          fontSize: "0.8rem",
                        }
                      : {
                          whiteSpace: "nowrap",
                        }
                  }
                >
                  {option.item}
                </SmallText>
              </Row>
            ))}
          </StyledSelectMenu>
        </ClickAwayListener>
      )}
    </StyledSelectMenuWrapper>
  );
}

const StyledSelectMenu = styled(Column)`
  position: absolute;
  width: 100%;
  left: 50%;
  top: 100%;
  transform: translateX(-50%);
  border-radius: ${borderRads.SMALL};
  background-color: ${({ theme }) => theme.colors.OLDLEVEL};
  z-index: ${zIndexes.DROPDOWN_MENU};
  align-items: start;

  @keyframes fadeContainerIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  animation: fadeContainerIn 0.1s;
  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  -ms-animation-fill-mode: forwards;
  -o-animation-fill-mode: forwards;
  animation-fill-mode: forwards;

  & > * {
    width: 100%;
    cursor: pointer;
    padding: 0.75rem 1rem;
    &:hover {
      color: white;
      background-color: ${({ theme }) => theme.colors.LEVEL2};
    }
    background-color: ${({ theme }) => theme.colors.LEVEL};
  }

  overflow-y: auto;
  max-height: 20rem;
`;

const StyledSelectMenuWrapper = styled(Column)`
  position: relative;
  @media (max-width: 40rem) {
    width: 100%;
  }
`;

const StyledSelectMenuButton = styled.button`
  border: none;
  cursor: pointer;
  width: 15rem;
  padding: 0.75rem 1rem;
  border-radius: ${borderRads.SMALL};
  background-color: ${({ theme }) => theme.colors.LEVEL};
  &:hover {
    background-color: ${({ theme }) => theme.colors.LEVEL2};
  }

  //if mobile, 100% width
  @media (max-width: 40rem) {
    width: 100%;
  }
`;
