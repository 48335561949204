import { useQuery } from "@tanstack/react-query";
import { Container } from "../containers/boxes.styled";
import HolderCompareDesktop from "./holderCompareDesktop";
import HolderCompareMobile from "./holderCompareMobile";
import { getCoinPage, getCommonHolders } from "../stream";
import { useLocation, useParams } from "react-router-dom";
import { HolderCompareCoinsContext } from "../../context";
import { useEffect, useState } from "react";
import { Paragraph, SmallText } from "../texts.styled";
import { Column } from "../containers/flexbox.styled";
import { textSizes } from "../sizes";
import Loader from "../misc/loader";
import { TfiFaceSad } from "react-icons/tfi";
import { Icon } from "../icon.styled";
import { Helmet } from "react-helmet";

export default function HolderCompareWrapper() {
  let { coin1, coin2 } = useParams();

  return <HolderCompare key={coin1 + coin2} coin1={coin1} coin2={coin2} />;
}

//empty react component
export function HolderCompare({ coin1, coin2 }) {
  const location = useLocation();

  const { data: coin1Data, status: coin1Status } = useQuery(
    ["holder-compare-coin1", coin1],
    () => getCoinPage(coin1),
    {
      retry: 1,
      refetchOnWindowFocus: false,
      onError: (error) => {
        setError(true);
      },
    }
  );

  const { data: coin2Data, status: coin2Status } = useQuery(
    ["holder-compare-coin2", coin2],
    () => getCoinPage(coin2),
    {
      retry: 1,
      refetchOnWindowFocus: false,
      onError: (error) => {
        setError(true);
      },
    }
  );

  const [compareData, setCompareData] = useState(null);

  const [excludeCex, setExcludeCex] = useState(false);

  useEffect(() => {
    setCompareData(null);
  }, [coin1, coin2]);

  const [isError, setError] = useState(false);

  const {
    data,
    status,
    refetch: fetchHolders,
    isInitialLoading,
  } = useQuery(
    ["holder-compare", coin1, coin2],
    () =>
      getCommonHolders(
        coin1Data.id,
        coin1Data.contractAddress,
        coin2Data.id,
        coin2Data.contractAddress,
        coin1Data.blockChain,
        coin2Data.blockChain
      ),
    {
      enabled: false,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        setCompareData(data);
      },
      onError: (error) => {
        setError(true);
      },
    }
  );

  useEffect(() => {
    if (coin1Status === "success" && coin2Status === "success") {
      setError(false);
      fetchHolders();
    }
  }, [coin1Status, coin2Status]);

  useEffect(() => {
    return () => {
      setCompareData(null);
      setError(false);
    };
  }, []);

  useEffect(() => {
    setCompareData(null);
    setError(false);
  }, [location]);

  return status && !isInitialLoading === "loading" ? (
    <Column
      style={{ margin: "0 auto", height: "40rem" }}
      justify="center"
      gap="2rem"
    >
      <Loader />
      <Paragraph weight={textSizes.fontWeights.BOLD}>
        Getting holder data...
      </Paragraph>
    </Column>
  ) : compareData ? (
    <HolderCompareCoinsContext.Provider
      value={{ coin1Data, coin2Data, compareData, excludeCex, setExcludeCex }}
    >
      <>
        <Helmet>
          <title>
            {coin1Data.symbol.toUpperCase()} vs {coin2Data.symbol.toUpperCase()}{" "}
            Holder Comparison
          </title>
          <meta
            name="description"
            content={
              "See common holders, top holders and holder distributions of " +
              coin1Data.symbol.toUpperCase() +
              " and " +
              coin2Data.symbol.toUpperCase()
            }
          />
        </Helmet>
        <HolderCompareMobile />
        <HolderCompareDesktop />
      </>
    </HolderCompareCoinsContext.Provider>
  ) : isError ? (
    <Column style={{ margin: "0 auto", marginTop: "15rem" }} gap="2rem">
      <Icon size="4rem" color={({ theme }) => theme.colors.LIGHT_TEXT}>
        <TfiFaceSad />
      </Icon>
      <Paragraph weight={textSizes.fontWeights.BOLD}>
        No holder data for these coins.
      </Paragraph>
    </Column>
  ) : (
    <Column
      style={{ margin: "0 auto", height: "40rem" }}
      justify="center"
      gap="2rem"
    >
      <Loader />
      <Paragraph weight={textSizes.fontWeights.BOLD}>
        Getting holder data...
      </Paragraph>
    </Column>
  );
}
