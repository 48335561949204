import styled from "styled-components";
import { EmptyBox } from "../containers/boxes.styled";
import { paddings } from "../paddings.styled";
import { borderRads, textSizes } from "../sizes";
import { BigText } from "../texts.styled";
import {
  MarketDataRow, RecentlyListedCardImage,
  StyledRecentlyListedCard,
  StyledRecentlyListedCardSocial,
  StyledRecentlyListedCardSocialLink
} from "./recentlyListedSwiper.styled";

export const StyledRecentlyListedPageWrapper = styled(EmptyBox)`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  
  gap: 1rem;
  
  @media(max-width: 100rem){
    grid-template-columns: 1fr 1fr;
  }

  @media(max-width: 60rem){
    grid-template-columns: 1fr;
  }
`

export const RecentlyListedPageCard = styled(StyledRecentlyListedCard)`
  height: 10rem;
  position: relative;
  align-items: center;
  justify-content: space-between;
  padding: ${paddings.MID};
  padding-top: 1.8rem !important;
  flex-direction: row;
  
  overflow: hidden;

  @media(max-width: 40rem){
    padding: ${paddings.LOW} ${paddings.LOW} ${paddings.MID} ${paddings.MID};
  }
  
  &:hover{
    ${RecentlyListedCardImage}{
      bottom: -0.5rem;
      right: 1rem;
    }
  }
  gap: 0.8rem;
  background-color: ${({theme}) => theme.colors.SECONDARY};
  border-radius: ${borderRads.SMALL};

  ${BigText}{
    font-size: ${textSizes.fontSizes.HEADER};
  };
  
  ${MarketDataRow}{
    gap: 3rem;
    & > * {
      padding: 0;
      background-color: transparent;
      white-space: nowrap;
      @media(max-width: 70rem){
        ${BigText}{
          font-size: ${textSizes.fontSizes.HEADER};
        };
      }
    }
  }
  
  ${StyledRecentlyListedCardSocial}{
    gap: 1rem;
    flex-direction: column;
    width: fit-content;
    height: 100%;
    justify-content: center;
  }

  ${StyledRecentlyListedCardSocialLink}{
    width: 2rem;
    height: 2rem;
  }
  padding: ${paddings.MID};
  
  & > *:first-child{
    position: absolute;
    right: -1rem;
    width: 15rem;
    filter: brightness(0.2) opacity(0.3);
  }
`

export const RecentlyListedTimeAdded = styled.div`
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  top: 0;
  border-radius: 0 0 ${borderRads.SMALL} ${borderRads.SMALL};
  background-color: rgba(255,255,255,0.2);
  text-align: center;
  padding: 0.2rem 0.5rem;
`