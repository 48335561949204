import React, { useEffect, useState } from "react";
import Dropdown from "../compareSection/dropdown";
import { Column, FullRow, Row } from "../containers/flexbox.styled";
import { borderRads, borderWidths, textSizes } from "../sizes";
import { LightHeader, Paragraph, SmallText } from "../texts.styled";
import { theme } from "../theme.styled";
import RoiBattleDropdown from "./roiBattleDropdown";
import { DateRangePicker, DateRangePickerWrapper } from "./roiBattleDatePicker";
import { TransparentButton } from "../buttons/buttons.styled";
import { Icon } from "../icon.styled";
import { FaCheck } from "react-icons/fa6";
import {
  getAllCoinsWithAllData,
  getCoin,
  getCoinPage,
  getExternalCoinGraphData,
  getHistoricalDataOfCoin,
} from "../stream";
import { VerticalSeperator } from "../containers/boxes.styled";
import {
  formatNumber,
  getSmallNumber,
  graphFormatter,
} from "../../util/numberFormatter";
import { toast } from "react-toastify";
import { defaultCoins } from "../compareSection/defaultCoins";
import styled from "styled-components";
import { StyledDropdown } from "../compareSection/dropdown.styled";
import { set, setDate } from "date-fns";
import { MdOutlineCompareArrows, MdOutlineTrendingUp } from "react-icons/md";
import { useQuery } from "@tanstack/react-query";
import Loader from "../misc/loader";

export default function RoiBattle({ coin, margin }) {
  const notify = (text) =>
    toast(text || "There has been an error. Please try another coin.");

  const price = coin.price;
  const [otherCoin, setOtherCoin] = useState();
  const [coinData, setCoinData] = useState(null);
  const [otherCoinData, setOtherCoinData] = useState(null);

  const [firstCompareDone, setFirstCompareDone] = useState(false);

  const [dates, setDates] = useState(null);
  const [notMatchingDates, setNotMatchingDates] = useState(null);

  const [firstDate, setFirstDate] = useState(null);
  const [secondDate, setSecondDate] = useState(null);

  const [resultData, setResultData] = useState(null);

  const [isLoading, setLoading] = useState(false);

  const [dateChanged, setDateChanged] = useState(false);

  const { data: dropdownData, status } = useQuery(
    ["roi-compare", coin.id],
    () => getAllCoinsWithAllData(),
    {
      refetchOnWindowFocus: false,
      onSuccess: (data) => {},
    }
  );

  useEffect(() => {
    setDateChanged(true);

    if (firstCompareDone && firstDate && secondDate) {
      handleClick();
    }
  }, [firstDate, secondDate]);

  useEffect(() => {
    setFirstCompareDone(false);

    //reset dates
    setDates(null);
    setNotMatchingDates(null);
    setFirstDate(null);
    setSecondDate(null);
    setResultData(null);
  }, [otherCoin]);

  useEffect(() => {
    fetchData();
  }, []);

  async function fetchData() {
    let data;

    if (coin.source === "ds") {
      data = await getExternalCoinGraphData(
        coin.pairAddress,
        coin.blockChain,
        true
      );

      let result = data.map((item) => ({
        x: item.time * 1000,
        y: item.close,
      }));

      setCoinData(result);
    } else {
      data = await getHistoricalDataOfCoin(coin.id);

      setCoinData(data.price);
    }
  }

  useEffect(() => {
    if (coinData === null) return;

    fetchOtherData();
  }, [coinData]);

  useEffect(() => {
    if (coinData === null) return;

    setResultData(null);

    fetchOtherData();
  }, [otherCoin]);

  useEffect(() => {
    return () => {
      setCoinData(null);
      setOtherCoinData(null);
      setDates(null);
      setNotMatchingDates(null);
      setFirstDate(null);
      setSecondDate(null);
      setResultData(null);
    };
  }, []);

  async function fetchOtherData() {
    if (!otherCoin) return;

    try {
      let data;

      if (otherCoin.source === "ds") {
        data = await getExternalCoinGraphData(otherCoin.ca, otherCoin.bc);
        let result = data.map((item) => ({
          x: item.time,
          y: item.close,
        }));
        setOtherCoinData(result);
      } else {
        data = await getHistoricalDataOfCoin(otherCoin.id);
        setOtherCoinData(data.price);
      }

      const dates = coinData.map((coin) => coin.x);

      const otherDates = data.price.map((coin) => coin.x);
      const commonDates = dates.filter((date) => otherDates.includes(date));

      let nonMatchingDates = dates.filter((date) => !otherDates.includes(date));
      const nonMatchingDates2 = otherDates.filter(
        (date) => !dates.includes(date)
      );

      nonMatchingDates = nonMatchingDates.concat(nonMatchingDates2);

      setDates(commonDates);
      setNotMatchingDates(nonMatchingDates);
    } catch (e) {
      setOtherCoinData(null);
      notify();
    }
  }

  async function handleClick() {
    if (!firstDate || !secondDate) {
      notify("Please select a date range");
      return;
    }

    setDateChanged(false);

    //get the price of the coins for both dates

    let date1 = new Date(firstDate);
    date1 = getClosestDateInArray(
      date1,
      coinData.map((coin) => coin.x)
    );

    let date2 = new Date(firstDate);
    date2 = getClosestDateInArray(
      date2,
      coinData.map((coin) => coin.x)
    );

    //convert to epoch and find the closest

    let price1 = coinData.find((coin) => coin.x === date1).y;
    let price2 = otherCoinData.find((coin) => coin.x === date2).y;

    let currentPrice1 = price;
    setLoading(true);
    let other = await getCoinPage(otherCoin.id);
    setLoading(false);
    let currentPrice2 = other.price;

    let change1 = currentPrice1 / price1;
    let change2 = currentPrice2 / price2;

    setResultData({
      change1,
      change2,
    });

    setFirstCompareDone(true);
  }

  //do the necessary cleanups

  useEffect(() => {
    return () => {
      setCoinData(null);
      setOtherCoinData(null);
      setDates(null);
      setNotMatchingDates(null);
      setFirstDate(null);
      setSecondDate(null);
      setResultData(null);
    };
  }, []);

  return (
    <StyledRoiBattle
      resultData={resultData}
      style={margin ? { margin: "0 0.5rem" } : {}}
    >
      <FullRow width="fit-content" gap="0.5rem" justify="center">
        <MdOutlineTrendingUp />
        <LightHeader>ROI COMPARE</LightHeader>
      </FullRow>

      <FullRow gap="1rem" justify="center">
        <Row
          style={{
            padding: "0.4rem",
            borderRadius: borderRads.SMALL,
            width: "50%",
            backgroundColor: theme.colors.LEVEL,
          }}
          width="50%"
          gap="0.5rem"
          justify="end"
        >
          <Paragraph weight={textSizes.fontWeights.SEMI_BOLD}>
            {coin.symbol.toUpperCase()}
          </Paragraph>
          <img
            style={{ width: "1.75rem", borderRadius: borderRads.SMALLER }}
            alt="logo"
            src={coin.image}
          />
        </Row>
        <SmallText>vs</SmallText>
        <RoiBattleDropdown
          items={dropdownData}
          setCoin={setOtherCoin}
          defaultCoin={defaultCoins.bitcoin}
        />
      </FullRow>
      {resultData && (
        <RoiBattleResult
          coin1={coin}
          coin2={otherCoin}
          resultData={resultData}
        />
      )}
      {otherCoin ? (
        <>
          <FullRow gap="1rem" justify="center">
            <div style={{ position: "relative", width: "11.5rem" }}>
              <DateRangePickerWrapper
                dates={dates}
                excludeDates={notMatchingDates}
                setFirstDate={setFirstDate}
                setSecondDate={setSecondDate}
              />

              {isLoading && (
                <div
                  style={{
                    position: "absolute",
                    top: "0.26rem",
                    left: "-0.75rem",
                  }}
                >
                  <Loader
                    style={{
                      position: "absolute",
                      top: "0",
                    }}
                    isSmall={true}
                  />
                </div>
              )}

              {!firstCompareDone && (
                <TransparentButton
                  style={{
                    backgroundColor: theme.colors.LEVEL2,
                    position: "absolute",
                    left: "100%",
                    top: "0",
                    padding: "0.5rem",
                    opacity: dateChanged && firstDate && secondDate ? 1 : 0.5,
                  }}
                  onClick={() => {
                    if (!otherCoinData) {
                      notify();
                      return;
                    }
                    if (dateChanged && firstDate && secondDate) {
                      handleClick();
                    }
                  }}
                >
                  <Icon size="1.12rem">
                    <FaCheck />
                  </Icon>
                </TransparentButton>
              )}
            </div>
          </FullRow>
        </>
      ) : (
        <SmallText
          style={{ marginTop: "0.75rem" }}
          color={theme.colors.LIGHT_TEXT}
        >
          Select a coin
        </SmallText>
      )}
    </StyledRoiBattle>
  );
}

export const StyledRoiBattle = styled(Column)`
  /* style={{
          backgroundColor: theme.colors.SECONDARY,
          borderRadius: borderRads.SMALL,
          padding: "0.5rem",
          transition: "0.4s",
          height: !resultData ? "9rem" : "17rem",
        }}
        align="center"
        width="100%"
        gap="1rem" */

  background-color: ${theme.colors.SECONDARY};
  border-radius: ${borderRads.SMALL};
  padding: 0.5rem;
  transition: 0.4s;
  height: ${({ resultData }) => (resultData ? "18.8rem" : "11.4rem")};
  align-items: center;
  width: 100%;
  gap: 1rem;

  .roi-battle-result {
    @keyframes fadeContainerIn {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }

    animation: fadeContainerIn 1s;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    -ms-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
  }

  //for min width 70rem, have 0.5erm padding and background color level2

  @media (min-width: 70rem) {
    padding: 1rem;
    background-color: ${theme.colors.LEVEL};

    height: ${({ resultData }) => (resultData ? "19.6rem" : "12rem")};

    ${StyledDropdown} {
      background-color: ${theme.colors.LEVEL2};
    }
  }
`;

function RoiBattleResult({ coin1, coin2, resultData }) {
  return (
    <Column className="roi-battle-result">
      <FullRow gap="1rem" style={{ marginTop: "0.6rem" }} justify="center">
        <Column style={{ width: "5rem" }} align="end" gap="1rem">
          <Column align="end" gap="0.4rem">
            <Row gap="0.4rem" justify="end">
              <SmallText>{coin1.symbol.toUpperCase()}</SmallText>
              <img style={{ width: "1.1rem" }} alt="logo" src={coin1.image} />
            </Row>
            <SmallText>{resultData.change1 >= 1 ? "made" : "made"}</SmallText>
          </Column>

          <Paragraph
            style={{
              color:
                Number(formatNumber(resultData.change1)) > 1
                  ? theme.colors.GREEN
                  : Number(formatNumber(resultData.change1)) === 1
                  ? theme.colors.LIGHT_TEXT
                  : theme.colors.RED,
              fontSize: textSizes.fontSizes.LARGE,
            }}
          >
            {resultData.change1 > 0.001 ? (
              formatNumber(resultData.change1)
            ) : (
              <React.Fragment>
                <span>0.0</span>
                <Paragraph>
                  {getSmallNumber(resultData.change1).zeroCount}
                </Paragraph>
                <span>{getSmallNumber(resultData.change1).nonZeroDigits}</span>
              </React.Fragment>
            )}
            x
          </Paragraph>
        </Column>
        <VerticalSeperator
          height={"6rem"}
          style={{
            backgroundColor: theme.colors.LIGHT_TEXT,
          }}
        />
        <Column style={{ width: "5rem" }} align="start" gap="1rem">
          <Column align="start" gap="0.4rem">
            <Row gap="0.4rem" justify="start">
              <img style={{ width: "1.1rem" }} alt="logo" src={coin2.image} />
              <SmallText>{coin2.symbol.toUpperCase()}</SmallText>
            </Row>
            <SmallText>{resultData.change2 >= 1 ? "made" : "made"}</SmallText>
          </Column>

          <Paragraph
            style={{
              color:
                Number(formatNumber(resultData.change2)) > 1
                  ? theme.colors.GREEN
                  : Number(formatNumber(resultData.change2)) === 1
                  ? theme.colors.LIGHT_TEXT
                  : theme.colors.RED,
              fontSize: textSizes.fontSizes.LARGE,
            }}
          >
            {resultData.change2 > 0.001 ? (
              formatNumber(resultData.change2)
            ) : (
              <React.Fragment>
                <span>0.0</span>
                <Paragraph>
                  {getSmallNumber(resultData.change2).zeroCount}
                </Paragraph>
                <span>{getSmallNumber(resultData.change2).nonZeroDigits}</span>
              </React.Fragment>
            )}
            x
          </Paragraph>
        </Column>
      </FullRow>
    </Column>
  );
}

function getClosestDateInArray(date, array) {
  let closest = array[0];
  let diff = Math.abs(date - closest);
  for (let i = 1; i < array.length; i++) {
    let newDiff = Math.abs(date - array[i]);
    if (newDiff < diff) {
      closest = array[i];
      diff = newDiff;
    }
  }
  return closest;
}
