import styled from "styled-components";
import { StyledSmallList } from "../threeListSection/smallList.styled";
import { paddings } from "../paddings.styled";
import { Column, Row } from "../containers/flexbox.styled";
import { borderRads, textSizes } from "../sizes";
import { RouteLink } from "../../routing/routeLink";
import { zIndexes } from "../globalStyle.styled";
import { BigText } from "../texts.styled";

export const RecentlyListedCardImage = styled.img`
  width: 6rem !important;
  height: 6rem !important;
  bottom: -1rem;
  right: -1rem;
  transition: 0.4s;
  position: absolute;
`;

export const StyledRecentlyListedSwiper = styled(StyledSmallList)`
  width: 25rem;
  position: relative;
  padding: 0;
  /* background-image: url("/images/rl-bg-2.webp"); */
  background-size: cover;
  background-position: center;
  background-color: transparent;
`;

export const MarketDataRow = styled(Row)`
  gap: 1rem;
  width: fit-content;

  & > * {
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    background-color: rgba(255, 255, 255, 0.2);
    padding: ${paddings.LOWER} ${paddings.LOW};
    border-radius: ${borderRads.SMALL};
  }

  ${Column} {
    align-items: start;
  }

  @media (max-width: 40rem) {
    gap: 1rem !important;
    & > * > * {
      font-size: 0.9rem;
    }
  }
`;

export const RecentlyListedLink = styled(RouteLink)`
  border-radius: ${borderRads.SMALL} 0 ${borderRads.SMALL} 0;
  padding: ${paddings.LOW};
  background-color: rgba(255, 255, 255, 0.2);
  font-size: ${textSizes.fontSizes.SMALL};
  position: absolute;
  top: 0;
  left: 0;
  &:hover {
    text-decoration: underline;
  }

  z-index: ${zIndexes.HOMEPAGE_RECENTLY_LISTED_LINK};

  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);

  ${Row} {
    gap: 0.2rem;
  }

  @media (max-width: 40rem) {
    & > * > span {
      font-size: ${textSizes.fontSizes.SMALLER};
    }
  }
`;

export const StyledRecentlyListedCard = styled(Column)`
  position: relative;
  align-items: start;
  justify-content: space-between;
  height: 100%;
  padding: ${paddings.MID};

  overflow: hidden;

  border-radius: ${borderRads.SMALL};

  background-position: center;
  background-size: cover;
  backdrop-filter: blur(100);

  background-color: ${({ imageLink }) =>
    imageLink ? "rgba(0, 0, 0, 0.7)" : "transparent"};

  overflow: hidden;

  @media (max-width: 40rem) {
    padding: ${paddings.LOW} ${paddings.LOW} ${paddings.MID} ${paddings.MID};
  }

  @media (max-width: 70rem) {
    ${BigText} {
      font-size: ${textSizes.fontSizes.HEADER};
    }
  }
`;

export const ImageBG = styled.div`
  width: 100%;
  height: 100%;

  background-image: ${({ imageLink }) =>
    imageLink ? `url(${imageLink})` : `url("/images/rl-bg-2.webp")`};
  background-position: center;
  background-size: cover;

  border-radius: ${borderRads.SMALL};
`;

export const StyledRecentlyListedCardSocial = styled(Row)`
  align-self: end;
  gap: 1rem;
  justify-content: end;
  @media (max-width: 40rem) {
    gap: 0.5rem;
  }
`;

export const StyledRecentlyListedCardSocialLink = styled(RouteLink)`
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 123123123rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  @media (max-width: 40rem) {
    width: 1.6rem;
    height: 1.6rem;
  }
  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
`;
