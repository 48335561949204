import styled from "styled-components";
import {Column, FullRow, Row} from "../containers/flexbox.styled";
import {Paragraph, Title} from "../texts.styled";
import {paddings} from "../paddings.styled";
import {OutlineButton} from "../buttons/buttons.styled";

export const StyledAboutUsTopMessage = styled(FullRow)`

  ${Column}{
    align-items: start;
  }

  gap: 5rem;
  
  margin: 8rem;
  
  justify-content: center;
  
  img{
    width: 20rem;
  }
  
  ${Title}{
    font-size: 2.6rem;
  }
  
  @media(max-width: 70rem){
    margin: 3rem;
  }
  
  ${OutlineButton}{
    margin-top: 1rem;
    padding: ${paddings.MID} 2rem;
    transition: 0.4s;
  }

  @media(max-width: 50rem){
    margin: 1.5rem;
    ${Paragraph}{
      padding-left: ${paddings.LOWER};
      padding-right: ${paddings.LOWER};
    }
    ${Column}{
      align-items: center;
    }
    ${Title}{
      font-size: 1.8rem;
    }
    gap: 2rem;
    width: 100%;
    flex-direction: column;
    & > * {
      text-align: center;
    }
    img{
      width: 10rem;
    }

    ${OutlineButton}{
      width: 100%;
    }
  }
  
`