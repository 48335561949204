import {
  BigText,
  LightHeader,
  Paragraph,
  SmallText,
  Title,
} from "../components/texts.styled";
import {
  StyledTerminal,
  StyledTerminalCategory,
  StyledTerminalCoinInfo,
  StyledTerminalMainColumn,
  StyledTerminalMarketData,
  TerminalColumn,
  TerminalControlButtons,
  TerminalCustomizeMenu,
  TerminalCustomizeMenuWrapper,
  TerminalDropdownWrapper,
} from "../components/terminal/terminal.styled";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { getCoin } from "../components/stream";
import Loader from "../components/misc/loader";
import { Column, FullRow } from "../components/containers/flexbox.styled";
import Dropdown from "../components/compareSection/dropdown";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { formatNumber } from "../util/numberFormatter";
import {
  HideOnMobileWrapper,
  ShowOnMobileWrapper,
} from "../components/hideOnMobile";
import { VerticalSeperator } from "../components/containers/boxes.styled";
import { textSizes } from "../components/sizes";
import { RouteLink } from "../routing/routeLink";
import { TransparentButton } from "../components/buttons/buttons.styled";
import { Icon } from "../components/icon.styled";
import { IoMdSettings } from "react-icons/io";
import { MdFullscreen, MdFullscreenExit, MdPlusOne } from "react-icons/md";
import { IoAdd } from "react-icons/io5";
import { ClickAwayListener } from "@mui/base";
import { Helmet } from "react-helmet";

export default function Terminal() {
  const notify = () => toast(`There has been an error.`);
  const notifyRedirect = () => toast(`No such coin found.`);

  const navigate = useNavigate();

  let { coinId } = useParams();

  const [coin, setCoin] = useState(null);
  const [currentCoinId, setCurrentCoinId] = useState(coinId);
  const [isFullScreen, setFullScreen] = useState(
    document.mozFullScreen || document.webkitIsFullScreen
  );
  const [isCustomizeScreenOn, setCustomizeScreenOn] = useState(false);

  const [selectedBackgroundImage, setSelectedBackgroundImage] = useState("");

  const {
    data,
    status,
    refetch: fetchData,
  } = useQuery(["terminal"], () => getCoin(currentCoinId), {
    refetchInterval: 5000,
    enabled: false,
    onSuccess: (data) => {
      if (data !== "") setCoin(data);
      else {
        notifyRedirect();
        navigate(`/terminal/bitcoin`);
        setCurrentCoinId("bitcoin");
      }
    },
    onError: (err) => notify(),
  });

  useEffect(() => {
    if (currentCoinId) fetchData();
  }, [currentCoinId]);

  function handleCoinSelection(item) {
    navigate(`/terminal/${item.id}`);
    setCurrentCoinId(item.id);
  }

  function enterFullScreen() {
    try {
      document.body.requestFullscreen();
    } catch (e) {}
    setFullScreen(true);
  }

  function exitFullScreen() {
    if (document.fullscreenElement !== null) {
      document.exitFullscreen();
    }
    setFullScreen(false);
  }

  const backgroundImages = [
    "/terminalBackgrounds/clouds.webp",
    "/terminalBackgrounds/planet.webp",
    "/terminalBackgrounds/rain.webp",
    "/terminalBackgrounds/sky.webp",
  ];

  useEffect(() => {
    document.body.style.backgroundColor = "#000";
    return () => {
      document.body.style.backgroundColor = "#17072B";
      //remove background image
      document.body.style.backgroundImage = "none";
    };
  }, []);

  return (
    <StyledTerminal /* image={"url(" + selectedBackgroundImage + ")"} */>
      <Helmet>
        <title>Terminal - CompareMarketCap</title>
        <meta
          name="description"
          content={
            "Track your favourite crypto currencies important parameters like price, volume, 24 hour low and high, market capitalization on a dedicated screen."
          }
        />
      </Helmet>
      <FullRow
        padding={"1rem"}
        gap={"1rem"}
        justify={"center"}
        align={"center"}
      >
        <RouteLink to={"/"}>
          <img
            alt="logo"
            style={{ height: "2rem" }}
            src={"/logos/comparemarketcap-logo.svg"}
          />
        </RouteLink>
        <VerticalSeperator height={"1.8rem"} />
        <LightHeader
          weight={textSizes.fontWeights.THIN}
          size={textSizes.fontSizes.LARGE}
        >
          TERMINAL
        </LightHeader>
      </FullRow>
      {status === "success" && coin !== null ? (
        <StyledTerminalMainColumn>
          <FullRow justify={"space-between"} verticalOnMobile>
            <StyledTerminalCoinInfo>
              <img alt="logo" src={coin.image} />
              <Column align={"start"}>
                <Title>{coin.symbol.toUpperCase()}</Title>
                <Paragraph>{coin.coinName}</Paragraph>
              </Column>
            </StyledTerminalCoinInfo>
            <TerminalDropdownWrapper>
              <Dropdown
                defaultExternalCoin={coin}
                setCoin={handleCoinSelection}
              />
            </TerminalDropdownWrapper>
          </FullRow>
          <StyledTerminalMarketData>
            <ShowOnMobileWrapper>
              <Column>
                <Title>${formatNumber(coin.price)}</Title>
                <BigText
                  color={({ theme }) =>
                    coin.change1H > 0 ? theme.colors.GREEN : theme.colors.RED
                  }
                >
                  {formatNumber(coin.change1H, false, true)}%
                </BigText>
              </Column>
            </ShowOnMobileWrapper>
            <TerminalColumn align={"start"}>
              <Column gap={"0.4rem"} align={"start"}>
                <Paragraph>${formatNumber(coin.marketcap)}</Paragraph>
                <StyledTerminalCategory>Market Cap</StyledTerminalCategory>
              </Column>
              <Column gap={"0.4rem"} align={"start"}>
                <Paragraph>{formatNumber(coin.volume24H)}</Paragraph>
                <StyledTerminalCategory>Volume</StyledTerminalCategory>
              </Column>
            </TerminalColumn>
            <HideOnMobileWrapper>
              <Column>
                <Title>${formatNumber(coin.price)}</Title>
                <BigText
                  color={({ theme }) =>
                    coin.change1H > 0 ? theme.colors.GREEN : theme.colors.RED
                  }
                >
                  {formatNumber(coin.change1H, false, true)}%
                </BigText>
              </Column>
            </HideOnMobileWrapper>
            <TerminalColumn align={"end"}>
              <Column gap={"0.4rem"} align={"end"}>
                <Paragraph>${formatNumber(coin.low24)}</Paragraph>
                <StyledTerminalCategory>24H Low</StyledTerminalCategory>
              </Column>
              <Column gap={"0.4rem"} align={"end"}>
                <Paragraph>${formatNumber(coin.high24)}</Paragraph>
                <StyledTerminalCategory>24H High</StyledTerminalCategory>
              </Column>
            </TerminalColumn>
          </StyledTerminalMarketData>
        </StyledTerminalMainColumn>
      ) : (
        <Loader height={"100vh"} />
      )}
      <TerminalControlButtons>
        <TransparentButton onClick={() => setCustomizeScreenOn(true)}>
          <Icon size={"3rem"}>
            <IoMdSettings />
          </Icon>
        </TransparentButton>
        <TransparentButton
          onClick={() => (isFullScreen ? exitFullScreen() : enterFullScreen())}
        >
          <Icon size={"3rem"}>
            {isFullScreen ? <MdFullscreenExit /> : <MdFullscreen />}
          </Icon>
        </TransparentButton>
      </TerminalControlButtons>
      {isCustomizeScreenOn && (
        <TerminalCustomizeMenuWrapper>
          <LightHeader>Select Background</LightHeader>
          <ClickAwayListener onClickAway={() => setCustomizeScreenOn(false)}>
            <TerminalCustomizeMenu>
              <button
                style={{
                  backgroundImage:
                    "url(" + "/terminalBackgrounds/clouds.webp" + ")",
                }}
                onClick={() =>
                  (document.body.style.backgroundImage = `url('${backgroundImages[0]}')`)
                }
              />
              <button
                style={{
                  backgroundImage:
                    "url(" + "/terminalBackgrounds/planet.webp" + ")",
                }}
                onClick={() =>
                  (document.body.style.backgroundImage = `url('${backgroundImages[1]}')`)
                }
              />
              <button
                style={{
                  backgroundImage:
                    "url(" + "/terminalBackgrounds/rain.webp" + ")",
                }}
                onClick={() =>
                  (document.body.style.backgroundImage = `url('${backgroundImages[2]}')`)
                }
              />
              <button
                style={{
                  backgroundImage:
                    "url(" + "/terminalBackgrounds/sky.webp" + ")",
                }}
                onClick={() =>
                  (document.body.style.backgroundImage = `url('${backgroundImages[3]}')`)
                }
              />
              <input
                className="custom-file-input"
                type="file"
                accept="image/png, image/jpeg"
                name="myImage"
                id="add-bg"
                content="Put your own background"
                onChange={(event) => {
                  document.body.style.backgroundImage = `url('${URL.createObjectURL(
                    event.target.files[0]
                  )}')`;
                }}
                hidden
              />
              <label htmlFor="add-bg" className="add-bg-label">
                <Column justify={"center"} gap={"0.3rem"}>
                  <SmallText>Add your own background (png, jpeg)</SmallText>
                  <Icon size={"1.5rem"}>
                    <IoAdd />
                  </Icon>
                </Column>
              </label>
            </TerminalCustomizeMenu>
          </ClickAwayListener>
        </TerminalCustomizeMenuWrapper>
      )}
    </StyledTerminal>
  );
}
