import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { useMediaQuery } from "react-responsive";
import Compare from "../components/compareSection/compare";
import {
  Container,
  VerticalSeperator,
} from "../components/containers/boxes.styled";
import { Column, FullRow, Row } from "../components/containers/flexbox.styled";
import HomePageMainTable from "../components/homePageMainTable/homePageMainTable";
import CookieWarning from "../components/misc/cookieConsent";
import MostCompared from "../components/misc/mostCompared/mostCompared";
import { borderRads, GRID_GAP } from "../components/sizes";
import TopGainingTable from "../components/tables/topGainingTable";
import ThreeListSection from "../components/threeListSection/threeListSection";
import Banners from "../components/misc/banners";
import { RouteLink } from "../routing/routeLink";
import {
  NavbarPrimaryButton,
  OutlineButton,
} from "../components/buttons/buttons.styled";
import { LightHeader } from "../components/texts.styled";
import { ShowOnMobileWrapper } from "../components/hideOnMobile";
import {
  StyledBigBanner,
  StyledBigBannerWrapper,
  StyledBigBannerWrapperMobile,
} from "../components/misc/banners.styled";
import RecentlyListedSquare from "../components/recentlyListedSquare";
import MultiSwitch from "../components/misc/multiSwitch";
import TrendingSwitchMobile from "../components/trendingSwitchMobile";
import PromotedCompareSection from "../components/promotedCompareSection";
import { AreaChartWrapper } from "../components/dedicatedCoin/simpleChart";
import TopMemeCoins from "../components/tables/topMemeCoins";
import styled from "styled-components";
import Switch from "../components/misc/switch";
import { theme } from "../components/theme.styled";

export default function Home() {
  const isMobile = useMediaQuery({
    query: "(max-width: 70rem)",
  });

  return (
    <Container>
      <Helmet>
        <title>CompareMarketCap - Crypto Compare Tools</title>
        <meta
          name="description"
          content={
            "Compare market cap of crypto coins, or crypto currencies, to find out their potential price and market capitalization."
          }
        />
      </Helmet>
      <CookieWarning />
      <MostCompared />
      {/* <Banners /> */}
      <PromotedCompareSection />
      <FullRow verticalonmobile={"true"} gap={GRID_GAP}>
        <Compare />
        {!isMobile && <HomePageMainTable />}
      </FullRow>
      <TrendingSwitchMobile />
      <ShowOnMobileWrapper style={{ margin: "1rem auto", maxWidth: "30rem" }}>
        <RouteLink to={"/promote"}>
          <StyledBigBannerWrapperMobile>
            <StyledBigBanner
              alt="Promote Banner"
              src={"banners/promote-list-banner.png"}
            />
          </StyledBigBannerWrapperMobile>
        </RouteLink>
      </ShowOnMobileWrapper>
      <HomePageCoins />
    </Container>
  );
}

export function HomePageCoins() {
  const [mode, setMode] = useState(0);

  return (
    <StyledHomePageCoinsWrapper gap={"0rem"} width={"100%"}>
      <ShowOnMobileWrapper style={{ width: "100%" }}>
        <Switch
          fullWidth={true}
          selectedIndexs={mode}
          options={[
            { name: "Top Gainers", onClick: () => setMode(0) },
            { name: "Top Meme Coins", onClick: () => setMode(1) },
          ]}
        />
      </ShowOnMobileWrapper>

      <StyledHomePageCoins mode={mode}>
        <TopGainingTable isExternal={true} isHome={true} />
        <VerticalSeperator
          height="35rem"
          style={{
            width: "0.1rem",
            backgroundColor: "rgba(255,255,255,0.1)",
            marginTop: "4rem",
          }}
        />
        <TopMemeCoins isExternal={true} isHome={true} />
      </StyledHomePageCoins>
    </StyledHomePageCoinsWrapper>
  );
}

const StyledHomePageCoinsWrapper = styled(Column)`
  padding: 0.5rem;
  border-radius: ${borderRads.SMALL};
  background-color: ${theme.colors.LEVEL};

  @media (min-width: 70rem) {
    margin-top: 0.5rem;
  }

  @media (max-width: 70rem) {
    background-color: ${theme.colors.LEVEL};
  }
`;

const StyledHomePageCoins = styled(FullRow)`
  gap: 2rem;
  align-items: start;
  & > * {
    width: 50%;
  }

  //depending on the mode, show the right table

  //if smaller than 70rem, make it a column

  align-items: center;

  @media (max-width: 70rem) {
    flex-direction: column;
    gap: 1rem;
    & > * {
      width: 100%;
    }

    & > *:first-child {
      display: ${({ mode }) => (mode === 0 ? "block" : "none")};
    }
    & > *:nth-child(2) {
      display: none;
    }
    & > *:last-child {
      display: ${({ mode }) => (mode === 1 ? "block" : "none")};
    }
  }
`;
