import { Container } from "../components/containers/boxes.styled";
import TopGainingTable from "../components/tables/topGainingTable";
import MostComparedTable from "../components/tables/mostComparedTable";
import { Helmet } from "react-helmet";
import TopMarketCapTable from "../components/tables/topMarketCapTable";
import React, { Suspense } from "react";
import Loader from "../components/misc/loader";

const MC = React.lazy(() => import("../components/tables/mostComparedTable"));
const TopMarketCapT = React.lazy(() =>
  import("../components/tables/topMarketCapTable")
);
const TopGaining = React.lazy(() =>
  import("../components/tables/topGainingTable")
);

export function MostCompared() {
  return (
    <Container>
      <Helmet>
        <title>Most Compared Coins - CompareMarketCap</title>
        <meta
          name="description"
          content={
            "The top 20 weekly most compared crypto coins on CompareMarketCap and where you can check their prices, market caps and volumes."
          }
        />
      </Helmet>
      <Suspense fallback={<Loader />}>
        <MC isWithSwitch={true} />
      </Suspense>
      {/*<MostComparedTable isWithSwitch={true}/>*/}
    </Container>
  );
}

export function TopGainers() {
  return (
    <Container>
      <Helmet>
        <title>Top Gaining Coins - CompareMarketCap</title>
        <meta
          name="description"
          content={
            "Daily top gaining crypto coins on CompareMarketCap where you can check their prices, market caps and volumes."
          }
        />
      </Helmet>
      <Suspense fallback={<Loader />}>
        <TopGaining isWithSwitch={true} />
      </Suspense>
    </Container>
  );
}

export function TopMarketCap() {
  return (
    <Container>
      <Helmet>
        <title>Cryptocurrency Prices by Market Cap - CompareMarketCap</title>
        <meta
          name="description"
          content={"Biggest cryptocurrencies in terms of their market caps."}
        />
      </Helmet>
      <Suspense fallback={<Loader />}>
        <TopMarketCapT isWithSwitch={true} />
      </Suspense>
      {/* <TopMarketCapTable isWithSwitch={true} /> */}
    </Container>
  );
}
