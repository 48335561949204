import { useEffect } from "react";
import { formatNumber, graphFormatter } from "../../util/numberFormatter";
import Switch from "../misc/switch";
import { AmountInputStyled } from "./amountInput.styled";

export default function AmountInput({
  coin,
  setUpperAmount,
  upperAmount,
  mode,
  setMode,
}) {
  const switchElements = [
    {
      name: coin.symbol.toUpperCase(),
      onClick: () => setMode("amount"),
      image: coin.image,
    },
    {
      name: "$",
      onClick: () => setMode("$"),
    },
  ];

  return (
    <AmountInputStyled>
      <input
        value={upperAmount ? formatNumber(upperAmount) : ""}
        onChange={(e) => {
          let value = e.target.value;
          //remove any commas
          value = value.replace(/,/g, "");

          setUpperAmount(Number(value) < 0 ? -Number(value) : value);
        }}
        placeholder={`Enter amount in ${
          mode === "amount" ? coin.symbol.toUpperCase() : "$"
        }`}
      />
      <Switch
        selectedIndexs={mode === "amount" ? 0 : 1}
        options={switchElements}
      />
    </AmountInputStyled>
  );
}
