import { useContext, useEffect, useRef, useState } from "react";
import { MdExpandMore } from "react-icons/md";
import { AllCoinsContext } from "../../context";
import { Column, FullRow, Row } from "../containers/flexbox.styled";
import { Icon } from "../icon.styled";
import Loader from "../misc/loader";
import { borderRads, textSizes } from "../sizes";
import { Paragraph, SmallText } from "../texts.styled";
import { ClickAwayListener } from "@mui/base";
import { add3Dots, graphFormatter } from "../../util/numberFormatter";
import { StyledDropdown } from "../compareSection/dropdown.styled";
import DropdownMenu from "../compareSection/dropdownMenu";

export default function RoiBattleDropdown({
  coin,
  isCommonHolders,
  setCoin,
  defaultCoin,
  otherCoin,
  isSwitching,
  setSwitching,
  defaultExternalCoin,
  noPromotion,
  items,
}) {
  const scrollRef = useRef(null);

  const [ison, setOn] = useState(false);

  const [selectedCoin, setSelectedCoin] = useState(
    defaultCoin ? defaultCoin : null
  );

  const allCoins = useContext(AllCoinsContext);

  if (selectedCoin === null && defaultExternalCoin !== undefined) {
    setSelectedCoin(
      allCoins?.find((item) => item.id === defaultExternalCoin.id)
    );
  }

  function handleCoinSet(item) {
    setSelectedCoin(item);
    setCoin(item);
    setOn(false);
  }

  useEffect(() => {
    if (isSwitching === true) {
      setSelectedCoin(otherCoin);
      setCoin(otherCoin);
      setSwitching(false);
    }
  }, [isSwitching]);

  useEffect(() => {
    handleCoinSet(defaultCoin);
  }, [defaultCoin]);

  return (
    <ClickAwayListener
      style={{ width: "50%" }}
      onClickAway={() => setOn(false)}
    >
      <Column width="100%" style={{ width: "50%", position: "relative" }}>
        <div
          ref={scrollRef}
          style={{ position: "absolute", top: "-10rem" }}
        ></div>
        <StyledDropdown
          style={{ padding: "0.4rem" }}
          ison={ison ? 1 : 0}
          onClick={() => setOn((prevState) => !prevState)}
        >
          {selectedCoin !== null ? (
            <FullRow justify="space-between">
              <Row gap="0.5rem">
                <img
                  style={{
                    width: "1.75rem",
                    height: "1.75rem",
                    borderRadius: borderRads.SMALLER,
                  }}
                  alt="logo"
                  src={selectedCoin.image}
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null;
                    currentTarget.src = "logos/empty-coin.svg";
                  }}
                />

                <Column align="start">
                  <Paragraph weight={textSizes.fontWeights.SEMI_BOLD}>
                    {selectedCoin.symbol.toUpperCase()}
                  </Paragraph>
                </Column>
              </Row>

              <Icon
                reverse={ison ? 1 : 0}
                style={{ justifySelf: "end" }}
                size="1rem"
              >
                <MdExpandMore />
              </Icon>
            </FullRow>
          ) : (
            <Loader isSmall={true} />
          )}
        </StyledDropdown>
        <DropdownMenu
          customStyle={{
            width: "200%",
            right: 0,
            // marginTop: "2.4rem", // i have no idea why this used to work and not working anymore
            borderTopLeftRadius: "0.5rem",
          }}
          noPromotion={noPromotion}
          setCoin={handleCoinSet}
          dropdownItems={items || allCoins}
          status={"success"}
          ison={ison}
        />
      </Column>
    </ClickAwayListener>
  );
}
