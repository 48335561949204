import { useContext, useEffect, useRef, useState } from "react";
import { MdExpandMore } from "react-icons/md";
import { AllCoinsContext } from "../../context";
import { Column, FullRow, Row } from "../containers/flexbox.styled";
import { Icon } from "../icon.styled";
import Loader from "../misc/loader";
import { textSizes } from "../sizes";
import { Paragraph, SmallText } from "../texts.styled";
import { StyledDreamfolioDropdown } from "../compareSection/dropdown.styled";
import DropdownMenu from "../compareSection/dropdownMenu";
import { theme } from "../theme.styled";
import { ClickAwayListener } from "@mui/base";

export default function DreamfolioDropdown({
  isFull,
  setCoin,
  defaultCoin,
  otherCoin,
  isSwitching,
  setSwitching,
  defaultExternalCoin,
  noPromotion,
  items,
  resetCoinCallback,
  coinList,
}) {
  const scrollRef = useRef(null);

  const [ison, setOn] = useState(true);

  const [selectedCoin, setSelectedCoin] = useState(
    defaultCoin ? defaultCoin : null
  );

  let allCoins = useContext(AllCoinsContext);

  if (coinList) {
    allCoins = coinList;
  }

  if (selectedCoin === null && defaultExternalCoin !== undefined) {
    setSelectedCoin(
      allCoins?.find((item) => item.id === defaultExternalCoin.id)
    );
  }

  function handleCoinSet(item) {
    setSelectedCoin(item);
    setCoin(item);
    setOn(false);
  }

  useEffect(() => {
    // setCoin(allCoins?.find((item) => item.id === "bitcoin"));
  }, []);

  // useEffect(() => {
  //   if (ison === true) {
  //     scrollRef.current?.scrollIntoView({ behavior: "smooth" });
  //   }
  // }, [ison]);

  useEffect(() => {
    if (isSwitching === true) {
      setSelectedCoin(otherCoin);
      setCoin(otherCoin);
      setSwitching(false);
    }
  }, [isSwitching]);

  return (
    <ClickAwayListener onClickAway={() => setOn(false)}>
      <Column width="100%" style={{ position: "relative" }}>
        <div
          ref={scrollRef}
          style={{ position: "absolute", top: "-10rem" }}
        ></div>
        <StyledDreamfolioDropdown
          isFull={isFull}
          ison={ison ? 1 : 0}
          onClick={() => setOn((prevState) => !prevState)}
        >
          {selectedCoin !== null ? (
            <FullRow justify="space-between">
              <Row gap="1rem">
                <img alt="logo" src={selectedCoin.image} />
                <Column align="start">
                  <Paragraph
                    size={textSizes.fontSizes.MEDIUM}
                    weight={textSizes.fontWeights.BOLD}
                  >
                    {selectedCoin.symbol.toUpperCase()}{" "}
                  </Paragraph>
                </Column>
              </Row>

              <Icon
                reverse={ison ? 1 : 0}
                style={{ justifySelf: "end" }}
                size="2rem"
              >
                <MdExpandMore />
              </Icon>
            </FullRow>
          ) : (
            <Loader isSmall={true} />
          )}
        </StyledDreamfolioDropdown>
        <DropdownMenu
          hidePromote={true}
          backgroundColor={theme.colors.OLDLEVEL}
          dontAutoFocus={true}
          noPromotion={noPromotion}
          setCoin={handleCoinSet}
          dropdownItems={items || allCoins}
          status={"success"}
          ison={ison}
          marginTop={"3.2rem"}
          isResettable={true}
          isDreamfolio={true}
          resetCoinCallback={() => {
            resetCoinCallback();
          }}
        />
      </Column>
    </ClickAwayListener>
  );
}
