import styled from "styled-components";
import { borderRads, borderWidths, textSizes } from "../sizes";
import { paddings } from "../paddings.styled";
import { Column, FullRow, Row } from "../containers/flexbox.styled";
import { BigText, SmallText } from "../texts.styled";
import { theme } from "../theme.styled";
import { useContext, useEffect, useState } from "react";
import {
  getAllCoinsWithAllData,
  getAthMarketCap,
  getAthMarketCapDreamfolio,
  getCoin,
} from "../stream";
import { useQuery } from "@tanstack/react-query";
import {
  add3Dots,
  formatNumber,
  graphFormatter,
} from "../../util/numberFormatter";
import { MdExpandLess, MdExpandMore, MdOutlineClose } from "react-icons/md";
import { TransparentButton } from "../buttons/buttons.styled";
import { Icon } from "../icon.styled";
import DreamfolioDropdown from "./dreamfoiloDropdown";
import { ClickAwayListener } from "@mui/base";
import { defaultCoins } from "../compareSection/defaultCoins";
import { FaCheck, FaSort, FaSortDown, FaSortUp } from "react-icons/fa6";
import { DreamfolioSwitch, MobileCurrentWallets } from "../../pages/dreamfolio";
import { VscQuestion } from "react-icons/vsc";
import { IoIosFlag, IoMdArrowForward } from "react-icons/io";
import { RouteLink } from "../../routing/routeLink";
import { MdOutlineVisibility, MdOutlineVisibilityOff } from "react-icons/md";
import { LuMinus, LuPlus } from "react-icons/lu";
import { UnlistedMessage, UnlistedModalOpener } from "../dreamfolioList";
import { TableRow } from "@mui/material";

export function DreamfolioTableDesktop({
  data,
  isCompare,
  setIsCompare,
  currentAmount,
  setCoinAmountsInUSD,
  dreamfolioData,
  setDreamfolioData,
  coinAmountsInUSD,
  resetComparisons,
  wallet,
  newWalletHandler,
  ethWallet,
  solWallet,
  tronWallet,
  handleClick,
  setSolWallet,
  setEthWallet,
  setTronWallet,
  dropdownData,
  dreamAmount,
  setFilter,
  filter,
}) {
  const [localWallet, setLocalWallet] = useState(wallet);

  const handleNewWallet = (wallet) => {
    newWalletHandler(wallet);
  };

  return (
    <Column width="100%" gap="3rem" style={{ marginTop: "4rem" }}>
      <FullRow justify="space-between" align="end">
        {coinAmountsInUSD.find((item) => item.edited === true) && isCompare ? (
          <FullRow gap="5rem" justify="start">
            <Column align="start" gap="0.5rem">
              <SmallText>Current Amount</SmallText>
              <BigText size={textSizes.fontSizes.XLARGE}>
                ${isNaN(currentAmount) ? "-" : graphFormatter(currentAmount)}
              </BigText>
            </Column>
            <Column align="start" gap="0.5rem">
              <SmallText>Dream Amount</SmallText>
              <BigText
                size={textSizes.fontSizes.XLARGE}
                color={({ theme }) =>
                  dreamAmount >= currentAmount
                    ? theme.colors.GREEN
                    : theme.colors.RED
                }
              >
                ${graphFormatter(dreamAmount)}
              </BigText>
            </Column>
          </FullRow>
        ) : (
          <Column width="100%" align="start" gap="0.5rem">
            <SmallText>Current Amount</SmallText>
            <BigText size={textSizes.fontSizes.XLARGE}>
              ${graphFormatter(currentAmount)}
            </BigText>
          </Column>
        )}
        <MobileCurrentWallets
          setTronWallet={setTronWallet}
          setEthWallet={setEthWallet}
          setSolWallet={setSolWallet}
          tronWallet={tronWallet}
          ethWallet={ethWallet}
          solWallet={solWallet}
          handleClick={handleClick}
        />
      </FullRow>

      <Column width="100%" gap="0.6rem" align="start">
        <FullRow justify="space-between" align="end">
          <SmallText style={{ marginBottom: "0.5rem" }}>Your Coins</SmallText>

          <Column gap="0.6rem" align="end">
            {coinAmountsInUSD.find((item) => item.edited === true) &&
              isCompare && (
                <TransparentButton
                  style={{ position: "absolute", top: "-1.4rem" }}
                  onClick={() => resetComparisons()}
                  color={theme.colors.LIGHT_TEXT}
                >
                  Reset Your Comparisons
                </TransparentButton>
              )}

            <DreamfolioSwitch
              isCompare={isCompare}
              setIsCompare={setIsCompare}
            />
          </Column>
        </FullRow>
        <StyledDreamfolioTableDesktop isCompare={isCompare}>
          <thead>
            <tr>
              <th>Coin</th>
              <th>Holding</th>
              <th>Market Cap</th>
              <th
                onClick={() =>
                  setFilter(
                    (prev) =>
                      `Amount${prev.slice(-4) === "desc" ? "asc" : "desc"}`
                  )
                }
                style={isCompare ? { color: "white" } : {}}
              >
                <Row
                  gap="0.5rem"
                  justify="end"
                  style={{
                    cursor: "pointer",
                    width: "fit-content",
                    marginLeft: "auto",
                  }}
                >
                  <span>Amount</span>
                  <Icon color={filter.includes("Amount") && theme.colors.GREEN}>
                    {filter === "Amountasc" ? <FaSortUp /> : <FaSortDown />}
                  </Icon>
                </Row>
              </th>
              {isCompare && (
                <th
                  onClick={() =>
                    setFilter(
                      (prev) =>
                        `Dream amount${
                          prev.slice(-4) === "desc" ? "asc" : "desc"
                        }`
                    )
                  }
                  style={{ color: "white" }}
                >
                  <Row
                    gap="0.5rem"
                    justify="end"
                    style={{
                      cursor: "pointer",
                      width: "fit-content",
                      marginLeft: "auto",
                    }}
                  >
                    <span>Dream Amount</span>
                    <Icon
                      color={filter.includes("Dream") && theme.colors.GREEN}
                    >
                      {filter === "Dream amountasc" ? (
                        <FaSortUp />
                      ) : (
                        <FaSortDown />
                      )}
                    </Icon>
                  </Row>
                </th>
              )}

              {isCompare && (
                <th
                  style={{
                    color: "white",
                    borderTopRightRadius: borderRads.SM,
                  }}
                >
                  Compare to
                </th>
              )}
            </tr>
          </thead>
          <tbody>
            {data.map(
              (coin, index) =>
                dreamfolioData.find(
                  (item) => item.customId === coin.customId
                ) &&
                dreamfolioData.find((item) => item.customId === coin.customId)
                  .isVisible === true && (
                  <DreamfolioTableDesktopRow
                    coinAmountsInUSD={coinAmountsInUSD}
                    setCoinAmountsInUSD={setCoinAmountsInUSD}
                    key={coin.customId}
                    coin={coin}
                    isCompare={isCompare}
                    dreamfolioData={dreamfolioData}
                    setDreamfolioData={setDreamfolioData}
                    dropdownData={dropdownData}
                  />
                )
            )}
            {
              // if any of the coins is hidden
              dreamfolioData.find((item) => item.isVisible === false) && (
                <tr
                  style={{
                    height: "1rem",
                    backgroundColor: theme.colors.PRIMARY,
                  }}
                >
                  <td style={{ opacity: 0.5 }}>Hidden Coins</td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              )
            }

            {data.map(
              (coin, index) =>
                dreamfolioData.find(
                  (item) => item.customId === coin.customId
                ) &&
                dreamfolioData.find((item) => item.customId === coin.customId)
                  .isVisible === false && (
                  <DreamfolioTableDesktopRow
                    isHidden={true}
                    coinAmountsInUSD={coinAmountsInUSD}
                    setCoinAmountsInUSD={setCoinAmountsInUSD}
                    key={index}
                    coin={coin}
                    isCompare={isCompare}
                    dreamfolioData={dreamfolioData}
                    setDreamfolioData={setDreamfolioData}
                    dropdownData={dropdownData}
                  />
                )
            )}
          </tbody>
        </StyledDreamfolioTableDesktop>
      </Column>
    </Column>
  );
}

const StyledDreamfolioTableDesktop = styled.table`
  @media (max-width: 70rem) {
    display: none;
  }

  position: relative;

  //make a beautiful table
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 1rem;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  background-color: rgba(255, 255, 255, 0.03);
  font-weight: ${textSizes.fontWeights.REGULAR};
  font-size: ${textSizes.fontSizes.SMALLER};

  .fake-table-gradient {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      to bottom,
      rgb(23, 7, 43, 0.4),
      rgb(23, 7, 43, 1)
    );
  }

  thead {
    th {
      padding: ${paddings.HIGH};
      font-weight: ${textSizes.fontWeights.BOLD};
      color: ${({ theme }) => theme.colors.LIGHT_TEXT};
      text-transform: uppercase;
      font-size: ${textSizes.fontSizes.SMALLER};
      border-bottom: 2px solid rgba(0, 0, 0, 0.5);

      &:nth-child(4),
      &:nth-child(5),
      &:nth-child(6) {
        background-color: ${({ isCompare }) =>
          isCompare && "rgba(255, 255, 255, 0.05)"};
      }
    }
  }
  tbody {
    tr {
      border-bottom: 1px solid rgba(255, 255, 255, 0.05);
      td {
        padding: ${paddings.HIGH};
      }
    }
  }
  th,
  td {
    white-space: nowrap;
    text-align: right;
    width: 15rem;
    img {
      width: 1.5rem;
      height: 1.5rem;
    }
  }
  th:first-child,
  td:first-child {
    width: 10rem;
    text-align: start;
  }

  tr:last-child {
    border-bottom: none;
  }
`;

export default function FakeDreamfolioTableDesktop() {
  const { data, status } = useQuery(
    ["dreamfolio-fake-coins"],
    () => getAllCoinsWithAllData(),
    {
      staleTime: Infinity,
    }
  );

  return (
    status === "success" && (
      <StyledFakeDreamfolioTableDesktop>
        <thead>
          <tr>
            <th>Coin</th>
            <th>Amount Holding</th>
            <th>Market Cap</th>
            <th>Amount in USD</th>
            <th>Compare to</th>
          </tr>
        </thead>
        <tbody>
          <DreamfolioTableDesktopRow
            isFake={true}
            coin={{
              image:
                "https://assets.coingecko.com/coins/images/1/large/bitcoin.png?1547033579",
              symbol: "btc",
              name: "Bitcoin",
              price: data.find((coin) => coin.id === "bitcoin")?.price,
              amountInUSD:
                1 * data.find((coin) => coin.id === "bitcoin")?.price,
              marketCap: data.find((coin) => coin.id === "bitcoin")?.marketcap,
              change24H: data.find((coin) => coin.id === "bitcoin")?.change24H,
              amount: 1,
            }}
            coinAmountsInUSD={[]}
          />
          <DreamfolioTableDesktopRow
            isFake={true}
            coin={{
              image:
                "https://assets.coingecko.com/coins/images/279/large/ethereum.png?1595348880",
              symbol: "eth",
              name: "Ethereum",
              price: data.find((coin) => coin.id === "ethereum")?.price,
              amountInUSD:
                50 * data.find((coin) => coin.id === "ethereum")?.price,
              marketCap: data.find((coin) => coin.id === "ethereum")?.marketcap,
              change24H: data.find((coin) => coin.id === "ethereum")?.change24H,
              amount: 50,
            }}
            coinAmountsInUSD={[]}
          />
          <DreamfolioTableDesktopRow
            isFake={true}
            coin={{
              symbol: "bnb",
              name: "BNB",
              price: data.find((coin) => coin.id === "binancecoin")?.price,
              amountInUSD:
                22 * data.find((coin) => coin.id === "binancecoin").price,
              marketCap: data.find((coin) => coin.id === "binancecoin")
                ?.marketcap,
              image: data.find((coin) => coin.id === "binancecoin")?.image,
              change24H: data.find((coin) => coin.id === "binancecoin")
                .change24H,
              amount: 22,
            }}
            coinAmountsInUSD={[]}
          />
          <DreamfolioTableDesktopRow
            isFake={true}
            coin={{
              symbol: "ada",
              name: "Cardano",
              price: data.find((coin) => coin.id === "cardano")?.price,
              amountInUSD:
                10 * data.find((coin) => coin.id === "cardano")?.price,
              marketCap: data.find((coin) => coin.id === "cardano")?.marketcap,
              image: data.find((coin) => coin.id === "cardano")?.image,
              change24H: data.find((coin) => coin.id === "cardano")?.change24H,
              amount: 10,
            }}
            coinAmountsInUSD={[]}
          />
          <DreamfolioTableDesktopRow
            isFake={true}
            coin={{
              symbol: "doge",
              name: "Doge",
              price: data.find((coin) => coin.id === "dogecoin")?.price,
              amountInUSD:
                2 * data.find((coin) => coin.id === "dogecoin")?.price,
              marketCap: data.find((coin) => coin.id === "dogecoin")?.marketcap,
              image: data.find((coin) => coin.id === "dogecoin")?.image,
              change24H: data.find((coin) => coin.id === "dogecoin")?.change24H,
              amount: 2,
            }}
            coinAmountsInUSD={[]}
          />
        </tbody>
        <div className="fake-table-gradient"></div>
      </StyledFakeDreamfolioTableDesktop>
    )
  );
}

const DreamfolioTableDesktopRow = ({
  coin,
  coinAmountsInUSD,
  setCoinAmountsInUSD,
  dreamfolioData,
  setDreamfolioData,
  isCompare,
  isFake,
  dropdownData,
  isHidden,
}) => {
  const [compareMenuOn, setCompareMenuOn] = useState(false);
  const [compareCoin, setCompareCoin] = useState({ id: "bitcoin" });
  const [bufferCoin, setBufferCoin] = useState(null);
  const [resultMode, setResultMode] = useState(false);
  const [futureData, setFutureData] = useState(null);

  const [isVisible, setVisible] = useState(true);

  const [isFetching, setFetching] = useState(false);

  const myCoin = coin;
  const [visibleForOnce, setVisibleForOnce] = useState(false);

  //look at the fetchCompareCoin function in dreamfolioList.js and make it work here, as an alternative of the useQuery

  async function fetchCompareCoin(fetchCoin) {
    try {
      if (fetchCoin.id.includes("custom")) {
        //set the future data according to the custom market cap
        setFutureData({
          price: (fetchCoin.marketCap / coin.marketCap) * coin.amountInUSD,
          times: fetchCoin.marketCap / coin.marketCap,
        });

        setCoinAmountsInUSD((prev) => {
          return prev.map((loopCoin) => {
            if (loopCoin.customId === coin.customId) {
              return {
                ...loopCoin,
                amount:
                  (fetchCoin.marketCap / coin.marketCap) * coin.amountInUSD,
                edited: true,
              };
            }
            return loopCoin;
          });
        });

        setResultMode(true);
        setFetching(false);
      } else if (fetchCoin.athMode) {
        setFetching(true);
        const data = await getAthMarketCapDreamfolio(
          fetchCoin.id,
          fetchCoin.athDate,
          fetchCoin
        );

        if (data === 0) {
          setCoinAmountsInUSD((prev) => {
            return prev.map((loopCoin) => {
              if (loopCoin.customId === coin.customId) {
                return {
                  ...loopCoin,
                  amount: parseFloat(coin.amountInUSD),
                  edited: false,
                };
              }
              return loopCoin;
            });
          });

          setDreamfolioData((prev) => {
            return prev.map((coin) => {
              if (coin.id === myCoin.id) {
                return { ...coin, compareCoin: null };
              }
              return coin;
            });
          });

          setFetching(false);
          return;
        }

        setFutureData({
          price: (data / coin.marketCap) * coin.amountInUSD,
          times: data / coin.marketCap,
        });
        setCoinAmountsInUSD((prev) => {
          return prev.map((loopCoin) => {
            if (loopCoin.customId === coin.customId) {
              return {
                ...loopCoin,
                amount: (data / coin.marketCap) * coin.amountInUSD,
                edited: true,
              };
            }
            return loopCoin;
          });
        });
        setResultMode(true);
        setFetching(false);
      } else {
        setFetching(true);
        const data = await getCoin(fetchCoin.id);
        if (coin.id === data.id) {
          setFutureData({
            price: parseFloat(coin.amountInUSD),
            times: 1,
          });
          setFetching(false);
          setResultMode(true);
          return;
        }
        setFutureData({
          price: (data.marketcap / coin.marketCap) * coin.amountInUSD,
          times: data.marketcap / coin.marketCap,
        });
        setCoinAmountsInUSD((prev) => {
          return prev.map((loopCoin) => {
            if (loopCoin.customId === coin.customId) {
              return {
                ...loopCoin,
                amount: (data.marketcap / coin.marketCap) * coin.amountInUSD,
                edited: true,
              };
            }
            return loopCoin;
          });
        });
        setFetching(false);
        setResultMode(true);
      }
    } catch (e) {
      setFetching(false);
    }
  }

  const handleCompare = (comparingCoin) => {
    setCompareCoin(comparingCoin);
    setDreamfolioData((prev) => {
      return prev.map((coin) => {
        if (coin.id === myCoin.id) {
          return { ...coin, compareCoin: comparingCoin };
        }
        return coin;
      });
    });
    return;
  };

  useEffect(() => {
    if (dreamfolioData) {
      const coin = dreamfolioData.find((coin) => coin.id === myCoin.id);
      if (coin.compareCoin) {
        setCompareCoin(coin.compareCoin);
        setCompareMenuOn(false);
        fetchCompareCoin(coin.compareCoin);
      } else {
        setCompareCoin(defaultCoins.bitcoin);
        setResultMode(false);
      }
    }
  }, [dreamfolioData]);

  return (
    <tr
      className="coin-row"
      style={
        compareMenuOn
          ? {
              backgroundColor: "#31263F",
              position: "relative",
            }
          : {
              position: "relative",
            }
      }
    >
      <td>
        {!isFake && (
          <TransparentButton
            className="coin-visibility-button"
            onClick={() => {
              let amIVisible = dreamfolioData.find(
                (item) => item.customId === myCoin.customId
              ).isVisible;

              if (coin.id === "null" && !amIVisible && !visibleForOnce) {
                return;
              }

              setDreamfolioData((prev) => {
                return prev.map((coin) => {
                  if (coin.customId === myCoin.customId) {
                    const visibility = coin.isVisible;

                    if (coin.id === "null") {
                      setVisibleForOnce(true);
                    }

                    setVisible(!visibility);
                    return { ...coin, isVisible: !visibility };
                  }
                  return coin;
                });
              });
            }}
            style={{ left: "0.5rem", top: "37%", position: "absolute" }}
          >
            <Icon
              size={textSizes.fontSizes.MEDIUM}
              color={theme.colors.LIGHT_TEXT}
            >
              {dreamfolioData &&
              dreamfolioData.find((item) => item.customId === coin.customId) &&
              dreamfolioData.find((item) => item.customId === coin.customId)
                .isVisible ? (
                <MdOutlineVisibilityOff />
              ) : coin.id === "null" ? (
                <UnlistedModalOpener
                  visibleForOnce={visibleForOnce}
                  coin={coin}
                  confirmCallback={() => {
                    setDreamfolioData((prev) => {
                      return prev.map((coin) => {
                        if (coin.customId === myCoin.customId) {
                          const visibility = coin.isVisible;

                          if (coin.id === "null") {
                            setVisibleForOnce(true);
                          }

                          setVisible(!visibility);
                          return { ...coin, isVisible: !visibility };
                        }
                        return coin;
                      });
                    });
                  }}
                />
              ) : (
                <MdOutlineVisibility />
              )}
            </Icon>
          </TransparentButton>
        )}

        {!compareMenuOn && resultMode && isCompare && futureData && (
          <div
            style={{
              position: "absolute",
              top: 0,
              height: "100%",
              width: "40%",
              right: 0,
              bottom: 0,
              background:
                futureData.times >= 1
                  ? `linear-gradient(90deg, rgba(130, 221, 85, 0)
           0%, rgba(130, 221, 85, 0.2) 100%)`
                  : `linear-gradient(90deg, rgba(255, 63, 63, 0)
           0%, rgba(255, 63, 63, 0.2) 100%)`,
            }}
          ></div>
        )}

        <FullRow
          gap="0.5rem"
          style={{
            marginLeft: "1rem",
          }}
        >
          <a
            target="_blank"
            style={{
              pointerEvents: coin.marketCap === -1 ? "none" : "auto",
              opacity: isFetching ? 0.5 : isHidden ? 0.5 : 1,
            }}
            href={`/coin/${coin.id}`}
          >
            <Row
              style={{
                opacity: isFetching ? 0.5 : isHidden ? 0.5 : 1,
              }}
              gap="0.5rem"
            >
              <img
                src={coin.image}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null;
                  currentTarget.src = "logos/empty-coin.svg";
                }}
              />
              <span>
                {add3Dots(coin.symbol, 10).toUpperCase()}
                {coin.chain ? (
                  <span
                    style={{
                      fontSize: textSizes.fontSizes.SMALLER,
                      color: theme.colors.LIGHT_TEXT,
                      fontWeight: textSizes.fontWeights.REGULAR,
                    }}
                  >
                    {" "}
                    ({coin.chain.toUpperCase()})
                  </span>
                ) : (
                  ""
                )}
              </span>
              <SmallText
                weight={textSizes.fontWeights.LIGHT}
                color={theme.colors.LIGHT_TEXT}
                size={textSizes.fontSizes.SMALLER}
              >
                {add3Dots(coin.name, 15)}
              </SmallText>
            </Row>
          </a>
          {coin.id === "null" && <UnlistedMessage isTable={true} />}
        </FullRow>
      </td>{" "}
      <td
        style={{
          opacity: isFetching ? 0.5 : isHidden ? 0.5 : 1,
          color: theme.colors.LIGHT_TEXT,
        }}
      >
        {isHidden ? graphFormatter(coin.amount) : formatNumber(coin.amount)}{" "}
        {add3Dots(coin.symbol, 7).toUpperCase()}
      </td>
      <td
        style={
          isCompare
            ? {
                color: theme.colors.LIGHT_TEXT,
                opacity: isFetching ? 0.5 : isHidden ? 0.5 : 1,
              }
            : { opacity: isFetching ? 0.5 : isHidden ? 0.5 : 1 }
        }
      >
        {coin.marketCap === -1 ? (
          <Icon
            size={textSizes.fontSizes.MEDIUM}
            color={theme.colors.LIGHT_TEXT}
            style={{ position: "relative" }}
          >
            <VscQuestion />
            <div className="mcap-tooltip">
              <SmallText size={textSizes.fontSizes.SMALLER}>
                This coin's market cap is not available <br /> as it is not on
                CompareMarketCap.
              </SmallText>
            </div>
          </Icon>
        ) : (
          "$" + graphFormatter(coin.marketCap)
        )}
      </td>
      <td style={{ opacity: isFetching ? 0.5 : isHidden ? 0.5 : 1 }}>
        {coin.change24H !== -1 && (
          <span
            style={
              coin.change24H > 0
                ? {
                    color: theme.colors.GREEN,
                    fontSize: textSizes.fontSizes.SMALLER,
                    fontWeight: textSizes.fontWeights.LIGHT,
                  }
                : {
                    color: theme.colors.RED,
                    fontSize: textSizes.fontSizes.SMALLER,
                    fontWeight: textSizes.fontWeights.LIGHT,
                  }
            }
          >
            %{formatNumber(coin.change24H)}
          </span>
        )}{" "}
        ${formatNumber(coin.amountInUSD)}{" "}
      </td>
      {isCompare &&
        (resultMode ? (
          <td>
            <span
              style={
                futureData.times > 1
                  ? {
                      color: theme.colors.GREEN,
                      fontSize: textSizes.fontSizes.SMALLER,
                      fontWeight: textSizes.fontWeights.LIGHT,
                    }
                  : {
                      color: theme.colors.RED,
                      fontSize: textSizes.fontSizes.SMALLER,
                      fontWeight: textSizes.fontWeights.LIGHT,
                    }
              }
            >
              {graphFormatter(futureData.times)}x
            </span>{" "}
            ${formatNumber(futureData.price)}
          </td>
        ) : (
          <td>-</td>
        ))}
      {(isCompare || isFake) && (
        <td
          style={{
            position: "relative",
            opacity: isFetching ? 0.5 : isHidden ? 0.5 : 1,
          }}
        >
          <Row justify="end" gap="0.5rem">
            {resultMode ? (
              <Row gap="0.5rem">
                <img src={compareCoin.image} />
                <SmallText weight={textSizes.fontWeights.BOLD}>
                  {compareCoin.symbol.toUpperCase()}
                </SmallText>
                {compareCoin.athMode === true && (
                  <SmallText
                    style={{
                      pointerEvents: "none",
                      position: "absolute",
                      width: "2rem",
                      right: "0",
                      top: "1.2rem",
                      fontWeight: textSizes.fontWeights.BOLD,
                      fontSize: textSizes.fontSizes.MOBILE_LITTLE_NUMBER,
                      borderRadius: "0.5rem",
                      color: theme.colors.LIGHT_TEXT,
                    }}
                  >
                    ATH
                  </SmallText>
                )}
              </Row>
            ) : (
              coin.marketCap !== -1 &&
              (coin.id === "usd-coin" || coin.id === "tether" ? (
                <SmallText
                  style={{ marginRight: "-0.3rem" }}
                  size={textSizes.fontSizes.SMALLER}
                  color="rgba(255, 255, 255, 0.25)"
                >
                  Stable
                </SmallText>
              ) : (
                <SmallText
                  style={{ marginRight: "-0.3rem", cursor: "pointer" }}
                  size={textSizes.fontSizes.SMALLER}
                  color="rgba(255, 255, 255, 0.5)"
                  onClick={() => {
                    setCompareMenuOn(true);
                  }}
                >
                  Compare
                </SmallText>
              ))
            )}

            <Row gap={"0.4rem"}>
              {coin.id !== "usd-coin" &&
                coin.id !== "tether" &&
                coin.marketCap !== -1 && (
                  <TransparentButton
                    onClick={() => {
                      setCompareMenuOn(true);
                    }}
                  >
                    <Icon
                      color="rgba(255, 255, 255, 0.5)"
                      size={textSizes.fontSizes.HEADER}
                    >
                      {compareMenuOn ? <MdExpandLess /> : <MdExpandMore />}
                    </Icon>
                  </TransparentButton>
                )}
              {
                //if the coin is being compared, show a cancel button
                resultMode && (
                  <TransparentButton
                    onClick={() => {
                      //cancel the comparison
                      setDreamfolioData((prev) => {
                        return prev.map((coin) => {
                          if (coin.id === myCoin.id) {
                            return {
                              id: coin.id,
                              compareCoin: null,
                              isVisible: coin.isVisible,
                              customId: coin.customId,
                            };
                          }
                          return coin;
                        });
                      });

                      setCoinAmountsInUSD((prev) => {
                        return prev.map((loopCoin) => {
                          if (loopCoin.customId === myCoin.customId) {
                            return {
                              id: loopCoin.id,
                              customId: loopCoin.customId,
                              amount: parseFloat(myCoin.amountInUSD),
                              edited: false,
                            };
                          }
                          return loopCoin;
                        });
                      });
                      setResultMode(false);
                    }}
                  >
                    <Icon color="rgba(255, 255, 255, 0.5)" size={"1rem"}>
                      <MdOutlineClose />
                    </Icon>
                  </TransparentButton>
                )
              }
            </Row>
          </Row>

          {compareMenuOn && (
            <ClickAwayListener onClickAway={() => setCompareMenuOn(false)}>
              <div
                style={{
                  position: "absolute",
                  top: "110%",
                  right: "0.5rem",
                  width: "22rem",
                  borderRadius: borderRads.MEDIUM,
                  boxShadow: "0 0 20px rgba(0, 0, 0, 0.1)",
                  padding: "1rem",
                  zIndex: 100,
                  background: theme.colors.OLDLEVEL,
                }}
              >
                <Column align="start" gap="0.5rem" width="100%">
                  <SmallText>Compare {coin.symbol.toUpperCase()} to</SmallText>
                  <FullRow gap="1rem">
                    <DreamfolioDropdown
                      isFull={true}
                      style={{ width: "100%" }}
                      defaultCoin={defaultCoins.bitcoin}
                      setCoin={handleCompare}
                      coinList={dropdownData}
                    />
                    {/* <TransparentButton
                      onClick={() => handleCompare()}
                      style={{ width: "2rem" }}
                    >
                      <Icon size="1rem">
                        <FaCheck />
                      </Icon>
                    </TransparentButton> */}
                  </FullRow>
                </Column>
              </div>
            </ClickAwayListener>
          )}
        </td>
      )}
    </tr>
  );
};

const StyledFakeDreamfolioTableDesktop = styled.table`
  @media (max-width: 70rem) {
    display: none;
  }

  position: relative;

  //make a beautiful table
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 1rem;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  background-color: rgba(255, 255, 255, 0.03);
  font-weight: ${textSizes.fontWeights.REGULAR};
  font-size: ${textSizes.fontSizes.SMALL};

  .fake-table-gradient {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      to bottom,
      rgb(23, 7, 43, 0.4),
      rgb(23, 7, 43, 1)
    );
  }

  thead {
    th {
      padding: ${paddings.HIGH};
      font-weight: ${textSizes.fontWeights.BOLD};
      color: ${({ theme }) => theme.colors.LIGHT_TEXT};
      text-transform: uppercase;
      font-size: ${textSizes.fontSizes.SMALLER};
    }
  }
  tbody {
    tr {
      border-bottom: 1px solid rgba(255, 255, 255, 0.05);
      td {
        padding: ${paddings.HIGH};
      }
    }
  }
  th,
  td {
    white-space: nowrap;
    text-align: right;
    width: 15rem;
    img {
      width: 1.5rem;
      height: 1.5rem;
    }
  }
  th:first-child,
  td:first-child {
    width: 10rem;
    text-align: start;
  }
  tr:last-child {
    border-bottom: none;
  }
`;
