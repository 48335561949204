import styled from "styled-components";
import {zIndexes} from "../globalStyle.styled";
export const StyledThreeListShadow = styled.div`
  display: none;
  @media(max-width: 70rem) {
    display: block;
    position: absolute;
    width: 1rem;
    height: 100%;

    top: 0;
    bottom: 0;
    background: rgb(0,0,0);
    background: linear-gradient(270deg, rgba(23, 7, 43, 1) 0%, rgba(23, 7, 43, 0) 100%);
    right: 0;
    pointer-events: none;
    z-index: ${zIndexes.COMPARED_MOSTLY_WITH};
  };
`