import styled from "styled-components";
import { Header, Paragraph } from "../texts.styled";
import { borderRads, borderWidths, textSizes } from "../sizes";
import { zIndexes } from "../globalStyle.styled";
import { RouteLink } from "../../routing/routeLink";

export const StyledComparedMostlyWith = styled.div`
  height: fit-content;
  display: flex;
  align-items: center;
`;

export const StyledComparedMostlyWithCoin = styled.a`
  &:first-child {
    margin-right: -0.7rem;
  }

  &:nth-child(2) {
    img {
      width: 4.5rem;
    }
    z-index: ${zIndexes.COMPARED_MOSTLY_WITH};
  }

  &:last-child {
    margin-left: -0.7rem;
  }

  @media (max-width: 40rem) {
    &:first-child {
      margin-right: 1rem;
    }
    &:last-child {
      margin-left: 1rem;
    }
  }

  flex: 1 0 auto;
  height: fit-content;
  display: flex;
  align-items: center;
  flex-direction: column;
  ${Paragraph} {
    margin: 0 auto;
    font-size: ${textSizes.fontSizes.MEDIUM};
  }
  gap: 0.4rem;

  position: relative;

  :hover {
    cursor: pointer;
  }

  overflow: hidden;

  :hover {
    a {
      opacity: 1;
    }
  }
`;

export const CMWimage = styled.div`
  img {
    height: 3rem;
    width: 3rem;
    //border-radius: 123123123123rem;
    //background-color: black;
    //border: ${borderWidths.MEDIUM} solid rgba(200, 200, 200, 1);

    //add drop shadow to bottom

    filter: drop-shadow(0px 8px 10px #000000);
  }

  position: relative;
  overflow: hidden;
  align-items: center;
  display: flex;
  justify-content: center;

  a {
    align-items: center;
    display: flex;
    justify-content: center;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0;
    transition: 1s;
    width: 100%;
    height: 100%;
    top: 0;
    border-radius: 1231231231231rem;
    overflow: hidden;
    ${Header} {
      font-size: 1rem;
    }
  }
`;
