//empty react component

import styled from "styled-components";
import { Column, FullRow, Row } from "./containers/flexbox.styled";
import { SmallText } from "./texts.styled";
import { RouteLink } from "../routing/routeLink";
import { Icon } from "./icon.styled";
import { IoIosArrowForward } from "react-icons/io";
import { borderRads, textSizes } from "./sizes";
import { HorizontalSeperator } from "./containers/boxes.styled";
import { theme } from "./theme.styled";
import { paddings } from "./paddings.styled";
import { Route } from "react-router-dom";

export const mostWantedData = [
  {
    id: "popcat",
    coinName: "Popcat",
    image:
      "https://coin-images.coingecko.com/coins/images/33760/small/image.jpg?1702964227",
    symbol: "POPCAT",
  },
  {
    id: "dogwifcoin",
    coinName: "dogwifhat",
    image:
      "https://coin-images.coingecko.com/coins/images/33566/small/dogwifhat.jpg?1702499428",
    symbol: "WIF",
  },
  {
    id: "gigachad-2",
    coinName: "Gigachad",
    image:
      "https://coin-images.coingecko.com/coins/images/34755/small/IMG_0015.png?1705957165",
    symbol: "GIGA",
  },
];

export default function MostWantedMobile() {
  return (
    <Column gap="0.5rem" align="start" width="100%">
      <SmallText>Everybody wants to be them.</SmallText>
      <Column
        gap="0.7rem"
        style={{
          padding: paddings.HIGH,
          borderRadius: borderRads.SMALL,
          backgroundColor: theme.colors.SECONDARY,
        }}
        width="100%"
      >
        {mostWantedData.map((item, index) => (
          <>
            <RouteLink
              style={{ width: "100%" }}
              to={`/coin/${item.id}`}
              key={index}
            >
              <Row
                style={{ position: "relative", paddingLeft: "2rem" }}
                key={index}
              >
                <SmallText
                  weight={textSizes.fontWeights.SEMI_BOLD}
                  style={{ position: "absolute", left: "0.1rem" }}
                >
                  {index + 1}
                </SmallText>

                <img style={{ width: "1.5rem" }} src={item.image} />

                <SmallText
                  style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    textAlign: "end",
                  }}
                  weight={textSizes.fontWeights.SEMI_BOLD}
                >
                  {item.symbol.toUpperCase()}
                </SmallText>

                <SmallText color={theme.colors.LIGHT_TEXT}>
                  {item.coinName}
                </SmallText>

                <Icon style={{ marginLeft: "auto" }}>
                  <IoIosArrowForward />
                </Icon>
              </Row>
            </RouteLink>
            {index !== mostWantedData.length - 1 && (
              <HorizontalSeperator width="100%" style={{ opacity: "0.2" }} />
            )}
          </>
        ))}
      </Column>
    </Column>
  );
}

const StyledMostWantedMobile = styled.div``;
