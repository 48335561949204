import styled, { keyframes } from "styled-components";
import { LEFT_MARGIN, RIGHT_MARGIN } from "../sizes";
import { paddings } from "../paddings.styled";

export const PageContentStyled = styled.div`
  margin: ${paddings.MID};
  margin-left: ${LEFT_MARGIN};
  margin-right: ${RIGHT_MARGIN};

  @media (max-width: 70rem) {
    margin: ${({ noMargin }) => (noMargin ? "0" : paddings.MID)};
  }
`;

export const PageStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  height: fit-content;

  @media (max-width: 70rem) {
    padding-bottom: 3rem;
    min-height: calc(100vh);
  }

  @media (min-width: 70rem) {
    min-height: calc(100vh);
  }

  max-width: 92rem;
  margin: 0 auto;

  //if below 70rem, remove the margin
`;
