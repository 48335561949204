import styled from "styled-components";
import { StyledNavbar } from "../navbar/navbar.styled";
import { StyledFooter } from "../footer.styled";
import { StyledSearchBar } from "../search/searchBar.styled";

export const StyledHalvingPage = styled.div`
  top: 0;
  right: 0;
  left: 0;
  height: fit-content;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh !important;

  ${StyledNavbar} {
    position: absolute;
    z-index: 2;
    background-color: rgba(255, 255, 255, 0);
    ${StyledSearchBar} {
      background-color: rgba(255, 255, 255, 0.1) !important;
      input {
        background-color: rgba(255, 255, 255, 0.1) !important;
        color: white;
        &::placeholder {
          color: white !important;
        }
      }
    }
  }

  ${StyledFooter} {
    background-color: rgba(255, 255, 255, 0.1);
  }
`;
