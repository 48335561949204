import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Column, FullRow, Row } from "../containers/flexbox.styled";
import { theme } from "../theme.styled";
import { borderRads, borderWidths, textSizes } from "../sizes";
import { StyledDropdown } from "../compareSection/dropdown.styled";
import { Header, LightHeader, Paragraph, SmallText } from "../texts.styled";
import { defaultCoins } from "../compareSection/defaultCoins";
import RoiBattleDropdown from "./roiBattleDropdown";
import { PrimaryButton } from "../buttons/buttons.styled";
import { getCoinPage } from "../stream";
import { formatNumber, getSmallNumber } from "../../util/numberFormatter";
import { RouteLink } from "../../routing/routeLink";
import { MdOutlineCompareArrows } from "react-icons/md";
import Loader from "../misc/loader";

export default function MiniCompare({ coin, margin }) {
  const [otherCoin, setOtherCoin] = useState(defaultCoins.bitcoin);

  const [futureData, setFutureData] = useState(null);

  const [isLoading, setLoading] = useState(false);

  //on every other coin set, make futuredata null

  useEffect(() => {
    setFutureData(null);
  }, [otherCoin]);

  useEffect(() => {
    return () => {
      setFutureData(null);
      setOtherCoin(defaultCoins.bitcoin);
    };
  }, []);

  const handleClick = async () => {
    if (!otherCoin) return;

    try {
      setLoading(true);
      const data = await getCoinPage(otherCoin.id);

      const times = data.marketcap / coin.marketcap;
      const futurePrice = coin.price * times;

      setFutureData({
        times,
        futurePrice,
      });

      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  return (
    <StyledMiniCompare
      futureData={futureData}
      style={margin ? { margin: "0 0.5rem" } : {}}
    >
      <FullRow width="fit-content" gap="0.5rem" justify="center">
        <MdOutlineCompareArrows />
        <LightHeader>COMPARE</LightHeader>
      </FullRow>{" "}
      <FullRow gap="1rem" justify="center">
        <Row
          style={{
            padding: "0.4rem",
            borderRadius: borderRads.SMALL,
            width: "50%",
            backgroundColor: theme.colors.LEVEL,
          }}
          width="50%"
          gap="0.5rem"
          justify="end"
        >
          <Paragraph weight={textSizes.fontWeights.SEMI_BOLD}>
            {coin.symbol.toUpperCase()}
          </Paragraph>
          <img
            style={{ width: "1.75rem", borderRadius: borderRads.SMALLER }}
            alt="logo"
            src={coin.image}
          />
        </Row>
        <SmallText>vs</SmallText>
        <RoiBattleDropdown
          setCoin={setOtherCoin}
          defaultCoin={defaultCoins.bitcoin}
        />
      </FullRow>
      {futureData ? (
        <Column className="mini-compare-result" gap="0.5rem" align="center">
          <SmallText>
            If {coin.symbol.toUpperCase()} reaches the market cap of{" "}
            {otherCoin.symbol.toUpperCase()}, <br /> its price would be
          </SmallText>
          <Row gap="0.5rem" align="center" justify="center">
            <Header weight={textSizes.fontWeights.SEMI_BOLD}>
              $
              {futureData.futurePrice > 0.001 ? (
                formatNumber(futureData.futurePrice)
              ) : (
                <>
                  <span>0.0</span>
                  <SmallText>
                    {getSmallNumber(futureData.futurePrice).zeroCount}
                  </SmallText>
                  <span>
                    {getSmallNumber(futureData.futurePrice).nonZeroDigits}
                  </span>
                </>
              )}
            </Header>
            <Header
              color={
                futureData.times >= 1 ? theme.colors.GREEN : theme.colors.RED
              }
              weight={textSizes.fontWeights.LIGHT}
            >
              ({formatNumber(futureData.times)}x)
            </Header>
          </Row>
          <button
            style={{
              backgroundColor: "transparent",
              padding: "0",
            }}
          >
            <RouteLink to={`/compare/${coin.id}/${otherCoin.id}`}>
              <SmallText
                color={theme.colors.LIGHT_TEXT}
                style={{ margin: "0 auto" }}
              >
                See in detail
              </SmallText>
            </RouteLink>
          </button>
        </Column>
      ) : null}
      {(!futureData || isLoading) && (
        <button onClick={() => handleClick()}>
          {isLoading ? (
            <Loader isRealSmall={true} />
          ) : (
            <SmallText>Compare</SmallText>
          )}
        </button>
      )}
    </StyledMiniCompare>
  );
}

export const StyledMiniCompare = styled(Column)`
  /* style={{
          backgroundColor: theme.colors.SECONDARY,
          borderRadius: borderRads.SMALL,
          padding: "0.5rem",
          transition: "0.4s",
          height: !resultData ? "9rem" : "17rem",
        }}
        align="center"
        width="100%"
        gap="1rem" */

  .mini-compare-result {
    @keyframes fadeContainerIn {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }

    animation: fadeContainerIn 1s;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    -ms-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
  }

  background-color: ${theme.colors.SECONDARY};
  border-radius: ${borderRads.SMALL};
  padding: 0.5rem;
  transition: 0.4s;
  height: ${({ futureData }) => (futureData ? "12rem" : "8.6rem")};
  align-items: center;
  width: 100%;
  gap: 1rem;

  button {
    background-color: ${theme.colors.LEVEL2};
    padding: 0.5rem 1rem;
    border-radius: ${borderRads.SMALL};
    border: none;
    width: 7rem;
  }

  //for min width 70rem, have 0.5erm padding and background color level2

  @media (min-width: 70rem) {
    padding: 0.5rem;
    background-color: ${theme.colors.LEVEL};

    ${StyledDropdown} {
      background-color: ${theme.colors.LEVEL2};
    }
  }
`;
