import { Container } from "../components/containers/boxes.styled";
import { Helmet } from "react-helmet";
import PageHeader from "../components/misc/pageHeader";
import { Column, FullRow } from "../components/containers/flexbox.styled";
import MultiSwitch from "../components/misc/multiSwitch";
import { StyledThreeListShadow } from "../components/threeListSection/threeListShadow.styled";
import { BlogBoxWrapper } from "../components/blog/blog.styled";
import {
  HalvingBlock,
  HalvingBlockWrapper,
  HalvingLogoWrapper,
  HalvingMobileSwitchWrapper,
  HalvingTime,
} from "../components/halving/halving.styled";
import FlipClockCountdown from "@leenguyen/react-flip-clock-countdown";
import "@leenguyen/react-flip-clock-countdown/dist/index.css";
import { textSizes } from "../components/sizes";
import {
  BigText,
  Header,
  LightHeader,
  Paragraph,
  SmallText,
  Title,
} from "../components/texts.styled";
import "../countdown.css";
import HalvingGraph from "../components/halving/halvingGraph";
import { useState } from "react";
import HalvingTop from "../components/halving/halvingTop";
import {
  QueryClient,
  QueryClientProvider,
  useQuery,
} from "@tanstack/react-query";
import { Theme } from "../components/theme.styled";
import { GlobalStyle } from "../components/globalStyle.styled";
import { StyledErrorPage } from "../components/errorPage.styled";
import Navbar from "../components/navbar/navbar";
import Footer from "../components/footer";
import { StyledNavbar } from "../components/navbar/navbar.styled";
import { StyledHalvingPage } from "../components/halving/styledHalvingPage";
import HalvingBox from "../components/halving/halvingBox";
import HalvingInfo from "../components/halving/halvingInfo";
import { getHalvingDate } from "../components/stream";
import { HalvingDateContext } from "../context";
import Loader from "../components/misc/loader";
import InteractiveFaq from "../components/misc/faq";
import { useParams } from "react-router-dom";

export default function Halving() {
  const queryClient = new QueryClient();

  let { coin } = useParams();

  const { data: halvingDate, status: halvingDateStatus } = useQuery(
    ["halving-date"],
    () => getHalvingDate(),
    {
      refetchOnWindowFocus: false,
      onSuccess: () => {},
    }
  );

  return (
    <QueryClientProvider client={queryClient}>
      <Theme>
        <GlobalStyle />
        <Helmet>
          <title>Halving Report - CompareMarketCap</title>
          <meta
            name="description"
            content={
              "CompareMarketCap is a leading crypto coin comparison tool. This is CompareMarketCap’s official blog where we post articles that might be useful to our users."
            }
          />
        </Helmet>
        <StyledHalvingPage>
          {halvingDateStatus === "success" ? (
            <HalvingDateContext.Provider value={halvingDate}>
              <Container>
                <Navbar />
                <HalvingTop />
                <HalvingBox externalCoin={coin} />
                <HalvingInfo />
                <InteractiveFaq />
              </Container>
            </HalvingDateContext.Provider>
          ) : (
            <Loader height={"30rem"} />
          )}
        </StyledHalvingPage>
      </Theme>
    </QueryClientProvider>
  );
}

{
  /* <Column gap={"0.5rem"}>
          <FlipClockCountdown
            className="flip-clock"
            to={new Date("2024-04-15")}
            labelStyle={{
              fontFamily: "montserrat",
              textTransform: "uppercase",
              fontSize: textSizes.fontSizes.SMALL,
            }}
            separatorStyle={{ size: "0.4rem" }}
            dividerStyle={{ color: "#31263F", height: 1 }}
          />
          <SmallText>TO HALVING</SmallText>
        </Column> */
}
