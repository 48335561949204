import { PageDescription, PageTitle } from "../texts.styled";
import { Column } from "../containers/flexbox.styled";

export default function PageHeader({ title, description }) {
  return (
    <Column width="100%" gap="0.6rem" align="start">
      <PageTitle>{title}</PageTitle>
      <PageDescription>{description}</PageDescription>
    </Column>
  );
}
