import styled from "styled-components";
import { FullRow, Row } from "../containers/flexbox.styled";
import { Icon } from "../icon.styled";
import { paddings } from "../paddings.styled";
import { TransparentButton } from "../buttons/buttons.styled";

export const StyledSocialMediaSection = styled(FullRow)`
  justify-content: ${({ isMobile }) => (isMobile ? "start" : "end")};
  color: ${({ theme }) => theme.colors.LIGHT_TEXT};
  padding: ${({ isMobile }) =>
    isMobile ? "0" : `${paddings.MID} ${paddings.MID} 0 0`};
  gap: 2rem;

  ${Icon} {
    font-size: 1.5rem;
  }

  svg {
    height: 1.5rem;
    fill: ${({ theme }) => theme.colors.LIGHT_TEXT};
  }

  img {
    height: 1.5rem;
    width: 1.5rem;
  }

  a {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  ${TransparentButton} {
    &:hover {
      svg {
        fill: white;
      }
    }
  }

  @media (min-width: 70rem) {
    margin-bottom: ${({ isMobile }) => (isMobile ? "0" : "1rem")};
  }

  @media (max-width: 40rem) {
    gap: 0.5rem;
    ${Icon} {
      font-size: 1.1rem;
    }

    svg {
      height: 1.2rem;
      fill: ${({ theme }) => theme.colors.LIGHT_TEXT};
    }

    img {
      height: 1.2rem;
      width: 1.2rem;
    }
  }
`;
