import styled from "styled-components";
import { borderRads, borderWidths, textSizes } from "../sizes";
import { paddings } from "../paddings.styled";
import { Header, Paragraph, SmallText } from "../texts.styled";
import { Column, Row } from "../containers/flexbox.styled";
import MultiSwitch from "../misc/multiSwitch";
import { StyledMultiSwitch } from "../misc/multiSwitch.styled";
import { Icon } from "../icon.styled";

export const HalvingLogoWrapper = styled(Column)`
  align-items: center;
  max-width: 50rem;
  width: fit-content;
  gap: 2.5rem;
  img {
    height: 1.5rem;
    @media (max-width: 40rem) {
      height: auto;
      width: 70%;
    }
  }
  @media (max-width: 70rem) {
    gap: 2rem !important;
  }
  @media (max-width: 70rem) {
    text-align: start;
  }
  text-align: center;
`;

export const HalvingTime = styled.div`
  border-radius: 2rem;
  border: 0.1rem solid white;
  margin: 0 auto;
  width: fit-content;
  height: fit-content;
  padding: 1rem 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  ${Paragraph} {
    font-weight: ${textSizes.fontWeights.LIGHT};
    font-size: 7rem;
  }
`;

export const HalvingBlockWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  max-width: 70rem;
  gap: 1rem;
  justify-content: space-between;
  margin-top: 2rem;
  @media (max-width: 40rem) {
    margin-top: 1rem;
    display: none;
  }
`;

export const HalvingBlock = styled.div`
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  width: 20rem;
  background-color: ${({ theme }) => theme.colors.SECONDARY};
  margin: 0 auto;
  height: 12rem;
  border-radius: ${borderRads.MEDIUM};
  padding: ${paddings.HIGH};
  @media (max-width: 40rem) {
    height: fit-content;
    width: 100%;
  }
  border: ${borderWidths.SMALL} solid rgba(0, 133, 255, 0.5);

  & > *:last-child {
    color: ${({ theme }) => theme.colors.GREEN};
  }
`;

export const HalvingMobileSwitchWrapper = styled(Column)`
  width: 30rem;
  gap: 0.5rem;
  display: none;
  @media (max-width: 40rem) {
    width: 100%;
    display: flex;
  }
`;

export const HalvingNavbar = styled.div`
  height: 2rem;
  width: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.05);
  border: ${borderWidths.SMALL} solid #0084ff;
  border-radius: 0.4rem;

  @media (max-width: 70rem) {
    height: 2rem;
    width: 2rem;
  }
`;

export const HalvingNavbarWrapper = styled(Row)`
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: ${borderRads.SMALL};
  /* border: ${borderWidths.SMALL} solid ${({ theme }) =>
    theme.colors.HALVING}; */
  width: 100%;
  gap: 0.5rem;
  transition: 0.5s;

  img {
    width: 1.5rem;
  }

  padding: ${paddings.LOW};

  @media (max-width: 40rem) {
    gap: 0rem;
  }

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
    ${Icon} {
      transform: translate(0.2rem, 0);
    }
  }

  ${Icon} {
    position: relative;
    margin-left: 0.4rem;
    margin-right: 0.4rem;
    transition: 0.2s;
  }
`;

export const StyledHalvingTopWrapper = styled(Column)`
  background-image: url("/images/halving-bga.png");
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  width: 100%;
  height: 25rem;
  position: relative;
  justify-content: center;
  @media (max-width: 50rem) {
    height: fit-content;
    padding-top: 5rem;
    padding-bottom: 3rem;
  }
`;

export const StyledHalvingTop = styled(Row)`
  width: 80%;
  max-width: 85rem;
  position: relative;
  align-items: start;
  justify-content: center;

  @media (max-width: 50rem) {
    flex-direction: column;
    align-items: center;
    width: 90%;
  }
`;

export const StyledHalvingTopBg = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0);
  -webkit-backdrop-filter: blur(100px);
  backdrop-filter: blur(100px);
`;

export const StyledCountdownWrapper = styled(Column)`
  align-items: end;
  gap: 1.3rem;
  @media (max-width: 70rem) {
    align-items: start;
    gap: 1rem;
    ${Header} {
      font-size: ${textSizes.fontSizes.MEDIUM};
    }
  }
`;

export const HalvingGraphNumber = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: ${borderWidths.SMALL} solid #ff3f3f;
  border-radius: 129308123098rem;
  width: 1.4rem;
  height: 1.4rem;
  position: absolute;
  right: -1.7rem;
  //top: -1rem;
  color: #ff3f3f;
  font-weight: 600;

  @media (max-width: 40rem) {
    right: -2rem;
    top: 0;
    font-size: ${textSizes.fontSizes.SMALL};
    width: 1.2rem;
    height: 1.2rem;
  }
`;
