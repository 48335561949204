import styled from "styled-components";
import { Column, Row } from "../containers/flexbox.styled";
import { SmallText } from "../texts.styled";
import DedicatedCoinShareButton from "./dedicatedCoinShareButton";
import { borderRads, borderWidths, textSizes } from "../sizes";
import { paddings } from "../paddings.styled";
import { StyledCopyButton } from "../misc/copyButton.styled";
import { Icon } from "../icon.styled";
import { BasicStats } from "./basicStat";
import { theme } from "../theme.styled";

export const StyledCoinInfo = styled(Column)`
  //if type === 2, add 0.5rem padding

  padding: ${({ type }) => (type === 2 || type === 3 ? "0.75rem" : "0")};

  background-color: ${({ type }) =>
    type === 2 || type === 3 ? theme.colors.SECONDARY : "transparent"};

  align-items: start;
  justify-content: start;
  gap: 0.5rem;

  @media (min-width: 70rem) {
    min-width: 24rem;
  }

  @media (max-width: 70rem) {
    width: 100%;
  }
  @media (max-width: 40rem) {
    width: 100%;
  }

  & > *:first-child {
    gap: 0.5rem;
    img {
      width: 1.7rem;
    }
    @media (min-width: 40rem) {
      ${SmallText} {
        font-size: 1rem;
      }
      gap: 1rem;
    }
    @media (min-width: 70rem) {
      ${SmallText} {
        font-size: 1rem;
      }
      gap: 1rem;
      & > *:last-child {
        display: none;
      }
    }
  }

  .deck {
    gap: 0.25rem;
    @media (min-width: 70rem) {
      gap: 1rem;
    }
  }

  ${BasicStats} {
    @media (max-width: 70rem) {
      display: none !important;
    }
  }
`;

export const CoinInfoSection = styled(Row)`
  background-color: ${({ theme }) => theme.colors.SECONDARY};
  gap: 0.5rem;
  border-radius: ${({ isRight }) =>
    isRight
      ? `0  ${borderRads.SMALL} ${borderRads.SMALL} 0   `
      : borderRads.SMALL};
  padding: ${paddings.LOW};
  width: ${({ isRight }) => (isRight ? "100%" : "fit-content")};
  height: 2rem;

  & > *:first-child &::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  & > *:first-child {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    overflow-x: scroll;
  }

  color: ${({ isLight, theme }) => (isLight ? theme.colors.LIGHT_TEXT : "")};

  svg {
    fill: ${({ theme, noFill }) => !noFill && theme.colors.LIGHT_TEXT};
  }

  @media (min-width: 70rem) {
    background-color: ${({ theme }) => theme.colors.LEVEL};
  }

  background-color: ${({ isRight }) => isRight && "transparent"};
  border: ${({ isRight, theme }) =>
    isRight && `${borderWidths.SMALL} solid ${theme.colors.SECONDARY}`};

  box-sizing: border-box;
`;

export const MostComparedBadge = styled(CoinInfoSection)`
  gap: 0.3rem;
  border: ${borderWidths.SMALL} solid #ffb800;
  ${SmallText} {
    font-weight: ${textSizes.fontWeights.LIGHT};
  }
`;

export const CoinInfoMarketAlt = styled(Column)`
  gap: 0.3rem;
  width: 100%;

  & > *:first-child {
    & > *:first-child {
      font-size: 1rem;
      font-weight: ${textSizes.fontWeights.SEMI_BOLD};
    }
  }

  //every child 100% width
  & > * {
    gap: 0.3rem;
    & > * {
      width: 100%;
      background-color: ${({ theme }) => theme.colors.SECONDARY};
      padding: 0.3rem;
      border-radius: ${borderRads.SMALL};
      gap: 0.2rem;
      & > *:first-child {
        color: ${({ theme }) => theme.colors.LIGHT_TEXT};
      }
      & > *:nth-child(2) {
        font-size: 1rem;
      }
    }
  }
`;

export const CoinInfoMarket = styled(Row)`
  width: 100%;
  gap: 0.3rem;
  @media (min-width: 70rem) {
    display: none;
  }
  & > * {
    background-color: ${({ theme }) => theme.colors.SECONDARY};
    border-radius: ${borderRads.SMALL};
    width: 33%;
    height: 3.25rem;
    gap: 0.25rem;
    justify-content: center;
    & > *:first-child {
      color: ${({ theme }) => theme.colors.LIGHT_TEXT};
      @media (min-width: 70rem) {
        font-size: ${textSizes.fontSizes.MEDIUM};
      }
    }
    @media (min-width: 70rem) {
      align-items: start;
      gap: 0.5rem;
    }
  }

  & > *:nth-child(2) {
    /* gap: 0.1rem; */
    width: 50%;
    border: ${borderWidths.SMALL} solid ${({ theme }) => theme.colors.LEVEL};
    & > *:nth-child(2) {
      & > *:nth-child(1) {
        font-size: 1.1rem;
        font-weight: ${textSizes.fontWeights.SEMI_BOLD};
      }
    }
    @media (min-width: 70rem) {
      border: none;
      width: 33%;
      gap: 0.5rem;
    }
  }
  /* & > *:nth-child(1) {
    @media (min-width: 70rem) {
      display: none;
    }
  }
  & > *:nth-child(3) {
    @media (max-width: 70rem) {
      display: none;
    }
  } */
`;
