import styled from "styled-components";
import {Column} from "../containers/flexbox.styled";
import {LightHeader} from "../texts.styled";
import {textSizes} from "../sizes";

export const StyledMobileNavMenu = styled(Column)`
  padding-top: 3rem;
  padding-bottom: 5rem;
  align-items: center;
  
  justify-content: space-between;
  height: 100%;
  
  ${LightHeader}{
    font-size: ${textSizes.fontSizes.MEDIUM};
    font-weight: ${textSizes.fontWeights.LIGHT};
  }
  
  ${Column}{
    gap: 1.9rem;
  }
`