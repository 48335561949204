import {
  Container,
  VerticalSeperator,
} from "../components/containers/boxes.styled";
import PageHeader from "../components/misc/pageHeader";
import { pageTitlesAndDescriptions } from "../pageTitlesAndDescriptions";
import {
  RecentlyListedPageCard,
  RecentlyListedTimeAdded,
  StyledRecentlyListedPageWrapper,
} from "../components/recentlyListed/recentlyListedPage.styled";
import {
  MarketDataRow,
  StyledRecentlyListedCardSocial,
  StyledRecentlyListedCardSocialLink,
} from "../components/recentlyListed/recentlyListedSwiper.styled";
import { Icon } from "../components/icon.styled";
import { BiWorld } from "react-icons/bi";
import { FaTelegramPlane, FaTwitter } from "react-icons/fa";
import { Column, FullRow, Row } from "../components/containers/flexbox.styled";
import {
  BigText,
  Header,
  LightHeader,
  Paragraph,
  SmallText,
} from "../components/texts.styled";
import { textSizes } from "../components/sizes";
import { CoinBadge } from "../components/dedicatedCoin/coinBadge.styled";
import { useQuery } from "@tanstack/react-query";
import {
  getMostComparedAll,
  getRecentlyListedPage,
} from "../components/stream";
import Loader from "../components/misc/loader";
import { isSocialLinkValid } from "../validators";
import {
  add3Dots,
  formatNumber,
  maxLittleNumber,
} from "../util/numberFormatter";
import { RouteLink } from "../routing/routeLink";
import { Helmet } from "react-helmet";

export default function RecentlyListed() {
  const { data, status } = useQuery(["recently-listed-page"], () =>
    getRecentlyListedPage()
  );

  const { data: mc, status: mcStatus } = useQuery(
    ["recently-listed-mc"],
    () => getMostComparedAll(),
    {
      staleTime: Infinity,
    }
  );

  function getTimeAdded(item) {
    let date1 = new Date(item.whenAdded);
    let date2 = new Date();

    let currentDate = new Date();

    let utcTimestamp =
      currentDate.getTime() + currentDate.getTimezoneOffset() * 60000;

    let gmtOffset = 3 * 60 * 60 * 1000;

    let gmtPlus3Timestamp = new Date(utcTimestamp + gmtOffset);

    date2 = gmtPlus3Timestamp;

    let diffInMilliseconds = date2 - date1;

    let diffInHours = diffInMilliseconds / (1000 * 60 * 60);
    let diffInDays = diffInHours / 24;

    let timeValue = "";

    if (diffInHours < 24)
      timeValue = `${Math.floor(diffInHours).toString()} ${
        Math.floor(diffInHours) === 1 ? "hour" : "hours"
      } ago`;
    if (diffInHours >= 24)
      timeValue = `${Math.floor(diffInDays).toString()} ${
        Math.floor(diffInDays) === 1 ? "day" : "days"
      } ago`;
    if (diffInDays > 30)
      timeValue = `${Math.floor(diffInDays / 30).toString()} ${
        Math.floor(diffInDays / 30) === 1 ? "month" : "months"
      } ago`;

    return timeValue;
  }

  return (
    <Container>
      <Helmet>
        <title>Recently Listed - CompareMarketCap</title>
        <meta
          name="description"
          content={
            "A selection of recently listed crypto coins on CompareMarketCap where you can check prices, market caps and related links of the crypto currencies."
          }
        />
      </Helmet>

      <PageHeader
        title={pageTitlesAndDescriptions.recentlyListed.title}
        description={pageTitlesAndDescriptions.recentlyListed.description}
      />

      {status === "success" ? (
        <StyledRecentlyListedPageWrapper>
          {data.map((item, index) => (
            <RecentlyListedPageCard key={index}>
              <img alt="logo" src={item.image} />
              <RecentlyListedTimeAdded>
                <SmallText>{getTimeAdded(item)}</SmallText>
              </RecentlyListedTimeAdded>
              <Column height={"100%"} justify={"space-between"} align={"start"}>
                <FullRow align={"start"} justify="space-between">
                  <RouteLink to={`/coin/${item.id}`}>
                    <Row gap="1rem">
                      <img
                        alt="logo"
                        style={{ width: "2rem" }}
                        src={item.image}
                      />
                      <Header>{item.symbol.toUpperCase()}</Header>
                      <VerticalSeperator />
                      <Paragraph
                        style={{ whiteSpace: "no-wrap" }}
                        color={({ theme }) => theme.colors.LIGHT_TEXT}
                        weight={textSizes.fontWeights.LIGHT}
                      >
                        {add3Dots(item.coinName, 20)}
                      </Paragraph>
                    </Row>
                  </RouteLink>
                </FullRow>

                {mcStatus === "success" &&
                  mc.findIndex((mcItem) => mcItem.id === item.id) < 50 &&
                  mc.findIndex((mcItem) => mcItem.id === item.id) > -1 &&
                  mc.find((mcItem) => mcItem.id === item.id) && (
                    <CoinBadge>
                      #{mc.findIndex((mcItem) => mcItem.id === item.id) + 1}{" "}
                      MOST COMPARED
                    </CoinBadge>
                  )}

                <MarketDataRow>
                  <Column gap="0.4rem">
                    <SmallText>Price</SmallText>
                    {item.price ? (
                      item.price < maxLittleNumber ? (
                        <BigText weight={textSizes.fontWeights.MEDIUM}>
                          $0.
                          <BigText
                            weight={textSizes.fontWeights.MEDIUM}
                            size={"0.7rem !important"}
                          >
                            {
                              formatNumber(item.price, false, false, true)
                                .zeroCount
                            }
                          </BigText>
                          {
                            formatNumber(item.price, false, false, true)
                              .finalNumber
                          }
                        </BigText>
                      ) : (
                        <BigText weight={textSizes.fontWeights.MEDIUM}>
                          ${formatNumber(item.price, false, true)}
                        </BigText>
                      )
                    ) : (
                      <span>👀</span>
                    )}
                  </Column>
                  <Column gap="0.4rem">
                    <SmallText>Market Cap</SmallText>
                    <BigText weight={textSizes.fontWeights.MEDIUM}>
                      {item.marketcap ? (
                        <span>${formatNumber(item.marketcap)}</span>
                      ) : (
                        <span>👀</span>
                      )}
                    </BigText>
                  </Column>
                </MarketDataRow>
              </Column>

              <StyledRecentlyListedCardSocial>
                {isSocialLinkValid(item.web) && (
                  <StyledRecentlyListedCardSocialLink
                    to={
                      item.web.slice(0, 8) === "https://"
                        ? item.web
                        : `https://${item.web}`
                    }
                  >
                    <Icon>
                      <BiWorld />
                    </Icon>
                  </StyledRecentlyListedCardSocialLink>
                )}
                {isSocialLinkValid(item.twitter) && (
                  <StyledRecentlyListedCardSocialLink
                    to={`https://x.com/${item.twitter.toString()}`}
                  >
                    <Icon>
                      <FaTwitter />
                    </Icon>
                  </StyledRecentlyListedCardSocialLink>
                )}
                {isSocialLinkValid(item.telegram) && (
                  <StyledRecentlyListedCardSocialLink
                    to={`https://t.me/${item.telegram}`}
                  >
                    <Icon>
                      <FaTelegramPlane />
                    </Icon>
                  </StyledRecentlyListedCardSocialLink>
                )}
              </StyledRecentlyListedCardSocial>
            </RecentlyListedPageCard>
          ))}
        </StyledRecentlyListedPageWrapper>
      ) : (
        <Loader height={"30rem"} />
      )}
    </Container>
  );
}
