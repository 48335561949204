import React, { useRef, useEffect, useContext } from "react";
import { createChart } from "lightweight-charts";
import { formatNumber, graphFormatter } from "../../util/numberFormatter";
import { dateDifferenceInDays } from "../halving/halvingResultThree";
import { HalvingContext, HalvingDateContext } from "../../context";

function LineChartComponent({ data, mode, coinData, isHalving, isMobile }) {
  const chartContainerRef = useRef(null);
  const chartInstance = useRef(null);

  function GetPrecisionAndMinMove(data) {
    const lastPrice = data[data.length - 1].value;

    let precision = 1;
    let minMove = 0;
    if (lastPrice < 0.00000000001) {
      precision = 13;
      minMove = 0.0000000000001;
    } else if (lastPrice < 0.0000000001) {
      precision = 12;
      minMove = 0.000000000001;
    } else if (lastPrice < 0.000000001) {
      precision = 11;
      minMove = 0.00000000001;
    } else if (lastPrice < 0.00000001) {
      precision = 10;
      minMove = 0.0000000001;
    } else if (lastPrice < 0.0000001) {
      precision = 9;
      minMove = 0.000000001;
    } else if (lastPrice < 0.000001) {
      precision = 8;
      minMove = 0.00000001;
    } else if (lastPrice < 0.00001) {
      precision = 7;
      minMove = 0.0000001;
    } else if (lastPrice < 0.0001) {
      precision = 6;
      minMove = 0.000001;
    } else if (lastPrice < 0.001) {
      precision = 5;
      minMove = 0.00001;
    } else if (lastPrice < 0.01) {
      precision = 4;
      minMove = 0.0001;
    } else if (lastPrice < 0.1) {
      precision = 3;
      minMove = 0.001;
    } else {
      precision = 2;
      minMove = 0.01;
    }

    return {
      precision: precision,
      minMove: minMove,
    };
  }

  useEffect(() => {}, []);

  const oHalvingDate = useContext(HalvingDateContext);

  const { halvingCoins, coin2 } = useContext(HalvingContext);
  let targetCoin = halvingCoins.find((coin) => coin.id === coin2.id);

  useEffect(() => {
    if (chartContainerRef.current) {
      chartInstance.current = createChart(chartContainerRef.current, {
        timeScale: {
          minBarSpacing: 0.2,
        },
        localization: {
          priceFormatter: (val) => graphFormatter(val),
        },
        width: chartContainerRef.current.clientWidth,
        height: 250,
        layout: {
          background: {
            type: "solid",
            color: "#31263F",
          },
          textColor: "#ffffff",
        },
        grid: {
          horzLines: {
            color: "rgba(255,255,255,0.2)",
          },
          vertLines: {
            color: "transparent",
          },
        },
      });

      const handleResize = () => {
        if (chartContainerRef.current) {
          chartInstance.current.applyOptions({
            width: chartContainerRef.current.clientWidth,
          });
        }
      };

      let lineSeries = null;

      lineSeries = chartInstance.current.addAreaSeries({
        priceLineVisible: false,
        lineColor: "#70FF00",
        topColor: "rgba(112,255,0,0.6)",
        bottomColor: "rgba(112,255,0,0)",
        priceFormat: {
          type: "price",
          precision: GetPrecisionAndMinMove(data).precision,
          minMove: GetPrecisionAndMinMove(data).minMove,
        },
      });
      lineSeries.setData(
        data.map((item) => ({
          time: item.time / 1000,
          value: item.value,
        }))
      );

      let difference = dateDifferenceInDays(new Date(oHalvingDate), new Date());

      let halvingDate = new Date("2020-05-11");

      var dateOffset = 24 * 60 * 60 * 1000 * difference;
      var myDate = new Date();
      myDate.setTime(halvingDate.getTime() + dateOffset);

      var markers = [
        {
          time: {
            day: 11,
            month: 5,
            year: 2020,
          },
          position: "belowBar",
          color: "#0085FF",
          shape: "arrowUp",
          text: "2020 HALVING",
        },
        {
          time: {
            day: myDate.getDate(),
            month: myDate.getMonth() + 1,
            year: myDate.getFullYear(),
          },
          position: "aboveBar",
          color: "#FF3F3F",
          shape: "arrowDown",
          text: difference + " days after halving",
        },
        {
          time: {
            day: new Date(targetCoin.date1).getDate(),
            month: new Date(targetCoin.date1).getMonth() + 1,
            year: new Date(targetCoin.date1).getFullYear(),
          },
          position: "aboveBar",
          color: "#FF3F3F",
          shape: "arrowDown",
          text: "1",
        },
        {
          time: {
            day: new Date(targetCoin.date2).getDate(),
            month: new Date(targetCoin.date2).getMonth() + 1,
            year: new Date(targetCoin.date2).getFullYear(),
          },
          position: "aboveBar",
          color: "#FF3F3F",
          shape: "arrowDown",
          text: "2",
        },
        {
          time: {
            day: new Date(targetCoin.date3).getDate(),
            month: new Date(targetCoin.date3).getMonth() + 1,
            year: new Date(targetCoin.date3).getFullYear(),
          },
          position: "aboveBar",
          color: "#FF3F3F",
          shape: "arrowDown",
          text: "3",
        },
      ];

      lineSeries.setMarkers(markers);

      window.addEventListener("resize", handleResize);

      const container = chartContainerRef.current;

      const toolTipWidth = 250;
      const toolTipHeight = 80;
      const toolTipMargin = 15;

      // Create and style the tooltip html element
      const toolTip = document.createElement("div");
      toolTip.style = `font-weight: bold;border-radius: 1rem; -webkit-backdrop-filter: blur(10px); backdrop-filter: blur(10px); width: fit-content; height: 80px; position: absolute; display: none; padding: 8px; box-sizing: border-box; font-size: 12px; text-align: left; z-index: 1000; top: 12px; left: 12px; pointer-events: none; border: 1px solid; border-radius: 2px;font-family: -apple-system, BlinkMacSystemFont, 'Trebuchet MS', Roboto, Ubuntu, sans-serif; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale;`;
      toolTip.style.background = "rgba(255,255,255,0.5)";
      toolTip.style.color = "black";
      toolTip.style.borderColor = "#70FF00";
      container.appendChild(toolTip);

      // update tooltip
      chartInstance.current.subscribeCrosshairMove((param) => {
        if (
          param.point === undefined ||
          !param.time ||
          param.point.x < 0 ||
          param.point.x > container.clientWidth ||
          param.point.y < 0 ||
          param.point.y > container.clientHeight
        ) {
          toolTip.style.display = "none";
        } else {
          // time will be in the same format that we supplied to setData.
          // thus it will be YYYY-MM-DD
          const dateStr = param.time;
          const date = new Date(dateStr * 1000);
          toolTip.style.display = "block";
          const data = param.seriesData.entries().next().value[1];
          const price = data.value !== undefined ? data.value : data.close;
          toolTip.innerHTML = `<div style="color: ${"white"}">${
            coinData.coinName
          }</div><div style="font-size: 24px; margin: 4px 0px; color: ${"white"}">

                    ${mode !== "holder" ? "$" : ""}${formatNumber(
            price,
            true,
            false,
            true
          )}
                    
                    </div><div style="color: ${"#3a3b3c"}">
                    ${
                      date.getDate() +
                      "-" +
                      (date.getMonth() + 1) +
                      "-" +
                      date.getFullYear()
                    }
			</div>`;

          const coordinate = lineSeries.priceToCoordinate(price);
          let shiftedCoordinate = param.point.x - 50;
          if (coordinate === null) {
            return;
          }
          shiftedCoordinate = Math.max(
            0,
            Math.min(container.clientWidth - toolTipWidth, shiftedCoordinate)
          );
          const coordinateY =
            coordinate - toolTipHeight - toolTipMargin > 0
              ? coordinate - toolTipHeight - toolTipMargin
              : Math.max(
                  0,
                  Math.min(
                    container.clientHeight - toolTipHeight - toolTipMargin,
                    coordinate + toolTipMargin
                  )
                );
          toolTip.style.left = shiftedCoordinate + "px";
          toolTip.style.top = coordinateY + "px";
        }
      });
      //#endregion
    }

    chartInstance.current.timeScale().setVisibleRange({
      from: new Date(Date.UTC(2019, 9, 20, 0, 0, 0, 0)).getTime() / 1000,
      to: new Date(targetCoin.date3).getTime() / 1000 + 5000000,
    });

    return () => {
      if (chartInstance.current) {
        chartInstance.current.remove();
      }
    };
  }, [data]);

  return <div ref={chartContainerRef} />;
}

export default LineChartComponent;
