import styled from "styled-components";
import { ShortBox } from "../containers/boxes.styled";
import { borderWidths } from "../sizes";

export const StyledCompare = styled(ShortBox)`
  border: ${({ external }) => (external ? 0 : borderWidths.SMALL)} solid
    ${({ theme }) => theme.colors.LEVEL};
  padding: 1rem;

  height: ${({ external }) => (external ? "auto" : "25rem")};
  .holder-loader {
    height: ${({ external }) => (external ? "auto" : "15.5rem")};
    justify-content: center;
  }

  @media (max-width: 70rem) {
    height: auto;
    .holder-loader {
      height: ${({ external }) => (external ? "auto" : "12.05rem")};
    }
  }

  @media (max-width: 40rem) {
    padding: 0.75rem;
    & > *:first-child {
      gap: 0.75rem;
    }
  }

  max-width: ${({ external }) => external && "23rem !important"};
  min-width: ${({ external }) => external && "23rem !important"};
`;
