import styled from "styled-components";
import { StyledSidebarItem } from "./sidebar/sidebarItem.styled";
import { borderRads } from "./sizes";

export const Icon = styled.div`
  font-size: ${({ size }) => size};
  padding: ${({ padding }) => padding};
  display: inline-flex;
  align-items: center;
  transform: ${({ reverse }) => reverse && "rotate(180deg)"};
  transition: 0.2s;
  & > * > * {
    color: ${({ theme, color }) => (color ? color : theme.colors.TEXT)};
  }

  img {
    width: ${({ sidebar }) => sidebar && "1.8rem"};
  }

  .mcap-tooltip {
    position: absolute;
    background-color: ${({ theme }) => theme.colors.LEVEL};
    padding: 0.5rem;
    border-radius: ${borderRads.MEDIUM};
    top: 100%;
    z-index: 1;
    text-align: center;
    display: none;
  }

  &:hover {
    .mcap-tooltip {
      display: block;
    }
  }
`;
