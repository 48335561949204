import FlipClockCountdown from "@leenguyen/react-flip-clock-countdown";
import { textSizes } from "../sizes";
import {
  HalvingLogoWrapper,
  StyledCountdownWrapper,
  StyledHalvingTop,
  StyledHalvingTopBg,
  StyledHalvingTopWrapper,
} from "./halving.styled";
import { Column, Row } from "../containers/flexbox.styled";
import { Header, LightHeader, SmallText } from "../texts.styled";
import { useContext, useEffect } from "react";
import { HalvingDateContext } from "../../context";

export default function HalvingTop() {
  return (
    <StyledHalvingTopWrapper>
      <StyledHalvingTop>
        <HalvingLogoWrapper>
          <img src="/logos/halving-logo.png" />
          <SmallText>
            The 2024 Bitcoin halving has cut mining rewards in half, decreasing
            the rate of new bitcoins entering circulation. This event aims to
            mimic digital scarcity, often leading to significant market
            volatility and speculation on Bitcoin's value. On this page, you can
            calculate how much your favorite coins would gain if they would
            perform similar to select coins on 2020 halving cycle.
          </SmallText>
        </HalvingLogoWrapper>
        {/* <CountdownWrapper /> */}
      </StyledHalvingTop>
    </StyledHalvingTopWrapper>
  );
}

function CountdownWrapper() {
  let halvingDate = useContext(HalvingDateContext);

  return (
    <StyledCountdownWrapper>
      <Header>Bitcoin Halving Countdown</Header>
      <FlipClockCountdown
        className="flip-clock"
        to={new Date(halvingDate)}
        labels={["D", "H", "M", "S"]}
        labelStyle={{
          fontFamily: "montserrat",
          textTransform: "uppercase",
          fontSize: textSizes.fontSizes.SMALL,
        }}
        separatorStyle={{ size: "0.4rem" }}
        dividerStyle={{ color: "#31263F", height: 1 }}
      />
    </StyledCountdownWrapper>
  );
}
