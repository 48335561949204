import styled from "styled-components";
import {Column} from "../containers/flexbox.styled";
import {Icon} from "../icon.styled";
import {paddings} from "../paddings.styled";
import {borderRads} from "../sizes";
import {Paragraph, SmallText} from "../texts.styled";

export const StyledAboutUsTools = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 0 8rem;
  
  width: 100%;
  
  justify-content: space-between;
  
  row-gap: 4rem;

  @media(max-width: 70rem){
    margin: 1rem;
  }
  
  @media(max-width: 50rem){
    margin: 0;
    width: 100%;
    flex-direction: column;
    align-items: center;
    gap: 3rem;
  }
`

export const AboutUsTool = styled(Column)`
  border-radius: 1rem;
  padding: 1rem;
  background: ${({theme}) => theme.colors.SECONDARY};
  
  &:nth-child(2){
    background-image: url("/images/aquarium-creatures-bg.webp");
    background-position: center;
    background-size: cover;
  }
  
  align-items: start;
  gap: 0.8rem;
  width: 45%;
  @media(max-width: 50rem){
    gap: 0.5rem;
    width: 100%;
    align-items: center;
    text-align: center;
    ${SmallText}{
      padding-left: ${paddings.HIGH};
      padding-left: ${paddings.HIGH};
    }
  }
  
  button {
    margin-top: 1rem;
    padding: ${paddings.LOW} ${paddings.HIGH};
    transition: 0.4s;
    border-radius: ${borderRads.MEDIUM};
  }
  
  img{
    height: 2rem;
  }
`

export const AboutUsToolIcon = styled(Icon)`
  height: 2rem;
  svg {
    fill: ${({theme}) => theme.colors.BRAND}
  }
  
  & > * {
    font-size: 2rem;
  }
`