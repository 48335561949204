import styled from "styled-components";
import { paddings } from "../paddings.styled";
import { borderRads } from "../sizes";
import { Link } from "react-router-dom";
import { RouteLink } from "../../routing/routeLink";
import { Paragraph } from "../texts.styled";

export const StyledSidebarItem = styled(RouteLink)`
  width: 100%;
  padding: ${paddings.MID};
  border-radius: ${borderRads.SMALL};

  &:hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.colors.LEVEL};
  }

  ${Paragraph} {
    img {
      height: 1.5rem;
    }
    white-space: nowrap;
  }
`;
