import { Column, FullRow } from "../containers/flexbox.styled";
import { paddings } from "../paddings.styled";
import { BigText, Header, SmallText } from "../texts.styled";
import styled from "styled-components";
import { MdOutlineExpandMore } from "react-icons/md";
import { MdOutlineExpandLess } from "react-icons/md";
import { Icon } from "../icon.styled";
import { useState } from "react";
import { textSizes } from "../sizes";

export default function InteractiveFaq() {
  return (
    <StyledInteractiveFaq>
      <BigText style={{ marginBottom: "1rem" }}>FAQ</BigText>
      {faqData.map((item) => (
        <FaqQuestion data={item} />
      ))}
    </StyledInteractiveFaq>
  );
}

const StyledInteractiveFaq = styled(Column)`
  width: 90%;
  margin: 0 auto;
  max-width: 55rem;
  margin-top: 3rem;
`;

const StyledIFaqQuestion = styled(FullRow)`
  padding: ${paddings.HIGH} ${paddings.HIGH};
  background-color: rgba(255, 255, 255, 0.05);
  justify-content: space-between;
  cursor: pointer;
`;

const StyledIFaqAnswer = styled(Column)`
  padding: ${paddings.HIGH} ${paddings.HIGH};
  background-color: rgba(255, 255, 255, 0.1);
  width: 100%;
`;

export function FaqQuestion({ data }) {
  const [isOn, setOn] = useState(false);

  return (
    <Column width="100%">
      <StyledIFaqQuestion
        onClick={() => {
          setOn((prevState) => !prevState);
        }}
      >
        <SmallText
          weight={
            isOn
              ? textSizes.fontWeights.SEMI_BOLD
              : textSizes.fontWeights.REGULAR
          }
        >
          {data.question}
        </SmallText>
        <Icon size="2rem">
          {isOn ? <MdOutlineExpandLess /> : <MdOutlineExpandMore />}
        </Icon>
      </StyledIFaqQuestion>
      {isOn && (
        <StyledIFaqAnswer>
          <SmallText>{data.answer}</SmallText>
        </StyledIFaqAnswer>
      )}
    </Column>
  );
}

const faqData = [
  {
    question: "What is Bitcoin halving?",
    answer:
      "Bitcoin halving is an event that halves the reward for mining Bitcoin transactions, reducing the rate at which new bitcoins are created.",
  },
  {
    question: "When is the next Bitcoin halving?",
    answer: "The next Bitcoin halving is expected in 2024.",
  },
  {
    question: "Why does Bitcoin halve?",
    answer:
      "Bitcoin halves to introduce digital scarcity, similar to limited natural resources, thereby potentially increasing its value over time.",
  },
  {
    question: "How often does Bitcoin halving occur?",
    answer: "Bitcoin halving occurs approximately every four years.",
  },
  {
    question: "What was the mining reward before the 2024 halving?",
    answer:
      "Before the 2024 halving, the mining reward was 6.25 bitcoins per block.",
  },
  {
    question: "How does halving affect Bitcoin miners?",
    answer:
      "Halving reduces miners’ rewards, potentially impacting profitability unless compensated by a rise in Bitcoin’s value.",
  },
  {
    question: "Can Bitcoin halving affect Bitcoin’s price?",
    answer:
      "Yes, halving can affect Bitcoin’s price, often leading to increased volatility and speculation.",
  },
  {
    question: "How many Bitcoin halvings have occurred?",
    answer:
      "As of 2024, there have been four Bitcoin halvings since its inception.",
  },
  {
    question: "What happens when all Bitcoins are mined?",
    answer:
      "Once all Bitcoins are mined, miners will only earn transaction fees, not block rewards.",
  },
  {
    question: "Is Bitcoin the only cryptocurrency that undergoes halving?",
    answer:
      "No, several other cryptocurrencies also undergo halving events, following a similar principle to control inflation.",
  },
  {
    question: "Does Bitcoin halving guarantee a price increase?",
    answer:
      "No, while historically halving has led to price increases, it does not guarantee future price movements.",
  },
];
