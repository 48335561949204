import styled, { keyframes } from 'styled-components';
import {borderRads, borderWidths} from "../sizes";
import {paddings} from "../paddings.styled";

const fadeIn = keyframes`
  from {
    opacity: 0;
    -webkit-backdrop-filter: blur(0px);
    backdrop-filter: blur(0px);
  }
  to {
    opacity: 1;
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
  }
`

export const FullScreenMenu = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0,0,0,0.9);
  border: ${({theme})=> `${borderWidths.SMALL} solid ${theme.colors.LEVEL}`};
  border-radius: ${borderRads.SMALL};
  z-index: ${({zindex})=>zindex};
  transition: 1s;
  animation: ${fadeIn} 0.3s forwards;
  padding: ${paddings.LOW} 0 ${paddings.LOW} ${paddings.LOW};
`