import React, { useContext, useEffect, useState } from "react";
import { MdClose } from "react-icons/md";
import { AllCoinsContext, TrendsContext } from "../../context";
import { add3Dots } from "../../util/numberFormatter";
import { TransparentButton } from "../buttons/buttons.styled";
import { SearchMenuItem } from "../compareSection/dropdownItem";
import { Column, FullRow } from "../containers/flexbox.styled";
import { zIndexes } from "../globalStyle.styled";
import { Icon } from "../icon.styled";
import Loader from "../misc/loader";
import { paddings } from "../paddings.styled";
import {
  MobileSearchMostCompared,
  MobileSearchRow,
  StyledMobileSearchMenu,
} from "../search/searchBar.styled";
import { Header, Paragraph } from "../texts.styled";
import { RouteLink } from "../../routing/routeLink";
import { theme } from "../theme.styled";

export default function MobileSearchMenu({ onClose, isOn }) {
  const { mcData, trendingData } = useContext(TrendsContext);

  const data = useContext(AllCoinsContext);

  const allItems = useContext(AllCoinsContext);
  const [searchQuery, setSearchQuery] = useState();
  const [searchResults, setSearchResults] = useState(allItems);
  const [searchTimeout, setSearchTimeout] = useState(null);

  useEffect(() => {
    if (searchTimeout) clearTimeout(searchTimeout);
    setLoading(true);
    setSearchTimeout(
      setTimeout(() => {
        handleSearch();
      }, 700)
    );
  }, [searchQuery]);

  const [isLoading, setLoading] = useState(false);

  const handleSearch = () => {
    const regex = new RegExp(searchQuery, "i");

    const filteredAndSortedItems = [];

    for (let i = 0; i < (searchQuery ? allItems.length : 50); i++) {
      const item = allItems[i];

      if (
        regex.test(item.symbol) ||
        regex.test(item.coinName) ||
        (item.contractAddress && regex.test(item.contractAddress))
      ) {
        filteredAndSortedItems.push({ ...item, index: firstIndex(item) });
      }
    }

    filteredAndSortedItems.sort((a, b) => a.index - b.index);

    setSearchResults(filteredAndSortedItems);

    setLoading(false);
  };

  const firstIndex = (item) => {
    const [name, symbol, ca] = [
      item.coinName.toLowerCase().indexOf(searchQuery),
      item.symbol.toLowerCase().indexOf(searchQuery),
      item.contractAddress
        ? item.contractAddress.indexOf(searchQuery)
        : Infinity,
    ];

    let index = Infinity;
    for (let curr of [name, symbol, ca]) {
      if (curr === 0) {
        // Exit early if the first occurrence is found
        return 0;
      }

      if (curr < index && curr !== -1) {
        index = curr;
      }
    }

    return index;
  };

  if (!isOn) return null;

  return (
    <StyledMobileSearchMenu zindex={zIndexes.MOBILE_NAV_MENU}>
      <Column
        padding={`${paddings.LOW} 0 ${paddings.LOW} ${paddings.LOW}`}
        gap="2rem"
        align="start"
      >
        <FullRow gap="1rem">
          <FullRow>
            <FullRow className="search-bar">
              <input
                autoFocus
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                placeholder="Search by address or name"
              />
              {!isLoading && searchQuery !== "" && searchQuery && (
                <TransparentButton
                  onClick={() => setSearchQuery("")}
                  width="4rem"
                >
                  <Icon size="1.25rem" color={theme.colors.LIGHT_TEXT}>
                    <MdClose />
                  </Icon>
                </TransparentButton>
              )}
            </FullRow>

            {isLoading && (
              <Loader
                position={"absolute"}
                right={0}
                isSmall={true}
                width={"3rem"}
              />
            )}
          </FullRow>

          <TransparentButton onClick={() => onClose()} width="4rem">
            <Icon size="2.4rem">
              <MdClose />
            </Icon>
          </TransparentButton>
        </FullRow>

        {searchQuery !== "" && searchResults.length === 0 && (
          <Paragraph>No coins found.</Paragraph>
        )}
        {(searchQuery === "" || !searchQuery) && (
          <Column width="100%" gap="1rem">
            <Header width="100%">Most Compared 🔥</Header>
            <MobileSearchMostCompared>
              {mcData.map((item, index) => (
                <RouteLink
                  onClick={() => {
                    onClose();
                    setSearchQuery("");
                  }}
                  key={index}
                  to={`/coin/${item.id}`}
                >
                  <Column>
                    <img alt="logo" src={item.image} />
                    <Paragraph>{item.symbol.toUpperCase()}</Paragraph>
                  </Column>
                </RouteLink>
              ))}
            </MobileSearchMostCompared>
          </Column>
        )}

        {(searchQuery === "" || !searchQuery) && (
          <Column width="100%" gap="2rem">
            <Header width="100%">Top Gainers 💸</Header>
            {trendingData.slice(0, 5).map((item, index) => (
              <RouteLink
                style={{ width: "100%" }}
                onClick={() => {
                  onClose();
                  setSearchQuery("");
                }}
                key={index}
                to={`/coin/${item.id}`}
              >
                <MobileSearchRow>
                  <img alt="logo" src={item.image} />
                  <Header>{item.symbol.toUpperCase()}</Header>
                  <Paragraph>
                    {add3Dots(item.coinName.toUpperCase(), 10)}
                  </Paragraph>
                </MobileSearchRow>
              </RouteLink>
            ))}
          </Column>
        )}
        <Header width="100%">Search Results</Header>
        {searchResults.slice(0, 100).map((item, index) => (
          <RouteLink
            style={{ width: "100%" }}
            key={index}
            to={`/coin/${item.id}`}
            onClick={() => {
              onClose();
              setSearchQuery("");
            }}
          >
            <SearchMenuItem
              symbol={item.symbol}
              name={item.coinName}
              img={item.image}
            />
          </RouteLink>
        ))}
      </Column>
    </StyledMobileSearchMenu>
  );
}
