import styled from "styled-components";
import { paddings } from "../paddings.styled";
import { borderRads, borderWidths, boxSizes } from "../sizes";

export const Container = styled.div`
  @keyframes fadeContainerIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem !important;
  width: 100%;
  animation: fadeContainerIn 1s;
  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  -ms-animation-fill-mode: forwards;
  -o-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
`;

export const EmptyBox = styled.div`
  width: ${({ width }) => width || "100%"};
  height: ${({ height }) => height || "fit-content"};
  background-color: transparent;
  position: ${({ position }) => position};
  overflow: ${({ overflow }) => overflow};
`;

export const Box = styled.div`
  width: ${({ width }) => width || "1rem"};
  height: ${({ height }) => height || "fit-content"};
  background-color: ${({ theme, color }) => color || theme.colors.SECONDARY};
  border-radius: ${borderRads.SMALL};
  padding: ${paddings.MID};
  border: ${({ withborder, theme }) =>
    withborder && `${borderWidths.SMALL} solid ${theme.colors.LEVEL}`};
`;

export const ShortBox = styled(Box)`
  width: ${boxSizes.SHORT};
  max-width: ${boxSizes.SHORT};
  min-width: ${boxSizes.SHORT};
  flex-direction: column;
  @media (max-width: 90rem) {
    width: ${({ isBig }) => (isBig ? "29rem" : "22rem")};
    min-width: ${({ isBig }) => (isBig ? "29rem" : "22rem")};
  }
  @media (max-width: 80rem) {
    width: 18rem !important;
    min-width: 18rem;
  }
  @media (max-width: 70rem) {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important;
    gap: 1rem;
    flex-direction: column;
    padding: ${paddings.MID};
  }
  @media (max-width: 40rem) {
    padding: 0;
  }
  display: flex;
  align-items: center;
  gap: 1rem;
  justify-content: space-between;
`;

export const LongBox = styled(Box)`
  width: calc(100% - 26rem);
  @media (max-width: 90rem) {
    width: ${({ isSmall }) =>
      isSmall ? "calc(100% - 30rem)" : "calc(100% - 23rem)"};
  }
  @media (max-width: 80rem) {
    width: calc(100% - 20rem);
  }
  @media (max-width: 70rem) {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important;
  }
`;

export const FullBox = styled(Box)`
  width: 100%;
  display: flex;
`;

export const VerticalSeperator = styled.div`
  width: ${({ width }) => width || "0.1rem"};
  height: ${({ height }) => height || "1rem"};
  min-width: ${({ width }) => width || "0.1rem"};
  background-color: ${({ theme, color }) => color || theme.colors.LIGHT_TEXT};
`;

export const BlurBox = styled(Box)`
  background-color: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(50px);
  outline: ${borderWidths.SMALL} solid ${({ theme }) => theme.colors.LEVEL};
`;

export const HorizontalSeperator = styled.div`
  width: ${({ width }) => width || "1rem"};
  height: ${({ height }) => height || "0.1rem"};
  min-width: ${({ width }) => width || "1rem"};
  background-color: ${({ theme, color }) => color || theme.colors.LIGHT_TEXT};
`;
