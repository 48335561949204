import { useContext, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { Container } from "../components/containers/boxes.styled";
import {
  BigText,
  Header,
  LightHeader,
  Paragraph,
  SmallText,
  Title,
} from "../components/texts.styled";
import { Column, FullRow, Row } from "../components/containers/flexbox.styled";
import { borderRads, borderWidths, textSizes } from "../components/sizes";
import { paddings } from "../components/paddings.styled";
import styled from "styled-components";
import DreamfolioList, { DreamfolioCoin } from "../components/dreamfolioList";
import { getPortfolioCoins, getPortfolioList } from "../components/stream";
import { useQuery } from "@tanstack/react-query";
import { graphFormatter } from "../util/numberFormatter";
import {
  OutlineButton,
  PrimaryButton,
  TransparentButton,
} from "../components/buttons/buttons.styled";
import { Icon } from "../components/icon.styled";
import { IoMdArrowForward, IoMdClose, IoMdExpand } from "react-icons/io";
import { CoinsContext } from "../context";
import { toast } from "react-toastify";
import Loader from "../components/misc/loader";
import {
  HideOnMobileWrapper,
  ShowOnMobileWrapper,
} from "../components/hideOnMobile";
import FakeDreamfolioTableDesktop, {
  DreamfolioTableDesktop,
} from "../components/portfolio/dreamfolioTableDesktop";
import Cookies from "js-cookie";
import { theme } from "../components/theme.styled";
import { MdExpandLess, MdExpandMore } from "react-icons/md";
import { ClickAwayListener, Modal } from "@mui/base";

import Box from "@mui/material/Box";
import { getFakePortfolioCoins } from "../util/dreamfolio";
import axios from "axios";

let fetchLock = false;

export default function Dreamfolio() {
  let fake = false;

  if (process.env.NODE_ENV === "production") {
    fake = false;
  }

  const notifyError = (text) => {
    if (document.visibilityState === "visible")
      return toast(text || `There has been an error.`);
  };

  const [isCompare, setIsCompare] = useState(
    Cookies.get("compareMode") && Cookies.get("compareMode") === "true"
      ? true
      : false
  );

  useEffect(() => {
    Cookies.set("compareMode", isCompare);
  }, [isCompare]);

  const [currentAmount, setCurrentAmount] = useState(null);
  const [dreamAmount, setDreamAmount] = useState(null);
  const [multiplier, setMultiplier] = useState(1);

  const [dreamfolioData, setDreamfolioData] = useState(null);

  const [coinAmountsInUSD, setCoinAmountsInUSD] = useState([]);

  const [pageMode, setPageMode] = useState(0);
  const [wallet, setWallet] = useState(null);
  const [cookieLoad, setCookieLoad] = useState(true);

  const [ethWallet, setEthWallet] = useState(null);
  const [solWallet, setSolWallet] = useState(null);
  const [tronWallet, setTronWallet] = useState(null);

  const [isFetching, setFetching] = useState(false);

  const [data, setData] = useState([]);

  const [filter, setFilter] = useState("Amount");

  const nextFilter = () => {
    if (filter === "Amount") {
      setFilter("Dream amount");
    } else if (filter === "Dream amount") {
      setFilter("Amount");
    }
  };

  const { data: portfolioList, status } = useQuery(["portfolioList"], () =>
    getPortfolioList()
  );

  useEffect(() => {
    if (dreamfolioData !== null && dreamfolioData.length > 0) {
      //if any of the items in dreamfolioData has not a non null compareCoin, dont save cooke
      Cookies.set("dreamfolioData", JSON.stringify(dreamfolioData), {
        expires: 30,
      });
    }
  }, [dreamfolioData]);

  useEffect(() => {
    //if it ends with desc or asc, do it so
    if (filter === "Amountdesc") {
      setData(
        data.sort((a, b) => {
          const aAmount = coinAmountsInUSD.find(
            (item) => item.customId === a.customId
          );
          const bAmount = coinAmountsInUSD.find(
            (item) => item.customId === b.customId
          );

          if (!aAmount || !bAmount) return 0;

          return bAmount.oldAmount - aAmount.oldAmount;
        })
      );
    }

    if (filter === "Amountasc") {
      setData(
        data.sort((a, b) => {
          const aAmount = coinAmountsInUSD.find(
            (item) => item.customId === a.customId
          );
          const bAmount = coinAmountsInUSD.find(
            (item) => item.customId === b.customId
          );

          if (!aAmount || !bAmount) return 0;

          return aAmount.oldAmount - bAmount.oldAmount;
        })
      );
    }

    if (filter === "Dream amountdesc") {
      setData(
        data.sort((a, b) => {
          const aAmount = coinAmountsInUSD.find(
            (item) => item.customId === a.customId
          );
          const bAmount = coinAmountsInUSD.find(
            (item) => item.customId === b.customId
          );

          if (!aAmount || !bAmount) return 0;

          return bAmount.amount - aAmount.amount;
        })
      );
    }

    if (filter === "Dream amountasc") {
      let sortedData = data.sort((a, b) => {
        const aAmount = coinAmountsInUSD.find(
          (item) => item.customId === a.customId
        );
        const bAmount = coinAmountsInUSD.find(
          (item) => item.customId === b.customId
        );

        if (!aAmount || !bAmount) return 0;

        return aAmount.amount - bAmount.amount;
      });

      //place the compared coins to the top, but keep the sorting
      const comparedCoins = sortedData.filter((item) => {
        const dreamCoin = dreamfolioData.find(
          (dreamItem) => dreamItem.customId === item.customId
        );
        return dreamCoin.compareCoin !== null;
      });

      const notComparedCoins = sortedData.filter((item) => {
        const dreamCoin = dreamfolioData.find(
          (dreamItem) => dreamItem.customId === item.customId
        );
        return dreamCoin.compareCoin === null;
      });

      setData([...comparedCoins, ...notComparedCoins]);
    }

    if (filter === "Amount") {
      setData(
        data.sort((a, b) => {
          const aAmount = coinAmountsInUSD.find(
            (item) => item.customId === a.customId
          );
          const bAmount = coinAmountsInUSD.find(
            (item) => item.customId === b.customId
          );

          if (!aAmount || !bAmount) return 0;

          return bAmount.oldAmount - aAmount.oldAmount;
        })
      );
    }
    if (filter === "Dream amount") {
      setData(
        data.sort((a, b) => {
          const aAmount = coinAmountsInUSD.find(
            (item) => item.customId === a.customId
          );
          const bAmount = coinAmountsInUSD.find(
            (item) => item.customId === b.customId
          );

          if (!aAmount || !bAmount) return 0;

          return bAmount.amount - aAmount.amount;
        })
      );
    }
  }, [filter]);

  useEffect(() => {
    //if any of them is edited, open compare mode
    if (!fetchLock && coinAmountsInUSD.find((item) => item.edited === true)) {
      setIsCompare(true);
    }
  }, [coinAmountsInUSD]);

  useEffect(() => {
    const ethWalletCookie = Cookies.get("ethWallet");
    const solWalletCookie = Cookies.get("solWallet");
    const tronWalletCookie = Cookies.get("tronWallet");

    if (
      (ethWalletCookie && ethWalletCookie !== "null") ||
      (solWalletCookie && solWalletCookie !== "null") ||
      (tronWalletCookie && tronWalletCookie !== "null")
    ) {
      handleClick(ethWalletCookie, solWalletCookie, tronWalletCookie);
    }
  }, []);

  const resetComparisons = () => {
    const newDreamfolioData = dreamfolioData.map((coin) => {
      return {
        id: coin.id,
        compareCoin: null,
        isVisible: coin.id === "null" ? false : true,
        customId: coin.customId,
      };
    });

    // Cookies.set("dreamfolioData", JSON.stringify(newDreamfolioData));

    setDreamfolioData(newDreamfolioData);
    const newAmounts = data.map((coin) => ({
      id: coin.id,
      amount: parseFloat(coin.amountInUSD),
      customId: coin.customId,
    }));

    setCoinAmountsInUSD(newAmounts);

    setIsCompare(false);
  };

  /* useEffect(() => {
    const interval = setInterval(() => {
      if (window.navigator.onLine && document.visibilityState === "visible") {
        handleClick(ethWallet, solWallet, tronWallet, false, true);
      }
    }, 60000);
    return () => {
      clearInterval(interval);
    };
  }, []); */

  const handleClick = async (
    ethWalletI,
    solWalletI,
    tronWalletI,
    isWallets,
    isRefresh = false
  ) => {
    if (!window.navigator.onLine || document.visibilityState === "hidden")
      return;

    if (
      (ethWalletI === "" && solWalletI === "" && tronWalletI === "") ||
      (!solWalletI && !ethWalletI && !tronWalletI)
    ) {
      if (!isRefresh) {
        axios.post("https://api.comparemcap.com/dreamfolio-refresh", {
          no: "1",
          ethWallet: ethWalletI,
          solWallet: solWalletI,
          time: new Date(),
          os: window.navigator.platform,
        });
        setPageMode(0);
      }

      axios.post("https://api.comparemcap.com/dreamfolio-refresh", {
        no: "6",
        ethWallet: ethWalletI,
        solWallet: solWalletI,
        time: new Date(),
        os: window.navigator.platform,
      });

      // Cookies.remove("ethWallet");
      // Cookies.remove("solWallet");
      // Cookies.remove("tronWallet");

      // Cookies.remove("dreamfolioData");

      return;
    }
    if (!isRefresh) {
      setFetching(true);
      setPageMode(1);
    }

    //if any of them is empty or null, or undefined, remove the cookie
    if (!ethWalletI || ethWalletI === "null") {
      axios.post("https://api.comparemcap.com/dreamfolio-refresh", {
        no: "3",
        ethWallet: ethWalletI,
        solWallet: solWalletI,
        time: new Date(),
        os: window.navigator.platform,
      });
      Cookies.remove("ethWallet");
    }
    if (!solWalletI || solWalletI === "null") {
      axios.post("https://api.comparemcap.com/dreamfolio-refresh", {
        no: "4",
        ethWallet: ethWalletI,
        solWallet: solWalletI,
        time: new Date(),
        os: window.navigator.platform,
      });
      Cookies.remove("solWallet");
    }
    if (!tronWalletI || tronWalletI === "null") {
      axios.post("https://api.comparemcap.com/dreamfolio-refresh", {
        no: "5",
        ethWallet: ethWalletI,
        solWallet: solWalletI,
        time: new Date(),
        os: window.navigator.platform,
      });
      Cookies.remove("tronWallet");
    }

    let solData = [];
    let ethData = [];
    let tronData = [];

    let completedWallets = 0;

    if (solWalletI && solWalletI !== "null") {
      try {
        if (fake) solData = await getFakePortfolioCoins();
        else solData = await getPortfolioCoins(solWalletI, "solana");

        setSolWallet(solWalletI);
        completedWallets++;
      } catch (err) {
        notifyError("There has been an error with the SOLANA wallet");
        setSolWallet(null);
      }
    }
    if (ethWalletI && ethWalletI !== "null") {
      if (fake) return;
      try {
        ethData = await getPortfolioCoins(ethWalletI, "ethereum");
        setEthWallet(ethWalletI);
        completedWallets++;
      } catch (err) {
        notifyError("There has been an error with the ETH-BNB-BASE wallet");
        setEthWallet(null);
      }
    }
    if (tronWalletI && tronWalletI !== "null") {
      if (fake) return;
      try {
        tronData = await getPortfolioCoins(tronWalletI, "tron");

        setTronWallet(tronWalletI);
        completedWallets++;
      } catch (err) {
        notifyError("There has been an error with the TRON wallet");
        setTronWallet(null);
      }
    }

    if (completedWallets === 0 && !isRefresh) {
      if (isWallets) notifyError("Please enter a valid wallet address.");
      setFetching(false);
      axios.post("https://api.comparemcap.com/dreamfolio-refresh", {
        no: "2",
        ethWallet: ethWalletI,
        solWallet: solWalletI,
        time: new Date(),
        os: window.navigator.platform,
      });
      setPageMode(0);
      return;
    }

    let data = [...solData, ...ethData, ...tronData];

    //sort data by amountInUSD descending
    data = data.sort((a, b) => b.amountInUSD - a.amountInUSD);

    //add a new field to each coin named customId

    data = addChainField(data);

    data = data.map((coin, index) => {
      return {
        ...coin,
        customId:
          coin.id === "null"
            ? coin.contractAddress
            : coin.chain
            ? coin.id + coin.chain
            : coin.id,
      };
    });

    setData(data);

    if (ethWalletI && ethWalletI !== "null" && ethWalletI.length > 15)
      Cookies.set("ethWallet", ethWalletI, {
        expires: 30,
      });
    if (solWalletI && solWalletI !== "null" && solWalletI.length > 15)
      Cookies.set("solWallet", solWalletI, {
        expires: 30,
      });
    if (tronWalletI && tronWalletI !== "null" && tronWalletI.length > 15)
      Cookies.set("tronWallet", tronWalletI, {
        expires: 30,
      });

    setCoinAmountsInUSD(
      data.map((coin) => ({
        id: coin.id,
        customId: coin.customId,
        amount: parseFloat(coin.amountInUSD),
        oldAmount: parseFloat(coin.amountInUSD),
      }))
    );

    setCurrentAmount(
      data.reduce(
        (acc, coin) =>
          acc + parseFloat(isNaN(coin.amountInUSD) ? 0 : coin.amountInUSD),
        0
      )
    );

    const cookieDreamfolioData = Cookies.get("dreamfolioData");

    if (
      cookieDreamfolioData &&
      cookieDreamfolioData !== "null" &&
      data.length > 0
    ) {
      const parsedData = JSON.parse(cookieDreamfolioData);
      if (!isRefresh && parsedData.find((item) => item.compareCoin !== null)) {
        setIsCompare(true);
      }

      setDreamfolioData(
        data.map((coin) => {
          const compareCoin = parsedData.find(
            (item) => item.customId === coin.customId
          );
          const isVisible = parsedData.find(
            (item) => item.customId === coin.customId
          )
            ? parsedData.find((item) => item.customId === coin.customId)
                .isVisible
            : coin.id === "null"
            ? false
            : true;

          return {
            id: coin.id,
            compareCoin: compareCoin ? compareCoin.compareCoin : null,
            isVisible: isVisible,
            customId: coin.customId,
          };
        })
      );
    } else {
      setDreamfolioData(
        data.map((coin) => {
          return {
            id: coin.id,
            compareCoin: null,
            isVisible: coin.id === "null" ? false : true,
            customId: coin.customId,
          };
        })
      );
    }

    setFetching(false);
    setCookieLoad(false);

    fetchLock = true;
    setTimeout(() => {
      fetchLock = false;
    }, 1000);
  };

  function newWalletHandler(wallet) {}

  //for both current amount and dream amount, exclude the coins that are not visible

  useEffect(() => {
    if (dreamfolioData === null) return;

    const newAmount = coinAmountsInUSD.reduce((acc, coin) => {
      const dreamCoin = dreamfolioData.find(
        (item) => item.customId === coin.customId
      );
      if (dreamCoin && dreamCoin.isVisible) {
        let amount = isNaN(coin.amount) ? 0 : coin.amount;
        return acc + parseFloat(amount);
      } else {
        return acc;
      }
    }, 0);

    setDreamAmount(newAmount);

    const newCurrentAmount = data.reduce((acc, coin) => {
      const dreamCoin = dreamfolioData.find(
        (item) => item.customId === coin.customId
      );
      if (dreamCoin && dreamCoin.isVisible) {
        let amount = isNaN(coin.amountInUSD) ? 0 : coin.amountInUSD;
        return acc + parseFloat(amount);
      } else {
        return acc;
      }
    }, 0);

    setCurrentAmount(newCurrentAmount);
  }, [coinAmountsInUSD, dreamfolioData]);

  return (
    <DreamfolioContainer
      style={{ position: "relative", maxWidth: "65rem", margin: "0 auto" }}
    >
      <Helmet>
        <title>Dreamfolio - Track and Compare Your Crypto Portfolio</title>
        <meta
          name="description"
          content={
            "Dreamfolio helps you track and compare your crypto portfolio, showing you what your wallet could be worth if all your comparisons come true."
          }
        />
      </Helmet>
      <div
        style={{
          position: "absolute",
          left: "0",
          right: "0",
          top: "-2.75rem",
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <ShowOnMobileWrapper>
          <LightHeader>DREAMFOLIO</LightHeader>
        </ShowOnMobileWrapper>
      </div>

      <SmallText
        color={({ theme }) => theme.colors.LIGHT_TEXT}
        style={{
          fontSize: "0.8rem",
          marginTop: "0.5rem",
          marginRight: "auto",
          marginLeft: "auto",
          textAlign: "center",
          paddingLeft: "1rem",
          paddingRight: "1rem",
        }}
      >
        Dreamfolio is in beta stage. Please report any bugs encountered to{" "}
        <a href="mailto:contact@comparemarketcap.com">
          contact@comparemarketcap.com
        </a>
      </SmallText>

      {pageMode === 0 ? (
        <DreamfolioLanding
          solWallet={solWallet}
          ethWallet={ethWallet}
          tronWallet={tronWallet}
          setEthWallet={setEthWallet}
          setSolWallet={setSolWallet}
          setTronWallet={setTronWallet}
          setWallet={setWallet}
          handleClick={handleClick}
        />
      ) : isFetching ||
        dreamfolioData === null ||
        cookieLoad ||
        status !== "success" ? (
        <Column
          width="100%"
          height={"20rem"}
          align="center"
          justify="center"
          gap="1.5rem"
        >
          <Paragraph weight={textSizes.fontWeights.BOLD}>
            Fetching wallet...
          </Paragraph>
          <Loader />
        </Column>
      ) : (
        <>
          <DreamfolioMobileContainer>
            <MobileCurrentWallets
              handleClick={handleClick}
              solWallet={solWallet}
              ethWallet={ethWallet}
              tronWallet={tronWallet}
              setSolWallet={setSolWallet}
              setEthWallet={setEthWallet}
              setTronWallet={setTronWallet}
            />

            <AmountSection
              wallet={wallet}
              currentAmount={currentAmount}
              dreamAmount={dreamAmount}
              coinAmountsInUSD={coinAmountsInUSD}
              isCompare={isCompare}
              newWalletHandler={newWalletHandler}
            />
            <Column>
              {coinAmountsInUSD.find((item) => item.edited === true) &&
                isCompare && (
                  <TransparentButton
                    style={{ position: "absolute", bottom: "3.5rem" }}
                    onClick={() => resetComparisons()}
                    color={theme.colors.LIGHT_TEXT}
                  >
                    Reset Your Comparisons
                  </TransparentButton>
                )}
              <DreamfolioSwitch
                isCompare={isCompare}
                setIsCompare={setIsCompare}
              />
            </Column>

            <Column width="100%" align="center" gap="1.5rem">
              <SmallText>Your Coins</SmallText>
              <DreamfolioList
                filter={filter}
                nextFilter={nextFilter}
                setCoinAmountsInUSD={setCoinAmountsInUSD}
                currentAmount={currentAmount}
                data={data}
                isCompare={isCompare}
                dreamfolioData={dreamfolioData}
                dropdownData={portfolioList}
                setDreamfolioData={setDreamfolioData}
                resetComparisons={resetComparisons}
                setFilter={setFilter}
              />
            </Column>
          </DreamfolioMobileContainer>

          <DreamfolioDesktopContainer>
            <DreamfolioTableDesktop
              setCoinAmountsInUSD={setCoinAmountsInUSD}
              currentAmount={currentAmount}
              dreamAmount={dreamAmount}
              data={data}
              dropdownData={portfolioList}
              coinAmountsInUSD={coinAmountsInUSD}
              isCompare={isCompare}
              dreamfolioData={dreamfolioData}
              setDreamfolioData={setDreamfolioData}
              setIsCompare={setIsCompare}
              resetComparisons={resetComparisons}
              wallet={wallet}
              setWallet={setWallet}
              newWalletHandler={newWalletHandler}
              ethWallet={ethWallet}
              solWallet={solWallet}
              tronWallet={tronWallet}
              handleClick={handleClick}
              setEthWallet={setEthWallet}
              setSolWallet={setSolWallet}
              setTronWallet={setTronWallet}
              setFilter={setFilter}
              filter={filter}
            />
          </DreamfolioDesktopContainer>
        </>
      )}
    </DreamfolioContainer>
  );
}

export const DreamfolioMobileContainer = styled(Column)`
  width: 100%;
  padding-top: 1rem;
  gap: 0.5rem;
  display: flex;
  @media (min-width: 70rem) {
    display: none;
  }
`;

export const DreamfolioDesktopContainer = styled(Column)`
  width: 100%;
  display: flex;
  gap: 1.5rem;
  @media (max-width: 70rem) {
    display: none;
  }

  .coin-row {
    .coin-visibility-button {
      display: none;
    }
    &:hover {
      .coin-visibility-button {
        display: block;
      }
    }
  }
`;

export const DreamfolioContainer = styled(Container)`
  position: relative;
  max-width: 80rem;
  margin: 0 auto;

  z-index: 1;

  .dreamfolio-header {
    font-size: 2rem;
    font-weight: bold;
    margin-top: 5rem;
  }

  .dreamfolio-input-container {
    &:hover {
      opacity: 1 !important;
    }

    width: 100%;
    background-color: rgba(255, 255, 255, 0.07);
    border: ${borderWidths.SMALL} solid rgba(255, 255, 255, 0.2);
    border-radius: ${borderRads.SMALL};
    height: 2.75rem;
    padding: 0 ${paddings.MID};
  }

  @media (min-width: 70rem) {
    .dreamfolio-input-container {
      max-width: 40rem;
    }
  }

  @media (max-width: 70rem) {
    .dreamfolio-header {
      font-size: 1.2rem;
      font-weight: bold;
      margin-top: 0.6rem;
    }
  }
`;

function AmountSection({
  wallet,
  currentAmount,
  dreamAmount,
  coinAmountsInUSD,
  isCompare,
  newWalletHandler,
}) {
  const [localWallet, setLocalWallet] = useState(wallet);

  const handleNewWallet = (wallet) => {
    newWalletHandler(wallet);
  };

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "start",
        position: "relative",
        gap: "2rem",
        height: "4rem",
        marginTop: "0.5rem",
        marginBottom: "2rem",
      }}
    >
      {coinAmountsInUSD.find((item) => item.edited === true) && isCompare ? (
        <FullRow>
          <Column width="100%" align="center" gap="0.5rem">
            <SmallText>Current Amount</SmallText>
            <BigText>${graphFormatter(currentAmount)}</BigText>
          </Column>
          <Column width="100%" align="center" gap="0.5rem">
            <SmallText>Dream Amount</SmallText>
            <BigText
              color={({ theme }) =>
                dreamAmount >= currentAmount
                  ? theme.colors.GREEN
                  : theme.colors.RED
              }
            >
              ${graphFormatter(dreamAmount)}
            </BigText>
          </Column>
        </FullRow>
      ) : (
        <Column width="100%" align="center" gap="0.5rem">
          <SmallText>Current Amount</SmallText>
          <Title size="3rem">${graphFormatter(currentAmount)}</Title>
        </Column>
      )}
    </div>
  );
}

function DreamfolioLanding({
  ethWallet,
  setEthWallet,
  solWallet,
  setSolWallet,
  tronWallet,
  setTronWallet,
  wallet,
  setWallet,
  handleClick,
}) {
  return (
    <Column width="100%" style={{ marginTop: "3rem" }} gap="1.5rem">
      <ShowOnMobileWrapper>
        <Column
          width="100%"
          align="center"
          gap="0.5rem"
          style={{ opacity: "0.2" }}
        >
          <SmallText>Current Amount</SmallText>
          <BigText size={textSizes.fontSizes.XLARGE}>$256.12</BigText>
        </Column>
      </ShowOnMobileWrapper>

      <Paragraph className="dreamfolio-header">
        Track your wallets and compare your coins.
      </Paragraph>

      <Column
        style={{ marginTop: "1rem" }}
        width="95%"
        align="center"
        gap="1rem"
      >
        <FullRow gap="1rem" verticalOnMobile>
          <Column align="start" gap="0.2rem" width="100%">
            <SmallText
              weight={textSizes.fontWeights.BOLD}
              size={textSizes.fontSizes.SMALLER}
              color={theme.colors.LIGHT_TEXT}
            >
              BNB - ETH - BASE
            </SmallText>
            <Row className="dreamfolio-input-container">
              <input
                autoComplete="off"
                style={{
                  width: "100%",
                  backgroundColor: "transparent",
                  height: "100%",
                  border: "none",
                  outline: "none",
                }}
                placeholder="Enter wallet address"
                onChange={(e) => setEthWallet(e.target.value)}
                value={ethWallet}
              />
            </Row>
          </Column>

          <Column align="start" gap="0.2rem" width="100%">
            <SmallText
              weight={textSizes.fontWeights.BOLD}
              size={textSizes.fontSizes.SMALLER}
              color={theme.colors.LIGHT_TEXT}
            >
              SOLANA
            </SmallText>
            <Row className="dreamfolio-input-container">
              <input
                autoComplete="off"
                style={{
                  width: "100%",
                  backgroundColor: "transparent",
                  height: "100%",
                  border: "none",
                  outline: "none",
                }}
                placeholder="Enter wallet address"
                onChange={(e) => setSolWallet(e.target.value)}
                value={solWallet}
              />
            </Row>
          </Column>

          <Column align="start" gap="0.2rem" width="100%">
            <SmallText
              weight={textSizes.fontWeights.BOLD}
              size={textSizes.fontSizes.SMALLER}
              color={theme.colors.LIGHT_TEXT}
            >
              TRON
            </SmallText>
            <Row className="dreamfolio-input-container">
              <input
                autoComplete="off"
                style={{
                  width: "100%",
                  backgroundColor: "transparent",
                  height: "100%",
                  border: "none",
                  outline: "none",
                }}
                placeholder="Enter wallet address"
                onChange={(e) => setTronWallet(e.target.value)}
                value={tronWallet}
              />
            </Row>
          </Column>
        </FullRow>

        <PrimaryButton
          style={
            ethWallet || solWallet || tronWallet
              ? { opacity: "1", cursor: "pointer" }
              : { opacity: "0.4", cursor: "none" }
          }
          isfull="true"
          onClick={() => {
            if (ethWallet || solWallet || tronWallet) {
              handleClick(ethWallet, solWallet, tronWallet);
            }
          }}
        >
          <SmallText weight={textSizes.fontWeights.SEMI_BOLD}>TRACK</SmallText>
        </PrimaryButton>
      </Column>

      <HideOnMobileWrapper style={{ marginRight: "auto" }}>
        <Column
          width="100%"
          align="start"
          gap="0.5rem"
          style={{ opacity: "0.2", marginTop: "2rem" }}
        >
          <SmallText>Current Amount</SmallText>
          <Header size={"3rem"}>$256.32</Header>
        </Column>
      </HideOnMobileWrapper>

      <FakeCoinList />
      <FakeDreamfolioTableDesktop />
    </Column>
  );
}

const StyledFakeCoinList = styled(Column)`
  width: 100%;
  gap: 1rem;
  margin-top: 2rem;

  @media (min-width: 70rem) {
    display: none;
  }
`;

function FakeCoinList() {
  const coins = useContext(CoinsContext);

  return (
    <StyledFakeCoinList width="100%" gap="1rem" style={{ marginTop: "2rem" }}>
      <SmallText color={({ theme }) => theme.colors.LIGHT_TEXT}>
        Enter your wallet to see your coins
      </SmallText>
      <Column width="100%" align="center" gap="1rem">
        <DreamfolioCoin
          isFake={true}
          coin={{
            id: 1,
            image: "https://cryptologos.cc/logos/bitcoin-btc-logo.png",
            symbol: "btc",
            name: "Bitcoin",
            amountInUSD: 812,
          }}
          isCompare={false}
        />
        <DreamfolioCoin
          isFake={true}
          coin={{
            id: 2,
            image: "https://cryptologos.cc/logos/ethereum-eth-logo.png",
            symbol: "eth",
            name: "Ethereum",
            amountInUSD: 496,
          }}
          isCompare={false}
        />
        <DreamfolioCoin
          isFake={true}
          coin={{
            id: 3,
            image: "https://cryptologos.cc/logos/cardano-ada-logo.png",
            symbol: "ada",
            name: "Cardano",
            amountInUSD: 98,
          }}
          isCompare={false}
        />
        <DreamfolioCoin
          isFullFake={true}
          coin={{
            id: 4,
            image: "https://cryptologos.cc/logos/cardano-ada-logo.png",
            symbol: "ada",
            name: "Cardano",
            amountInUSD: 98,
          }}
          isCompare={false}
        />
      </Column>
    </StyledFakeCoinList>
  );
}

export function DreamfolioSwitch({ isCompare, setIsCompare }) {
  return (
    <div
      style={{
        height: "3rem",
        margin: "0rem auto 0 auto",
        width: "fit-content",
        transition: "0.4s",
      }}
    >
      <Row
        gap="0.75rem"
        style={{
          backgroundColor: "rgba(255,255,255,0.075)",
          padding: `${paddings.LOW} ${paddings.MID}  ${paddings.LOW} ${paddings.MID}`,
          borderRadius: borderRads.SMALL,
        }}
      >
        <SmallText style={{ whiteSpace: "nowrap" }}>Compare Mode</SmallText>
        <Row>
          <Toggle isCompare={isCompare} setIsCompare={setIsCompare} />
        </Row>
      </Row>
    </div>
  );
}

function Toggle({ isCompare, setIsCompare }) {
  function handleClick() {
    setIsCompare((prev) => !prev);
  }

  return (
    <StyledToggle onClick={() => handleClick()}>
      <StyledToggleInside isOn={!isCompare} />
      <StyledToggleInside isOn={isCompare} />
    </StyledToggle>
  );
}

const StyledToggleInside = styled.div`
  width: 50%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);

  border-radius: ${borderRads.SMALLER};

  &:first-child {
    background-color: rgba(255, 255, 255, 0.2);
    //make left border radiuses
  }

  visibility: ${({ isOn }) => (isOn ? "visible" : "hidden")};
`;

const StyledToggle = styled.div`
  padding: 0.1rem;
  width: 2.5rem;
  height: 1.5rem;
  background-color: transparent;
  border: ${borderWidths.SMALL} solid rgba(255, 255, 255, 0.2);
  border-radius: ${borderRads.SMALL};
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;

export function MobileCurrentWallets({
  solWallet,
  ethWallet,
  tronWallet,
  setSolWallet,
  setEthWallet,
  setTronWallet,
  handleClick,
}) {
  const localSol = solWallet || "";
  const localEth = ethWallet || "";
  const localTron = tronWallet || "";
  const [localSolWallet, setLocalSolWallet] = useState(localSol);
  const [localEthWallet, setLocalEthWallet] = useState(localEth);
  const [localTronWallet, setLocalTronWallet] = useState(localTron);

  const [expanded, setExpanded] = useState(false);

  const solInputRef = useRef(null);
  const ethInputRef = useRef(null);
  const tronInputRef = useRef(null);

  const [hasChanged, setHasChanged] = useState(false);

  useEffect(() => {
    setLocalSolWallet(solWallet);
  }, [solWallet]);

  useEffect(() => {
    setLocalEthWallet(ethWallet);
  }, [ethWallet]);

  useEffect(() => {
    setLocalTronWallet(tronWallet);
  }, [tronWallet]);

  return (
    <StyledMobileCurrentWallets expanded={expanded}>
      <FullRow justify="space-between">
        <SmallText
          onClick={() => {
            setExpanded((prev) => !prev);
          }}
          style={{
            opacity: "0.6",
            marginRight: "auto",
            marginLeft: "0.2rem",
            width: "100%",
            textAlign: "start",
            cursor: "pointer",
          }}
        >
          Wallets
        </SmallText>
        {hasChanged && (
          <button
            style={{
              backgroundColor: "transparent",
              border: "none",
              borderRadius: borderRads.SMALL,
            }}
            onClick={() => {
              handleClick(
                localEthWallet,
                localSolWallet,
                localTronWallet,
                true
              );
            }}
          >
            <SmallText>TRACK</SmallText>
          </button>
        )}
        <TransparentButton
          onClick={() => {
            setExpanded((prev) => !prev);
          }}
        >
          <Icon size={"1.4rem"}>
            {expanded ? <MdExpandLess /> : <MdExpandMore />}
          </Icon>
        </TransparentButton>
      </FullRow>
      {expanded && (
        <Column width="100%" align="start" gap="0.4rem">
          <SmallText
            weight={textSizes.fontWeights.BOLD}
            style={{
              marginLeft: "0.4rem",
              whiteSpace: "nowrap",
              opacity: "0.8",
              marginTop: "0.2rem",
            }}
            size={"0.6rem"}
          >
            ETH - BNB - BASE
          </SmallText>
          <FullRow
            gap="0.2rem"
            style={
              localEthWallet !== localEth || !localEth
                ? {
                    outline: `${borderWidths.SMALL} solid rgba(255,255,255,0.1)`,
                    borderRadius: borderRads.SMALL,
                  }
                : {
                    borderRadius: borderRads.SMALL,
                    outline: `${borderWidths.SMALL} solid rgba(255,255,255,0.1)`,
                  }
            }
          >
            <MobileCurrentWalletInput
              ref={ethInputRef}
              onChange={(e) => {
                setLocalEthWallet(e.target.value);
                setHasChanged(true);
              }}
              value={localEthWallet}
              isActive={localEthWallet && localEthWallet.length > 0}
              placeholder="ETH - BNB - BASE"
            />
            {localEthWallet && localEthWallet.length > 0 && (
              <BasicModal
                ethWallet={ethWallet}
                solWallet={solWallet}
                tronWallet={tronWallet}
                setEthWallet={setEthWallet}
                setSolWallet={setSolWallet}
                setTronWallet={setTronWallet}
                setLocalEthWallet={setLocalEthWallet}
                setLocalSolWallet={setLocalSolWallet}
                setLocalTronWallet={setLocalTronWallet}
                handleClick={handleClick}
                wallet={"eth"}
              />
            )}
          </FullRow>
          <SmallText
            weight={textSizes.fontWeights.BOLD}
            style={{
              marginLeft: "0.4rem",
              whiteSpace: "nowrap",
              opacity: "0.8",
              marginTop: "0.2rem",
            }}
            size={"0.6rem"}
          >
            SOL
          </SmallText>
          <FullRow
            gap="0.2rem"
            style={
              localSolWallet !== localSol || !localSol
                ? {
                    outline: `${borderWidths.SMALL} solid rgba(255,255,255,0.1)`,
                    borderRadius: borderRads.SMALL,
                  }
                : {
                    borderRadius: borderRads.SMALL,
                    outline: `${borderWidths.SMALL} solid rgba(255,255,255,0.1)`,
                  }
            }
          >
            <MobileCurrentWalletInput
              ref={solInputRef}
              onChange={(e) => {
                setLocalSolWallet(e.target.value);
                setHasChanged(true);
              }}
              value={localSolWallet}
              isActive={localSolWallet && localSolWallet.length > 0}
              placeholder="SOLANA"
            />
            {localSolWallet && localSolWallet.length > 0 && (
              <BasicModal
                ethWallet={ethWallet}
                solWallet={solWallet}
                setEthWallet={setEthWallet}
                setSolWallet={setSolWallet}
                setTronWallet={setTronWallet}
                setLocalEthWallet={setLocalEthWallet}
                setLocalSolWallet={setLocalSolWallet}
                setLocalTronWallet={setLocalTronWallet}
                handleClick={handleClick}
                wallet={"sol"}
              />
            )}
          </FullRow>
          <SmallText
            weight={textSizes.fontWeights.BOLD}
            style={{
              marginLeft: "0.4rem",
              whiteSpace: "nowrap",
              opacity: "0.8",
              marginTop: "0.2rem",
            }}
            size={"0.6rem"}
          >
            TRON
          </SmallText>
          <FullRow
            gap="0.2rem"
            style={
              localTronWallet !== localTron || !localTron
                ? {
                    outline: `${borderWidths.SMALL} solid rgba(255,255,255,0.1)`,
                    borderRadius: borderRads.SMALL,
                  }
                : {
                    borderRadius: borderRads.SMALL,
                    outline: `${borderWidths.SMALL} solid rgba(255,255,255,0.1)`,
                  }
            }
          >
            <MobileCurrentWalletInput
              ref={tronInputRef}
              onChange={(e) => {
                setLocalTronWallet(e.target.value);
                setHasChanged(true);
              }}
              value={localTronWallet}
              isActive={localTronWallet && localTronWallet.length > 0}
              placeholder="TRON"
            />
            {localTronWallet && localTronWallet.length > 0 && (
              <BasicModal
                ethWallet={ethWallet}
                solWallet={solWallet}
                setEthWallet={setEthWallet}
                setSolWallet={setSolWallet}
                setTronWallet={setTronWallet}
                setLocalEthWallet={setLocalEthWallet}
                setLocalSolWallet={setLocalSolWallet}
                setLocalTronWallet={setLocalTronWallet}
                handleClick={handleClick}
                wallet={"tron"}
              />
            )}
          </FullRow>
        </Column>
      )}
    </StyledMobileCurrentWallets>
  );
}

const StyledMobileCurrentWallets = styled(Column)`
  padding: ${paddings.MID};
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: ${borderRads.SMALL};

  max-width: 25rem;

  width: 95%;
  gap: 0.5rem;

  //if expanded, add a border
  border: ${({ expanded }) =>
    expanded ? `${borderWidths.SMALL} solid rgba(255,255,255,0.1)` : "none"};

  @media (min-width: 70rem) {
    position: absolute;
    top: 2rem;
    right: 0;
    z-index: 10000 !important;

    background-color: #331a46;
    //blur
    backdrop-filter: blur(20px);
    //add webkiit too
    -webkit-backdrop-filter: blur(20px);
  }
`;

const MobileCurrentWalletInput = styled.input`
  font-size: 0.8rem;
  width: 95%;
  border-radius: ${borderRads.SMALL};
  padding: 0.5rem;
  opacity: 0.5;

  //if the prop named isActive is true, hide all borders and background
  background-color: rgba(255, 255, 255, 0);
  border: none;

  &:focus {
    outline: none;
  }
  &:active {
    outline: none;
  }

  @media (max-width: 70rem) {
    width: 90%;
  }
`;

const StyledDesktopCurrentWallets = styled(Column)`
  width: 100%;
  gap: 1rem;
  margin-top: 2rem;

  @media (max-width: 70rem) {
    display: none;
  }
`;

const style = {
  position: "fixed",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "20rem",
  bgcolor: theme.colors.OLDLEVEL2,
  border: theme.colors.OLDLEVEL,
  borderRadius: borderRads.SMALL,
  boxShadow: 24,
  p: 3,
  zIndex: 1,
};

export function BasicModal({
  wallet,
  setEthWallet,
  setSolWallet,
  setTronWallet,
  handleClick,
  setLocalEthWallet,
  setLocalSolWallet,
  setLocalTronWallet,
  ethWallet,
  solWallet,
  tronWallet,
}) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  let ethWalletLocal = ethWallet;
  let solWalletLocal = solWallet;
  let tronWalletLocal = tronWallet;

  return (
    <div>
      <TransparentButton
        onClick={() => {
          handleOpen();
        }}
      >
        <IoMdClose />
      </TransparentButton>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ClickAwayListener onClickAway={handleClose}>
          <Box sx={style}>
            <Column align="center" gap="1rem">
              <SmallText style={{ margin: "0 auto" }}>
                Are you sure to remove the{" "}
                {wallet === "eth"
                  ? "ETH - BNB - BASE"
                  : wallet === "tron"
                  ? "TRON"
                  : "SOLANA"}{" "}
                wallet?
              </SmallText>
              <Row justify="center" gap="1rem">
                <PrimaryButton
                  style={{
                    padding: "0.5rem 1rem",
                  }}
                  onClick={() => {
                    if (wallet === "eth") {
                      setEthWallet("");
                      setLocalEthWallet("");
                      ethWalletLocal = "";
                    } else if (wallet === "sol") {
                      setSolWallet("");
                      setLocalSolWallet("");
                      solWalletLocal = "";
                    } else if (wallet === "tron") {
                      setTronWallet("");
                      setLocalTronWallet("");
                      tronWalletLocal = "";
                    }
                    handleClick(
                      ethWalletLocal,
                      solWalletLocal,
                      tronWalletLocal
                    );
                    handleClose();
                  }}
                  backgroundColor={theme.colors.SECONDARY}
                  outlineColor={theme.colors.SECONDARY}
                >
                  Yes
                </PrimaryButton>
                <OutlineButton
                  borderColor={theme.colors.SECONDARY}
                  style={{
                    padding: "0.5rem 1rem",
                  }}
                  onClick={handleClose}
                >
                  No
                </OutlineButton>
              </Row>
            </Column>
          </Box>
        </ClickAwayListener>
      </Modal>
    </div>
  );
}

function addChainField(coins) {
  // Create a map to track coin IDs and their occurrences
  const coinMap = new Map();

  // Iterate through the coins array to populate the map
  coins.forEach((coin) => {
    if (coinMap.has(coin.id)) {
      coinMap.get(coin.id).push(coin);
    } else {
      coinMap.set(coin.id, [coin]);
    }
  });

  // Iterate through the map and add the "chain" field for coins with the same id
  coinMap.forEach((coinList) => {
    if (coinList.length > 1) {
      const blockChains = coinList.map((coin) => coin.blockChain);
      coinList.forEach((coin) => {
        coin.chain = coin.blockchain;
      });
    }
  });

  return coins;
}

// Example usage
const coins = [
  { id: "1", name: "Coin A", blockChain: "Ethereum" },
  { id: "2", name: "Coin B", blockChain: "Binance" },
  { id: "1", name: "Coin A", blockChain: "Polygon" },
  { id: "3", name: "Coin C", blockChain: "Solana" },
];
