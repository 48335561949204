import { getDay } from "date-fns";
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { SmallText } from "../texts.styled";
import { borderRads, borderWidths } from "../sizes";
import Loader from "../misc/loader";
import { Column } from "../containers/flexbox.styled";
import MultiSwitch from "../misc/multiSwitch";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import "@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css";
import "react-calendar/dist/Calendar.css";

export const DateRangePickerWrapper = ({
  dates,
  excludeDates,
  setFirstDate,
  setSecondDate,
}) => {
  return dates ? (
    <DateRangePickerComponent
      dates={dates}
      excludeDates={excludeDates}
      setFirstDate={setFirstDate}
      setSecondDate={setSecondDate}
    />
  ) : (
    <Loader isSmall={true} />
  );
};

export const DateRangePickerComponent = ({
  dates,
  excludeDates,
  setFirstDate,
  setSecondDate,
}) => {
  if (!excludeDates) excludeDates = [];

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const onChange = (dates1) => {
    const [start, end] = dates1;
    setStartDate(start);
    setFirstDate(start);
    setEndDate(end);
    setSecondDate(end);
  };

  const options = [
    {
      name: "1D",
      onClick: () => {
        setStartDate(getDateFromEpoch(dates[dates.length - 2]));
        setEndDate(getDateFromEpoch(dates[dates.length - 1]));
        setFirstDate(getDateFromEpoch(dates[dates.length - 2]));
        setSecondDate(getDateFromEpoch(dates[dates.length - 1]));
      },
      isAbsent:
        dates.includes(dates[dates.length - 2]) &&
        dates.includes(dates[dates.length - 1]),
    },
    {
      name: "1W",
      onClick: () => {
        setStartDate(getDateFromEpoch(dates[dates.length - 8]));
        setEndDate(getDateFromEpoch(dates[dates.length - 1]));
        setFirstDate(getDateFromEpoch(dates[dates.length - 8]));
        setSecondDate(getDateFromEpoch(dates[dates.length - 1]));
      },
      isAbsent:
        dates.includes(dates[dates.length - 8]) &&
        dates.includes(dates[dates.length - 1]),
    },
    {
      name: "1M",
      onClick: () => {
        setStartDate(getDateFromEpoch(dates[dates.length - 30]));
        setEndDate(getDateFromEpoch(dates[dates.length - 1]));
        setFirstDate(getDateFromEpoch(dates[dates.length - 30]));
        setSecondDate(getDateFromEpoch(dates[dates.length - 1]));
      },
      isAbsent:
        dates.includes(dates[dates.length - 30]) &&
        dates.includes(dates[dates.length - 1]),
    },
    {
      name: "3M",
      onClick: () => {
        setStartDate(getDateFromEpoch(dates[dates.length - 90]));
        setEndDate(getDateFromEpoch(dates[dates.length - 1]));
        setFirstDate(getDateFromEpoch(dates[dates.length - 90]));
        setSecondDate(getDateFromEpoch(dates[dates.length - 1]));
      },
      isAbsent: dates[dates.length - 90] && dates[dates.length - 1],
    },
  ];

  //create a useref to store the datepicker
  const datepicker = React.useRef();

  //for every input in the datepicker, make autocomplete and autofocus false
  useEffect(() => {
    //  get all the inputs under the element in the class react-daterange-picker__inputGroup, and set autocomplete and autofocus to false, also prevent mobile keyboard from popping up and make them readonly
    const inputs = document.querySelectorAll(
      ".react-daterange-picker__inputGroup input"
    );
    inputs.forEach((input) => {
      input.autocomplete = "off";
      input.autofocus = false;
      input.readOnly = true;
    });
  }, [datepicker]);

  const preventTouchScroll = (e) => {
    e.preventDefault();
  };

  // Attach event listener on component mount
  useEffect(() => {
    const datePickerElement = datepicker.current;

    if (datePickerElement) {
      datePickerElement.addEventListener("touchmove", preventTouchScroll, {
        passive: false,
      });
    }

    // Cleanup the event listener on component unmount
    return () => {
      if (datePickerElement) {
        datePickerElement.removeEventListener("touchmove", preventTouchScroll);
      }
    };
  }, [datepicker]);

  return (
    <Column gap="0.5rem">
      <DateRangePicker
        ref={datepicker}
        monthPlaceholder="mm"
        yearPlaceholder="yy"
        dayPlaceholder="dd"
        autoFocus={false}
        onChange={onChange}
        value={[startDate, endDate]}
        maxDate={new Date()}
        minDate={new Date(dates[0])}
        shouldCloseCalendar={({ reason }) => reason !== "select"}
      />

      <MultiSwitch
        options={options.filter((item) => item.isAbsent)}
        isDreamfolio={true}
        isRoi={true}
      />
    </Column>
  );
};

const getDateFromEpoch = (epoch) => {
  let date = new Date(epoch);
  return date;
};
