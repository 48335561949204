import styled from "styled-components";
import { Autoplay, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { Column, FullRow, Row } from "./containers/flexbox.styled";
import { Header, Paragraph, SmallText } from "./texts.styled";
import { borderRads, borderWidths, textSizes } from "./sizes";
import { getRecentlyListedHome, getRecentlyListedPage } from "./stream";
import { useQuery } from "@tanstack/react-query";
import { Icon } from "./icon.styled";
import { MdKeyboardArrowRight } from "react-icons/md";
import { RouteLink } from "../routing/routeLink";
import { VerticalSeperator } from "./containers/boxes.styled";
import React from "react";

//emptry react component
export default function RecentlyListedSquare() {
  const { data, status } = useQuery(
    ["recently-listed-home"],
    () => getRecentlyListedPage(),
    {
      onSuccess: (data) => {},
    }
  );

  return (
    <RecentlyListedSquareStyled>
      <Swiper
        modules={[Autoplay, Pagination]}
        pagination={{ clickable: true }}
        loop={true}
        autoplay={{
          delay: 5000,
        }}
        className="mySwiper rcSquareSwiper"
      >
        {status === "success" && (
          <React.Fragment>
            <SwiperSlide>
              <RecentlyListedSquareSwiperStyled>
                {data.slice(0, 6).map((item, index) => (
                  <Column justify="center" gap="0rem" width="100%" key={index}>
                    <div style={{ marginTop: "0.2rem" }}>
                      <RouteLink to={`/coin/${item.id}`} key={index}>
                        <img src={item.image} alt={item.name} />{" "}
                      </RouteLink>
                    </div>
                    <Paragraph
                      weight={textSizes.fontWeights.BOLD}
                      color="rgba(255,255,255,0.8)"
                      style={{
                        marginTop: "0.7rem",
                        width: "5.5rem",
                        textAlign: "center",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        fontSize: "0.9rem",
                      }}
                    >
                      {item.symbol}
                    </Paragraph>
                  </Column>
                ))}
              </RecentlyListedSquareSwiperStyled>
            </SwiperSlide>
            <SwiperSlide>
              <RecentlyListedSquareSwiperStyled>
                {data.slice(6, 12).map((item, index) => (
                  <Column justify="center" gap="0rem" width="100%" key={index}>
                    <div style={{ marginTop: "0.2rem" }}>
                      <RouteLink to={`/coin/${item.id}`} key={index}>
                        <img src={item.image} alt={item.name} />{" "}
                      </RouteLink>{" "}
                    </div>
                    <Paragraph
                      weight={textSizes.fontWeights.BOLD}
                      color="rgba(255,255,255,0.8)"
                      style={{
                        marginTop: "0.7rem",
                        width: "5.5rem",
                        textAlign: "center",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        fontSize: "0.9rem",
                      }}
                    >
                      {item.symbol}
                    </Paragraph>
                  </Column>
                ))}
              </RecentlyListedSquareSwiperStyled>
            </SwiperSlide>
          </React.Fragment>
        )}
      </Swiper>
    </RecentlyListedSquareStyled>
  );
}

const RecentlyListedSquareStyled = styled(Column)`
  align-items: start;
  width: 100%;
  gap: 1rem;

  @media (min-width: 70rem) {
    display: none;
  }

  & > *:nth-child(2) {
    max-width: 50rem;
    margin: 0 auto;
  }
`;

const RecentlyListedSquareSwiperStyled = styled.div`
  //make a grid with 3 columns and 2 rows
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 0.75rem;
  width: 96%;
  margin: 0 auto;

  img {
    width: 3.4rem;
    height: 3.4rem;
    border-radius: 10rem;
    outline: ${borderWidths.LARGE} solid ${({ theme }) => theme.colors.LEVEL2};
  }

  & > * {
    width: 100%;
    height: 6.5rem;
    background-color: ${({ theme }) => theme.colors.SECONDARY};
    border-radius: ${borderRads.MEDIUM};
    border: ${borderWidths.SMALL} solid ${({ theme }) => theme.colors.LEVEL2};
  }
`;
