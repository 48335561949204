import styled from "styled-components";
import { Column, FullRow } from "../containers/flexbox.styled";
import { borderRads } from "../sizes";

export const BasicStats = styled(FullRow)`
  gap: 1rem;
  @media (max-width: 70rem) {
    display: none;
  }
`;

export const BasicStat = styled(Column)`
  width: 100%;
  height: 100%;
  padding: 0.5rem 1rem;
  gap: 0.5rem !important;
  border-radius: ${borderRads.SMALL};
  background-color: ${({ theme }) => theme.colors.LEVEL};

  @media (max-width: 70rem) {
    background-color: ${({ theme }) => theme.colors.SECONDARY};
    padding: 0.5rem;

    & > * {
      font-size: 1rem;
    }
  }
`;
