import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { useNavigate, useRouteError } from "react-router-dom";
import { PrimaryButton } from "../components/buttons/buttons.styled";
import { Column } from "../components/containers/flexbox.styled";
import { StyledErrorPage, StyledErrorPageMessage } from "../components/errorPage.styled";
import Footer from "../components/footer";
import { GlobalStyle } from "../components/globalStyle.styled";
import Navbar from "../components/navbar/navbar";
import { Header, Title } from "../components/texts.styled";
import { Theme } from "../components/theme.styled";

export default function ErrorPage() {

    const queryClient = new QueryClient()

    const error = useRouteError();

    const navigate = useNavigate()

    return (
        <QueryClientProvider client={queryClient}>
            <Theme>
                <GlobalStyle/>
                <StyledErrorPage>
                    <Navbar/>
                    <StyledErrorPageMessage>
                        <img alt="error" src={"/images/error-image.png"} />
                        <Column gap={"1rem"}>
                            <Title>
                                404
                            </Title>
                            <Header>
                                Seems like you shouldn't be here.
                            </Header>
                            <PrimaryButton onClick={() => navigate("/")}>Go Compare Something</PrimaryButton>
                        </Column>
                    </StyledErrorPageMessage>
                    <Footer/>
                </StyledErrorPage>
            </Theme>
        </QueryClientProvider>
    );
}